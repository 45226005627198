import { useSelector, useDispatch } from 'react-redux'
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect'

import { AppState } from 'store'
import { experimentQuery } from 'store/experiments/selectors'
import { fetchExperimentsForQueryThunk } from 'store/experiments/thunk'
import { ExperimentQuery } from 'store/experiments/types'

/**
 * useExperiment will check if a user is enrolled in an experiment case. If the
 * user is not enrolled in the experiment, they will automatically be assigned
 * a case and this hook will return their enrollment after fetching.
 *
 * Because of the quirkiness of the experiments table in products, the querying
 * for this is slightly more verbose than you would expect. You must provide one
 * of the following:
 *
 * 1. An experiment and case ID
 * 2. An experiment label and a case label
 * 3. An experiment label and a case descriptor
 *
 * @returns {boolean} Wether or not a user is enrolled in the experiment.
 */
export default function useExperiment(query: ExperimentQuery): boolean {
  const dispatch = useDispatch()
  const [userIsInCase, experimentNeedsLoading] = useSelector<
    AppState,
    ReturnType<typeof experimentQuery>
  >((state) => experimentQuery(state.experiments, query))

  // Fetch the experiment if it isn't in the store yet
  useDeepCompareEffect(() => {
    if (experimentNeedsLoading) {
      dispatch(fetchExperimentsForQueryThunk(query))
    }
  }, [experimentNeedsLoading, query])

  return userIsInCase
}
