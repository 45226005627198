import { UpsellsActions } from './actions'

import {
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
  HashMap,
} from '@talentinc/state-utils'
import { ItemTypes } from 'store/items/types'

export interface UpsellItem {
  id: number
  item_id: number
  package_id: number
  quantity: number
  name: string
  item_type_key: ItemTypes
}

export interface Upsell {
  id: number
  name: string
  quantity: number
  plan_code: string
  price: number
  original_price: number | null
  short_pitch: string | null
  very_short_pitch: string | null
  icon_url: string | null
  created_at: Date
  purchases_today: number
  items: UpsellItem[]
  package: Package
  currency_code: string
  currency_symbol: string
  selected: boolean
  oms_fulfillable_items: number
  upsell_priority: number
  bundle_groups: Bundles[]
  bundle: Bundles | null
}
export interface GroupedUpsells {
  last_chance: Upsell[]
  other: Upsell[]
}

export interface CartUpsellProps {
  primary: Upsell[]
  secondary: Upsell[]
  order_enhancement: Upsell[]
}

export interface Package {
  id: number
  name: string
  description: string
  created_at: Date
  updated_at: Date
}

export interface UpdateQuantityPayload {
  quantity: number
}

export interface AddToCartPayload extends UpdateQuantityPayload {
  sku: string
}

export interface CartItem {
  id: number
  sku_id: number
  site_id: number
  quantity: number
  created_at: Date
  updated_at: Date
}

export interface CartItemWithUpsell extends CartItem {
  upsell: Upsell
}

export enum Bundles {
  NextStep = 'next-step',
  AccelerateSearch = 'accelerate-search',
  DigitallySavvy = 'digitally-savvy',
  WinInterview = 'win-interview',
  WriterExpertise = 'writer-expertise',
  GetWordOut = 'get-word-out',
  Linkedin = 'linkedin',
  ExpertConnect = 'expert-connect',
}

export const catalogBundles = new Set([
  Bundles.AccelerateSearch,
  Bundles.NextStep,
  Bundles.DigitallySavvy,
  Bundles.WinInterview,
  Bundles.WriterExpertise,
])

export const validBundles = new Set(Object.values(Bundles))

export const bundlePriorities: HashMap<number> = {
  [Bundles.NextStep]: 5,
  [Bundles.DigitallySavvy]: 4,
  [Bundles.ExpertConnect]: 4,
  [Bundles.AccelerateSearch]: 2,
  [Bundles.WinInterview]: 3,
  [Bundles.WriterExpertise]: 3,
  [Bundles.GetWordOut]: 2,
}

export interface UpsellsState {
  upsells: HashMap<Upsell>
  byBundle: HashMap<number[]>
  byBundleGroup: HashMap<number[]>
  byPlanCode: HashMap<number>
  cart: HashMap<CartItem>
  shoppingCartOpen: boolean
  emailQueryParams: HashMap<string>
  cartItemsBySkuID: HashMap<number>
  meta: {
    [UpsellsActions.FETCH_UPSELLS]: LoadingLoadedErrorState
    [UpsellsActions.FETCH_CART_ITEMS]: LoadingLoadedErrorState
    [UpsellsActions.ADD_ITEM_TO_CART]: LoadingLoadedErrorState
    [UpsellsActions.REMOVE_ITEM_FROM_CART]: HashMap<LoadingLoadedErrorState>
    [UpsellsActions.UPDATE_CART_ITEM_QUANTITY]: HashMap<LoadingLoadedErrorState>
  }
}

export const initialUpsellsState: UpsellsState = {
  upsells: {},
  byBundle: {},
  byBundleGroup: {},
  byPlanCode: {},
  cart: {},
  cartItemsBySkuID: {},
  shoppingCartOpen: false,
  emailQueryParams: {},
  meta: {
    [UpsellsActions.FETCH_UPSELLS]: { ...initialLoadedLoadingErrorState },
    [UpsellsActions.FETCH_CART_ITEMS]: { ...initialLoadedLoadingErrorState },
    [UpsellsActions.ADD_ITEM_TO_CART]: { ...initialLoadedLoadingErrorState },
    [UpsellsActions.REMOVE_ITEM_FROM_CART]: {},
    [UpsellsActions.UPDATE_CART_ITEM_QUANTITY]: {},
  },
}
