import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'

interface Props {
  avatarInitials: string
  readAt?: Date | null
  sender?: string
  outbound?: boolean
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    anchorOriginTopRightCircle: {
      right: '13%',
    },
    avatarUser: {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
    },
    badge: {
      border: '1px solid white',
      borderRadius: '100%',
      height: '.88em',
      minWidth: '.88em',
    },
    unread: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  })
)

const MessageAvatar: React.FC<Props> = ({
  avatarInitials,
  readAt = null,
  sender = '',
  outbound = true,
  className = '',
}) => {
  const classes = useStyles()
  const showBadge = !readAt && !outbound

  return (
    <Badge
      classes={{
        anchorOriginTopRightCircle: classes.anchorOriginTopRightCircle,
        badge: classes.badge,
      }}
      color="primary"
      invisible={!showBadge}
      overlap="circle"
      variant="dot"
    >
      <Avatar
        className={clsx(
          sender === 'user' && classes.avatarUser,
          showBadge && classes.unread,
          className
        )}
      >
        {avatarInitials}
      </Avatar>
    </Badge>
  )
}

export default MessageAvatar
