import * as ram from '@talentinc/redux-axios-middleware'

import { UserIdentifier } from 'store/user/types'
import { Message, SendMessagePayload, MessageStatus } from './types'
import { ResettableInterval, CronSignature } from 'store/crons/types'
import { AppThunkAction } from 'store'
import { fetchFilesForUserAndRelatedRecords } from 'store/files/thunks'

export enum MessageActions {
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL',
  FETCH_MESSAGES_FOR_USER = 'FETCH_MESSAGES_FOR_USER',
  FETCH_MESSAGES_FOR_USER_SUCCESS = 'FETCH_MESSAGES_FOR_USER_SUCCESS',
  FETCH_MESSAGES_FOR_USER_FAIL = 'FETCH_MESSAGES_FOR_USER_FAIL',
  MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ',
  MARK_MESSAGE_AS_READ_SUCCESS = 'MARK_MESSAGE_AS_READ_SUCCESS',
  MARK_MESSAGE_AS_READ_FAIL = 'MARK_MESSAGE_AS_READ_FAIL',
}

interface SendMessage extends ram.AxiosMiddlewareActionCreator {
  type: typeof MessageActions.SEND_MESSAGE
}

export interface SendMessageSuccess
  extends ram.AxiosMiddlewareActionSuccess<Message, SendMessage> {
  type: typeof MessageActions.SEND_MESSAGE_SUCCESS
}

interface SendMessageFail extends ram.AxiosMiddlewareActionFail<SendMessage> {
  type: typeof MessageActions.SEND_MESSAGE_FAIL
}

export const sendUserMesssage = (
  userIdentifier: UserIdentifier,
  data: SendMessagePayload
): SendMessage => ({
  type: MessageActions.SEND_MESSAGE,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/messages`,
      method: 'POST',
      data: {
        ...data,
        body: `<pre>${data.body}</pre>`,
      },
    },
  },
})

interface FetchMessagesForUser extends ram.AxiosMiddlewareActionCreator {
  type: MessageActions.FETCH_MESSAGES_FOR_USER
}

export interface FetchMessagesForUserSuccess
  extends ram.AxiosMiddlewareActionSuccess<Message[], FetchMessagesForUser> {
  type: MessageActions.FETCH_MESSAGES_FOR_USER_SUCCESS
}

interface FetchMessagesForUserFail
  extends ram.AxiosMiddlewareActionFail<FetchMessagesForUser> {
  type: MessageActions.FETCH_MESSAGES_FOR_USER_FAIL
}

export const fetchMessagesForUser = (
  userIdentifier: UserIdentifier = 'me'
): FetchMessagesForUser => ({
  type: MessageActions.FETCH_MESSAGES_FOR_USER,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/messages`,
    },
  },
})

export function fetchMessagesForUserTimed({
  interval,
  initialize,
}: CronSignature): AppThunkAction<ResettableInterval> {
  return async function (dispatch, getState) {
    if (
      !getState().messages.meta.FETCH_MESSAGES_FOR_USER.isLoading &&
      !initialize
    ) {
      dispatch(fetchMessagesForUser())
      // we must also fetch files to show attachments
      dispatch(fetchFilesForUserAndRelatedRecords())
    }

    const intervalID = window.setInterval(
      () => dispatch(fetchMessagesForUser()),
      interval
    )
    return {
      intervalID,
      interval,
    }
  }
}

interface MarkMessageAsRead extends ram.AxiosMiddlewareActionCreator {
  type: MessageActions.MARK_MESSAGE_AS_READ
  messageID: number
}

interface MarkMessageAsReadSuccess
  extends ram.AxiosMiddlewareActionSuccess<MessageStatus, MarkMessageAsRead> {
  type: MessageActions.MARK_MESSAGE_AS_READ_SUCCESS
}

interface MarkMessageAsReadFail
  extends ram.AxiosMiddlewareActionFail<MarkMessageAsRead> {
  type: MessageActions.MARK_MESSAGE_AS_READ_FAIL
}

export const markMessageAsRead = (
  messageID: number,
  userIdentifier: UserIdentifier = 'me'
): MarkMessageAsRead => ({
  type: MessageActions.MARK_MESSAGE_AS_READ,
  messageID,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/messages/${messageID}`,
      method: 'PATCH',
    },
  },
})

export type MessagesAction =
  | SendMessage
  | SendMessageSuccess
  | SendMessageFail
  | FetchMessagesForUser
  | FetchMessagesForUserSuccess
  | FetchMessagesForUserFail
  | MarkMessageAsRead
  | MarkMessageAsReadSuccess
  | MarkMessageAsReadFail
