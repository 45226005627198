import React, { useCallback } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { CartItemWithUpsell } from 'store/upsells/types'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { purchaseUrlWithEmailQueryParams } from 'store/upsells/selectors'
import useShoppingCart from 'hooks/useShoppingCart'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import useEvents from 'hooks/useEvents'
import Price from 'components/shared/Price'
import Box from '@material-ui/core/Box'

interface Props {
  upsells: CartItemWithUpsell[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: '1em',
      width: '100%',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    containerMain: {
      alignItems: 'flex-end',
      borderTop: `1px solid ${theme.palette.divider}`,
      flexDirection: 'column',
      padding: '1.8em 2.5em',
      [theme.breakpoints.down('sm')]: {
        padding: '1.8em 1.13em',
      },
    },
    containerSavings: {
      display: 'flex',
    },
    text: {
      color: theme.palette.primary.dark,
      fontWeight: 400,
    },
    savings: {
      color: theme.palette.success.main,
    },
    total: {
      fontWeight: 600,
      margin: '.06em 0 .09em',
    },
  })
)

const PurchaseTotal: React.FC<Props> = ({ upsells }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const sendEvent = useEvents()

  const { cartPlanCodes } = useShoppingCart()

  const upsellUrl = useSelector<AppState, string | null>((state) =>
    purchaseUrlWithEmailQueryParams(
      state.user,
      state.brand,
      state.upsells,
      cartPlanCodes
    )
  )

  const redirectTopPurchase = useCallback(async () => {
    await sendEvent({
      event: `Portal - Checkout Button Clicked`,
      variables: {
        in_cart: cartPlanCodes,
      },
    })
    window.location.href = upsellUrl || ''
  }, [sendEvent, cartPlanCodes, upsellUrl])

  let currencySymbol = null
  let total = 0
  let subtotal = 0
  let savings = 0

  // Calculate total & subtotal, Get currency symbol
  if (cartPlanCodes.length > 0) {
    currencySymbol = upsells[0].upsell.currency_symbol
    upsells.map((selectedUpsell) => {
      const originalPrice = selectedUpsell.upsell.original_price
        ? selectedUpsell.upsell.original_price * selectedUpsell.quantity
        : null
      const price = selectedUpsell.upsell.price * selectedUpsell.quantity
      total += price
      subtotal += originalPrice && originalPrice > price ? originalPrice : price

      return null
    })
  }

  // Calculate savings
  savings = subtotal - total

  return (
    <Container className={clsx(classes.container, classes.containerMain)}>
      <Container className={classes.container} disableGutters>
        <Typography className={classes.text} component="h3" variant="h5">
          {t('components.Checkout.subtotal')}
        </Typography>

        {subtotal > 0 ? (
          <Price
            currencyCode={upsells[0].upsell.currency_code}
            currencySymbol={currencySymbol ?? ''}
            amount={subtotal}
            className={classes.text}
            typographyProps={{ component: 'p' }}
          />
        ) : (
          <Typography className={classes.text} component="p" variant="h5">
            -
          </Typography>
        )}
      </Container>
      <Container className={classes.container} disableGutters>
        <Typography
          className={clsx(classes.text, classes.total)}
          component="h3"
          variant="h5"
        >
          {t('components.Checkout.total')}
        </Typography>

        {total > 0 ? (
          <Price
            currencyCode={upsells[0].upsell.currency_code}
            currencySymbol={currencySymbol ?? ''}
            amount={total}
            className={clsx(classes.text, classes.total)}
            typographyProps={{ component: 'p' }}
          />
        ) : (
          <Typography
            className={clsx(classes.text, classes.total)}
            component="p"
            variant="h5"
          >
            -
          </Typography>
        )}
      </Container>
      {savings > 0 && (
        <Box className={clsx(classes.containerSavings)}>
          <Typography
            className={clsx(classes.text, classes.savings)}
            component="p"
            variant="h5"
          >
            {t('components.Checkout.save')}
          </Typography>
          &nbsp;
          <Price
            currencyCode={upsells[0].upsell.currency_code}
            currencySymbol={currencySymbol ?? ''}
            amount={savings}
            className={clsx(classes.text, classes.savings)}
            typographyProps={{ component: 'p' }}
          />
        </Box>
      )}

      <Button
        className={classes.button}
        color="primary"
        onClick={redirectTopPurchase}
        variant="contained"
      >
        {t('components.Checkout.button')}
      </Button>
    </Container>
  )
}

export default PurchaseTotal
