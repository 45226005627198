import {
  UpsellsState,
  GroupedUpsells,
  Upsell,
  CartUpsellProps,
  catalogBundles,
  bundlePriorities,
  CartItemWithUpsell,
} from './types'
import { UpsellsActions } from './actions'
import {
  documentTabUpsellTypes,
  ItemTypes,
  orderEnhancementItems,
  timeSensitiveTypes,
} from 'store/items/types'
import { UserState } from 'store/user/types'
import { OrderStatusTypes, OrdersState } from 'store/orders/types'
import {
  activeOrders,
  allOrders,
  currentOrders,
  earliestActiveOrder,
} from 'store/orders/selectors'
import { HashMap } from '@talentinc/state-utils'
import { BrandState } from 'store/brand/types'

export const userUpsells = (state: UpsellsState): GroupedUpsells => {
  const group: GroupedUpsells = { last_chance: [], other: [] }
  //sorting by package priority descending
  const upsells = getUpsells(state, [])

  upsells.forEach((upsell) => {
    if (upsell.oms_fulfillable_items > 0) {
      group.last_chance.push(upsell)
    } else {
      group.other.push(upsell)
    }
  })

  return group
}
//TODO: Yes this is a hack. Need to figure out a better way to differentiate the different rush upsells...
export const upsellItemType = (upsell: Upsell): string => {
  if (upsell.name.indexOf('Rush') > -1) {
    if (upsell.name.indexOf('24') > -1) {
      return ItemTypes.RushOrder24Hours
    } else if (upsell.name.indexOf('48') > -1) {
      return ItemTypes.RushOrder48Hours
    } else if (upsell.name.indexOf('72') > -1) {
      return ItemTypes.RushOrder72Hours
    }
  }
  return upsell.items[0].item_type_key
}

export const upsellsByItemTypes = (
  state: UpsellsState,
  iTypes: ItemTypes[],
  excludedTypes: ItemTypes[]
): Upsell[] | null =>
  getUpsells(state, excludedTypes).filter(
    (upsell) =>
      upsell.items
        .flatMap((item) => item.item_type_key)
        .filter((key) => iTypes.includes(key)).length > 0
  )

export const documentsUpsells = (upsellState: UpsellsState): Upsell[] => {
  const docUpsellTypes = new Set(documentTabUpsellTypes)
  return getUpsells(upsellState, []).filter((u) =>
    u.items.find((i) => docUpsellTypes.has(i.item_type_key))
  )
}

export const curatedUpsells = (
  orderState: OrdersState,
  upsellState: UpsellsState
): Upsell[] | null => {
  const ordersActive = activeOrders(orderState).sort((a, b) => a.id - b.id)
  const ordersAll = allOrders(orderState).sort((a, b) => b.id - a.id)
  const orders = ordersActive.length > 0 ? ordersActive : ordersAll

  if (orders.length === 0) return null

  //if all orders are completed, default to using the completed order status for upsell selection
  const nextStatus =
    ordersActive.length === 0 && ordersAll.length > 0
      ? OrderStatusTypes.Completed
      : orders[0]?.status.active[0]?.status

  //a static mapping of order status to upsell, by item type
  const upsellStatusMapping: HashMap<ItemTypes[]> = {
    [OrderStatusTypes.ExpertAssignment]: [
      ItemTypes.RushOrder,
      ItemTypes.PhoneCall,
      ItemTypes.LinkedInDocument,
      ItemTypes.CoverLetter,
      ItemTypes.InterviewPrep,
    ],
    [OrderStatusTypes.LinkedInDue]: [
      ItemTypes.InterviewPrep,
      ItemTypes.PostingService,
      ItemTypes.RecruiterService,
      ItemTypes.ThankYouLetter,
      ItemTypes.CoverLetter,
    ],
    [OrderStatusTypes.SchedulePhone]: [
      ItemTypes.LinkedInDocument,
      ItemTypes.CoverLetter,
      ItemTypes.ThankYouLetter,
      ItemTypes.InterviewPrep,
      ItemTypes.PostingService,
    ],
    [OrderStatusTypes.Phone]: [
      ItemTypes.ThankYouLetter,
      ItemTypes.LinkedInDocument,
      ItemTypes.PostingService,
      ItemTypes.RecruiterService,
      ItemTypes.InterviewPrep,
    ],
    [OrderStatusTypes.ScheduleInterview]: [
      ItemTypes.LinkedInDocument,
      ItemTypes.CoverLetter,
      ItemTypes.ThankYouLetter,
      ItemTypes.PostingService,
      ItemTypes.RecruiterService,
    ],
    [OrderStatusTypes.Interview]: [
      ItemTypes.ThankYouLetter,
      ItemTypes.LinkedInDocument,
      ItemTypes.CoverLetter,
      ItemTypes.PostingService,
      ItemTypes.RecruiterService,
    ],
    [OrderStatusTypes.FirstDraft]: [
      ItemTypes.LinkedInDocument,
      ItemTypes.PostingService,
      ItemTypes.CoverLetter,
      ItemTypes.InterviewPrep,
      ItemTypes.ThankYouLetter,
    ],
    [OrderStatusTypes.RevisionPeriod]: [
      ItemTypes.LinkedInDocument,
      ItemTypes.PostingService,
      ItemTypes.CoverLetter,
      ItemTypes.InterviewPrep,
      ItemTypes.ThankYouLetter,
    ],
    [OrderStatusTypes.Completed]: [
      ItemTypes.InterviewPrep,
      ItemTypes.PostingService,
      ItemTypes.RecruiterService,
      ItemTypes.ThankYouLetter,
      ItemTypes.Resume,
    ],
  }

  const upsellTypes = upsellStatusMapping[nextStatus]

  if (!upsellTypes || upsellTypes.length === 0) return null

  const upsells = upsellsByItemTypes(upsellState, upsellTypes, [])

  if (!upsells || upsells.length === 0) return null

  return upsells
}

export const portfolioUpsell = (state: UpsellsState): Upsell | null => {
  // limit to Document Item Types on the portfolio page
  const excludedTypes: ItemTypes[] = [
    ItemTypes.PhoneCall,
    ItemTypes.InterviewPrep,
    ItemTypes.RushOrder,
    ItemTypes.PostingService,
    ItemTypes.RecruiterService,
    ItemTypes.Guarantee,
    ItemTypes.PersonalConsultation,
  ]

  const upsells = getUpsells(state, excludedTypes)
  if (upsells.length === 0) {
    return null
  }
  return upsells[0]
}

export const railUpsell = (state: UpsellsState): Upsell | null => {
  //sorting by package priority descending
  const upsells = getUpsells(state, [])
  if (upsells.length === 0) {
    return null
  } else if (upsells.length === 1) {
    return upsells[0]
  }
  // return upsells[1] (so it is different from upsell on the main page)
  return upsells[1]
}

export const getUpsells = (
  state: UpsellsState,
  excludedTypes: ItemTypes[],
  excludeBundles = true
): Upsell[] => {
  // filter out item-types provided by the excludedTypes array
  let upsells = Object.values(state.upsells).filter(
    (upsell) =>
      upsell.items
        .flatMap((item) => item.item_type_key)
        .filter((key) => !excludedTypes.includes(key)).length > 0
  )
  if (excludeBundles) {
    upsells = upsells.filter((upsell) => upsell.items.length <= 1)
  }
  // if there are multiple items, it is abundle, so apply a 30% discount
  //TODO: remove this hardcoded discount when we integrate discount tech
  return upsells
    .map((upsell) => ({
      ...upsell,
      original_price:
        upsell.items.length > 1 &&
        (!upsell.original_price || upsell.original_price <= 0)
          ? upsell.price / 0.7
          : upsell.original_price,
    }))
    .sort((a, b) => b.upsell_priority - a.upsell_priority)
}

export const upsellsError = (state: UpsellsState): boolean => {
  if (state.meta[UpsellsActions.FETCH_UPSELLS].error != null) return true
  return false
}

export const upsellsFinishedLoading = (state: UpsellsState): boolean =>
  state.meta[UpsellsActions.FETCH_UPSELLS].loaded ||
  Object.keys(state.upsells).length > 0

export const purchaseUrl = (
  userState: UserState,
  brandState: BrandState,
  planCodes: string[],
  pathOverride = '',
  queryParamOverrides?: HashMap<string> | null
): string | null => {
  if (userState.currentUser === null) return null
  if (userState.currentUser.auth_token === null) return null
  if (planCodes.length === 0) return null

  const currentBrand = brandState.currentBrand
  const pUrl = basePurchaseUrl(
    currentBrand?.home_url ?? userState.currentUser.brand.home_url,
    planCodes,
    pathOverride,
    queryParamOverrides
  )
  if (!pUrl) return null

  pUrl.searchParams.append('at', userState.currentUser.auth_token)
  pUrl.searchParams.append('redirect_url', window.location.href)

  return pUrl.toString()
}

export const purchaseUrlWithEmailQueryParams = (
  userState: UserState,
  brandState: BrandState,
  upsellState: UpsellsState,
  planCodes: string[],
  pathOverride = ''
): string | null => {
  return purchaseUrl(
    userState,
    brandState,
    planCodes,
    pathOverride,
    upsellState.emailQueryParams
  )
}

export const basePurchaseUrl = (
  homeURL: string,
  planCodes: string[],
  pathOverride = '',
  queryParamOverrides?: HashMap<string> | null
): URL | null => {
  if (planCodes.length === 0) return null
  const pUrl: URL = new URL(homeURL + '/purchase/' + planCodes.join(','))
  let path = window.location.pathname
  if (path.lastIndexOf('/') !== -1) {
    // keep everything after the last slash '/'
    path = path.slice(path.lastIndexOf('/') + 1)
  }
  if (path.lastIndexOf('.') !== -1) {
    // keep everything before the last period '.'
    path = path.slice(0, path.lastIndexOf('.'))
  }
  if (pathOverride !== '') path = pathOverride

  //add default tags to the map
  const searchParamMap: HashMap<string> = {
    utm_keyword: 'portal',
    utm_content: 'upsell',
    utm_campaign: 'ups_portal_' + path,
    p_source: 'portal', //including this param, tells products to generate notifications
    //and clear the user's cart upon purchase completion
  }

  //if provided, override the default tags
  if (queryParamOverrides) {
    Object.entries(queryParamOverrides).forEach(([key, value]) => {
      searchParamMap[key] = value
    })
  }

  // add the tags to the url
  Object.keys(searchParamMap).forEach((key) => {
    pUrl.searchParams.append(key, searchParamMap[key])
  })

  return pUrl
}

export const upsellExpiryDate = (
  state: OrdersState,
  itemType: ItemTypes | null
) => {
  const earliestOrder = earliestActiveOrder(state)

  if (!earliestOrder) return null
  if (!itemType) return null
  if (!timeSensitiveTypes.has(itemType)) return null

  const hours24 = 1000 * 60 * 60 * 24

  if (itemType === ItemTypes.RushOrder) {
    return (
      new Date((earliestOrder?.closed_at || new Date()).getTime() + hours24) ||
      null
    )
  } else if (itemType === ItemTypes.PhoneCall) {
    return (
      earliestOrder.status.active.find(
        (s) => s.status === OrderStatusTypes.FirstDraft
      )?.expected_at || null
    )
  }

  return null
}

export const orderEnhancementUpsells = (state: UpsellsState): Upsell[] =>
  getUpsells(state, []).filter(
    (u) =>
      u.items.length > 0 && orderEnhancementItems.has(u.items[0].item_type_key)
  )

export const catalogUpsells = (state: UpsellsState): Upsell[] =>
  getUpsells(state, Array.from(orderEnhancementItems))

export const cartUpsells = (
  upsellsState: UpsellsState,
  ordersState: OrdersState,
  showBundles: boolean
): CartUpsellProps => {
  //if there are no current orders, do not show any order enhancement upsells
  if (currentOrders(ordersState).length === 0) {
    return {
      primary: getUpsells(upsellsState, []).sort(
        (a, b) => b.oms_fulfillable_items - a.oms_fulfillable_items //show document items first
      ),
      secondary: [],
      order_enhancement: [],
    } as CartUpsellProps
  }
  let uCatalog = catalogUpsells(upsellsState)

  //only show bundles for users with the feature flag
  if (showBundles) {
    uCatalog = [
      ...catalogBundleUpsells(upsellsState),
      ...catalogUpsells(upsellsState),
    ]
  }

  let uEnhancement = orderEnhancementUpsells(upsellsState)

  //if there are more than 3 order enhancement upsells, show the first to and add the rest to the regular catalog
  if (uEnhancement.length > 3) {
    uCatalog = uCatalog.concat(uEnhancement.slice(3))
    uEnhancement = uEnhancement.slice(0, 3)
  }

  return {
    primary: uCatalog.slice(0, 3),
    secondary: uCatalog.slice(3),
    order_enhancement: uEnhancement,
  } as CartUpsellProps
}

export const upsellsByPlanCode = (state: UpsellsState) =>
  Object.entries(state.byPlanCode).reduce((acc, [planCode, upsellID]) => {
    acc[planCode] = state.upsells[upsellID]
    return acc
  }, {} as HashMap<Upsell>)

export const upsellByPlanCode = (
  state: UpsellsState,
  planCode?: string
): Upsell | null => {
  if (!planCode) return null
  const upsellID = state.byPlanCode[planCode]
  if (!upsellID) return null
  return state.upsells[upsellID]
}

export const upsellsByBundle = (
  state: UpsellsState,
  bundle: string | undefined
) => {
  if (!bundle) return null
  const upsells = state.byBundleGroup[bundle]
  if (!upsells) return null
  return upsells
    .map((upsellID) => {
      const upsell = state.upsells[upsellID]
      return {
        ...upsell,
        original_price: upsell.price / 0.7, //TODO: Discount this for real when we integrate discount codes into the portal
      }
    })
    .sort((a, b) => b.items.length - a.items.length)
}

export const bundleUpsells = (state: UpsellsState) =>
  getUpsells(state, [], false).filter((upsell) => upsell.items.length > 1)

export const catalogBundleUpsells = (state: UpsellsState): Upsell[] =>
  Array.from(catalogBundles)
    .filter(
      (bundle) =>
        !!state.byBundleGroup[bundle] && state.byBundleGroup[bundle].length > 0 //make sure there are upsells for the bundle
    )
    .map((bundle) => {
      const upsell = Object.values(state.byBundleGroup[bundle])
        .map((upsellID) => state.upsells[upsellID])
        .sort((a, b) => b.items.length - a.items.length)[0]
      return {
        ...upsell,
        original_price: upsell.price / 0.7, //TODO: Discount this for real when we integrate discount codes into the portal
        upsell_priority:
          upsell.items.length > 1
            ? bundlePriorities[upsell.bundle || ''] || upsell.upsell_priority
            : upsell.upsell_priority, //overriding upsell priority with the bundle specific weights
      }
    })
    .filter((upsell) => upsell.items.length > 1)
    .sort((a, b) => b.upsell_priority - a.upsell_priority)

export const planCodesInCart = (state: UpsellsState) => {
  const codesInCart: string[] = []
  Object.values(state.cart).forEach((cartItem) => {
    const upsell = state.upsells[cartItem.sku_id]
    if (upsell) {
      for (let i = 0; i < cartItem.quantity; i++) {
        codesInCart.push(upsell.plan_code)
      }
    }
  })
  return codesInCart
}

export const cartItemIDsByPlanCode = (state: UpsellsState): HashMap<number> =>
  Object.keys(state.cartItemsBySkuID).reduce((acc, skuID) => {
    const upsell = state.upsells[skuID]
    if (upsell) {
      acc[upsell.plan_code] = state.cartItemsBySkuID[skuID]
    }
    return acc
  }, {} as HashMap<number>)

export const cartItemsWithUpsells = (
  state: UpsellsState
): CartItemWithUpsell[] =>
  Object.values(state.cart)
    .map(
      (cartItem) =>
        ({
          ...cartItem,
          upsell: state.upsells[cartItem.sku_id],
        } as CartItemWithUpsell)
    )
    .filter((cItem) => !!cItem.upsell)
