import React from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import { Upsell } from 'store/upsells/types'

import Skeleton from 'components/shared/Skeleton'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import OrderItemIcon from 'components/OrderItems/OrderItemIcon'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: '1.0em',
      marginBottom: '0',
      fontSize: '.88em',
      fontWeight: 600,
      width: '100%',
    },
    button_selected: {
      marginTop: '.72em',
      backgroundColor: '#CDCACA',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    paper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '0',
      width: 'calc(100% - 2em)',
    },
    headingContainer: {
      display: 'flex',
      marginLeft: '3em 0 .78em',
      marginRight: '3em 0 .78em',
    },
    gridContainer: {
      backgroundColor: '#eef6fc',
      padding: '0.75em 1.1em 1.1em',
    },
    icon: {
      width: '2.0em',
      height: '2.0em',
    },
    heading: {
      fontSize: '1em',
      fontWeight: 500,
    },
    body: {
      fontSize: '.88em',
      fontWeight: 500,
      color: '#66677d',
    },
  })
)

interface Props {
  upsell: Upsell | null
  finishedLoading: boolean
  hasError: boolean
  purchaseUrl: string
}

const RailUpsellCard: React.FC<Props> = ({
  upsell,
  finishedLoading,
  hasError,
  purchaseUrl,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (upsell === null || hasError) {
    return null
  }

  if (!finishedLoading) {
    return <Skeleton components={[{ height: '60em', width: '100%' }]} />
  }

  return (
    <Paper className={classes.paper} variant="outlined">
      <Container className={classes.gridContainer}>
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid container direction="row" justify="flex-start">
            <Grid item>
              <OrderItemIcon
                itemType={upsell.items[0].item_type_key}
                itemName={upsell.items[0].name}
                className={classes.icon}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" justify="space-evenly">
            <Grid item>
              <Typography className={classes.heading} variant="h3">
                {upsell.name}
              </Typography>
            </Grid>
            <Grid item>
              {upsell.items.length > 0 && (
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.body}
                >
                  {t(
                    `components.Upsells.shortPitch.${upsell.items[0].item_type_key}`
                  )}
                </Typography>
              )}
              {upsell.items.length <= 0 && (
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.body}
                  dangerouslySetInnerHTML={{
                    __html: upsell.very_short_pitch || '',
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={purchaseUrl}
              >
                {t('pages.Upgrade.add')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default RailUpsellCard
