import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { faqsDisplayProps } from 'store/orders/selectors'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'

import WidgetTitle from 'components/shared/WidgetTitle'
import FaqGroups from './FaqGroups'
import { sendEvent } from 'store/events/actions'

interface Props {
  widget: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1.9em 1.9em 1.6em',
    },
    paper: {
      padding: '0',
    },
  })
)

const FaqSection: React.FC<Props> = ({ widget }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  // Get current states
  const faqProps = useSelector<AppState, ReturnType<typeof faqsDisplayProps>>(
    (state) => faqsDisplayProps(state.orders, state.items),
    isEqual
  )

  const handleQuestionExpand = useCallback((key: string, route: string) => {
    dispatch(
      sendEvent({
        event: 'FAQ Impression',
        variables: {
          question: key,
          current_uri: route,
        },
      })
    )
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <>
      {widget &&
        faqProps.length > 0 &&
        faqProps[0].currentStatus &&
        faqProps[0].currentStatus.status && (
          <Paper className={classes.paper} variant="outlined">
            <WidgetTitle
              title={t('components.Faqs.title')}
              buttonLink={t('routes.faqs')}
              buttonText={t('components.Faqs.viewAll')}
            />
            <Divider />
            <FaqGroups
              faqProps={faqProps}
              widget={widget}
              handleQuestionExpand={handleQuestionExpand}
            />
          </Paper>
        )}
      {!widget && (
        <FaqGroups
          faqProps={faqProps}
          widget={widget}
          handleQuestionExpand={handleQuestionExpand}
        />
      )}
    </>
  )
}

export default FaqSection
