import {
  MultiLoadingLoadedErrorState,
  initialMultiLoadingLoadedErrorState,
} from '@talentinc/state-utils'

export enum NotificationTypes {
  Welcome = 'welcome',
  ExpertAssigned = 'expert_assigned',
  OrderReadyEarly = 'order_ready_early',
  OrderReady = 'order_ready',
  OrderLate = 'order_late',
  OrderReassigned = 'order_reassigned',
  OrderItemAdjusted = 'order_item_adjusted',
  OrderItemComped = 'order_item_comped',
  OrderUnassigned = 'order_unassigned',
  OrderCloned = 'order_cloned',
  FirstDraft = 'first_draft',
  FirstDraftDocument = 'first_draft_document',
  OrderReceived = 'order_created',
}

// Ordered list of notification types that trigger a modal.
// The order is the order in which to show the modals, if multiple exist.
export const ModalNotificationTypes = [
  NotificationTypes.Welcome,
  NotificationTypes.ExpertAssigned,
  NotificationTypes.OrderUnassigned,
  NotificationTypes.OrderItemAdjusted,
  NotificationTypes.OrderItemComped,
  NotificationTypes.OrderLate,
  NotificationTypes.OrderReadyEarly,
  NotificationTypes.OrderReady,
  NotificationTypes.OrderReassigned,
  NotificationTypes.OrderCloned,
  NotificationTypes.OrderReceived,
]

export const MenuNotificationTypes = new Set([
  NotificationTypes.Welcome,
  NotificationTypes.ExpertAssigned,
  NotificationTypes.OrderUnassigned,
  NotificationTypes.OrderItemAdjusted,
  NotificationTypes.OrderItemComped,
  NotificationTypes.OrderLate,
  NotificationTypes.OrderReadyEarly,
  NotificationTypes.OrderReady,
  NotificationTypes.OrderReassigned,
  NotificationTypes.OrderCloned,
  NotificationTypes.OrderReceived,
])

export interface BaseNotification<
  Name = Exclude<
    NotificationTypes,
    | NotificationTypes.OrderItemComped
    | NotificationTypes.ExpertAssigned
    | NotificationTypes.OrderItemAdjusted
    | NotificationTypes.OrderReassigned
    | NotificationTypes.OrderReceived
    | NotificationTypes.FirstDraft
    | NotificationTypes.FirstDraftDocument
    | NotificationTypes.OrderReady
  >,
  Variables = Record<string, any>
> {
  id: number
  name: Name
  variables: Variables
  created_at: Date
  viewed_at: Date | null
  order_ids: number[]
}

type OrderItemCompedNotification = BaseNotification<
  typeof NotificationTypes.OrderItemComped,
  {
    itemNames: string[]
  }
>

type ExpertAssignedNotification = BaseNotification<
  | typeof NotificationTypes.ExpertAssigned
  | typeof NotificationTypes.OrderReassigned,
  {
    expertName: string
  }
>

type OrderReceivedNotification = BaseNotification<
  typeof NotificationTypes.OrderReceived,
  {
    items: string[]
  }
>

type OrderItemAdjustedNotification = BaseNotification<
  typeof NotificationTypes.OrderItemAdjusted,
  {
    upgradedProduct: string
    originalProduct: string
  }
>

type FirstDraftNotification = BaseNotification<
  typeof NotificationTypes.FirstDraft,
  {
    attached_doc_types: string[]
  }
>

type FirstDraftDocumentNotification = BaseNotification<
  typeof NotificationTypes.FirstDraftDocument,
  {
    doc_type: string
  }
>

type OrderReadyNotification = BaseNotification<
  typeof NotificationTypes.OrderReady,
  {
    attached_doc_types: string[]
  }
>

export type Notification =
  | BaseNotification
  | OrderItemCompedNotification
  | ExpertAssignedNotification
  | OrderItemAdjustedNotification
  | OrderReceivedNotification
  | FirstDraftNotification
  | FirstDraftDocumentNotification
  | OrderReadyNotification

export interface NotificationList {
  [id: number]: Notification
}

export interface RouteNotification {
  viewed: boolean
  notificationID: number
}

export interface RouteNotificationMap {
  [notification: string]: string
}

export interface NotificationState {
  notifications: NotificationList
  meta: MultiLoadingLoadedErrorState
}

export const initialNotificationState: NotificationState = {
  notifications: {},
  meta: { ...initialMultiLoadingLoadedErrorState },
}
