import React from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import DocumentTabs from './DocumentTabs'
import Document from './Document'
import { DocumentsByTypeAndOrderItemID } from 'store/files/types'
import {
  InterviewPrepDocsDisplayProps,
  UserDocumentsItemProps,
} from 'store/items/types'
import SkeletonLoading from 'components/shared/Skeleton'
import FlashErrorBoundary from 'components/shared/Errors'
import { CompletedDocuments } from './CompletedDocuments'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { activeDocumentTabs } from 'store/files/selectors'
import { documentsUpsells } from 'store/upsells/selectors'
import { Upsell } from 'store/upsells/types'
import DocumentUpsell from 'components/Upsells/DocumentUpsell'
import InterviewPrepDocument from './InterviewPrepDocument'
import DocumentNotifications from 'components/Notifications/DocumentNotifications'
import { ItemTypes } from 'store/items/types'
import PhoneCallPrep from './PhoneCallPrep'

interface Props {
  itemsWithDocs: DocumentsByTypeAndOrderItemID<UserDocumentsItemProps>
  defaultDocumentsTab?: string | null
  isCompletedView?: boolean
  showRatingModal: () => void
  isInterviewPrep?: boolean
  interviewPrepProps?: InterviewPrepDocsDisplayProps | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: '1.5em',
      flex: 1,

      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 26.1em)',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '10em',
        width: '98%',
      },
    },
    paper: {
      borderRadius: '0 0 6px 6px',
      height: 'calc(100% - 5em)',
      marginTop: '0',
      marginBottom: '1em',
      overflow: 'scroll',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
      },
    },
    interviewPaper: {
      borderRadius: '0 0 6px 6px',
      marginTop: '0',
      marginBottom: '1em',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
      },
    },
  })
)

export const DocumentsLoader = () => {
  const classes = useStyles()

  return (
    <SkeletonLoading
      components={[
        {
          className: classes.container,
          height: '60vh',
        },
      ]}
    />
  )
}

/**
 * Documents is a list view component of all the documents to be shown on the
 * desktop.
 */
const Documents: React.FC<Props> = ({
  itemsWithDocs,
  defaultDocumentsTab = null,
  isCompletedView,
  showRatingModal,
  isInterviewPrep,
  interviewPrepProps,
}) => {
  const classes = useStyles()
  const tabNames = useSelector<AppState, string[]>((state) =>
    activeDocumentTabs(state.upsells, itemsWithDocs)
  )
  const docUpsells = useSelector<AppState, Upsell[]>((state) =>
    documentsUpsells(state.upsells)
  )

  if (isInterviewPrep && interviewPrepProps) {
    return (
      <Container className={classes.container} disableGutters>
        <Paper className={classes.interviewPaper} variant="outlined">
          <InterviewPrepDocument {...interviewPrepProps} />
        </Paper>
      </Container>
    )
  }
  return (
    <Container className={classes.container} disableGutters>
      {!isCompletedView && (
        <DocumentTabs
          tabNames={tabNames}
          defaultDocumentsTab={defaultDocumentsTab}
        >
          {(selectedTabName) => (
            <React.Fragment key={selectedTabName}>
              {Array.from(itemsWithDocs.entries()).map(
                ([docType, itemsByOrderItemID]) => {
                  const items = Array.from(itemsByOrderItemID.values())
                  return (
                    <React.Fragment key={docType}>
                      <Paper
                        className={classes.paper}
                        hidden={selectedTabName !== docType}
                        variant="outlined"
                      >
                        {Array.from(itemsByOrderItemID.entries()).map(
                          ([orderItemID, item]) => {
                            if (
                              item.item_type === ItemTypes.PhoneCall &&
                              !item.documents.length
                            ) {
                              return (
                                <FlashErrorBoundary key={orderItemID}>
                                  <PhoneCallPrep
                                    item={item}
                                    expert={item.expert}
                                    doc={item.documents?.[0] ?? null}
                                    isCompleted={false}
                                  />
                                </FlashErrorBoundary>
                              )
                            }

                            return (
                              <FlashErrorBoundary key={orderItemID}>
                                <Document
                                  {...item}
                                  showRatingModal={showRatingModal}
                                />
                              </FlashErrorBoundary>
                            )
                          }
                        )}
                      </Paper>
                      {items.length > 0 && (
                        <DocumentNotifications
                          documentType={items[0].item_type}
                          active={selectedTabName === docType}
                        />
                      )}
                    </React.Fragment>
                  )
                }
              )}
              {docUpsells.map((upsell) => (
                <Paper
                  key={upsell.items[0].item_type_key}
                  className={classes.paper}
                  hidden={selectedTabName !== upsell.items[0].name}
                  variant="outlined"
                >
                  <DocumentUpsell upsell={upsell} />
                </Paper>
              ))}
            </React.Fragment>
          )}
        </DocumentTabs>
      )}
      {isCompletedView && <CompletedDocuments />}
    </Container>
  )
}

export default Documents
