import React from 'react'
import { useSelector } from 'react-redux'

import {
  upsellsFinishedLoading,
  railUpsell,
  upsellsError,
  purchaseUrl,
} from 'store/upsells/selectors'
import { AppState } from 'store'
import { Upsell } from 'store/upsells/types'

import RailUpsellCard from './RailUpsellCard'

const RailUpsellCardContainer: React.FC = () => {
  const finishedLoading = useSelector<AppState, boolean>((state) =>
    upsellsFinishedLoading(state.upsells)
  )

  const hasError = useSelector<AppState, boolean>((state) =>
    upsellsError(state.upsells)
  )

  const upsell = useSelector<AppState, Upsell | null>((state) =>
    railUpsell(state.upsells)
  )

  let upsells: string[] = []
  if (upsell !== null) upsells = [upsell.plan_code]
  const upsellUrl = useSelector<AppState, string | null>((state) =>
    purchaseUrl(state.user, state.brand, upsells, 'rail')
  )

  return (
    <RailUpsellCard
      upsell={upsell}
      hasError={hasError}
      finishedLoading={finishedLoading}
      purchaseUrl={upsellUrl !== null ? upsellUrl : ''}
    />
  )
}

export default RailUpsellCardContainer
