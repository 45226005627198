import { produce } from 'immer'
import omit from 'lodash/omit'
import * as stateUtils from '@talentinc/state-utils'

import { OrdersState, initialState, OrderResponse } from './types'
import { OrderAction, OrderActions } from './actions'

export default function orderReducer(
  state: OrdersState = initialState,
  action: OrderAction
): OrdersState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OrderActions.FETCH_ORDERS_FOR_USER:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break

      case OrderActions.FETCH_ORDERS_FOR_USER_SUCCESS: {
        const reassignedOrderIDs = action.payload.data.flatMap(
          (o) => o.original_order_id || []
        )
        action.payload.data.forEach((order) =>
          storeOrderInDraft(order, draft, reassignedOrderIDs)
        )
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        break
      }

      case OrderActions.FETCH_ORDERS_FOR_USER_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }

      case OrderActions.FETCH_ORDER_FOR_USER:
      case OrderActions.APPROVE_ORDER: {
        const { type, orderID } = action
        draft.meta[type][orderID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }

      case OrderActions.APPROVE_ORDER_SUCCESS: {
        const { type, orderID } = action.meta.previousAction
        storeOrderInDraft(action.payload.data, draft, [])
        draft.meta[type][orderID] = stateUtils.setSuccessState(
          state.meta[type][orderID]
        )
        break
      }

      case OrderActions.APPROVE_ORDER_LOADING: {
        draft.approveOrderLoading = action.payload
        break
      }

      case OrderActions.FETCH_ORDER_FOR_USER_FAIL:
      case OrderActions.APPROVE_ORDER_FAIL: {
        const { type, orderID } = action.meta.previousAction
        draft.meta[type][orderID] = stateUtils.setErrorState(
          state.meta[type][orderID],
          action.error
        )
        break
      }

      case OrderActions.FETCH_ORDER_FOR_USER_SUCCESS: {
        const order = action.payload.data
        const reassignedOrderIDs = order.original_order_id
          ? [order.original_order_id]
          : []

        storeOrderInDraft(order, draft, reassignedOrderIDs)
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][order.id] = stateUtils.setSuccessState(
          state.meta[previousActionType][order.id]
        )
        break
      }
    }
  })
}

const storeOrderInDraft = (
  order: OrderResponse,
  draft: OrdersState,
  reassignedOrderIDs: number[]
) => {
  const orderItemIDs = order.packages
    .flatMap((pkg) => pkg.items)
    .map((item) => item.id)

  draft.orders[order.id] = {
    ...omit(order, ['packages']),
    packages: order.packages.map((pkg) => ({
      ...omit(pkg, ['items']),
      itemIDs: pkg.items.map((item) => item.id),
    })),
    status: {
      active: order.status.active.map((status) => ({
        ...status,
        itemIDs: orderItemIDs,
      })),
      completed: order.status.completed.map((status) => ({
        ...status,
        itemIDs: orderItemIDs,
      })),
    },
  }
  if (order.expert_hub_client_ids) {
    order.expert_hub_client_ids.forEach((id) => {
      if (!draft.byClientID[id]) {
        draft.byClientID[id] = new Set<number>()
      }
      draft.byClientID[id].add(order.id)
    })
  }
  if (!reassignedOrderIDs.includes(order.id)) {
    order.packages.forEach((pkg) => {
      pkg = { ...pkg, orderID: order.id }
      if (pkg.expert_hub_client_id) {
        // only add this pkg, if pgk.id not already added
        if (draft.packagesByClientID[pkg.expert_hub_client_id]) {
          if (
            !draft.packagesByClientID[pkg.expert_hub_client_id]
              .flatMap((p) => p.id)
              .includes(pkg.id)
          ) {
            draft.packagesByClientID[pkg.expert_hub_client_id].push(pkg)
          }
        } else {
          draft.packagesByClientID[pkg.expert_hub_client_id] = [pkg]
        }
        //storing a list of all client ids
        draft.clientIDs.add(pkg.expert_hub_client_id)
      }
      // Precalculate byPackageID lookups
      draft.byPackageID[pkg.id] = order.id
      pkg.items.forEach((item) => {
        // Precalculate byItemID lookups
        draft.byItemID[item.id] = order.id
      })
    })
  }
}
