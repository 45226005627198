import React from 'react'
import { useTranslation } from 'react-i18next'

import ContentHeader from 'components/Content/ContentHeader'
import FlashErrorBoundary from 'components/shared/Errors'
import PageShell from 'pages/PageShell'
import TextContainer from 'components/Content/TextContainer'
import ArticleTrans from 'components/Content/ArticleTrans'
import useBrand from 'hooks/useBrand'

import SettingTheStageImg from 'assets/images/setting-the-stage-large.png'

const Article3: React.FC = () => {
  const { t } = useTranslation()
  const brand = useBrand()

  return (
    <PageShell content={true} contentDir="column">
      <FlashErrorBoundary>
        <ContentHeader
          date={t([
            `pages.Article3.${brand.name}.date`,
            'pages.Article3.default.date',
          ])}
          img={SettingTheStageImg}
          imgAlt={t([
            `pages.Article3.${brand.name}.imgAlt`,
            'pages.Article3.default.imgAlt',
          ])}
          title={t([
            `pages.Article3.${brand.name}.title`,
            'pages.Article3.default.title',
          ])}
        />
        <TextContainer>
          <ArticleTrans
            i18nKeys={[
              `pages.Article3.${brand.name}.text`,
              `pages.Article3.default.text`,
            ]}
          />
        </TextContainer>
      </FlashErrorBoundary>
    </PageShell>
  )
}

export default Article3
