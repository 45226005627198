import React, { useCallback } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { ItemTypes } from 'store/items/types'
import { CartItemWithUpsell } from 'store/upsells/types'
import useShoppingCart from 'hooks/useShoppingCart'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import OrderItemIcon from 'components/OrderItems/OrderItemIcon'
import NumberInput from 'components/shared/NumberInput'
import SvgDelete from 'components/shared/icons/Delete'
import { joinConversationally } from 'utils/formatting'
import Box from '@material-ui/core/Box'
import { colors } from 'styles'
import BundleThumbnail from './BundleThumbnail'
import Price from 'components/shared/Price'

interface Props {
  index: number
  upsell: CartItemWithUpsell
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 'initial',
      marginRight: 'initial',
    },
    containerSpaceAround: {
      display: 'flex',
      justifyContent: 'space-around',
      marginLeft: 'initial',
      marginRight: 'initial',
    },
    containerColumn: {
      flexDirection: 'column',
      height: '100%',
    },
    containerItem: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '1.5em 2.5em',
      width: '100%',

      '&:last-of-type': {
        borderBottom: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1.5em 1.13em',
      },
    },
    containerName: {
      width: '70%',
    },
    containerPrice: {
      alignItems: 'flex-end',
      width: '10%',
    },
    containerText: {
      minHeight: '5em',
      width: 'calc(100% - 6em)',
    },
    h3: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      lineHeight: 1.25,
    },
    iconButton: {
      color: theme.palette.primary.dark,
      fontSize: '1.1em',
      width: '1em',
    },
    img: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px',
      display: 'flex',
      height: '5em',
      justifyContent: 'center',
      marginRight: '1em',
      maxHeight: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      padding: 0,
      width: '5em',
    },
    price: {
      textAlign: 'right',
    },
    pricePresale: {
      textDecoration: 'line-through',
    },
    priceSale: {
      color: colors.green[600],
    },
    bundleIcon: {
      borderRadius: '0.25rem',
    },
    itemList: {
      fontSize: '1rem',
    },
    bundleIconContainer: {
      width: '5em',
      height: '5em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

const PurchaseItem: React.FC<Props> = ({ index, upsell }) => {
  const classes = useStyles()
  const planCode: string | undefined = upsell.upsell?.plan_code

  const { removeFromCart, updateQuantity } = useShoppingCart()

  // Remove an upsell from the cart
  const removeUpsell = useCallback(async () => {
    if (planCode) {
      removeFromCart(planCode, 'shopping-cart')
    }
  }, [planCode, removeFromCart])

  const updateAmount = useCallback(
    async (amount: number) => {
      updateQuantity(upsell.upsell.plan_code, amount, 'shopping-cart')
    },
    [upsell.upsell.plan_code, updateQuantity]
  )

  const price = upsell.upsell.price * upsell.quantity
  const originalPrice = Math.floor(
    upsell.upsell.original_price
      ? upsell.upsell.original_price * upsell.quantity
      : 0
  )

  // Check if the upsell has a sales price
  const onSale =
    upsell.upsell.original_price &&
    upsell.upsell.original_price > upsell.upsell.price
      ? true
      : false

  const isBundle =
    upsell.upsell.items.length > 1 && upsell.upsell.bundle_groups.length > 0
  const itemNames = joinConversationally(
    upsell.upsell.items.map((item) => item.item_type_key),
    true,
    'itemTypes.'
  )

  return (
    <Container className={clsx(classes.container, classes.containerItem)}>
      {isBundle && (
        <Box className={classes.bundleIconContainer}>
          <BundleThumbnail
            bundle={upsell.upsell.bundle_groups[0]}
            itemName={upsell.upsell.name}
            itemType={upsell.upsell.items[0].item_type_key}
            className={classes.bundleIcon}
          />
        </Box>
      )}
      {!isBundle && (
        <Container className={classes.img} disableGutters>
          <OrderItemIcon
            itemName={upsell.upsell.name}
            itemType={upsell.upsell.items[0].item_type_key}
            drawer={true}
          />
        </Container>
      )}

      <Container
        className={clsx(classes.container, classes.containerText)}
        disableGutters
      >
        <Container
          className={clsx(
            isBundle ? classes.containerSpaceAround : classes.container,
            classes.containerColumn,
            classes.containerName
          )}
          disableGutters
        >
          <Typography className={classes.h3} component="h3" variant="body2">
            {upsell.upsell.name ? upsell.upsell.name : null}
          </Typography>
          {upsell.upsell.items.length > 1 && (
            <Typography variant="body2" className={classes.itemList}>
              {itemNames}
            </Typography>
          )}
          {upsell.upsell.items[0].item_type_key === ItemTypes.PhoneCall && (
            <NumberInput
              amount={upsell.quantity}
              updateQuantity={updateAmount}
            />
          )}
        </Container>
        <Container
          className={clsx(
            classes.container,
            classes.containerColumn,
            classes.containerPrice
          )}
          disableGutters
        >
          <IconButton className={classes.iconButton} onClick={removeUpsell}>
            <SvgDelete />
          </IconButton>
          <Container disableGutters>
            {onSale && (
              <Price
                currencyCode={upsell.upsell.currency_code}
                currencySymbol={upsell.upsell.currency_symbol}
                amount={upsell.upsell.price}
                className={clsx(classes.price, classes.priceSale)}
                typographyProps={{ variant: 'h4', component: 'p' }}
              />
            )}

            <Price
              currencyCode={upsell.upsell.currency_code}
              currencySymbol={upsell.upsell.currency_symbol}
              amount={onSale ? originalPrice : price}
              className={clsx(
                classes.price,
                onSale ? classes.pricePresale : null
              )}
              typographyProps={{
                variant: 'h4',
                component: 'p',
                color: 'secondary',
              }}
            />
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export default PurchaseItem
