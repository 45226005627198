import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'

import Question from './Question'
import WidgetTitle from 'components/shared/WidgetTitle'

interface FaqQuestion {
  answer: string
  question: string
}

interface Props {
  questions: FaqQuestion[]
  title: string
  widget: boolean
  handleQuestionExpand: (key: string, route: string) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '0',
    },
  })
)

const FaqGroup: React.FC<Props> = ({
  questions,
  title,
  widget,
  handleQuestionExpand,
}) => {
  const classes = useStyles()

  return (
    <Container disableGutters>
      <Paper className={classes.paper} variant="outlined">
        <WidgetTitle title={title} />
        {questions.map((question, index) => (
          <Question
            answer={question.answer}
            key={question.question}
            question={question.question}
            handleExpand={handleQuestionExpand}
          />
        ))}
      </Paper>
    </Container>
  )
}

export default FaqGroup
