import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import usePATAuth from 'hooks/usePATAuth'
import StartSession from 'components/Call/StartSession'
import Loader from 'components/shared/Loader'
import { AppState } from 'store'
import { fetchScheduledItems } from 'store/scheduling/actions'
import { unauthenticatedItemForScheduler } from 'store/scheduling/selectors'

interface Props {
  omsItemID: number
}

const UnauthenticatedCallPage: React.FC<Props> = ({ omsItemID }) => {
  const { pat } = usePATAuth()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const item = useSelector<
    AppState,
    ReturnType<typeof unauthenticatedItemForScheduler>
  >(
    (store) => unauthenticatedItemForScheduler(store.scheduling, omsItemID),
    isEqual
  )

  useEffect(() => {
    if (pat) {
      dispatch(fetchScheduledItems(pat))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pat])

  if (!item?.meeting_id) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{t('components.Call.StartSession.defaultItemName')}</title>
      </Helmet>
      <StartSession item={item} />
    </>
  )
}

export default UnauthenticatedCallPage
