import React, { useCallback } from 'react'

import { UserUpdatePayload } from 'store/user/types'
import FormItem, { FormField } from './FormItem'
import TextItem from './TextItem'
import { OnUpdateUser } from './types'
import { InfoItemWrapper } from './wrappers'

interface Props {
  fieldName: string
  formFields: FormField[]
  displayValue?: string | null
  onUpdateUser: OnUpdateUser
  isUpdatingUser?: boolean
}

const PersonalInfoItem: React.FC<Props> = ({
  fieldName,
  formFields,
  onUpdateUser,
  displayValue,
  isUpdatingUser,
}) => {
  // State to open & close the form
  const [editing, setEditing] = React.useState(false)

  // Open & close the form
  const handleClick =
    formFields.length > 0
      ? (isEditing: boolean) => {
          setEditing(isEditing)
        }
      : null

  const handleUpdateUser = useCallback(
    async (data: UserUpdatePayload) => {
      const errors = await onUpdateUser(data)

      if (!errors) {
        setEditing(false)
      }

      return errors
    },
    [onUpdateUser, setEditing]
  ) as unknown as OnUpdateUser

  return (
    <InfoItemWrapper>
      {!editing && (
        <TextItem
          fieldName={fieldName}
          handleClick={handleClick}
          value={displayValue}
        />
      )}
      {editing && (
        <FormItem
          fieldName={fieldName}
          formFields={formFields}
          onUpdateUser={handleUpdateUser}
          setEditing={setEditing}
          isUpdating={isUpdatingUser}
        />
      )}
    </InfoItemWrapper>
  )
}

export default PersonalInfoItem
