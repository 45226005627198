import React from 'react'
import { Route, Redirect, RedirectProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePATAuth from 'hooks/usePATAuth'
import useAuth0 from 'components/Auth/useAuth0'
import Loader from 'components/shared/Loader'
import { ProtectedRouteProps } from './ProtectedRoute'

/**
 * PATProtectedRoute works like ProtectedRoute but allows the user to
 * authenticate with a products access token provided by a query param pat OR if
 * they are logged in.
 */
const PATProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { t } = useTranslation()
  const { loggedIn } = useAuth0()
  const { isLoading, error, hasPATParam } = usePATAuth()
  const redirectProps: RedirectProps = {
    to: t('routes.login'),
    ...(props.RedirectProps || {}),
  }

  if (loggedIn) {
    return <Route {...props} />
  }

  if (!hasPATParam || error) {
    return <Redirect {...redirectProps} />
  }

  if (isLoading) {
    return <Loader />
  }

  return <Route {...props} />
}

export default PATProtectedRoute
