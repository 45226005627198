import { APIError } from 'utils/axios'
import { AppThunkAction } from 'store'
import { fetchFilesForUser } from 'store/files/actions'
import { clearAttachments } from 'store/attachments/actions'
import { Message, SendMessagePayload } from './types'
import { sendUserMesssage, SendMessageSuccess } from './actions'
import { UserIdentifier } from 'store/user/types'
import { fetchOrdersAndOtherMetadataForUser } from 'store/orders/thunks'

export const sendMessageAndUpdateOrder =
  (
    data: SendMessagePayload,
    userIdentifier: UserIdentifier = 'me'
  ): AppThunkAction<Message | null> =>
  async (dispatch) => {
    const message = await dispatch(sendMessageThunk(userIdentifier, data))

    if ('id' in message) {
      // After sending a message, we must fetch the files in order to show them as
      // an attachment.
      if (message.attachment_ids.length > 0) {
        dispatch(fetchFilesForUser(userIdentifier))
      }

      // We also must fetch the orders because the order state will have advanced
      dispatch(fetchOrdersAndOtherMetadataForUser(userIdentifier))

      return message
    }

    return null
  }

export const sendMessageThunk =
  (
    userIdentifier: UserIdentifier,
    data: SendMessagePayload
  ): AppThunkAction<Message | APIError> =>
  async (dispatch, getStore): Promise<Message | APIError> => {
    const attachmentIDs = Object.values(getStore().attachments.toAttachmentID)

    try {
      const val = (await dispatch(
        sendUserMesssage(userIdentifier, {
          ...data,
          portal_attachment_ids: attachmentIDs,
        })
      )) as unknown as SendMessageSuccess

      dispatch(clearAttachments())

      return val.payload.data
    } catch (e) {
      return e as APIError
    }
  }
