import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { ItemTypes } from 'store/items/types'

import SvgCoverLetter from 'assets/images/upsell-cover-letter.svg'
import SvgInterviewCoaching from 'assets/images/upsell-top-interview.svg'
import SvgLinkedIn from 'assets/images/upsell-linkedin.svg'
import SvgPhoneConsultation from 'assets/images/upsell-phone.svg'
import SvgRecruiter from 'assets/images/upsell-recruiter-com.svg'
import SvgResume from 'assets/images/resume.svg'
import SvgResumeDistribution from 'assets/images/upsell-resume-rabbit.svg'
import SvgThankYouLetter from 'assets/images/upsell-thank-you-letter.svg'
import Svg48HourRush from 'assets/images/upsell-rush.svg'
import Svg60DayGuarantee from 'assets/images/upsell-guarantee.svg'

export interface OrderItemIconProps {
  itemType: ItemTypes
  itemName: string
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      maxHeight: 'inherit',
      maxWidth: 'inherit',
    },
  })
)

const UpsellIcon: React.FC<OrderItemIconProps> = ({
  itemType,
  itemName,
  className,
}) => {
  const classes = useStyles()
  let icon = SvgResume

  switch (itemType) {
    case ItemTypes.CoverLetter:
      icon = SvgCoverLetter
      break
    case ItemTypes.Resume:
      icon = SvgResume
      break
    case ItemTypes.InterviewPrep:
      icon = SvgInterviewCoaching
      break
    case ItemTypes.LinkedInDocument:
      icon = SvgLinkedIn
      break
    case ItemTypes.PhoneCall:
      icon = SvgPhoneConsultation
      break
    case ItemTypes.RecruiterService:
      icon = SvgRecruiter
      break
    case ItemTypes.PostingService:
      icon = SvgResumeDistribution
      break
    case ItemTypes.ThankYouLetter:
      icon = SvgThankYouLetter
      break
    case ItemTypes.RushOrder:
      icon = Svg48HourRush
      break
    case ItemTypes.Guarantee:
      icon = Svg60DayGuarantee
      break
    default:
      icon = SvgResume
  }

  return (
    <img
      alt={`${itemName} icon`}
      className={clsx(classes.img, className)}
      src={icon}
    />
  )
}

export default UpsellIcon
