import React from 'react'
import { useSelector } from 'react-redux'
import { ReactIntercom } from 'react-intercom'
import isEqual from 'lodash/isEqual'

import { intercomConfig } from 'store/config/selectors'
import { AppState } from 'store'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const IntercomContainer: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const excludedRoutes = [t(`routes.messages`), t(`routes.login`)]

  const config = useSelector<AppState, ReturnType<typeof intercomConfig>>(
    (state) => intercomConfig(state.config, state.user, state.orders),
    isEqual
  )

  if (
    !config?.app_id ||
    !showIntercomOnThisRoute(excludedRoutes, location.pathname)
  ) {
    return null
  }

  return <ReactIntercom {...config} />
}

function showIntercomOnThisRoute(
  excludedRoutes: string[],
  currentRoute: string
) {
  return !excludedRoutes.find((route) => currentRoute.includes(route))
}

export default IntercomContainer
