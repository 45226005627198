import min from 'lodash/min'
import { differenceInDays, compareAsc } from 'date-fns'
import i18n from 'i18next'
import every from 'lodash/every'

import { futureDate } from 'utils/formatting'
import {
  FaqsDisplayProps,
  OrdersState,
  PackageDisplayProps,
  OrderStatusTypes,
  Order,
  OrderStatus,
  FullOrderStatus,
  Expert,
  PackageGroup,
  PackageResponse,
  OrderAggregate,
  sortOrder,
  ClientIDWithStatus,
  ClientStatus,
  PackageExt,
  callOrDraftStatuses,
} from './types'
import { activeOrderReducer } from './order_selector'
import { ItemsState, Item, ItemStatusTypes, ItemTypes } from 'store/items/types'
import {
  itemsForActiveOrdersWithDocuments,
  itemsForActiveOrders,
  itemsByIDs,
  stepItemStatus,
} from 'store/items/selectors'
import { FilesState } from 'store/files/types'
import { OrderActions } from './actions'
import { RatingState } from 'store/ratings/types'

export const allOrderIDs = (state: OrdersState) =>
  Object.values(state.orders).map((order) => order.id)

export const packageSummary = (packages: PackageExt[], state: ItemsState) =>
  packages
    .slice()
    .sort((a: PackageResponse, b: PackageResponse) => (a.id <= b.id ? -1 : 1))
    .filter(
      (pkg) => pkg.items.filter((item) => item.requires_document).length > 0
    )
    .map((pkg, i, pkgs) => {
      const pkgName: string =
        pkg.itemIDs.length === 1
          ? i18n.t(`itemTypes.${state.items[pkg.itemIDs[0]].item_type}`)
          : pkg.name
      if (i === 0) {
        return pkg.name
      } else if (i === pkgs.length - 1) {
        return ' ' + i18n.t('pages.Portfolio.nameAnd') + ' ' + pkgName
      } else if (i === 1) {
        return ' ' + i18n.t('pages.Portfolio.nameWith') + ' ' + pkgName
      } else {
        return ', ' + pkgName
      }
    })
    .join('')

export const clientIDs = (state: OrdersState): number[] =>
  Array.from(state.clientIDs)

export const clientIDsWithStatus = (
  state: OrdersState
): ClientIDWithStatus[] => {
  const clientIDGroups: ClientIDWithStatus[] = []
  const uniqueGroups = new Set<string>()
  Object.values(activeOrders(state))
    .flatMap((o) => o.expert_hub_client_ids)
    .map(
      (id) =>
        ({
          clientID: id,
          status: ClientStatus.active,
          key: id + '-' + ClientStatus.active,
        } as ClientIDWithStatus)
    )
    .forEach((val) => {
      if (!uniqueGroups.has(val.key)) {
        uniqueGroups.add(val.key)
        clientIDGroups.push(val)
      }
    })
  Object.values(completedOrders(state))
    .flatMap((o) => o.expert_hub_client_ids)
    .map(
      (id) =>
        ({
          clientID: id,
          status: ClientStatus.completed,
          key: id + '-' + ClientStatus.completed,
        } as ClientIDWithStatus)
    )
    .forEach((val) => {
      if (!uniqueGroups.has(val.key)) {
        uniqueGroups.add(val.key)
        clientIDGroups.push(val)
      }
    })

  return clientIDGroups
}

export const activePackagesByClientID = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  clientID: number | null
): PackageGroup | null => {
  if (!clientID) return null
  const orders = activeOrders(ordersState).filter((o) =>
    o.expert_hub_client_ids.find((id) => id === clientID)
  )
  return packagesByClientID(itemsState, clientID, orders)
}

export const completedPackagesByClientID = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  clientID: number | null
): PackageGroup | null => {
  if (!clientID) return null
  const orders = completedOrders(ordersState).filter((o) =>
    o.expert_hub_client_ids.find((id) => id === clientID)
  )
  return packagesByClientID(itemsState, clientID, orders)
}

export const mostRecentOrder = (state: OrdersState): Order =>
  Object.values(state.orders)?.[0]

export const packagesByClientID = (
  itemsState: ItemsState,
  clientID: number,
  orders: Order[]
): PackageGroup | null => {
  if (orders.length === 0) {
    return null
  }
  const packages = orders
    .flatMap((o) => o.packages)
    .filter((p) => p.expert_hub_client_id === clientID)
    .map((p) => ({ ...p, items: p.itemIDs.map((i) => itemsState.items[i]) }))

  const packageGroup: PackageGroup = {
    expert_hub_client_id: clientID,
    summary: packageSummary(packages, itemsState),
    items: packages
      .flatMap((p) => p.itemIDs)
      .map((itemID) => itemsState.items[itemID]),
    packages: packages,
    items_summary: orders[0].brand_order_description,
  }
  return packageGroup
}

export const packageGroup = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  clientID: number
): PackageGroup | null => {
  if (!ordersState.packagesByClientID[clientID]) return null
  const pkgs: PackageResponse[] = Object.assign(
    ordersState.packagesByClientID[clientID]
  )
  const orderID = ordersState.byPackageID[pkgs[0].id]
  const orderDescription = Object.values(ordersState.orders)
    .filter((order) => order.id === orderID)
    .map((order) => order.brand_order_description)
  const pkgGroup: PackageGroup = {
    expert_hub_client_id: Number(pkgs[0].expert_hub_client_id),
    summary: packageSummary(
      Object.values(ordersState.orders)
        .flatMap((o) => o.packages)
        .map((p) => ({
          ...p,
          items: p.itemIDs.map((i) => itemsState.items[i]),
        })),
      itemsState
    ),
    items: pkgs.flatMap(({ items }) => items),
    packages: pkgs,
    items_summary: orderDescription[0],
  }
  return pkgGroup
}

export const allOrders = (state: OrdersState) => Object.values(state.orders)

export const packageDisplayProps = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  filesState: FilesState,
  ratingsState: RatingState
): PackageDisplayProps[] =>
  activeOrdersCombined(ordersState).map((order) => {
    const packageDisplays: PackageDisplayProps = {
      orderID: order.id,
      name: packageSummary(
        order.packages.map((p) => ({
          ...p,
          items: p.itemIDs.map((i) => itemsState.items[i]),
        })),
        itemsState
      ),
      datePurchased: min(
        order.packages.map((pkg) => pkg.date_purchased)
      ) as Date,
      items: itemsForActiveOrdersWithDocuments(
        itemsState,
        ordersState,
        filesState,
        ratingsState
      ),
      expert: expertForOrder(ordersState, order.id),
      currentOrderStatus: currentOrderStatus(order.status),
      aggregatedOrderIDs: Array.from(order.orderIDs),
    }
    return packageDisplays
  })

export const reassignedOrderIDs = (state: OrdersState): number[] => {
  return Object.values(state.orders).flatMap((o) => o.original_order_id || [])
}

export const activeOrdersCombined = (
  ordersState: OrdersState
): OrderAggregate[] => {
  const allActiveOrders = activeOrders(ordersState)
  if (allActiveOrders.length === 0) return [] // no active orders
  const ordersCombined: OrderAggregate[] = []
  if (allActiveOrders.length === 1) {
    // don't reduce if there is only one active order
    ordersCombined.push({
      ...allActiveOrders[0],
      orderIDs: new Set<number>([allActiveOrders[0].id]),
    })
  } else {
    // reduce all active orders down to one active order
    const activeOrdersReduced = allActiveOrders.reduce(activeOrderReducer)
    const orderIDs: Set<number> = new Set<number>(
      allActiveOrders.flatMap((order) => order.id)
    )
    const aggregateOrder: OrderAggregate = {
      ...activeOrdersReduced,
      orderIDs: orderIDs,
    }
    ordersCombined.push(aggregateOrder)
  }
  return ordersCombined
}

export const activeOrders = (state: OrdersState) => {
  const reassigned = reassignedOrderIDs(state)

  return allOrders(state).filter(
    (order) =>
      // An order is "active" if it isn't completed
      !order.status.completed.find(
        (status) => status.status === OrderStatusTypes.Completed
      ) && !reassigned.includes(order.id)
  )
}

export const completedOrders = (state: OrdersState) => {
  const reassigned = reassignedOrderIDs(state)

  return allOrders(state).filter(
    (order) =>
      order.status.completed.find(
        ({ status }) => status === OrderStatusTypes.Completed
      ) && !reassigned.includes(order.id)
  )
}

export const currentOrders = (state: OrdersState) => {
  const reassigned = reassignedOrderIDs(state)
  return allOrders(state).filter(
    (order) =>
      // An order is "current" if it isn't completed or completed
      // within the previous 7 days
      !order.status.completed.find(
        (status) =>
          status.status === OrderStatusTypes.Completed &&
          differenceInDays(new Date(), status.completed_at) >= 7
      ) && !reassigned.includes(order.id)
  )
}

export const activeOrdersByClient = (
  state: OrdersState,
  clientID: number
): Order[] | null => {
  if (!state.byClientID[clientID]) return null
  return Array.from(state.byClientID[clientID])
    .map((orderID) => state.orders[orderID])
    .filter(
      (order) =>
        !order.status.completed.find(
          (status) => status.status === OrderStatusTypes.Completed
        )
    )
    .sort((a, b) => b.id - a.id)
}

export const currentOrdersByClient = (
  state: OrdersState,
  clientID: number
): Order[] | null => {
  if (!state.byClientID[clientID]) return null
  return Array.from(state.byClientID[clientID])
    .map((orderID) => state.orders[orderID])
    .filter(
      (order) =>
        // An order is "current" if it isn't completed or completed
        // within the previous 7 days
        !order.status.completed.find(
          (status) =>
            status.status === OrderStatusTypes.Completed &&
            differenceInDays(new Date(), status.completed_at) >= 7
        )
    )
    .sort((a, b) => b.id - a.id)
}

export const pastOrders = (state: OrdersState) => {
  const reassigned = reassignedOrderIDs(state)

  return allOrders(state).filter(
    (order) =>
      // An order is "past" if it was completed
      order.status.completed.find(
        (status) => status.status === OrderStatusTypes.Completed
      ) && !reassigned.includes(order.id)
  )
}

export const pastOrdersByClient = (
  state: OrdersState,
  clientID: number
): Order[] | null => {
  if (!state.byClientID[clientID]) return null
  return Array.from(state.byClientID[clientID])
    .map((orderID) => state.orders[orderID])
    .filter((order) =>
      // An order is "past" if it was completed more than 7 days ago
      order.status.completed.find(
        (status) =>
          status.status === OrderStatusTypes.Completed &&
          differenceInDays(new Date(), status.completed_at) >= 7
      )
    )
    .sort((a, b) => {
      //sorting orders by completed date desc
      const aDate =
        a.status.completed.find(
          (status) => status.status === OrderStatusTypes.Completed
        )?.completed_at || new Date()

      const bDate =
        b.status.completed.find(
          (status) => status.status === OrderStatusTypes.Completed
        )?.completed_at || new Date()

      return bDate.getTime() - aDate.getTime()
    })
}

export const latestOrderByClient = (
  state: OrdersState,
  clientID: number | null
): Order | null => {
  if (!clientID) return null
  const curOrders = currentOrdersByClient(state, clientID)
  if (!curOrders) return null
  if (curOrders.length > 0) return curOrders[0]

  const pastOrdrs = pastOrdersByClient(state, clientID)
  if (!pastOrdrs) return null
  if (pastOrdersByClient.length > 0) return pastOrdrs[0]

  return null
}

export const revisionPeriodByClient = (
  state: OrdersState,
  clientID: number | null
): Date | null => {
  if (!clientID) return null
  const latestOrdr = latestOrderByClient(state, clientID)
  if (!latestOrdr) return null
  let revPeriod = latestOrdr.status.active.find(
    (status) => status.status === OrderStatusTypes.RevisionPeriod
  )?.expected_at
  if (!revPeriod) {
    revPeriod = latestOrdr.status.completed.find(
      (status) => status.status === OrderStatusTypes.RevisionPeriod
    )?.expected_at
  }
  if (!revPeriod) return null
  return revPeriod
}

export const revisionPeriodForOrder = (state: OrdersState, orderID: number) =>
  state.orders[orderID].status.active.find(
    (s) => s.status === OrderStatusTypes.RevisionPeriod
  )?.expected_at ||
  state.orders[orderID].status.completed.find(
    (s) => s.status === OrderStatusTypes.RevisionPeriod
  )?.completed_at

export const firstDraftDueForOrder = (state: OrdersState, orderID: number) =>
  state.orders[orderID].status.active.find(
    (s) => s.status === OrderStatusTypes.FirstDraft
  )?.expected_at ||
  state.orders[orderID].status.completed.find(
    (s) => s.status === OrderStatusTypes.FirstDraft
  )?.completed_at

export const faqsDisplayProps = (
  ordersState: OrdersState,
  itemsState: ItemsState
): FaqsDisplayProps[] => {
  const activeOrderFaqs = activeOrdersCombined(ordersState)
  return activeOrderFaqs.map((order) => {
    const currentStatus: OrderStatus | null = currentOrderStatus(order.status)
    const faqs: FaqsDisplayProps = {
      currentStatus: currentStatus,
    }
    return faqs
  })
}

export const filterTimelineLength = (statuses: FullOrderStatus) => {
  let maxShow = 5 // show a max of 5 statuses on the timeline
  let showCompleted = 2 // by default, show last 2 completed status, first 3 active status
  const filtered: FullOrderStatus = {
    active: [],
    completed: [],
  }
  const active = statuses.active.filter(
    ({ status }) => status !== OrderStatusTypes.RevisionPeriodStart
  )
  const completed = statuses.completed.filter(
    ({ status }) => status !== OrderStatusTypes.RevisionPeriodStart
  )

  // sometimes there are less statuses than maxShow, adjust for that
  if (active.length + completed.length < maxShow) {
    maxShow = active.length + completed.length
  }
  // sometimes we don't have many active, and we can show more completed
  if (active.length + showCompleted < maxShow) {
    showCompleted = maxShow - active.length
  }
  // sometimes we don't have many completed
  if (showCompleted > completed.length) {
    showCompleted = completed.length
  }
  // show showCompleted from the end of the completed array
  filtered.completed = completed.slice(
    completed.length - showCompleted,
    completed.length
  )
  // show the rest from the beginning of the active array
  filtered.active = statuses.active.slice(
    0,
    maxShow - filtered.completed.length
  )
  return filtered
}

export const currentOrderStatus = (
  statuses: FullOrderStatus
): OrderStatus | null => {
  if (!statuses.active.length) {
    return (
      statuses.completed.find(
        (status) => status.status === OrderStatusTypes.Completed
      ) || null
    )
  }

  const firstActiveStatus = statuses.active[0]

  // If the current status is revision period, we need to ensure that the
  // created_at time is when the revision period started. Because there is no
  // contract of order statuses being created when an event happens, we need to
  // match the time to the completed_at of the first draft status.
  if (firstActiveStatus.status === OrderStatusTypes.RevisionPeriod) {
    const firstDraftStatus = statuses.completed.find(
      ({ status }) => status === OrderStatusTypes.FirstDraft
    )

    if (
      firstDraftStatus &&
      firstActiveStatus.created_at.getTime() !==
        firstDraftStatus.completed_at.getTime()
    ) {
      return {
        ...firstActiveStatus,
        created_at: firstDraftStatus.completed_at,
      }
    }
  }

  return firstActiveStatus
}

// remove any status steps from the timeline that shouldn't be shown
export const filterTimelineStatuses = (
  statuses: FullOrderStatus,
  itemsState: ItemsState
): FullOrderStatus => {
  // filter out statuses from the timeline steps by type
  const filterStatusStep = (status: OrderStatus) => {
    if (status.status === OrderStatusTypes.FirstDraft) {
      // for first draft, check that all status items require documents
      const itemStatus: string = ItemStatusTypes.FirstDraft
      const statusItemsWithDocuments: Item[] = itemsByIDs(
        itemsState,
        status.itemIDs
      ).filter(
        (item) =>
          item.requires_document === true &&
          stepItemStatus(item, itemStatus) !== null
      )
      return statusItemsWithDocuments.length > 0
    } else if (
      status.status === OrderStatusTypes.Open &&
      (statuses.completed.find(
        (status) => status.status === OrderStatusTypes.CompleteQuestionnaire
      ) || null) !== null
    ) {
      // we need to filter out the 'open' status, if they have a completed 'questionnaire'
      return false
    } else if (status.status === OrderStatusTypes.Completed) {
      // do not show Completed status on timeline
      return false
    } else if (status.status === OrderStatusTypes.LinkedInDue) {
      // we don't need the LinkedInDue status, if the 'linked-in' item is in 'first-draft'
      // get the linkedInItem for this status
      const linkedInItem: Item | null =
        itemsByIDs(itemsState, status.itemIDs).find(
          (item) => item.item_type === ItemTypes.LinkedInDocument
        ) || null

      if (linkedInItem === null) {
        return false
      } else {
        const firstDraftHasLinkedIn: boolean = statuses.active
          .concat(statuses.completed)
          .filter((s) => s.status === OrderStatusTypes.FirstDraft)
          .flatMap((s) => s.itemIDs)
          .includes(linkedInItem.id || 0)
        if (firstDraftHasLinkedIn) return false
        return true
      }
    } else if (status.status === OrderStatusTypes.RevisionPeriodStart) {
      return false
    } else {
      // otherwise allow this status on timeline
      return true
    }
  }

  const orderStatuses: FullOrderStatus = { ...statuses } // create a copy of statuses
  // overwrite the active status array, remove the 'completed' step
  orderStatuses.active = statuses.active
    .filter((status) => filterStatusStep(status))
    .sort((a, b) => {
      if (sortOrder[a.status] < sortOrder[b.status]) {
        return -1
      } else if (sortOrder[a.status] > sortOrder[b.status]) {
        return 1
      } else {
        // same status sort level, compare dates
        // (choose a date far in the future, if null)
        return compareAsc(
          a.expected_at || futureDate(),
          b.expected_at || futureDate()
        )
      }
    })

  // overwrite the completed status array, remove the 'completed' step
  orderStatuses.completed = statuses.completed.filter((status) =>
    filterStatusStep(status)
  )
  return orderStatuses
}

const phoneCallStatuses = Object.freeze([
  OrderStatusTypes.Phone,
  OrderStatusTypes.SchedulePhone,
  OrderStatusTypes.Interview,
  OrderStatusTypes.ScheduleInterview,
])

const phoneStatuses = Object.freeze([
  OrderStatusTypes.Phone,
  OrderStatusTypes.SchedulePhone,
])

const interviewStatuses = Object.freeze([
  OrderStatusTypes.Interview,
  OrderStatusTypes.ScheduleInterview,
])

export const upcomingPhoneCall = (
  ordersState: OrdersState,
  itemsState: ItemsState
):
  | [OrderStatus, Item, number]
  | [null, null, null]
  | [OrderStatus, null, null] => {
  const activeStatuses = Object.values(ordersState.orders)
    .flatMap((order) => order.status.active)
    .filter((status) => status.purchase_item_id)
    .sort((a, b) => b.created_at.getTime() - a.created_at.getTime())

  const hasExpertBeenAssigned = !activeStatuses.find(
    ({ status }) => status === OrderStatusTypes.ExpertAssignment
  )

  if (!hasExpertBeenAssigned) {
    return [null, null, null]
  }

  const status =
    activeStatuses.find(({ status }) => phoneCallStatuses.includes(status)) ||
    null

  if (!status || !status.purchase_item_id) {
    return [null, null, null]
  }

  if (!itemsState.items[status.purchase_item_id]) {
    return [status, null, null]
  }

  const item = itemsState.items[status.purchase_item_id]
  const orderID =
    Object.entries(itemsState.byOrderID).find(
      ([, itemIDs]) => item && itemIDs.has(item.id)
    )?.[0] || '0'

  return [status, item, parseInt(orderID)]
}

export const upcomingVoiceDrivenItems = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  orderID: number
): Item[] => {
  if (!ordersState.orders[orderID]) {
    return []
  }
  const activeStatuses = ordersState.orders[orderID].status.active
    .filter((status) => status.purchase_item_id)
    .sort((a, b) => b.created_at.getTime() - a.created_at.getTime())

  const hasExpertBeenAssigned = !activeStatuses.find(
    ({ status }) => status === OrderStatusTypes.ExpertAssignment
  )

  if (!hasExpertBeenAssigned) {
    return []
  }

  const results = []

  const earliestPhoneCallStatus = activeStatuses.find(({ status }) =>
    phoneStatuses.includes(status)
  )
  if (!!earliestPhoneCallStatus && earliestPhoneCallStatus.purchase_item_id) {
    const phoneCall = itemsState.items[earliestPhoneCallStatus.purchase_item_id]
    if (!!phoneCall) {
      results.push(phoneCall)
    }
  }

  const earliestInterviewStatus = activeStatuses.find(({ status }) =>
    interviewStatuses.includes(status)
  )
  if (!!earliestInterviewStatus && earliestInterviewStatus.purchase_item_id) {
    const interview = itemsState.items[earliestInterviewStatus.purchase_item_id]
    if (!!interview) {
      results.push(interview)
    }
  }

  return results.filter(
    (item) =>
      (item.item_type === ItemTypes.PhoneCall ||
        item.item_type === ItemTypes.PersonalConsultation) &&
      !!item.status.active.find(
        ({ state }) =>
          state === ItemStatusTypes.ScheduleInterview ||
          state === ItemStatusTypes.SchedulePhone ||
          state === ItemStatusTypes.ConductCall ||
          state === ItemStatusTypes.ConductInterview
      )
  )
}

export const expertForOrder = (
  state: OrdersState,
  orderID: number
): Expert | null => state.orders[orderID]?.experts?.[0] || null

export const expertsForOrder = (
  state: OrdersState,
  orderID: number
): Expert[] => (!!state.orders[orderID] ? state.orders[orderID].experts : [])

export const expertForOrderIDs = (
  state: OrdersState,
  orderIDs: number[]
): Expert | null => {
  // return the first expert found in the list of orderIDs
  let expert: Expert | null = null
  for (let i = 0; i < orderIDs.length; i++) {
    expert = expertForOrder(state, orderIDs[i])
    if (expert !== null) break
  }
  return expert
}

export const latestOrder = (state?: OrdersState): Order | null => {
  if (!state) return null

  const orders = Object.values(state.orders).sort((a, b) => a.id - b.id)
  if (orders.length === 0) return null
  return orders[orders.length - 1]
}

export const latestOrderWithoutRating = (
  orderState: OrdersState,
  ratingState: RatingState
): Order | null => {
  const completedOrderIDs = completedOrders(orderState).map((o) => o.id)
  if (completedOrderIDs.length === 0) {
    return null
  }
  const orderId =
    Object.entries(ratingState.nps).filter(
      ([id, nps]) =>
        nps.can_submit && completedOrderIDs.indexOf(parseInt(id)) >= 0
    )?.[0]?.[0] || null
  if (!orderId) {
    return null
  }
  return orderState.orders[parseInt(orderId)]
}

export const ordersFinishedLoading = (state: OrdersState): boolean =>
  state.meta[OrderActions.FETCH_ORDERS_FOR_USER].loaded ||
  Object.keys(state.orders).length > 0

// completedFirstDraft - return true if all statuses for 'first-draft' are 'completed'
// check item statuses and order statuses, in all active orders
export const completedFirstDraft = (
  ordersState: OrdersState,
  itemsState: ItemsState
): boolean => {
  const orders = activeOrders(ordersState)
  const fdOrders = orders.filter((order) =>
    order.status.active.find(
      (status) => status.status === OrderStatusTypes.FirstDraft
    )
  )
  const allItemsHaveFirstDrafts = every(
    itemsForActiveOrders(itemsState, ordersState)
      .filter((item) => item.requires_document)
      .map(
        (item) =>
          !!item.status.completed.find(
            ({ state }) =>
              state === ItemStatusTypes.FirstDraft ||
              state === ItemStatusTypes.SendLinkedin
          )
      )
  )
  return fdOrders.length === 0 && allItemsHaveFirstDrafts && orders.length > 0
}

export const orderIsCompleted = (state: OrdersState, orderID: number) =>
  state.orders[orderID]?.status.active.length === 0 || false

export const isInterviewPrepClient = (
  state: OrdersState,
  clientID: number | null
) => {
  if (!clientID) return false
  return !(
    state.packagesByClientID[clientID]
      ?.flatMap((p) => p.items.flatMap((i) => i.item_type))
      .filter((x) => x !== ItemTypes.InterviewPrep).length > 0
  )
}

export const earliestActiveOrder = (state: OrdersState) => {
  const orders = activeOrders(state).sort(
    (a, b) => a.created_at.getTime() - b.created_at.getTime()
  )
  if (orders.length === 0) return null
  return orders[0]
}

export const activeDocumentItems = (
  orderState: OrdersState,
  itemState: ItemsState
) => {
  return activeOrders(orderState)
    .flatMap((o) => o.packages.flatMap((p) => p.itemIDs))
    .map((i) => itemState.items[i])
    .filter((i) => i.requires_document)
}

export const formattedOrderID = (order: Order): string =>
  order.expert_hub_client_ids?.length > 0
    ? `${order.id}-${order.expert_hub_client_ids[0]}`
    : order.id.toString()

export const orderLoaded = (state: OrdersState, orderID: number): boolean => {
  if (!state.meta.FETCH_ORDER_FOR_USER[orderID]) return false
  return state.meta.FETCH_ORDER_FOR_USER[orderID].loaded
}

const ordersWithDraftOrCallPending = (state: OrdersState) => {
  const invalidOrderIDs = reassignedOrderIDs(state)
  return allOrders(state).filter(
    (order) =>
      !invalidOrderIDs.includes(order.id) &&
      order.status.active.filter(
        (status) =>
          callOrDraftStatuses.has(status.status) && !status.completed_at
      ).length > 0
  )
}

export const draftOrCallPending = (state: OrdersState): boolean =>
  ordersWithDraftOrCallPending(state).length > 0

export const allDraftOrCallCompleted = (state: OrdersState): boolean =>
  ordersWithDraftOrCallPending(state).length === 0
