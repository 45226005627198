import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import NoPortfolioPackage from './NoPackage'
import PastItems from './PastItems'
import PortfolioPackage from './Package'
import { AppState } from 'store'
import { packageDisplayProps } from 'store/orders/selectors'
import { itemsForPastOrdersWithDocuments } from 'store/items/selectors'
import { DocumentModal } from 'components/Documents/DocumentModal'

const PortfolioContainer: React.FC = () => {
  const currentPackages = useSelector<
    AppState,
    ReturnType<typeof packageDisplayProps>
  >(
    (state) =>
      packageDisplayProps(
        state.orders,
        state.items,
        state.files,
        state.ratings
      ),
    isEqual
  )

  const pastItems = useSelector<
    AppState,
    ReturnType<typeof itemsForPastOrdersWithDocuments>
  >(
    (state) =>
      itemsForPastOrdersWithDocuments(
        state.items,
        state.orders,
        state.files,
        state.ratings
      ),
    isEqual
  )

  const [openDocView, setOpenDocView] = useState(false)
  const [selectedItemID, setSelectedItemID] = useState<null | number>(null)

  const handleClose = useCallback(() => {
    setOpenDocView(false)
    setSelectedItemID(null)
  }, [])

  const handlePastDocView = useCallback((itemID: number | null) => {
    setSelectedItemID(itemID)
    setOpenDocView(true)
  }, [])

  const showNoPortfolio = currentPackages.length === 0
  const showPastItems = pastItems.length > 0

  return (
    <>
      {showNoPortfolio && <NoPortfolioPackage />}
      {currentPackages.map((pkg) => (
        <PortfolioPackage key={pkg.orderID} {...pkg} />
      ))}
      {showPastItems && (
        <PastItems items={pastItems} handleViewClick={handlePastDocView} />
      )}
      <DocumentModal
        open={openDocView}
        handleClose={handleClose}
        itemID={selectedItemID}
      />
    </>
  )
}

export default PortfolioContainer
