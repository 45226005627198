import { ReactIntercom } from 'react-intercom'

import {
  ConfigState,
  IntercomConfigVariables,
  Auth0Config,
  EnvConfig,
} from './types'
import { UserState } from 'store/user/types'
import { OrdersState } from 'store/orders/types'
import { mostRecentOrder } from 'store/orders/selectors'

export const envConfig = (
  hostname: string = window.location.hostname
): EnvConfig | null => {
  const config: EnvConfig = {}

  // If we have the Auth0 details baked into the bundle, use them instead of
  // waiting for the API to return them.
  if (
    hostname?.includes('zipjob') &&
    process.env.REACT_APP_ZIPJOB_AUTH0_CLIENT_ID &&
    process.env.REACT_APP_ZIPJOB_AUTH0_DOMAIN
  ) {
    config.auth0 = {
      clientID: process.env.REACT_APP_ZIPJOB_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_ZIPJOB_AUTH0_DOMAIN,
    }
  }

  if (
    process.env.REACT_APP_TALENTINC_AUTH0_CLIENT_ID &&
    process.env.REACT_APP_TALENTINC_AUTH0_DOMAIN
  ) {
    config.auth0 = {
      clientID: process.env.REACT_APP_TALENTINC_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_TALENTINC_AUTH0_DOMAIN,
    }
  }

  if (!config) {
    return null
  }

  return config
}

export const appAuth0Config = (
  state: ConfigState,
  hostname?: string
): Auth0Config | null => {
  // Always prefer what the server provides us
  if (state.config?.auth0) {
    return state.config.auth0
  }

  const envAuth0Config = envConfig(hostname)?.auth0

  if (envAuth0Config) {
    return envAuth0Config
  }

  return null
}

export const intercomConfig = (
  configState: ConfigState,
  userState: UserState,
  ordersState: OrdersState
): Parameters<typeof ReactIntercom>[0] | null => {
  if (!configState.config || !configState.config?.intercom) {
    return null
  }

  // user data
  const { currentUser } = userState
  const user: Partial<Parameters<typeof ReactIntercom>[0]> = {}

  if (currentUser) {
    user.user_id = currentUser.id.toString()
    user.phone = currentUser.phone || undefined

    if (currentUser.first_name || currentUser.last_name) {
      user.name = [currentUser.first_name, currentUser.last_name]
        .filter((n) => !!n)
        .join(' ')
    }
  }

  // order data
  const expertHubInfo: Partial<IntercomConfigVariables> = {}
  const order = mostRecentOrder(ordersState)
  if (order) {
    if (order.expert_hub_client_ids[0]) {
      expertHubInfo.expert_hub_client_url = `https://experts.talentinc.com/client/{$order.expert_hub_client_ids[0]}`
    }
    if (order.experts[0]) {
      expertHubInfo.expert_hub_ghost_url = `https://experts.talentinc.com/?ghost={$order.experts[0].id}`
    }
  }

  return {
    ...configState.config.intercom,
    ...user,
    ...expertHubInfo,
  }
}
