import {
  HashMap,
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'
import { AutoLinkedinAPIResponse } from '@talentinc/linkedin-preview'
import { FileActions } from './actions'

export interface File {
  id: number
  context: 'client'
  context_id: number
  order_id: number | null
  order_item_id: number | null
  document_digest: string | null
  filename: string
  amazon_bucket: string
  amazon_filename: string
  created_at: Date
  updated_at: Date
  revision: number
  rating: number | null
  rated_at: Date | null
  page_count: number | null
  doc_type: string
  approved: boolean
  approved_at: Date | null
  pages: string[]
  download_url: string
  pdf_url: string | null
  size: number
  template_tracking: string | null
}

export interface FilesState {
  files: HashMap<File>
  linkedin: HashMap<AutoLinkedinAPIResponse>
  // These hashmaps of sets allow us to precache selectors that we will use for
  // other views.
  byOrderID: HashMap<Set<number>>
  byOrderItemID: HashMap<Set<number>>
  byMessageID: HashMap<Set<number>>
  byClientID: HashMap<Set<number>>
  meta: {
    [FileActions.FETCH_FILES_FOR_USER]: LoadingLoadedErrorState
  }
}

export const initialState: FilesState = {
  files: {},
  linkedin: {},
  byOrderID: {},
  byOrderItemID: {},
  byMessageID: {},
  byClientID: {},
  meta: {
    [FileActions.FETCH_FILES_FOR_USER]: { ...initialLoadedLoadingErrorState },
  },
}

export type DocumentsByTypeAndOrderItemID<T = File[]> = Map<
  string,
  Map<number, T>
>
export const DocumentOrder = Object.freeze([
  'resume',
  'cv',
  'cover-letter',
  'linkedin-document',
  'additional-document',
])
