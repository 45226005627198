import useModals from 'hooks/useModals'
import isEqual from 'lodash/isEqual'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { latestOrderWithoutRating } from 'store/orders/selectors'
import { Order } from 'store/orders/types'

interface Props {
  shouldShowRatingModal: boolean
}

const RatingModalContainer: React.FC<Props> = ({ shouldShowRatingModal }) => {
  const latestOrderToRate = useSelector<AppState, Order | null>(
    (state: AppState) => latestOrderWithoutRating(state.orders, state.ratings),
    isEqual
  )
  const { modal, onRateClick } = useModals(
    null,
    latestOrderToRate ? latestOrderToRate.id : null
  )

  useEffect(() => {
    if (shouldShowRatingModal && latestOrderToRate) {
      onRateClick()
    }
  }, [latestOrderToRate, shouldShowRatingModal, onRateClick])
  return <>{modal}</>
}

export default RatingModalContainer
