import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import HTMLEmailElement from '@talentinc/html-email-element'
import clsx from 'clsx'

import { File } from 'store/files/types'
import MessageAttachments from './MessageAttachments'

interface Props {
  body: string
  attachments?: File[]
  expandAccordion: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionDetails: {
      flexDirection: 'column',
    },
    button: {
      fontSize: '.88em',
      marginLeft: '-.25em',
      marginTop: '1.4em',
      width: '4em',
    },
    hiddenInput: {
      display: 'none',
    },
    validationRule: {
      paddingTop: '1rem',
    },
    body: {
      '& p:blank': {
        display: 'none',
      },
      '& p:empty': {
        display: 'none',
      },
      '& pre': {
        fontFamily: theme.typography.fontFamily,
        whiteSpace: 'break-spaces',
      },
    },
    plainTextMessage: {
      whiteSpace: 'break-spaces',
    },
  })
)

const MessageDetails: React.FC<Props> = ({
  body,
  attachments,
  expandAccordion,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isPlainText = !body.includes('<')

  return (
    <AccordionDetails className={classes.accordionDetails}>
      <Typography
        variant="body2"
        component="div"
        className={clsx(classes.body, isPlainText && classes.plainTextMessage)}
      >
        <HTMLEmailElement html={body} />
      </Typography>
      {attachments && <MessageAttachments attachments={attachments} />}
      <Button
        className={classes.button}
        color="primary"
        onClick={() => expandAccordion(false)}
      >
        {t('components.Messaging.seeLess')}
      </Button>
    </AccordionDetails>
  )
}

export default MessageDetails
