import React, { useCallback } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles/index'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

interface Props {
  documents: any
  draft: any
  index: number
  popperOpen: boolean
  selectedDocument: number
  setPopperOpen: any
  closeModals: () => void
  setSelectedDocument: (active: number) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    h4: {
      fontWeight: 400,
    },
    icon: {
      height: '100%',
      width: '100%',
    },
    iconButton: {
      borderRadius: '6px',
      color: theme.palette.primary.dark,
      height: '1.75em',
      marginLeft: '.5em',
      width: '2em',
    },
    listItem: {
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: colors.gray.a600,
      },
    },
    listItemText: {
      flexDirection: 'column',
    },
    popper: {
      marginTop: '.5em',
    },
  })
)

const DraftListItem: React.FC<Props> = ({
  documents,
  draft,
  index,
  popperOpen,
  selectedDocument,
  setPopperOpen,
  setSelectedDocument,
  closeModals,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const handleDocumentChange = useCallback(
    (index: number) => {
      setPopperOpen(false)
      closeModals()
      setSelectedDocument(index)
    },
    [setPopperOpen, closeModals, setSelectedDocument]
  )
  return (
    <ListItem
      button={true}
      className={classes.listItem}
      onClick={() => handleDocumentChange(index)}
    >
      <Checkbox checked={selectedDocument === index} />
      <Container className={classes.listItemText} disableGutters>
        <Typography variant="body2">
          {t('pages.Documents.draft', {
            number: documents.length - index,
          })}
        </Typography>
        <Typography className={classes.h4} component="time" variant="h4">
          {dateFormat(draft.created_at, t('pages.Documents.draftDateFormat'))}
        </Typography>
      </Container>
    </ListItem>
  )
}

export default DraftListItem
