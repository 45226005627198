import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import emptyMessageImage from 'assets/images/empty-message.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    body2: {
      fontStyle: 'italic',
      marginTop: '1em',
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    img: {
      height: '9.9em',
      width: '9.9em',
    },
  })
)

const EmptyMessages: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <img
        alt={t('components.Messaging.emptyMessageAlt')}
        className={classes.img}
        src={emptyMessageImage}
      />
      <Typography className={classes.body2} variant="body2">
        {t('components.Messaging.noMessages')}
      </Typography>
    </Container>
  )
}

export default EmptyMessages
