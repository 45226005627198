import React, { useCallback } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { HashMap } from '@talentinc/state-utils'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import useEffectOnce from 'react-use/lib/useEffectOnce'
import { useTranslation } from 'react-i18next'

import Header from 'components/Messaging/Header'
import InfoPopper from 'components/Messaging/InfoPopper'
import Messages from 'components/Messaging/Messages'
import Reply from 'components/Messaging/Reply'
import SkeletonLoading from 'components/shared/Skeleton'
import FlashErrorBoundary from 'components/shared/Errors'

import { AppState } from 'store/index'
import {
  Order,
  PackageGroup,
  ClientIDWithStatus,
  ClientStatus,
} from 'store/orders/types'
import {
  ordersFinishedLoading,
  revisionPeriodByClient,
  latestOrderByClient,
  activePackagesByClientID,
  completedPackagesByClientID,
  isInterviewPrepClient,
} from 'store/orders/selectors'
import {
  activeMessagesByClientIDWithAttachments,
  messagesFinishedLoading,
  completedMessagesWithAttachments,
} from 'store/messages/selectors'
import { Message } from 'store/messages/types'
import {
  markMessageAsRead as reduxMarkMessageAsRead,
  fetchMessagesForUser,
} from 'store/messages/actions'

import useUser from 'hooks/useUser'
import useBrand from 'hooks/useBrand'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { MessageDraftProvider } from 'hooks/useMessageDraft'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      margin: '0',
      position: 'relative',
      width: 'calc(100vw - 55.9em)',

      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 4.5em)',
        left: '0',
        marginTop: '9.5em',
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '24.1em',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 'calc(100vw - 55.9em)',
        width: 'calc((100vw - 1280px)/2 + 1280px - 41.6em)',
      },
    },
    paper: {
      borderRadius: '0',
      borderBottom: '0',
      borderLeft: '0',
      borderTop: '0',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      margin: '0',
      padding: '0',
      position: 'fixed',
      width: 'calc(100vw - 55.9em)',

      [theme.breakpoints.down('md')]: {
        width: '24.1em',
      },

      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 4.5em)',
        left: '0',
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 'calc(100vw - 55.9em)',
        width: 'calc((100vw - 1280px)/2 + 1280px - 41.6em)',
      },
    },
  })
)
interface Props {
  clientIDIndex: number
  clientIDWithStatus: ClientIDWithStatus
  totalClientIDs: number
  prevClientID: ClientIDWithStatus | null
  nextClientID: ClientIDWithStatus | null
}
const MessagingContainer: React.FC<Props> = ({
  clientIDIndex,
  clientIDWithStatus,
  totalClientIDs,
  prevClientID,
  nextClientID,
}) => {
  const classes = useStyles()
  const user = useUser()
  const brand = useBrand()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    sendMessages: sendMessagesFeatureFlag,
    markMessagesRead: markMessagesReadFeatureFlag,
  } = useFeatureFlags()
  const clientID = (clientIDWithStatus && clientIDWithStatus.clientID) || null

  // State for setting info popper Open
  const [infoOpen, setInfoOpen] = React.useState(false)

  const msgsFinishedLoading = useSelector<AppState, boolean>((state) =>
    messagesFinishedLoading(state.messages)
  )

  const ordrsFinishedLoading = useSelector<AppState, boolean>((state) =>
    ordersFinishedLoading(state.orders)
  )

  const activeMessagesByClient = useSelector<
    AppState,
    ReturnType<typeof activeMessagesByClientIDWithAttachments>
  >(
    (state: AppState) =>
      activeMessagesByClientIDWithAttachments(
        state.messages,
        state.files,
        state.orders
      ),
    isEqual
  )

  const completedMessagesByClient = useSelector<
    AppState,
    ReturnType<typeof completedMessagesWithAttachments>
  >(
    (state: AppState) =>
      completedMessagesWithAttachments(
        state.messages,
        state.files,
        state.orders
      ),
    isEqual
  )

  const activePackageGroup = useSelector<AppState, PackageGroup | null>(
    (state: AppState) =>
      activePackagesByClientID(state.orders, state.items, clientID),
    isEqual
  )

  const completedPackageGroup = useSelector<AppState, PackageGroup | null>(
    (state: AppState) =>
      completedPackagesByClientID(state.orders, state.items, clientID),
    isEqual
  )

  const latestOrder = useSelector<AppState, Order | null>(
    (state: AppState) => latestOrderByClient(state.orders, clientID),
    isEqual
  )

  const revPeriod = useSelector<AppState, Date | null>(
    (state: AppState) => revisionPeriodByClient(state.orders, clientID),
    isEqual
  )

  const isInterviewPrep = useSelector<AppState, boolean>((state) =>
    isInterviewPrepClient(state.orders, clientID)
  )

  const markMessageAsRead = useCallback(
    (messageID: number) => {
      if (markMessagesReadFeatureFlag) {
        dispatch(reduxMarkMessageAsRead(messageID))
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [markMessagesReadFeatureFlag]
  )

  // Upon opening this page, always fetch messages in case a new one came in
  useEffectOnce(() => {
    dispatch(fetchMessagesForUser())
  })

  const isCompletedView =
    clientIDWithStatus && clientIDWithStatus.status === ClientStatus.completed

  const canReply =
    (!isCompletedView || !activePackageGroup) && sendMessagesFeatureFlag

  const messagesByClient = isCompletedView
    ? completedMessagesByClient
    : activeMessagesByClient
  const packageGroup = isCompletedView
    ? completedPackageGroup
    : activePackageGroup
  const loaded = msgsFinishedLoading && ordrsFinishedLoading
  const senderName = user?.first_name || ''
  const lastMessageSubject = packageGroup
    ? getLastMessageSubject(messagesByClient, packageGroup.expert_hub_client_id)
    : ''
  const subject =
    lastMessageSubject !== ''
      ? `Re: ${lastMessageSubject}`
      : senderName !== ''
      ? t(`components.Messaging.defaultSubject`, { name: senderName })
      : t(`components.Messaging.defaultSubjectNoName`)

  return (
    <MessageDraftProvider
      initialValues={{
        expert_hub_client_id: packageGroup?.expert_hub_client_id || 0,
        subject,
        body: '',
        reply_message_id: '',
        portal_attachment_ids: [],
      }}
    >
      {!loaded && (
        <div className={classes.container}>
          <SkeletonLoading
            components={[
              {
                className: classes.paper,
              },
            ]}
          />
        </div>
      )}
      {loaded && (
        <Container className={classes.container} disableGutters>
          <Paper
            id="message-container"
            className={classes.paper}
            variant="outlined"
          >
            <FlashErrorBoundary>
              {packageGroup && (
                <Header
                  activeNum={clientIDIndex + 1}
                  open={infoOpen}
                  subHeaderText={packageGroup?.summary || ''}
                  popper={false}
                  headerText={packageGroup?.items_summary || ''}
                  setInfoOpen={setInfoOpen}
                  total={totalClientIDs}
                  prevClientID={prevClientID}
                  nextClientID={nextClientID}
                  isCompletedView={isCompletedView}
                />
              )}
              <FlashErrorBoundary>
                <Messages
                  key={`messages-${clientID}`}
                  messages={
                    clientID && messagesByClient[clientID]
                      ? messagesByClient[clientID]
                      : []
                  }
                  user={user}
                  markMessageAsRead={markMessageAsRead}
                />
              </FlashErrorBoundary>
              {packageGroup && canReply && <Reply />}
              {packageGroup && (
                <InfoPopper
                  dueDate={revPeriod}
                  items={packageGroup.items.filter(
                    (item) => item.requires_document
                  )}
                  open={infoOpen}
                  packageName={packageGroup.summary}
                  product={packageGroup.items_summary}
                  setInfoOpen={setInfoOpen}
                  supportEmail={brand?.default_support_email || ''}
                  supportPhone={brand?.default_phone_number || ''}
                  writer={latestOrder?.experts?.[0] || null}
                  prevClientID={prevClientID}
                  nextClientID={nextClientID}
                  isInterviewPrep={isInterviewPrep}
                />
              )}
            </FlashErrorBoundary>
          </Paper>
        </Container>
      )}
    </MessageDraftProvider>
  )
}

function getLastMessageSubject(
  messages: HashMap<Message[]>,
  clientID: number
): string {
  if (!messages[clientID]) return ''
  if (messages[clientID].length === 0) return ''
  return messages[clientID][messages[clientID].length - 1].subject
}

export default MessagingContainer
