import React from 'react'
import { useParams } from 'react-router-dom'

import SchedulerContainerPATAuth from 'components/Scheduler/SchedulerContainerPATAuth'
import usePATAuth from 'hooks/usePATAuth'
import UnauthenticatedPageShell from 'pages/PageShell/Unauthenticated'

const SchedulePage: React.FC = () => {
  const { omsItemID: omsItemIDStr } = useParams<{
    omsItemID: string
    orderID: string
  }>()
  const { pat, hasPATParam } = usePATAuth()
  const omsItemID = parseInt(omsItemIDStr)

  if (!hasPATParam || !pat) return null
  return (
    <UnauthenticatedPageShell footer={false} logoGoesToPortal={false}>
      <SchedulerContainerPATAuth omsItemID={omsItemID} pat={pat} />
    </UnauthenticatedPageShell>
  )
}

export default SchedulePage
