import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { format as dateFormat } from 'date-fns'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { generatePath, useLocation } from 'react-router'
import clsx from 'clsx'

import OrderItemIcon from 'components/OrderItems/OrderItemIcon'
import ProductStatus from 'components/OrderItems/ProductStatus'
import { ItemWithDocuments, ItemStatusTypes } from 'store/items/types'
import { currentItemStatus } from 'store/items/selectors'
import GhostLink, { generateGhostURL } from 'components/shared/GhostLink'
import DownloadModal from 'components/Modals/variants/Download'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'

import useFeatureFlags from 'hooks/useFeatureFlags'
import useUser from 'hooks/useUser'
import useViewInCIO from 'hooks/useViewInCIO'

interface Props {
  item: ItemWithDocuments
  handleViewClick?: (itemID: number | null) => void
  ringClass?: string
  dueForUpdateToolTip?: boolean
  isPastItem?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: '.72em',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    h3: {
      alignItems: 'center',
      color: theme.palette.primary.dark,
      display: 'flex',
    },
    h4: {
      fontWeight: 400,
      margin: '.29em 0 1em',
    },
    orderItemIcon: {
      height: '3.5em',
      width: '3.5em',
    },
    orderItemIconContainer: {
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '100%',
      display: 'flex',
      height: '5.3em',
      justifyContent: 'center',
      marginBottom: '-1em',
      width: '5.3em',
    },
    itemBorder: {
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    paper: {
      marginLeft: '.75em',
      marginRight: '.75em',
      padding: '1.5em',
      width: '20.2em',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '.5em',
        marginRight: '.5em',
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: '.38em',
        marginLeft: '0',
        marginRight: '0',
        width: '100%',
      },
      '&:target': {
        borderColor: theme.palette.primary.main,
      },
    },
    itemTargeted: {
      borderColor: theme.palette.primary.main,
    },
    textContainer: {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      display: 'flex',
      margin: '3em 0 .78em',
    },
    textContainer2: {
      maxWidth: 'calc(100% - 5.3em)',
    },
  })
)

const statusesToShowDatelineFor = [
  ItemStatusTypes.FirstDraft,
  ItemStatusTypes.RevisionMode,
  ItemStatusTypes.Complete,
  ItemStatusTypes.AwaitingWriterMatch,
  ItemStatusTypes.AwaitingResume,
  ItemStatusTypes.AwaitingReply,
]

const ItemCard: React.FC<Props> = ({
  item,
  ringClass,
  dueForUpdateToolTip,
  handleViewClick,
  isPastItem,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const latestDocument = item.documents?.[0] || null
  const status = currentItemStatus(item)
  const { canGhost } = useFeatureFlags()
  const { allowed_in_cio: allowedInCIO } = useUser()
  const { loading, onViewDocs } = useViewInCIO()

  const { hash } = useLocation()
  let dateText: string | null = null
  const toolTipText = dueForUpdateToolTip
    ? t(`components.OrderWidget.dueForUpdateToolTip`)
    : ''

  if (
    status?.state &&
    (status?.due_at || status?.ended_at) &&
    statusesToShowDatelineFor.includes(status.state)
  ) {
    dateText = t(`components.Portfolio.ItemCard.dateText.${status.state}`, {
      date: dateFormat(
        status.due_at || status.ended_at || new Date(),
        t('components.Portfolio.ItemCard.dateTextFormat')
      ),
    })
  }

  return (
    <>
      <Paper
        className={clsx(
          classes.paper,
          hash.includes(`item-${item.oms_order_item_id}`) &&
            classes.itemTargeted
        )}
        variant="outlined"
        id={`item-${item.oms_order_item_id}`}
      >
        {status && (
          <ProductStatus
            deadline={status.due_at}
            rating={item.rating}
            status={status.state}
            tooltipPlacement="bottom"
            itemID={item.id}
          />
        )}
        <Tooltip arrow placement="top" title={toolTipText}>
          <Container className={classes.textContainer} disableGutters>
            <Container className={classes.textContainer2} disableGutters>
              <Typography className={classes.h3} component="h3" variant="body1">
                {item.name}
              </Typography>
              {dateText && (
                <Typography
                  className={classes.h4}
                  color="secondary"
                  variant="h4"
                >
                  {dateText}
                </Typography>
              )}
            </Container>
            <Container
              className={clsx(
                classes.orderItemIconContainer,
                classes.itemBorder,
                ringClass
              )}
              disableGutters
            >
              <OrderItemIcon
                className={classes.orderItemIcon}
                itemType={item.item_type}
                itemName={item.name}
              />
            </Container>
          </Container>
        </Tooltip>

        <Container className={classes.buttonContainer} disableGutters>
          {!isPastItem ? (
            <Button
              className={classes.button}
              variant="contained"
              disabled={!latestDocument}
              to={
                !!latestDocument
                  ? generatePath(t('routes.messagesByClientAndItem'), {
                      clientID: item.oms_client_id || '',
                      itemID: item.oms_order_item_id || '',
                    })
                  : '#'
              }
              component={GhostLink}
              data-qa="document-link"
            >
              {t('pages.Portfolio.view')}
            </Button>
          ) : allowedInCIO ? (
            <Button
              className={classes.button}
              variant="outlined"
              disabled={loading}
              onClick={onViewDocs}
              data-qa="document-link"
            >
              {loading ? (
                <CircularProgress size={28} />
              ) : (
                t('pages.Portfolio.view')
              )}
            </Button>
          ) : null}
          {handleViewClick && (canGhost || !allowedInCIO) && (
            <Button
              className={classes.button}
              variant="contained"
              disabled={!latestDocument}
              onClick={() => handleViewClick(item.oms_order_item_id)}
              data-qa="document-link-admin"
            >
              {t('pages.Portfolio.view')}
            </Button>
          )}
          {(!isPastItem || canGhost || !allowedInCIO) && (
            <Button
              className={classes.button}
              variant="outlined"
              disabled={!latestDocument}
              onClick={() => setShowDownloadModal(true)}
              data-qa="download-modal-trigger"
            >
              {t('pages.Portfolio.download')}
            </Button>
          )}
        </Container>
      </Paper>
      {showDownloadModal && (
        <DownloadModal
          wordURL={generateGhostURL(latestDocument.download_url)}
          pdfURL={
            latestDocument.pdf_url
              ? generateGhostURL(latestDocument.pdf_url)
              : latestDocument.pdf_url
          }
          disableWord={latestDocument?.filename.endsWith('.pdf')}
          onClose={() => setShowDownloadModal(false)}
        />
      )}
    </>
  )
}

export default ItemCard
