import React, { ElementType } from 'react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import { formattedPrice } from 'utils/formatting'

interface Props {
  currencyCode: string
  currencySymbol: string
  amount: number
  className?: string
  typographyProps?: TypographyProps & { component?: ElementType }
}

const Price: React.FC<Props> = ({
  currencyCode,
  currencySymbol,
  amount,
  className,
  typographyProps,
}) => {
  if (typographyProps?.variant) {
    return (
      <Typography
        className={className}
        dangerouslySetInnerHTML={{
          __html: formattedPrice(currencyCode, currencySymbol, amount),
        }}
        {...typographyProps}
      />
    )
  } else {
    return (
      <Typography
        variant="h5"
        className={className}
        dangerouslySetInnerHTML={{
          __html: formattedPrice(currencyCode, currencySymbol, amount),
        }}
        {...typographyProps}
      />
    )
  }
}

export default Price
