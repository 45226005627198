import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { Item, ItemStatusTypes, ItemTypes } from '../../store/items/types'
import { format as dateFormat } from 'date-fns'
import MessageAvatar from '../Messaging/MessageAvatar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { getAvatarInitials } from '../../utils/formatting'

interface VoiceItemDetailsProps {
  item: Item
  expertName?: string | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    avatar: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem',
      },
    },
  })
)

const VoiceDrivenItemDetails: React.FC<VoiceItemDetailsProps> = ({
  item,
  expertName,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (
    item.item_type !== ItemTypes.PhoneCall &&
    item.item_type !== ItemTypes.PersonalConsultation
  ) {
    return null
  }

  const itemStatus = item.status.active.find(
    ({ state }) =>
      state === ItemStatusTypes.ScheduleInterview ||
      state === ItemStatusTypes.SchedulePhone ||
      state === ItemStatusTypes.ConductCall ||
      state === ItemStatusTypes.ConductInterview
  )

  if (!itemStatus) {
    return null
  }

  const scheduledDate = !!itemStatus?.due_at
    ? dateFormat(
        itemStatus?.due_at,
        t('components.VoiceDrivenItemDetails.headline.dateFormat')
      )
    : null
  const scheduledTime = !!itemStatus?.due_at
    ? dateFormat(
        itemStatus?.due_at,
        t('components.VoiceDrivenItemDetails.headline.timeFormat')
      )
    : null

  return (
    <Container className={classes.container} disableGutters={true}>
      <Container disableGutters={true}>
        <Typography component="h3" variant="h3">
          {(itemStatus.state === ItemStatusTypes.ConductInterview ||
            itemStatus.state === ItemStatusTypes.ConductCall) && (
            <Trans
              i18nKey={t(
                `components.VoiceDrivenItemDetails.headline.${item.item_type}.${itemStatus.state}`,
                { date: scheduledDate, time: scheduledTime }
              )}
            />
          )}
          {(itemStatus.state === ItemStatusTypes.SchedulePhone ||
            itemStatus.state === ItemStatusTypes.ScheduleInterview) && (
            <Trans
              i18nKey={t(
                `components.VoiceDrivenItemDetails.headline.${item.item_type}.${itemStatus.state}`
              )}
            />
          )}
        </Typography>
        <Typography variant="body2">
          <Trans
            i18nKey={`components.VoiceDrivenItemDetails.headlineHelper.${item.item_type}.${itemStatus.state}`}
          />
        </Typography>
      </Container>
      {expertName && (
        <MessageAvatar
          avatarInitials={getAvatarInitials(expertName)}
          className={classes.avatar}
        />
      )}
    </Container>
  )
}

export default VoiceDrivenItemDetails
