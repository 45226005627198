import React from 'react'
import { useTranslation } from 'react-i18next'

import useUser from 'hooks/useUser'

import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import SvgTrippleCheck from 'assets/images/tripple-check-mark.svg'

import LastChanceItem from './LastChanceItem'

import { Upsell } from 'store/upsells/types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Expert } from 'store/orders/types'
import { colors } from 'styles'

interface Props {
  expert: Expert | null
  packageName: string
  updateUpsells: (isSelected: boolean, planCode: string) => void
  upsells: Upsell[]
}

const LastChanceItems: React.FC<Props> = ({
  expert,
  packageName,
  updateUpsells,
  upsells,
}) => {
  const classes = useStyles()
  const user = useUser()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (upsells.length === 0) return null
  return (
    <Paper className={classes.paper}>
      {!isMobile && (
        <Paper className={classes.packageIconPaper}>
          <Container className={classes.packageIconContainer}>
            <img
              src={SvgTrippleCheck}
              alt="tripple check mark"
              className={classes.img}
            />
            <Typography variant="h2" className={classes.packageGreeting}>
              {user.first_name && user.first_name !== ''
                ? t(
                    `components.Upsells.shoppingCart.orderEnhancement.packageDescription`,
                    { userName: user.first_name, packageName: packageName }
                  )
                : t(
                    `components.Upsells.shoppingCart.orderEnhancement.packageDescriptionNoName`,
                    { userName: user.first_name, packageName: packageName }
                  )}
            </Typography>
          </Container>
        </Paper>
      )}
      {isMobile && (
        <Typography variant="h2" className={classes.packageGreetingMobile}>
          {user.first_name && user.first_name !== ''
            ? t(
                `components.Upsells.shoppingCart.orderEnhancement.packageDescription`,
                { userName: user.first_name, packageName: packageName }
              )
            : t(
                `components.Upsells.shoppingCart.orderEnhancement.packageDescriptionNoName`,
                { userName: user.first_name, packageName: packageName }
              )}
        </Typography>
      )}

      <Container className={classes.itemsContainer} disableGutters>
        <Typography variant="body1" className={classes.heading}>
          {t('components.Upsells.shoppingCart.orderEnhancement.addItems')}
        </Typography>
        {upsells.map((upsell, index) => (
          <LastChanceItem
            expert={expert}
            key={index}
            updateUpsells={updateUpsells}
            upsell={upsell}
          />
        ))}
      </Container>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '1.5rem',
      marginTop: '2rem',
      marginBottom: '2rem',
      marginRight: '15rem',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      boxShadow: 'none',
      border: `solid ${colors.gray[600]} 1px`,
    },
    packageIconPaper: {
      backgroundColor: theme.palette.primary.dark,
      flexBasis: '49%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '3rem 2rem 3rem 2rem',
    },
    packageIconContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center',
    },
    itemsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'left',
      flexBasis: '49%',
      margin: '0 0 0 0',
    },
    img: {
      maxHeight: 'inherit',
      maxWidth: '20rem',
      marginBottom: '2rem',
    },
    packageGreeting: {
      fontSize: '2rem',
      color: 'white',
      textAlign: 'center',
    },
    packageGreetingMobile: {
      fontSize: '2rem',
      textAlign: 'left',
      margin: '1rem 0rem 1rem 0rem',
    },
    packageName: {
      fontWeight: 600,
      fontSize: '1.2rem',
    },
    heading: {
      fontWeight: 'normal',
      color: theme.palette.primary.dark,
    },
    check: {
      color: theme.palette.primary.main,
      width: '4rem',
      height: '4rem',
      fontSize: 'large',
      fontWeight: 'bold',
    },
    checkContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  })
)

export default LastChanceItems
