import React from 'react'

import Favicon from 'components/shared/Favicon'
import Tracking from 'components/shared/Tracking'
import CronContainer from 'components/shared/CronContainer'
import IntercomContainer from 'components/shared/IntercomContainer'
import SchedulerReminderContainer from 'components/Scheduler/ReminderContainer'

/**
 * PageAccessories contains components that need to run on each page.
 */
const PageAccessories: React.FC = () => (
  <>
    <Favicon />
    <Tracking />
    <CronContainer />
    <IntercomContainer />
    <SchedulerReminderContainer />
  </>
)

export default PageAccessories
