import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import useUser from 'hooks/useUser'
import confirmedImage from 'assets/images/confirmed.svg'

interface Props extends ModalProps {
  upgradedProduct: string
  originalProduct: string
}

const OrderUpgradedModal: React.FC<Props> = ({
  callToActionProps,
  upgradedProduct,
  originalProduct,
  ...props
}) => {
  const { t } = useTranslation()
  const user = useUser()

  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.OrderUpgraded.cta'),
        ...callToActionProps,
      }}
      heroImageURL={confirmedImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.OrderUpgraded.title')}
      </Typography>
      <Typography variant="body2">
        {t(
          `components.Modals.variants.OrderUpgraded.copy${
            user.first_name ? '' : 'NoName'
          }`,
          {
            name: user.first_name,
            originalProduct,
            upgradedProduct,
          }
        )}
      </Typography>
    </ModalWrapper>
  )
}

export default OrderUpgradedModal
