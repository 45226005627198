import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import GhostLink from 'components/shared/GhostLink'
import docsImage from 'assets/images/docs.svg'

interface Props extends ModalProps {
  attachedDocTypes?: string[]
}

const DocumentReadyModal: React.FC<Props> = ({
  callToActionProps,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.DocumentReady.cta', {
          productName: props.attachedDocTypes
            ? t(`itemTypes.${props.attachedDocTypes[0]}`)
            : t(`shared.document`),
          count: props.attachedDocTypes ? props.attachedDocTypes.length : 1,
        }),
        // @ts-ignore
        component: GhostLink,
        to: t('routes.messages'),
        ...callToActionProps,
      }}
      heroImageURL={docsImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.DocumentReady.title', {
          productName: props.attachedDocTypes
            ? t(`itemTypes.${props.attachedDocTypes[0]}`)
            : t(`shared.document`),
          count: props.attachedDocTypes ? props.attachedDocTypes.length : 1,
        })}
      </Typography>
      <Typography variant="body2">
        {t('components.Modals.variants.DocumentReady.copy')}
      </Typography>
    </ModalWrapper>
  )
}

export default DocumentReadyModal
