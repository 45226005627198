import { HashMap, LoadingLoadedErrorState } from '@talentinc/state-utils'

import { RatingActions } from './actions'

export interface RatingPayload {
  value: number
  comment: string | null
}

export interface Rating extends RatingPayload {
  created_at: Date
}

export interface NPSRespose {
  can_submit: boolean
  results: Rating[]
}

export interface RatingState {
  // The keys are orderIDs
  nps: HashMap<NPSRespose>
  meta: {
    [RatingActions.FETCH_ORDER_NPS]: HashMap<LoadingLoadedErrorState>
    [RatingActions.SUBMIT_ORDER_NPS]: HashMap<LoadingLoadedErrorState>
  }
}

export const initialState: RatingState = Object.freeze({
  nps: {},
  meta: {
    [RatingActions.FETCH_ORDER_NPS]: {},
    [RatingActions.SUBMIT_ORDER_NPS]: {},
  },
})
