import React from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import BundleItemIcon from './BundleItemIcon'

import { ItemTypes } from 'store/items/types'

interface Props {
  itemType: ItemTypes
  itemName: string
}

const BundleItem: React.FC<Props> = ({ itemType, itemName }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const tRef = 'components.Product'
  return (
    <Box className={clsx(classes.flexCol, classes.container)}>
      <Box className={classes.flexRow}>
        <BundleItemIcon
          itemType={itemType}
          itemName={itemName}
          className={classes.icon}
        />
        <Box className={clsx(classes.flexCol, classes.headerDetailsContainer)}>
          <Typography variant="h3" className={classes.title}>
            {t(`components.Upsells.upsellName.${itemType}`)}
          </Typography>

          <Box className={clsx(classes.flexRow, classes.availabilityRow)}>
            <Typography className={clsx(classes.bullet)} variant="body2">
              &bull;
            </Typography>
            <Typography className={classes.availability} variant="body2">
              {t(`${tRef}.availableImmediately`)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.copyContainer}>
        <Typography variant="body2">
          {t(`${tRef}.items.copy.${itemType}`)}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    flexCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      maxHeight: '4.5rem',
      maxWidth: '4.5rem',
    },
    container: {
      marginBottom: '2rem',
    },
    headerDetailsContainer: {
      marginLeft: '1rem',
    },
    copyContainer: {
      marginTop: '1rem',
    },
    bullet: {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: colors.green[600],
      marginRight: '0.5rem',
    },
    availability: {
      color: colors.green[600],
      fontSize: '0.8rem',
      fontWeight: 400,
    },
    availabilityRow: {
      alignItems: 'center',
    },
    title: {
      fontWeight: 600,
      fontSize: '1rem',
    },
  })
)

export default BundleItem
