import React from 'react'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { colors } from 'styles'
import Rating from '@material-ui/lab/Rating'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import useBrand from 'hooks/useBrand'

interface ReviewProps {
  headline: string
  review: string
  user: string
  rating: number
}

const Review: React.FC<ReviewProps> = ({ ...props }) => {
  const classes = useStyles()
  return (
    <Container className={classes.reviewContainer}>
      <Rating
        name="disabled"
        value={props.rating}
        className={classes.rating}
        precision={0.5}
        readOnly
      />
      <Typography variant="body1" className={classes.review}>
        {props.review}
      </Typography>
      <Typography variant="body1" className={classes.user}>
        {'- ' + props.user}
      </Typography>
    </Container>
  )
}

const ReviewsCarousel: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const brand = useBrand()
  const { t } = useTranslation()

  const reviews: ReviewProps[] = t(
    [`components.Reviews.${brand.name}`, 'components.Reviews.default'],
    { returnObjects: true }
  )

  const isLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  //the ratio of width to height dictates the aspect ratio of the slide
  let width = 40
  let height = 10
  if (isXs) {
    width = 17
    height = 20
  } else if (isSm) {
    width = 80
    height = 50
  } else if (isMd) {
    height = 17
  } else if (isLg) {
    width = 20
    height = 5
  }

  return (
    <CarouselProvider
      naturalSlideWidth={width}
      naturalSlideHeight={height}
      totalSlides={reviews.length}
      interval={5000}
      isPlaying={true}
    >
      <Container className={classes.paper}>
        <Slider className={classes.slider}>
          {reviews.map((review, index) => (
            <Slide index={index} key={index}>
              <Review {...review} />
            </Slide>
          ))}
        </Slider>
        <Container disableGutters className={classes.dotContainer}>
          {reviews.map((review, index) => (
            <Dot slide={index} key={index} className={classes.dot} />
          ))}
        </Container>
      </Container>
    </CarouselProvider>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      backgroundColor: colors.blue.a500,
      paddingBottom: '2rem',
      marginTop: '2rem',
      borderRadius: '6px',
    },
    slider: {
      minHeight: '1rem',
    },
    reviewContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '4rem 15rem 3rem 15rem',
      [theme.breakpoints.down('lg')]: {
        padding: '4rem 5rem 3rem 5rem',
      },
      [theme.breakpoints.down('md')]: {
        padding: '4rem 1rem 3rem 1rem',
      },
    },
    rating: {
      marginBottom: '1rem',
      color: theme.palette.primary.dark,
      '&:disabled': {
        color: theme.palette.primary.dark,
      },
    },
    headline: {
      marginBottom: '1rem',
      fontSize: '1.625rem',
      textAlign: 'center',
      fontWeight: 600,
    },
    review: {
      marginBottom: '1rem',
      textAlign: 'center',
    },
    user: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0rem',
      },
      textAlign: 'center',
    },
    dotContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: '1.5rem',
      marginTop: '1rem',
    },
    dot: {
      border: `solid ${theme.palette.primary.dark} 1.5px`,
      padding: '0.25rem',
      borderRadius: '100%',
      backgroundColor: 'inherit',
      '&:disabled': {
        backgroundColor: theme.palette.primary.dark,
      },
      margin: '0rem 0.5rem 0rem 0.5rem',
    },
  })
)

export default ReviewsCarousel
