import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { format as dateFormat } from 'date-fns'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { phoneTypes } from 'store/items/types'
import { PackageDisplayProps } from 'store/orders/types'
import ItemCard from './ItemCard'
import PortfolioUpsellCardContainer from 'components/Upsells/PortfolioUpsellCardContainer'
import CTA from 'components/shared/TimelineWidgetCTA'

/**
 * PortfolioPackage is a display structure for an Order. You see, an order can
 * have multiple packages, each of which can have multiple items. In order to
 * group it a visually pleasing way, we only show one package name and purchase
 * data, even though orders can have multiple packages/items purchased at
 * different times.
 */
const PortfolioPackage: React.FC<PackageDisplayProps> = ({
  name,
  datePurchased,
  items,
  currentOrderStatus,
  aggregatedOrderIDs,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // remove phone type items, if there is no document
  items = items.filter((item) => {
    const latestDocument = item.documents?.[0] || null
    if (phoneTypes.has(item.item_type) && !latestDocument) {
      return false
    }
    return true
  })

  return (
    <Paper className={classes.paper} variant="outlined">
      <Container className={classes.container}>
        <Typography className={classes.h2} variant="h2">
          {name}
        </Typography>
        <Typography
          className={classes.body1}
          color="secondary"
          variant="body1"
          component="time"
          dateTime={datePurchased.toISOString()}
        >
          {t('pages.Portfolio.purchasedOn', {
            date: dateFormat(
              datePurchased,
              t('pages.Portfolio.purchasedOnFormat')
            ),
          })}
        </Typography>
      </Container>
      <Divider />
      <Container className={clsx(classes.container, classes.cardContainer)}>
        {items.map((item) => (
          <ItemCard key={item.id} item={item} />
        ))}
        <PortfolioUpsellCardContainer />
      </Container>
      {currentOrderStatus && (
        <CTA
          status={currentOrderStatus}
          orderIDs={aggregatedOrderIDs}
          variantContext="portfolio"
        />
      )}
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    body1: {
      marginTop: '.22em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
        fontWeight: 400,
      },
    },
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 1.25em 2em !important',
      [theme.breakpoints.down('xs')]: {
        padding: '0 5% 2em',
      },
    },
    container: {
      padding: '2em 2em',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0',
        padding: '2em 5%',
      },
    },
    contentContainer: {
      width: '72em',
    },
    h2: {
      fontSize: '1.63em',
      fontWeight: 600,
      marginBottom: '.23em',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.13em',
        fontWeight: 700,
      },
    },
    paper: {
      padding: '0',
    },
  })
)

export default PortfolioPackage
