import React from 'react'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'

import { useStyles as useDocumentStyles } from './Document'

interface Props {
  documentImageURLs: string[]
  useDivider?: boolean
  paperClassName?: string
}

const DocumentImages: React.FC<Props> = ({
  documentImageURLs,
  useDivider = true,
  paperClassName,
}) => {
  const documentClasses = useDocumentStyles()

  return (
    <>
      {documentImageURLs.map((url) => (
        <React.Fragment key={url}>
          {useDivider && <Divider className={documentClasses.divider} />}
          <Paper
            className={clsx(documentClasses.paper, paperClassName)}
            elevation={6}
          >
            <img
              alt="document preview"
              className={documentClasses.img}
              key={url}
              src={url}
            />
          </Paper>
        </React.Fragment>
      ))}
    </>
  )
}

export default DocumentImages
