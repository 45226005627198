import React, { useState, useEffect, useCallback } from 'react'
import useEffectOnce from 'react-use/lib/useEffectOnce'
import omit from 'lodash/omit'

import ModalWrapper, { ModalWrapperProps } from './Wrapper'

type StepProps = Partial<
  Pick<
    ModalWrapperProps,
    | 'DialogProps'
    | 'callToActionProps'
    | 'dotsStepperProps'
    | 'heroImageURL'
    | 'onClose'
  >
>

export const Step: React.FC<StepProps> = ({ heroImageURL, children }) => {
  return (
    <>
      {heroImageURL && <img src={heroImageURL} alt="" />}
      {children}
    </>
  )
}

export interface MultiStepModalProps {
  steps: React.ReactElement<StepProps>[]
  onStart?: () => void
  onComplete?: () => void
  onAdvance?: (stepIndex: number) => void
  onClose?: () => void
  allowMovingBackward?: boolean
  disableStepper?: boolean
  DialogProps?: ModalWrapperProps['DialogProps']
  ContentProps?: ModalWrapperProps['ContentProps']
}

const MultiStepModal: React.FC<MultiStepModalProps> = ({
  steps,
  onStart,
  onAdvance,
  onComplete,
  onClose,
  allowMovingBackward = true,
  disableStepper = false,
  DialogProps = {},
  ContentProps = {},
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const step = steps[activeStep]
  const stepOnClick = step?.props?.callToActionProps?.onClick

  const handleCTAClick = useCallback(
    (event) => {
      setActiveStep((s) => Math.min(s + 1, steps.length))

      if (stepOnClick) {
        stepOnClick(event)
      }
    },
    [setActiveStep, steps.length, stepOnClick]
  )

  const handleBackClick = useCallback(() => {
    setActiveStep((s) => Math.max(s - 1, 0))
  }, [setActiveStep])

  useEffectOnce(() => {
    if (onStart) {
      onStart()
    }
  })

  useEffect(() => {
    if (onAdvance) {
      onAdvance(activeStep)
    }
  }, [activeStep, onAdvance])

  useEffect(() => {
    if (onComplete && activeStep >= steps.length) {
      onComplete()
    }
  }, [onComplete, activeStep, steps.length])

  // We can't rely on the open prop in the wrapper becasue it will render an
  // empty slide first.
  if (activeStep >= steps.length) {
    return null
  }

  return (
    <ModalWrapper
      onClose={onClose}
      onBackClick={allowMovingBackward ? handleBackClick : undefined}
      callToActionProps={{
        onClick: handleCTAClick,
        children: 'Continue',
        ...omit(step.props.callToActionProps, ['onClick']),
      }}
      dotsStepperProps={
        !disableStepper
          ? {
              activeStep,
              steps: steps.length,
              ...step.props.dotsStepperProps,
            }
          : undefined
      }
      DialogProps={DialogProps}
      ContentProps={ContentProps}
    >
      {step}
    </ModalWrapper>
  )
}

export default MultiStepModal
