import React, { useState, useEffect } from 'react'
import Accordion from '@material-ui/core/Accordion'

import MessageDetails from 'components/Messaging/MessageDetails'
import MessageSummary from 'components/Messaging/MessageSummary'
import { MessageWithAttachments } from 'store/messages/types'
import { User } from 'store/user/types'
import { formatSender } from 'utils/formatting'

interface Props {
  expandedByDefault: boolean
  message: MessageWithAttachments
  user: Partial<User>
  markMessageAsRead: (messageID: number) => void
}

const Messages: React.FC<Props> = ({
  expandedByDefault,
  message,
  user,
  markMessageAsRead,
}) => {
  // State for setting whether accordion is expanded
  const [expanded, setExpanded] = useState(expandedByDefault)

  // Function for expanding & collapsing accordion
  const expandAccordion = (expanded: boolean) => {
    setExpanded(expanded)
  }

  // Why mark the message as read as an effect and not on user interaction?
  // We expand the latest message automatically and unless the user clicks on
  // it, it won't get marked as read. This will automatically mark it as read
  // when opened.
  useEffect(() => {
    if (!message.read_at && !message.outbound && expanded) {
      markMessageAsRead(message.id)
    }
  }, [
    message.read_at,
    message.outbound,
    expanded,
    markMessageAsRead,
    message.id,
  ])

  return (
    <Accordion expanded={expanded} square>
      <MessageSummary
        sender={
          message.outbound
            ? `${user.first_name} ${user.last_name}`
            : message.expert?.signature || formatSender(message.sender)
        }
        expanded={expanded}
        readAt={message.read_at}
        sent={message.sent}
        expandAccordion={expandAccordion}
        subject={message.subject}
        outbound={message.outbound}
      />
      <MessageDetails
        body={
          message.message_text && message.message_text !== ''
            ? message.message_text
            : message.body
        }
        expandAccordion={expandAccordion}
        attachments={message.attachments}
      />
    </Accordion>
  )
}

export default Messages
