import React from 'react'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import {
  GhostLinkButton,
  // GhostLinkButtonProps,
} from 'components/shared/GhostLink'

/**
 * Do you need something that looks like a Paper component but functions as
 * a Button? PaperButton is exactly what you are looking for! You get the
 * visually styling of Paper and the accessiblity of a Button!
 */

const Btn = withStyles((theme) => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
  },
  endIcon: {
    marginLeft: 'auto',
  },
}))(Button)

const PaperButton = withStyles((theme) => ({
  root: {
    textAlign: 'left',
    fontSize: theme.typography.fontSize,
  },
}))((props: PaperProps & ButtonProps) => (
  // @ts-ignore
  <Paper component={Btn} {...props}>
    <>{props.children}</>
  </Paper>
))

type Props = any

export const PaperLinkButton = (props: Props) => (
  <PaperButton component={GhostLinkButton} {...props} />
)

export default PaperButton
