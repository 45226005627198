import React from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Tooltip from '@material-ui/core/Tooltip'

interface Props {
  copy: string
  tooltipClass?: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: '0 .25em',
      maxHeight: '1em',
      maxWidth: '1em',
    },
  })
)

const TooltipIcon: React.FC<Props> = ({ children, copy, tooltipClass }) => {
  const classes = useStyles()
  const theme = useTheme()

  const position = useMediaQuery(theme.breakpoints.down('sm'))
    ? 'top'
    : 'bottom'

  return (
    <>
      <Tooltip arrow placement={position} title={copy}>
        <Container
          className={clsx(classes.container, tooltipClass)}
          disableGutters
        >
          <>{children}</>
        </Container>
      </Tooltip>
    </>
  )
}

export default TooltipIcon
