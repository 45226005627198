import React from 'react'
import useFavicon from 'react-use/lib/useFavicon'

import useBrand from 'hooks/useBrand'
import defaultFavicon from 'assets/images/default-favicon.png'

/**
 * Favicon will attempt to set the page's favicon by making it the favicon.ico
 * for the TLD that it belongs to.
 */
const Favicon: React.FC = () => {
  const { favicon } = useBrand()

  useFavicon(favicon || defaultFavicon)

  return null
}

export default Favicon
