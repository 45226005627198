import React from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'

import PageShell from 'pages/PageShell'
import OrdersWidgetContainer from 'components/OrderWidget/Container'
import ContentWidget from 'components/Content/ContentWidget'
import HomeUpsellContainer from 'components/Upsells/HomeUpsellContainer'
import FlashErrorBoundary from 'components/shared/Errors'
import FaqSection from 'components/Faqs/FaqSection'
import { SchedulersContainer } from 'components/Scheduler/Container'
import ProgressWidgetContainer from 'components/ProgressWidget/ProgressWidgetContainer'
import useExperiment from 'hooks/useExperiment'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: '0',
      flexBasis: '61%',
      marginRight: '1.5em',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginRight: '0em',
      },
    },
    pageContainer: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    sidePanel: {
      margin: '0',
      flexBasis: '39%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  })
)

const HomePage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // Success Widget Experiment
  const successWidgetExperiment = useExperiment({
    experimentLabel: 'Your Success Progress - Portal',
    caseDescriptor: 'Success-Progress-test',
  })

  return (
    <PageShell pageTitle={t('pages.Home.pageTitle')}>
      <Container className={classes.pageContainer} disableGutters>
        <Container className={classes.container} disableGutters>
          <FlashErrorBoundary>
            <SchedulersContainer />
          </FlashErrorBoundary>
          <FlashErrorBoundary>
            <OrdersWidgetContainer />
          </FlashErrorBoundary>
          {!isMobile && (
            <FlashErrorBoundary>
              <FaqSection widget={true} />
            </FlashErrorBoundary>
          )}
        </Container>
        <Container className={classes.sidePanel} disableGutters>
          <FlashErrorBoundary>
            <HomeUpsellContainer />
          </FlashErrorBoundary>
          {successWidgetExperiment && (
            <FlashErrorBoundary>
              <ProgressWidgetContainer />
            </FlashErrorBoundary>
          )}
          <FlashErrorBoundary>
            <ContentWidget />
          </FlashErrorBoundary>
        </Container>
        {isMobile && (
          <FlashErrorBoundary>
            <FaqSection widget={true} />
          </FlashErrorBoundary>
        )}
      </Container>
    </PageShell>
  )
}

export default HomePage
