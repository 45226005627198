import React from 'react'
import useBrand from 'hooks/useBrand'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Logo from 'components/shared/Logo'

interface Props {
  date: string
  img: string
  imgAlt: string
  title: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    byline: {
      alignItems: 'center',
      display: 'flex',
      marginTop: '1.6em',

      [theme.breakpoints.down('sm')]: {
        marginTop: '1.13em',
      },
    },
    bylineText: {
      marginLeft: '.75em',
    },
    container: {
      margin: 'initial',
      maxWidth: '65%',
      width: 'calc(100% - 16.9em)',

      [theme.breakpoints.down('sm')]: {
        marginTop: '2em',
        maxWidth: 'initial',
        width: '100%',
      },
    },
    h1: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6em',
      },
    },
    img: {
      width: '14.9em',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 8.4em)',
      },
    },
    paper: {
      alignItems: 'center',
      backgroundColor: colors.blue.a500,
      border: 'none',
      borderRadius: '.71em',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2.5em',
      marginTop: '0',
      padding: '5.7em 4.3em 6.1em 9.1em',

      [theme.breakpoints.down('sm')]: {
        borderRadius: '0',
        flexDirection: 'column-reverse',
        padding: '2.5em 1.2em 2em',
      },
    },
    subtitle2: {
      fontStyle: 'italic',
      textTransform: 'capitalize',
    },
  })
)

const ContentHeader: React.FC<Props> = ({ date, img, imgAlt, title }) => {
  const classes = useStyles()
  const brand = useBrand()

  return (
    <Paper className={classes.paper} variant="outlined">
      <Container className={classes.container} disableGutters>
        <Typography className={classes.h1} variant="h1">
          {title}
        </Typography>
        <Container className={classes.byline} disableGutters>
          <Logo minimized={true} />
          <Container className={classes.bylineText} disableGutters>
            <Typography variant="h3">{brand.name}</Typography>
            <Typography className={classes.subtitle2} variant="subtitle2">
              {date}
            </Typography>
          </Container>
        </Container>
      </Container>
      <img alt={imgAlt} className={classes.img} src={img} />
    </Paper>
  )
}

export default ContentHeader
