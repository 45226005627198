import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import PngAutoApply from 'assets/images/auto-apply.png'
import SvgCareerIoWithText from 'assets/images/career-io-with-text.svg'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { useCIORedirect } from 'hooks/useCIORedirect'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      width: '100%',
      display: 'flex',
      backgroundColor: '#EAF6FF',
      borderRadius: 8,
      padding: 30,
    },
    card_content: {
      padding: 10,
    },
    img_hero: {
      objectFit: 'contain',
      maxWidth: 250,
      margin: 5,
      marginTop: 15,
      marginBottom: 'auto',

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    list: {
      marginTop: '5px',
      marginBottom: '15px',
    },
    list_item: {
      paddingTop: 4,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    list_item_icon: {
      minWidth: 25,
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      alignItems: 'flex-start',

      [theme.breakpoints.down('md')]: {
        alignItems: 'stretch',
      },
    },
  })
)

export const CompletedDocuments: React.FC = () => {
  const classes = useStyles()
  const CIORedirect = useCIORedirect()

  const nextSteps = [
    'Find jobs with the search engine and tracker',
    'Improve with the AI-powered interview tool',
    'Evaluate job offers with the salary analyzer',
  ]

  return (
    <Paper className={classes.root} variant="outlined">
      <Box className={classes.card}>
        <img className={classes.img_hero} src={PngAutoApply} alt="Auto apply" />

        <Box className={classes.card_content}>
          <Typography variant="h2" style={{ fontSize: 30 }}>
            Your documents are ready!
          </Typography>
          <Typography style={{ fontSize: 20, marginBottom: 15 }}>
            Access your final documents on our career companion platform.
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 700,
              textTransform: 'uppercase',
              color: '#052A4D',
              letterSpacing: '1.8px',
            }}
          >
            30-day premium access includes:
          </Typography>

          <List className={classes.list}>
            {nextSteps.map((step) => (
              <ListItem key={step} className={classes.list_item}>
                <ListItemIcon className={classes.list_item_icon}>
                  <Check color="primary" />
                </ListItemIcon>
                <ListItemText primary={step} />
              </ListItem>
            ))}
          </List>

          <footer className={classes.footer}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => CIORedirect.goto('/app')}
            >
              View Documents
            </Button>

            <Box style={{ display: 'flex', gap: 7 }}>
              <Typography style={{ fontSize: 14 }}>Powered by</Typography>
              <img src={SvgCareerIoWithText} alt="Career.io" />
            </Box>
          </footer>
        </Box>
      </Box>
    </Paper>
  )
}
