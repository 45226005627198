import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useForm, useField } from 'react-final-form-hooks'
import { useDispatch } from 'react-redux'

import ValidationRule from 'components/shared/ValidationRule'
import { forgotPasswordSubmission } from 'store/user/actions'

const useStyles = makeStyles((theme) =>
  createStyles({
    body1: {
      margin: '.88em 0 1.6em',
      maxWidth: '19.6em',
      textAlign: 'center',
      width: '100%',
    },
    box: {
      alignItems: 'center',
      display: 'flex',
      height: 'calc(100vh - 6em)',
      justifyContent: 'center',
      width: '100%',
    },
    button: {
      marginTop: '1.8em',
      width: '100%',
    },
    input: {
      boxShadow: 'none',
      padding: '.4rem 1rem',
    },
    label: {
      fontWeight: 400,
      marginTop: '-.75em',
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-6em',
      maxWidth: '30.38em',
      padding: '2.25em 2.5em 2.5em',
      width: '100%',
    },
    textField: {
      width: '100%',
    },
  })
)

interface FormType {
  email: string
}

const Reset: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    form,
    handleSubmit,
    submitting,
    submitSucceeded,
    valid,
    submitErrors,
  } = useForm<FormType>({
    onSubmit: async ({ email }): Promise<string | undefined> => {
      try {
        await dispatch(forgotPasswordSubmission(email))
      } catch (e) {
        return (e as any)?.error?.response?.data?.error || 'Error'
      }
    },
    initialValues: {
      email: '',
    },
  })
  const emailField = useField<string, FormType>('email', form, (value) => {
    if (!value.includes('@')) {
      return 'Invalid Email'
    }
  })

  return (
    <form className={classes.box} onSubmit={handleSubmit}>
      <Paper className={classes.paper} elevation={0} variant="outlined">
        <Typography variant="subtitle1">
          {t('components.Auth.Reset.title')}
        </Typography>
        <Typography className={classes.body1} variant="body1">
          {t('components.Auth.Reset.instructions')}
        </Typography>
        <TextField
          className={classes.textField}
          InputProps={{ className: classes.input }}
          id="password-reset-email"
          // @ts-ignore
          inputProps={{
            type: 'email',
            ...emailField.input,
          }}
          label={
            <Typography
              className={classes.label}
              color="secondary"
              variant="h4"
            >
              {t('components.Auth.Reset.input')}
            </Typography>
          }
          helperText={
            <>
              {submitSucceeded && (
                <ValidationRule invalid={false}>
                  {t('components.Auth.Reset.success')}
                </ValidationRule>
              )}
              {submitErrors && (
                <ValidationRule invalid={true}>
                  {t('components.Auth.Reset.failure', { error: submitErrors })}
                </ValidationRule>
              )}
            </>
          }
        />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          type="submit"
          disabled={submitting || !valid}
        >
          {t('components.Auth.Reset.button')}
        </Button>
      </Paper>
    </form>
  )
}

export default Reset
