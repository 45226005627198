import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '0',

      '& > section': {
        padding: '0 9.1em',
      },
      '& a': {
        color: theme.palette.primary.main,
        fontWeight: 500,
        textDecoration: 'none',
        '&:hover': {
          color: colors.blue['a400'],
        },
      },
      '& h2': {
        color: theme.palette.primary.dark,
        fontSize: '1.63em',
        marginBottom: '.75em',
        marginTop: '0',
      },
      '& h3': {
        color: theme.palette.primary.dark,
        fontSize: '1.25em',
        fontWeight: 700,
        marginBottom: '.7em',
      },
      '& li': {
        fontSize: '1.13rem',
        lineHeight: '1.56em',
        padding: '.45em',
      },
      '& p': {
        fontSize: '1.13em',
        lineHeight: '1.56em',
        marginBottom: '1.75em',
        marginTop: '0',
      },
      '& section': {
        paddingTop: '1.25em',

        '& section': {
          paddingTop: '1em',
        },
      },
      '& ul': {
        margin: '0 0 1.5em 0',
        paddingLeft: '1.75em',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '0 1.2em',
      },
    },
  })
)

const TextContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles()

  return (
    <>
      {children && (
        <Container className={classes.container}>{children}</Container>
      )}
    </>
  )
}

export default TextContainer
