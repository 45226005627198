import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { CartItemWithUpsell, Upsell } from 'store/upsells/types'
import { cartItemsWithUpsells, getUpsells } from 'store/upsells/selectors'
import useShoppingCart from 'hooks/useShoppingCart'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import isEqual from 'lodash/isEqual'
import Container from '@material-ui/core/Container'
import CheckoutTitle from 'components/Checkout/CheckoutTitle'
import CheckoutUpsellCarousel from 'components/Upsells/CheckoutUpsellCarousel'
import Drawer from '@material-ui/core/Drawer'
import PurchaseItem from 'components/Checkout/PurchaseItem'
import PurchaseTotal from 'components/Checkout/PurchaseTotal'
import EmptyCart from './EmptyCart'
import useExperiment from 'hooks/useExperiment'

interface Props {
  classDrawer: string
  classDrawerMinimized: string
  classModal: string
  classModalMinimized: string
  drawerMax: boolean
  minimizedNav: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    containerMain: {
      flexDirection: 'column',
      height: 'calc(100% - 5.4em)',
    },
    drawerCheckout: {
      padding: '0 !important',
      width: '31.6em !important',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important',
      },
    },
    purchaseItems: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      overflow: 'auto',
    },
  })
)

const CheckoutDrawer: React.FC<Props> = ({
  classDrawer,
  classDrawerMinimized,
  classModal,
  classModalMinimized,
  drawerMax,
  minimizedNav,
}) => {
  const classes = useStyles()

  // Product Page Experiment
  const isInProductPageExperiment = useExperiment({
    experimentLabel: 'Product Pages Portal',
    caseDescriptor: 'test-product-page',
  })

  // Get upsells currently in cart
  const { cartPlanCodes, cartOpen, updateOpen } = useShoppingCart()

  // Get all possible upsells
  const upsellsList = useSelector<AppState, Upsell[]>(
    (state) => getUpsells(state.upsells, [], isInProductPageExperiment),
    isEqual
  )

  const cartUpsells = useSelector<AppState, CartItemWithUpsell[]>((state) =>
    cartItemsWithUpsells(state.upsells)
  )

  // Filter upsellsList based on which are not in cart
  const upsellsNotInCart = upsellsList.filter(
    (upsell) =>
      !cartPlanCodes.includes(upsell.plan_code) && upsell.items.length <= 1
  )

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: clsx(
          classDrawer,
          classes.drawerCheckout,
          minimizedNav && !drawerMax && classDrawerMinimized
        ),
        modal: clsx(
          classModal,
          minimizedNav && !drawerMax && classModalMinimized
        ),
      }}
      onClose={() => updateOpen(false)}
      open={cartOpen}
      PaperProps={{ elevation: 10, variant: 'outlined' }}
    >
      <CheckoutTitle />
      <Container
        className={clsx(classes.container, classes.containerMain)}
        disableGutters
      >
        <Container className={classes.purchaseItems} disableGutters>
          {cartUpsells.length === 0 && <EmptyCart />}
          {cartUpsells.map((upsell, index) => (
            <PurchaseItem index={index} upsell={upsell} />
          ))}
        </Container>
        <Container disableGutters>
          {upsellsNotInCart.length > 0 && (
            <CheckoutUpsellCarousel upsells={upsellsNotInCart} />
          )}
          {cartUpsells.length > 0 && <PurchaseTotal upsells={cartUpsells} />}
        </Container>
      </Container>
    </Drawer>
  )
}

export default CheckoutDrawer
