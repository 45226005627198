import React from 'react'
import { useSelector } from 'react-redux'

import { AppState } from 'store'
import { purchaseUrl, upsellExpiryDate } from 'store/upsells/selectors'

import useRandomUpsell from 'hooks/useRandomUpsell'
import { isEqual } from 'lodash'
import { activeDocumentItems } from 'store/orders/selectors'
import { Item } from 'store/items/types'
import HomeUpsellCard from './HomeUpsellCard'

const HomeUpsellContainer: React.FC = () => {
  const upsell = useRandomUpsell()
  const planCodes = upsell ? [upsell.plan_code] : []

  const upsellUrl = useSelector<AppState, string | null>(
    (state) => purchaseUrl(state.user, state.brand, planCodes, 'billboard'),
    isEqual
  )

  const expiryDate = useSelector<AppState, Date | null>(
    (state) =>
      upsellExpiryDate(
        state.orders,
        upsell && upsell.items.length > 0 ? upsell.items[0].item_type_key : null
      ),
    isEqual
  )

  const activeDocItems = useSelector<AppState, Item[]>(
    (state) => activeDocumentItems(state.orders, state.items),
    isEqual
  )

  if (!upsell) return null
  return (
    <HomeUpsellCard
      upsell={upsell}
      purchaseURL={upsellUrl}
      expirationDate={expiryDate}
      itemsInOrder={activeDocItems.length}
    />
  )
}

export default HomeUpsellContainer
