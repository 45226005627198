import { RatingState } from './types'
import { ItemsState } from 'store/items/types'
import { orderIDForItemID } from 'store/items/selectors'

export const npsForItem = (
  ratingsState: RatingState,
  itemsState: ItemsState,
  itemID: number
): number | null => {
  const orderID = orderIDForItemID(itemsState, itemID)

  if (!orderID) {
    return null
  }

  const nps = ratingsState.nps?.[orderID]?.results?.[0]

  if (!nps) {
    return null
  }

  return nps.value
}

export const latestNPSForOrder = (state: RatingState, orderID: number) => {
  if (!state.nps[orderID]) {
    return null
  }

  if (state.nps[orderID].results.length === 1) {
    return state.nps[orderID].results[0]
  }

  return state.nps[orderID].results
    .slice()
    .sort((a, z) => z.created_at.getTime() - a.created_at.getTime())[0]
}

export const starRatingForItem = (
  ratingsState: RatingState,
  itemsState: ItemsState,
  itemID: number
): number | null => {
  const nps = npsForItem(ratingsState, itemsState, itemID)

  if (!nps) {
    return null
  }

  return nps / 2
}

export const canRateOrder = (state: RatingState, orderID: number) =>
  !!(state.nps[orderID] && state.nps[orderID].can_submit)

export const isSubmittingNPS = (state: RatingState, orderID: number) =>
  state.meta.SUBMIT_ORDER_NPS[orderID]?.isLoading || false
