import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { initializeCrons, suspendCrons } from 'store/crons/actions'

export const CronContainer: React.FC = () => {
  const dispatch = useDispatch()

  //when the tab is not visible, suspend cron timers
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        dispatch(suspendCrons())
      } else {
        dispatch(initializeCrons(false))
      }
    }

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return function cleanup() {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

export default CronContainer
