import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { AppState } from 'store'
import {
  addItemToCart,
  fetchCartItems,
  removeItemFromCart,
  updateCartItemQuantity,
  updateCartOpen as reduxUpdateCartOpen,
} from 'store/upsells/actions'
import useEvents from './useEvents'
import useUser from './useUser'
import { cartItemIDsByPlanCode, planCodesInCart } from 'store/upsells/selectors'
import { HashMap } from '@talentinc/state-utils'

export default function useShoppingCart() {
  const sendEvent = useEvents()
  const dispatch = useDispatch()
  const user = useUser()

  const siteID = user.brand?.site_id

  const cartPlanCodes = useSelector<AppState, string[]>(
    (state) => planCodesInCart(state.upsells),
    isEqual
  )
  const cartOpen = useSelector<AppState, any>(
    (state) => state.upsells.shoppingCartOpen,
    isEqual
  )

  const userLoaded = useSelector<AppState, boolean>(
    (state) => state.user.meta.FETCH_USER.loaded
  )

  const cartNeedsLoading = useSelector<AppState, boolean>(
    (state) =>
      !state.upsells.meta.FETCH_CART_ITEMS.loaded &&
      !state.upsells.meta.FETCH_CART_ITEMS.isLoading
  )

  const itemIDsByPlanCode = useSelector<AppState, HashMap<number>>((state) =>
    cartItemIDsByPlanCode(state.upsells)
  )

  const addToCart = useCallback(
    async (planCode: string, source?: string) => {
      if (siteID) {
        dispatch(addItemToCart('me', siteID, { quantity: 1, sku: planCode }))
        sendEvent({
          event: `Upgrade Page - Upsell Added To Cart`,
          variables: {
            plan_code: planCode,
            in_cart: cartPlanCodes,
            source,
          },
        })
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [siteID, cartPlanCodes]
  )

  const removeFromCart = useCallback(
    async (planCode: string, source?: string) => {
      const cartItemID = itemIDsByPlanCode[planCode]
      if (siteID && cartItemID) {
        dispatch(removeItemFromCart('me', siteID, cartItemID))
        sendEvent({
          event: `Upgrade Page - Upsell Removed From Cart`,
          variables: {
            plan_code: planCode,
            in_cart: cartPlanCodes,
            source,
          },
        })
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [siteID, cartPlanCodes, itemIDsByPlanCode]
  )

  const updateQuantity = useCallback(
    async (planCode: string, quantity: number, source?: string) => {
      const cartItemID = itemIDsByPlanCode[planCode]
      if (siteID && cartItemID) {
        if (quantity <= 0) {
          dispatch(removeItemFromCart('me', siteID, cartItemID))
          sendEvent({
            event: `Upgrade Page - Upsell Quantity Updated`,
            variables: {
              plan_code: planCode,
              in_cart: cartPlanCodes,
              source,
            },
          })
          return
        }
        dispatch(updateCartItemQuantity('me', siteID, cartItemID, { quantity }))
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [siteID, cartPlanCodes, itemIDsByPlanCode]
  )

  const updateOpen = useCallback(
    (cartOpen: boolean) => {
      const open = cartOpen

      dispatch(reduxUpdateCartOpen(open))
      sendEvent({
        event: `Shopping Cart Drawer ${open ? 'opened' : 'closed'}`,
        variables: {
          cart_open: open,
        },
      })
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [cartOpen, sendEvent]
  )

  useEffect(() => {
    if (userLoaded && siteID && cartNeedsLoading) {
      dispatch(fetchCartItems('me', siteID))
    }
  }, [userLoaded, siteID, cartNeedsLoading])

  return {
    cartPlanCodes,
    addToCart,
    removeFromCart,
    updateQuantity,
    cartOpen,
    updateOpen,
  }
}
