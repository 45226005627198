import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import { useWrapperStyles } from './Wrapper'

interface Props {
  expertName?: string | null
}
const NoCalendar: React.FC<Props> = ({ expertName }) => {
  const classes = useWrapperStyles()
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t('components.Scheduler.NoCalendar.header', {
          expertName: expertName || t('shared.YourExpert'),
        })}
      </Typography>
      <Typography className={classes.bodyCopy}>
        {t('components.Scheduler.NoCalendar.copy')}
      </Typography>
    </>
  )
}

export default NoCalendar
