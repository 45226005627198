import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import clsx from 'clsx'
import { isIE } from 'react-device-detect'

import useBrand from 'hooks/useBrand'
import { colors } from 'styles'
import footerSparklesSVG from 'assets/images/footer-sparkles.svg'

const tKey = 'components.LiteFooter'

const LiteFooter: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brand = useBrand()

  return (
    <Paper variant="outlined" className={classes.paper} component="footer">
      <Container className={classes.container}>
        <Typography variant="h1">{t(`${tKey}.header`)}</Typography>
        <Typography
          variant="body2"
          className={clsx(classes.copy, classes.mainCopy)}
        >
          <Trans i18nKey={`${tKey}.copy`} />
        </Typography>
        <Button href={brand.checkout_url} variant="contained" color="primary">
          {t(`${tKey}.cta`)}
        </Button>
        <Typography className={clsx(classes.copy, classes.smallText)}>
          {t(`${tKey}.critique`)}
        </Typography>
      </Container>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      textAlign: 'center',
      position: 'absolute',
      bottom: 0,
      backgroundColor: colors.blue.a500,
      borderRadius: 0,
      borderBottom: 0,
      borderLeft: 0,
      borderRight: 0,
      width: '100%',
      paddingTop: '4em',
      paddingBottom: '4em',

      [theme.breakpoints.down('sm')]: {
        paddingTop: '2em',
        paddingBottom: '2em',
      },

      '@media only screen and (max-height: 900px)': {
        position: !isIE ? 'initial' : 'static',
      },
    },
    container: {
      backgroundImage: `url(${footerSparklesSVG})`,
      width: '852px',

      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
        width: '100%',
      },
    },
    copy: {
      color: theme.palette.primary.dark,
    },
    mainCopy: {
      margin: '1.5em 0',
    },
    smallText: {
      fontSize: '0.9em',
      marginTop: '1.5em',
    },
  })
)

export default LiteFooter
