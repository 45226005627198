import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import print from 'print-js'

import ProductStatus from 'components/OrderItems/ProductStatus'
import DoubleButton from './DoubleButton'
import DraftList from 'components/Documents/DraftList'
import DocumentImages from './DocumentImages'
import { LinkedInPreviewTabs } from './LinkedInPreviewContainer'
import { UserDocumentsItemProps } from 'store/items/types'
import { currentItemStatus } from 'store/items/selectors'
import { ItemStatusTypes } from 'store/items/types'
import { documentIsFromAutoLinkedin } from 'store/files/selectors'
import pendingLaptopSVG from 'assets/images/pending-laptop.svg'
import useModals from 'hooks/useModals'
import useBrand from 'hooks/useBrand'

export const DesktopDocument: React.FC<UserDocumentsItemProps> = ({
  expert,
  documents,
  rating,
  showRatingModal,
  ...item
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const status = currentItemStatus(item)
  const [selectedDocument, setSelectedDocument] = useState(0)
  const doc = documents[selectedDocument]

  const afterApprove = useCallback(() => {
    if (showRatingModal !== undefined) {
      showRatingModal()
    }
  }, [showRatingModal])

  const { modal, onDownloadClick, onRateClick, onApproveClick, closeModals } =
    useModals(doc, item.orderID, item.orderIDs, afterApprove)

  let infoText = t('pages.Documents.requestEdits')

  if (status?.state === ItemStatusTypes.Complete) {
    if (rating !== null) {
      infoText = t('pages.Documents.completed', {
        date: dateFormat(new Date(), t('pages.Documents.responseDateFormat')),
        expert: expert?.display_name,
      })
    } else {
      infoText = t('pages.Documents.rate', {
        product: item.name,
        expert: expert?.display_name,
      })
    }
  } else if (status?.state === ItemStatusTypes.FirstDraft) {
    infoText = t('pages.Documents.newDraft')
  }

  const onPrintClick = useCallback(() => {
    if (doc && doc.pdf_url) {
      print({
        type: 'pdf',
        printable: doc.pdf_url,
      })
    }
  }, [doc])

  if (!doc) {
    return (
      <PreFirstDraftDocument
        expert={expert}
        documents={documents}
        rating={rating}
        {...item}
      />
    )
  }

  return (
    <Container className={classes.enclosingContainer} key={doc.id}>
      <Container
        className={clsx(classes.container, classes.copyContainer)}
        disableGutters
      >
        <Container className={classes.container} disableGutters>
          <Container className={classes.dateContainer} disableGutters>
            <Typography variant="h2">
              {t('pages.Documents.draft', {
                number: documents.length - selectedDocument,
              })}
            </Typography>
            {documents.length > 1 && (
              <DraftList
                documents={documents}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                closeModals={closeModals}
              />
            )}
          </Container>
          <Typography className={classes.body1} variant="body1">
            {infoText}
          </Typography>
          <ProductStatus
            deadline={status?.due_at}
            rating={rating}
            status={status?.state || ItemStatusTypes.FirstDraft}
            tooltipPlacement="bottom"
          />
        </Container>
        <DoubleButton
          item={item}
          rating={rating}
          status={status?.state || ItemStatusTypes.FirstDraft}
          onDownloadClick={onDownloadClick}
          onPrintClick={doc?.pdf_url ? onPrintClick : undefined}
          onRateClick={onRateClick}
          onApproveClick={onApproveClick}
        />
      </Container>
      {documentIsFromAutoLinkedin(doc) && doc.order_item_id ? (
        <LinkedInPreviewTabs
          omsItemID={doc.order_item_id}
          documentImageURLs={doc.pages}
        />
      ) : (
        <DocumentImages documentImageURLs={doc.pages} />
      )}
      {modal}
    </Container>
  )
}

export const MobileDocument: React.FC<UserDocumentsItemProps> = ({
  expert,
  documents,
  rating,
  showRatingModal,
  ...item
}) => {
  const classes = useStyles()
  const doc = documents[0]

  const afterApprove = useCallback(() => {
    if (showRatingModal !== undefined) {
      showRatingModal()
    }
  }, [showRatingModal])

  const { modal, onDownloadClick, onRateClick, onApproveClick } = useModals(
    doc,
    item.orderID,
    item.orderIDs,
    afterApprove
  )
  const status = currentItemStatus(item)

  if (!doc) {
    return (
      <PreFirstDraftDocument
        expert={expert}
        documents={documents}
        rating={rating}
        {...item}
      />
    )
  }

  return (
    <>
      <Container className={classes.enclosingContainer}>
        <Paper className={classes.paper} elevation={6}>
          {doc.pages.map((url) => (
            <img
              alt="document preview"
              className={classes.img}
              key={url}
              src={url}
            />
          ))}
        </Paper>
        <DoubleButton
          item={item}
          rating={rating}
          status={status?.state || ItemStatusTypes.FirstDraft}
          onDownloadClick={onDownloadClick}
          onRateClick={onRateClick}
          onApproveClick={onApproveClick}
        />
      </Container>
      {modal}
    </>
  )
}

const PreFirstDraftDocument: React.FC<UserDocumentsItemProps> = ({
  expert,
  ...item
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const status = currentItemStatus(item)
  const brand = useBrand()
  const tKey = `pages.Documents.emptyState.${
    expert ? status?.state : ItemStatusTypes.AwaitingWriterMatch
  }`

  return (
    <Container className={classes.emptyState}>
      <img src={pendingLaptopSVG} alt="" />
      <Typography className={classes.h2} variant="h2">
        {t(
          [
            `${tKey}.header`,
            `pages.Documents.emptyState.${ItemStatusTypes.FirstDraft}.header`,
          ],
          {
            expert: expert?.display_name,
            product: item.name,
          }
        )}
      </Typography>
      <Typography>
        {t(
          [
            `${tKey}.copy`,
            `pages.Documents.emptyState.${ItemStatusTypes.FirstDraft}.copy`,
          ],
          {
            date: dateFormat(
              status?.due_at || new Date(),
              t('pages.Documents.emptyState.dateFormat')
            ),
            brandProduct: brand.default_resume_product,
          }
        )}
      </Typography>
    </Container>
  )
}

const Document: React.FC<UserDocumentsItemProps> = (props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile) {
    return <MobileDocument {...props} />
  }

  return <DesktopDocument {...props} />
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    body1: {
      margin: '.33em 0 .67em',
    },
    container: {
      margin: 'initial',
      width: 'initial',
    },
    copyContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dateContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    divider: {
      margin: '2.5em 0 3em',
      '&:nth-of-type(1)': {
        display: 'none',
      },
    },
    enclosingContainer: {
      padding: '1em 0 2em',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        padding: '0',
        width: '100%',
      },
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',

      '& img': {
        marginBottom: '2rem',
      },
    },
    h2: {
      fontSize: '1.13em',
      textAlign: 'center',
    },
    img: {
      marginBottom: '1em',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
        boxShadow: theme.shadows[6],
      },
    },
    paper: {
      borderRadius: '0 0 6px 6px',
      marginTop: '2.6em',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        marginTop: '0',
      },
    },
  })
)

export default Document
