import React, { useEffect } from 'react'

import useAuth0 from './useAuth0'

const Logout: React.FC = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout()
  }, [logout])

  return null
}

export default Logout
