import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import earlyImage from 'assets/images/early.svg'

const EarlyOrderModal: React.FC<ModalProps> = ({
  callToActionProps,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.EarlyOrder.cta'),
        ...callToActionProps,
      }}
      heroImageURL={earlyImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.EarlyOrder.title')}
      </Typography>
      <Typography variant="body2">
        {t('components.Modals.variants.EarlyOrder.copy')}
      </Typography>
    </ModalWrapper>
  )
}

export default EarlyOrderModal
