import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import lateImage from 'assets/images/late.png'

const LateOrderModal: React.FC<ModalProps> = ({
  callToActionProps,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.LateOrder.cta'),
        ...callToActionProps,
      }}
      heroImageURL={lateImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.LateOrder.title')}
      </Typography>
      <Typography variant="body2">
        {t('components.Modals.variants.LateOrder.copy')}
      </Typography>
    </ModalWrapper>
  )
}

export default LateOrderModal
