import * as Sentry from '@sentry/browser'

import { AppThunkAction } from 'store'
import { fetchUser, FetchUserSuccess } from './actions'
import { UserIdentifier } from './types'
import { sentryUserFromStore } from './selectors'

export const fetchUserAndConfigure =
  (userIdentifier: UserIdentifier): AppThunkAction<FetchUserSuccess> =>
  async (dispatch, getState) => {
    const userResponse = (await (dispatch(
      fetchUser(userIdentifier)
    ) as unknown)) as FetchUserSuccess

    Sentry.setUser(sentryUserFromStore(getState().user))

    return userResponse
  }
