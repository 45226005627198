import i18n from 'i18next'
import { Transaction } from '../store/transactions/types'

export enum CreditCardType {
  AmericanExpress = 'amex',
  Visa = 'visa',
  MasterCard = 'master-card',
  Discover = 'discover',
  Other = 'other',
}

// Adapted from https://stackoverflow.com/a/72801
// Proper credit card type detection relies on having the first 4 digits.
// Unfortunately, products only stores the first 2 numbers. Since we only need
// to show the icons for subset of cards, so we will return Other for anything
// that we can easily determine.
export const creditCardTypeFromObfuscatedNumber = (
  creditCardNumber: string | null
): CreditCardType => {
  if (!creditCardNumber) {
    return CreditCardType.Other
  }

  const firstTwoNumbers = parseInt(creditCardNumber.slice(0, 2))

  // Visa's always start with 4
  if (firstTwoNumbers >= 40 && firstTwoNumbers < 50) {
    return CreditCardType.Visa
  }

  // Amex is always these two numbers
  if (firstTwoNumbers === 34 || firstTwoNumbers === 37) {
    return CreditCardType.AmericanExpress
  }

  // MasterCard has big range
  if (
    (firstTwoNumbers >= 51 && firstTwoNumbers <= 55) ||
    (firstTwoNumbers >= 22 && firstTwoNumbers <= 27)
  ) {
    return CreditCardType.MasterCard
  }

  // Discover are only these numbers
  const discoverNumbers = [
    60,
    // Discover has a range of 622126-622925, which is fine, but because we only
    // save the first 2 numbers, 62 overlaps with UnionPay, which is 620. We
    // will just not show the credit card icon in this case.
    // 62,
    64, 65,
  ]
  if (discoverNumbers.includes(firstTwoNumbers)) {
    return CreditCardType.Discover
  }

  // There are more credit card types, but we don't care about them and will
  // omit the name in the UI.

  return CreditCardType.Other
}

export const accountNumber = (t: Transaction): string => {
  let accNumber = `•••• ${t.account_number.substring(
    t.account_number.length - 4
  )}`
  const tRef = 'pages.PurchaseHistory'

  // if transaction was a gift card type, show (Giftcard) text
  if (t.cc_type !== null && t.cc_type === 'blackhawk') {
    accNumber = i18n.t(`${tRef}.giftCard`)
  }

  // if transaction was a applepay type, show (ApplePay) text
  if (t.cc_type === 'applepay') {
    accNumber = i18n.t(`${tRef}.applePay`)
  }

  return accNumber
}
