import React from 'react'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { User } from 'store/user/types'
import { useTranslation } from 'react-i18next'

interface Props {
  user: User | Partial<User>
}

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    document: {
      width: '98%',
      paddingTop: '3rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingBottom: '0',
      position: 'relative',
      margin: '3rem 1% 0',
    },
    headerText: {
      color: colors.gray.a700,
      fontFamily: 'CenturyOldStyleStd',
    },
    documentText: {
      color: colors.gray.a700,
      fontWeight: 'normal',
    },
    documentName: {
      fontSize: '1.8rem',
      flexBasis: '40%',
      margin: 0,
      lineHeight: '2rem',
    },
    documentHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    contact: {
      fontSize: '.8rem',
      margin: 0,
      lineHeight: '1rem',
    },
    contactContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      justifyItems: 'flex-end',
      alignItems: 'flex-end',
      flexBasis: '60%',
    },
    profession: {
      lineHeight: 'normal',
      fontSize: '1rem',
      marginTop: '0.2rem',
    },
    divider: {
      marginTop: '1rem',
      marginBottom: '3rem',
    },
    body: {
      color: colors.gray.a700,
      fontSize: '0.8rem',
    },
    addressContainer: {
      marginBottom: '2rem',
      filter: 'blur(3px)',
    },
    address: {
      lineHeight: '.9rem',
    },
    greeting: {
      marginBottom: '1rem',
      filter: 'blur(4px)',
    },
    paragraph: {
      marginBottom: '1rem',
      filter: 'blur(5px)',
    },
    paragraphContainer: {
      maxHeight: '4em',
      overflow: 'hidden',
    },
    overlay: {
      backgroundImage:
        'linear-gradient(rgba(255,255,255,0) 20%, rgba(255,255,255,1) 80%)',
      height: '15.7em',
      left: '-2em',
      position: 'absolute',
      top: '8em',
      width: 'calc(100% + 4em)',
    },
  })
)

const LetterPlaceHolder: React.FC<Props> = ({ user }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const userName =
    user.first_name && user.last_name
      ? user.first_name + ' ' + user.last_name
      : t(`components.Documents.userDefaults.name`)
  const phone = user.phone || t(`components.Documents.userDefaults.phone`)
  const linkedin = t(`components.Documents.userDefaults.linkedin`)
  const area = user.state || t(`components.Documents.userDefaults.area`)
  return (
    <Paper elevation={20} className={classes.document}>
      <Container disableGutters className={classes.documentHeaderContainer}>
        <Typography
          variant="h1"
          component="h1"
          className={clsx(classes.headerText, classes.documentName)}
        >
          {userName}
        </Typography>
        <Container disableGutters className={classes.contactContainer}>
          <Typography
            variant="body1"
            className={clsx(classes.documentText, classes.contact)}
          >
            {user.email}&nbsp;&#8226;&nbsp;{phone}
          </Typography>
          <Typography
            variant="body1"
            className={clsx(classes.documentText, classes.contact)}
          >
            {linkedin}&nbsp;&#8226;&nbsp;{area}
          </Typography>
        </Container>
      </Container>
      <Divider className={classes.divider} />

      <Container disableGutters className={classes.addressContainer}>
        <Typography variant="body2" className={classes.body}>
          Hiring Manager Name or HR Department
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.body, classes.address)}
        >
          Reviewers Title
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.body, classes.address)}
        >
          Business/Organization Name
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.body, classes.address)}
        >
          Address
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.body, classes.address)}
        >
          City, State 00000
        </Typography>
      </Container>

      <Container className={classes.paragraphContainer} disableGutters>
        <Typography
          variant="body2"
          className={clsx(classes.body, classes.greeting)}
        >
          Dear Hiring Manager,
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.body, classes.paragraph)}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </Container>
      <div className={classes.overlay}></div>
    </Paper>
  )
}

export default LetterPlaceHolder
