import i18n from 'i18next'

export const initials = (name: string): string =>
  name
    .split(' ')
    .map((p) => p[0])
    .join('')

export const joinConversationally = (
  items: string[],
  translateItems = false,
  translationPrefix = ''
): string =>
  items
    .map((item, i, items) => {
      let translatedItem = item
      if (translateItems) {
        translatedItem = i18n.t(`${translationPrefix}${item}`)
      }
      if (i === 0) {
        return translatedItem
      } else if (i === items.length - 1) {
        return ` ${i18n.t('utils.formatting.and')} ${translatedItem}`
      }

      return `, ${translatedItem}`
    })
    .join('')

// https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals = 2): string => {
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const isEmail = (value: string): boolean => {
  return (
    value.search(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ) > -1
  )
}

export const formatSender = (originalSender: string): string => {
  let sender = originalSender.replace('<', '').replace('>', '')
  let hasEmail = false
  let email = ''
  const parts = sender.split(' ')
  parts.forEach((word) => {
    if (isEmail(word)) {
      email = word
      hasEmail = true
    }
  })

  if (!hasEmail) return sender

  if (parts.length >= 3) {
    //AARP TopResume <client@support.topresume.com>
    return parts[parts.length - 2]
  }
  if (parts.length === 2) {
    //TopResume <contact@topresume.com>
    return parts[0]
  }
  //contact@topresume.com
  const domainParts = email.split('@')
  if (domainParts.length >= 2) {
    const domain = domainParts[domainParts.length - 1]
    const dotParts = domain.split('.')
    if (dotParts.length >= 2) {
      sender = dotParts[dotParts.length - 2]
    }
  }
  sender =
    sender.length > 1
      ? sender.substring(0, 1).toUpperCase() + sender.substring(1)
      : sender.toUpperCase()

  return sender
}

export const futureDate = (): Date => {
  // return a date far in the future
  const d = new Date()
  d.setFullYear(d.getFullYear() + 1000)
  return d
}

export const getAvatarInitials = (name: string | undefined): string =>
  name ? `${name.charAt(0)}${name.charAt(name.indexOf(' ') + 1)}` : ''

export function formattedPrice(
  currencyCode: string,
  currencySymbol: string,
  amount: number
) {
  if (currencyCode.toUpperCase() === 'EUR') {
    return `${amount} ${currencySymbol}`
  }
  return `${currencySymbol}${amount}`
}
