import React from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'

interface Props {
  url: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.25em 0 3.5em',
      width: '100%',
    },
    iframe: {
      backgroundColor: 'black',
      border: 'none',
    },
  })
)

const ContentVideo: React.FC<Props> = ({ url }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isMedium = useMediaQuery(theme.breakpoints.down('sm'))

  // Get video id from URL
  let id
  if (url.includes('youtube.com')) {
    id = url.slice(url.indexOf('?v=') + 3, url.length)
  } else {
    id = url.slice(url.indexOf('vimeo.com/') + 10, url.length)
  }

  // Video player sizing
  let vidHeight = '315px'
  let vidWidth = '560px'

  if (isMobile) {
    vidHeight = '190px'
    vidWidth = '336px'
  } else if (isMedium) {
    vidHeight = '296px'
    vidWidth = '526px'
  }

  return (
    <Container className={classes.container} disableGutters>
      {url.includes('youtube.com') && (
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture"
          className={classes.iframe}
          height={vidHeight}
          src={`https://www.youtube.com/embed/${id}`}
          title="video"
          width={vidWidth}
        ></iframe>
      )}
      {url.includes('vimeo.com') && (
        <iframe
          allow="autoplay; fullscreen"
          className={classes.iframe}
          height={vidHeight}
          src="https://player.vimeo.com/video/14077196?title=0&byline=0&portrait=0"
          title="video"
          width={vidWidth}
        ></iframe>
      )}
    </Container>
  )
}

export default ContentVideo
