import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'
import Checkout from 'components/Navigation/Checkout'
import CheckoutDrawer from 'components/Checkout/CheckoutDrawer'
import Logo from 'components/shared/Logo'
import NavMenu from 'components/Navigation/NavMenu'
import NotificationsMenu from 'components/Navigation/NotificationsMenu'
import MenuIcon from 'assets/images/menu.svg'
import RouteNotifications from 'components/Notifications/RouteNotifications'
import FlashErrorBoundary, { NoOpErrorBoundary } from 'components/shared/Errors'
import AppUpgradeContainer from 'components/shared/AppUpgradeContainer'
import ScrollToTop from 'components/shared/ScrollToTop'
import useFeatureFlags from 'hooks/useFeatureFlags'

interface Props {
  checkoutDrawerOpen?: boolean
  containerClass?: string
  content?: boolean
  hideTitle?: boolean
  minimizedNav?: boolean
  noTitle?: boolean
  pageTitle?: string
  titleClass?: string
  contentDir?: 'row' | 'column'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    altTitle: {
      fontSize: '1.13em',
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
    },
    backButton: {
      fontSize: '1.13em',
      marginTop: '.25em',
      minWidth: '0',
    },
    backdrop: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    box: {
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      padding: '3em 0 4.5em 13em',

      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0',
        paddingTop: '0',
      },
    },
    boxDocs: {
      height: '100vh',
      paddingBottom: '1em',
    },
    boxMinimizedNav: {
      paddingLeft: '4.8em',
      paddingTop: '0',
    },
    button: {
      minWidth: '0',
      width: '1.1em',
    },
    closeButton: {
      position: 'absolute',
      right: '1.5em',
      top: '1.5em',
      width: '.88em',
    },
    container: {
      maxWidth: '95%',
      width: '72em',

      [theme.breakpoints.down('md')]: {
        maxWidth: '95%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        padding: '0',
        width: '100%',
      },
    },
    containerDocs: {
      height: '100%',
    },
    containerMinimizedNav: {
      margin: '0 1.06em 0 0',
      maxWidth: '100%',
      width: '100%',
    },
    desktopComponent: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    documentsContainer: {
      display: 'flex',
      height: '100%',
      maxWidth: '100%',
    },
    drawer: {
      borderRadius: '0',
      borderTop: '0',
      height: '100%',
      marginTop: '0',
      maxHeight: '100%',
      paddingBottom: '0',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '1.58em',
      width: '15em',
      transition: 'width ease-out .2s',
      [theme.breakpoints.down('sm')]: {
        width: '16.6em',
      },
    },
    drawerMinimized: {
      width: '4.8em',
    },
    logo: {
      fontSize: '1em',
      width: '6.9em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '1.2em',

      '& img': {
        maxHeight: '1.2em',
        margin: '0 auto',
      },
      [theme.breakpoints.down('sm')]: {
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
      },
    },
    menuIcon: {
      height: 'auto',
      width: '1.25em',
    },
    mobileComponent: {
      display: 'none',

      [theme.breakpoints.down('sm')]: {
        display: 'initial',
      },
    },
    modal: {
      height: '48em',
      maxHeight: '100%',
      width: '15em',
    },
    modalMinimized: {
      width: '4.8em',
    },
    notificationsCheckout: {
      margin: 'initial',
      width: 'initial',
    },
    pageContent: {
      paddingTop: '.5em',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '94%',
        paddingTop: '2.5em',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        paddingTop: '2em',
      },
    },
    pageContentContent: {
      paddingTop: '0',
    },
    pageContentMessaging: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    pageContentMinimizedNav: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: '0',
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6em',
        paddingBottom: '.3em',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 5%',
      },
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        padding: '1.3em 1.5em',
      },
    },
    toolbarFixed: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        width: '100%',
        zIndex: '100',
      },
    },
    toolbarMinimizedNav: {
      minHeight: '0',
    },
    upgradeFlashOnMessaging: {
      margin: 0,
      width: '100%',
      borderRadius: 0,
    },
  })
)

// PageShell is used to wrap all the pages in this application. It will hold
// things like the app's navigation and header.
const PageShell: React.FC<Props> = ({
  checkoutDrawerOpen = false,
  containerClass,
  content = false,
  children,
  hideTitle = false,
  minimizedNav = false,
  noTitle,
  pageTitle,
  titleClass,
  contentDir = 'row',
}) => {
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const { notificationsInteraction: notificationsInteractionFeatureFlag } =
    useFeatureFlags()

  const [state, setState] = useState({
    drawerMax: false,
    drawerOpen: false,
  })

  const toggleDrawer = useCallback(
    (open: boolean) => () => setState((s) => ({ ...s, drawerOpen: open })),
    []
  )

  const toggleDrawerMax = useCallback(
    (max: boolean) => () => setState((s) => ({ ...s, drawerMax: max })),
    []
  )

  const isDrawerOpen =
    !useMediaQuery(theme.breakpoints.down('sm')) || state.drawerOpen

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const altNav = !!(isMobile && noTitle)

  return (
    <>
      <ScrollToTop />
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      {notificationsInteractionFeatureFlag && (
        <NoOpErrorBoundary>
          <RouteNotifications />
        </NoOpErrorBoundary>
      )}
      <Box
        className={clsx(
          classes.box,
          minimizedNav ? classes.boxMinimizedNav : null,
          pageTitle === 'Documents' ? classes.boxDocs : null
        )}
      >
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(
              classes.drawer,
              minimizedNav && !state.drawerMax && classes.drawerMinimized
            ),
            modal: clsx(
              classes.modal,
              minimizedNav && !state.drawerMax && classes.modalMinimized
            ),
          }}
          open={isDrawerOpen}
          onClose={() => toggleDrawer(false)}
          ModalProps={{
            BackdropProps: { className: classes.backdrop },
            hideBackdrop: state.drawerMax ? false : !isMobile,
          }}
          PaperProps={{ elevation: 10, variant: 'outlined' }}
          variant={isMobile || state.drawerMax ? 'temporary' : 'permanent'}
        >
          <IconButton
            aria-label="Close menu"
            className={clsx(
              classes.button,
              classes.closeButton,
              classes.mobileComponent
            )}
            onClick={toggleDrawer(false)}
          >
            <CloseIcon className={classes.menuIcon} />
          </IconButton>
          <NavMenu
            drawerMax={state.drawerMax}
            minimized={minimizedNav}
            pageTitle={pageTitle}
            toggleDrawerMax={toggleDrawerMax}
          />
        </Drawer>
        <Container
          className={clsx(
            classes.container,
            containerClass,
            minimizedNav ? classes.containerMinimizedNav : null,
            pageTitle === 'Documents' ? classes.containerDocs : null
          )}
          disableGutters={minimizedNav}
        >
          {(!content || isMobile) && (
            <Toolbar
              className={clsx(
                classes.toolbar,
                minimizedNav ? classes.toolbarMinimizedNav : null,
                altNav || pageTitle === 'Documents'
                  ? classes.toolbarFixed
                  : null
              )}
              disableGutters
              variant="dense"
            >
              {!altNav && (
                <>
                  <IconButton
                    aria-label="Open menu"
                    className={clsx(classes.button, classes.mobileComponent)}
                    onClick={toggleDrawer(true)}
                  >
                    <img
                      alt="Menu icon"
                      className={classes.menuIcon}
                      src={MenuIcon}
                    />
                  </IconButton>
                  <>
                    {(!noTitle || !pageTitle) && (
                      <Typography
                        className={classes.desktopComponent}
                        variant="h1"
                      >
                        {pageTitle}
                      </Typography>
                    )}
                  </>
                  <Logo
                    className={clsx([classes.logo, classes.mobileComponent])}
                  />
                  <Container
                    className={classes.notificationsCheckout}
                    disableGutters
                  >
                    {!noTitle && notificationsInteractionFeatureFlag && (
                      <NoOpErrorBoundary>
                        <NotificationsMenu />
                      </NoOpErrorBoundary>
                    )}
                    {!noTitle && (
                      <NoOpErrorBoundary>
                        <Checkout />
                      </NoOpErrorBoundary>
                    )}
                  </Container>
                </>
              )}
              {altNav && (
                <>
                  <Button
                    className={classes.backButton}
                    color="secondary"
                    onClick={() => history.goBack()}
                    variant="text"
                  >
                    <CloseIcon />
                  </Button>
                  <Typography className={classes.altTitle} variant="h1">
                    {pageTitle}
                  </Typography>
                </>
              )}
            </Toolbar>
          )}
          <Container
            className={clsx(
              classes.pageContent,
              hideTitle || minimizedNav
                ? classes.pageContentMinimizedNav
                : null,
              pageTitle === 'Documents' ? classes.pageContentMessaging : null,
              content ? classes.pageContentContent : null
            )}
            disableGutters
          >
            {!noTitle && !hideTitle && !content && (
              <Typography
                className={clsx(
                  classes.mobileComponent,
                  classes.title,
                  titleClass
                )}
                variant="h1"
              >
                {pageTitle}
              </Typography>
            )}
            <AppUpgradeContainer
              className={clsx(noTitle && classes.upgradeFlashOnMessaging)}
            />
            <FlashErrorBoundary>
              {children && (
                <Container
                  className={
                    pageTitle === 'Documents' ? classes.documentsContainer : ''
                  }
                  disableGutters
                  style={{ display: 'flex', flexDirection: contentDir }}
                >
                  {children}
                </Container>
              )}
            </FlashErrorBoundary>
          </Container>
        </Container>
        <CheckoutDrawer
          classDrawer={classes.drawer}
          classDrawerMinimized={classes.drawerMinimized}
          classModal={classes.modal}
          classModalMinimized={classes.modalMinimized}
          drawerMax={state.drawerMax}
          minimizedNav={minimizedNav}
        />
      </Box>
    </>
  )
}

export default PageShell
