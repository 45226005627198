import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Login from 'components/Auth/Login'
import UnauthenticatedPageShell from 'pages/PageShell/Unauthenticated'

const LoginPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <UnauthenticatedPageShell logoGoesToPortal={false}>
      <Helmet>
        <title>{t('pages.Auth.Login.pageTitle')}</title>
      </Helmet>
      <Login />
    </UnauthenticatedPageShell>
  )
}

export default LoginPage
