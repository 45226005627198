import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Loader from 'components/shared/Loader'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { schedulableItemsLoaded } from 'store/scheduling/selectors'
import { fetchSchedulableItemsAndDetails } from 'store/scheduling/thunks'
import SchedulerContainer from './Container'

interface Props {
  omsItemID: number
  pat: string
}
const SchedulerContainerPATAuth: React.FC<Props> = ({ omsItemID, pat }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const itemsLoaded = useSelector<AppState, boolean>((state) =>
    schedulableItemsLoaded(state.scheduling)
  )

  useEffect(() => {
    if (!itemsLoaded && pat) {
      dispatch(fetchSchedulableItemsAndDetails(pat))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [itemsLoaded, pat])

  if (!itemsLoaded) return <Loader />
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>
        <SchedulerContainer omsItemID={omsItemID} isPATAuthenticated={true} />
      </Paper>
    </div>
  )
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginTop: '5rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '2rem',
      },
    },
    paper: {
      padding: '1rem 2rem 2rem 2rem',
      [theme.breakpoints.up('lg')]: {
        width: '80rem',
      },
    },
  })
)

export default SchedulerContainerPATAuth
