import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import MessageForm from './MessageForm'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      paddingBottom: '1em',
      paddingTop: '1em',
      zIndex: 100,
      overflow: 'scroll',

      [theme.breakpoints.down('sm')]: {
        bottom: '0',
        left: '0',
        position: 'fixed',
      },
    },
  })
)

const Reply: React.FC = () => {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <MessageForm />
    </Container>
  )
}

export default Reply
