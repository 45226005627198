import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import Loader from 'components/shared/Loader'
import ErrorPage from 'pages/Error'

import { fetchUserBrandThunk } from 'store/brand/thunk'
import { basePurchaseUrl } from 'store/upsells/selectors'
import { fetchBundledUpsellsThunk } from 'store/upsells/thunk'
import { Upsell } from 'store/upsells/types'
import { UserBrand } from 'store/brand/types'
import { HashMap } from '@talentinc/state-utils'

const UpgradeRedirect: React.FC = () => {
  const dispatch = useDispatch()
  const { email, bundle } = useParams<{
    email?: string
    bundle?: string
  }>()
  const queryParams = new URLSearchParams(window.location.search)
  const queryParamMap: HashMap<string> = {}
  queryParams.forEach((val, key) => {
    queryParamMap[key] = val
  })

  const [notFound, setNotFound] = useState(false)

  const redirectToPurchase = useCallback(async () => {
    if (!email || !bundle) {
      setNotFound(true)
      return
    }

    const upsellResult = await dispatch(
      fetchBundledUpsellsThunk(queryParamMap['at'] || email, bundle)
    )
    const brandResult = await dispatch(
      fetchUserBrandThunk(queryParamMap['at'] || email)
    )

    let selectedUpsell: Upsell | null = null
    let userBrand: UserBrand | null = null

    if ('length' in upsellResult) {
      const upsells: Upsell[] = upsellResult as unknown as Upsell[]
      if (upsells.length > 0) {
        selectedUpsell = upsells.sort(
          (a, b) => b.items.length - a.items.length
        )[0]
      }
    }

    if ('id' in brandResult) {
      userBrand = brandResult as unknown as UserBrand
    }

    if (!userBrand) {
      setNotFound(true)
      return
    }

    if (!selectedUpsell) {
      window.location.href = userBrand.home_url
    }

    if (selectedUpsell) {
      const purchaseURL = basePurchaseUrl(
        userBrand.home_url,
        [selectedUpsell.plan_code],
        'email',
        queryParamMap
      )

      window.location.href = purchaseURL
        ? purchaseURL.toString()
        : userBrand.home_url
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bundle, email, queryParamMap])

  useEffectOnce(() => {
    redirectToPurchase()
  })

  if (!notFound) return <Loader />
  return <ErrorPage code={404} />
}

export default UpgradeRedirect
