import {
  HashMap,
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'
import { SchedulingActions } from './actions'
import { Item } from 'store/items/types'
import { Expert } from 'store/orders/types'

export interface UnscheduledItem {
  item_id: number
  scheduled_start: null
  session_start: Date | null
  session_end: Date | null
  customer_joined: Date | null
  expert_joined: Date | null
  item_assigned: boolean
  meeting_id: null
  zoom_signature: null
  zoom_phone_numbers: {
    country: string
    country_name: string
    number: string
    type: 'toll'
  }[]
  zoom_api_key: null
  zoom_url: null
  zoom_password: null
  has_video: boolean
  name: string
  expert_display_name: string | null
}

export interface ScheduledItem
  extends Omit<
    UnscheduledItem,
    | 'scheduled_start'
    | 'meeting_id'
    | 'zoom_signature'
    | 'zoom_api_key'
    | 'zoom_sdk_key'
    | 'zoom_url'
    | 'zoom_password'
  > {
  scheduled_start: Date
  meeting_id: string
  zoom_signature: string
  zoom_api_key: string
  zoom_sdk_key: string
  zoom_url: string
  zoom_password: string
}

export type SchedulableItem = ScheduledItem | UnscheduledItem

export interface ItemSchedulingDetailsAfterAssignment {
  element_token: string
  expert_cronofy_calendars: {
    sub: string
    type: 'default' | 'user'
  }[]
  slot_length: number
  min_notice: number
  meeting_name: string
  meeting_description: string
  buffer: number
}

export interface ItemSchedulingDetailsBeforeAssignment
  extends Omit<
    ItemSchedulingDetailsAfterAssignment,
    'element_token' | 'min_notice' | 'buffer'
  > {
  element_token: null
  min_notice: null
  buffer: null
}

export type ItemSchedulingDetails =
  | ItemSchedulingDetailsAfterAssignment
  | ItemSchedulingDetailsBeforeAssignment

export interface CronofyTimePeriod<DateType = string> {
  start: DateType
  end: DateType
}

export interface CronofyDuration {
  minutes?: number
  hours?: number
}

export interface CronofyParticipant {
  sub: string
}

export interface CronofyParticipants {
  participants: CronofyParticipant[]
}

export interface CronofyAvailablityQuery {
  participants: {
    members: (CronofyParticipant & {
      calendar_ids?: string[]
      managed_availability?: boolean
      available_periods?: CronofyTimePeriod<Date | string>[]
    })[]
    required: string | 'all'
  }[]
  query_periods: CronofyTimePeriod<Date | string>[]
  required_duration: CronofyDuration
  buffer?: {
    before?: CronofyDuration
    after?: CronofyDuration
  }
}

export type CronofySlot = CronofyTimePeriod & CronofyParticipants

export enum CronofyNotifcationTypes {
  SlotSelected = 'slot_selected',
  SlotAdded = 'slot_added',
  SlotRemoved = 'slot_removed',
  NoSlotsFound = 'no_slots_found',
  NoVisibleSlots = 'no_visible_slots',
  VisibleSlots = 'visible_slots',
  QueryPeriodsEdited = 'query_periods_edited',
}

export interface CronofySlotSelectedNotification {
  notification: {
    type: CronofyNotifcationTypes.SlotSelected
    slot: CronofySlot
  }
}

export interface CronofySlotAddedNotification {
  notification: {
    type: CronofyNotifcationTypes.SlotAdded
    slot: CronofySlot
  }
  slots: CronofySlot[]
}

export interface CronofySlotRemovedNotification {
  notification: {
    type: CronofyNotifcationTypes.SlotRemoved
    slot: CronofySlot
  }
  slots: CronofySlot[]
}

export interface CronofyNoSlotsFoundNotification {
  notification: {
    type: CronofyNotifcationTypes.NoSlotsFound
    query: CronofyAvailablityQuery
  }
}

export interface CronofyNoVisibleSlotsNotification {
  notification: {
    type: CronofyNotifcationTypes.NoVisibleSlots
  }
}

export interface CronofyVisibleSlotsNotification {
  notification: {
    type: CronofyNotifcationTypes.VisibleSlots
  }
}

export interface CronofyQueryPeriodsEditiedNotification {
  notification: {
    type: CronofyNotifcationTypes.QueryPeriodsEdited
    query_periods: CronofyTimePeriod[]
  }
}

export type CronofyAvailablityViewerNotification =
  | CronofySlotSelectedNotification
  | CronofySlotAddedNotification
  | CronofySlotRemovedNotification
  | CronofyNoSlotsFoundNotification
  | CronofyNoVisibleSlotsNotification
  | CronofyVisibleSlotsNotification
  | CronofyQueryPeriodsEditiedNotification

export interface CronofyAvailablityViewerArgs {
  availability_query: CronofyAvailablityQuery
  element_token: string
  config?: {
    start_time?: string
    end_time?: string
    interval?: number
    mode?: 'confirm' | 'no_confirm' | 'multi_select' | 'free_select'
    week_start_day?: string
  }
  callback?: (notification: CronofyAvailablityViewerNotification) => void
}

export interface ScheduleItemRequest {
  slot_start: Date
  slot_end: Date
  timezone: string
}

export type ItemForScheduler<T = SchedulableItem> = Item &
  T & {
    expert: Expert | null
    orderID: number
  }

export type UnauthenticatedItemForScheduler<T = SchedulableItem> =
  Partial<Item> &
    T & {
      expert?: Expert | null
      orderID?: number
    }

export enum SchedulerStates {
  Loading = 'Loading',
  PreAssignment = 'PreAssignment',
  Schedule = 'Schedule',
  Scheduled = 'Scheduled',
  HappeningNow = 'HappeningNow',
  Missed = 'Missed',
  Rate = 'Rate',
  Completed = 'Completed',
}

export interface SchedulingState {
  scheduledItems: HashMap<SchedulableItem>
  details: HashMap<ItemSchedulingDetails>
  meta: {
    [SchedulingActions.FETCH_SCHEDULED_ITEMS]: LoadingLoadedErrorState
    [SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS]: HashMap<LoadingLoadedErrorState>
    [SchedulingActions.SCHEDULE_ITEM]: HashMap<LoadingLoadedErrorState>
  }
}

export const initalState: SchedulingState = Object.freeze({
  scheduledItems: {},
  details: {},
  meta: {
    [SchedulingActions.FETCH_SCHEDULED_ITEMS]: initialLoadedLoadingErrorState,
    [SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS]: {},
    [SchedulingActions.SCHEDULE_ITEM]: {},
  },
})
