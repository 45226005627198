import React from 'react'
import { useSelector } from 'react-redux'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import isEqual from 'lodash/isEqual'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AppointmentReminderModal from 'components/Modals/variants/AppointmentReminder'
import { AppState } from 'store'
import { itemsForAppointmentReminderModal } from 'store/scheduling/selectors'

const SchedulerReminderContainer: React.FC = () => {
  // Rules on dismissed notifications
  //
  // 1. If you are within 6 hours of your scheduled call, you will be presented
  //    with a notifcation that can be dismissed and will never be shown again.
  // 2. If you are within 15 minutes of your scheduled call, you will be
  //    presented a notification on each page load until your call is done.
  // 3. If the call is currently happening, you will be shown the notification
  //    to join the call until the slot length has passed. This accounts for
  //    users that disconnect mid call and need to get back in.
  //
  // The below rule accounts for the dismissal of point 1.
  const [dismissedNotifications, setDismissedNotifications] = useLocalStorage<
    number[]
  >('dismissedSchedulerNotifications', [])
  const item = useSelector<
    AppState,
    ReturnType<typeof itemsForAppointmentReminderModal>
  >(
    (store) =>
      itemsForAppointmentReminderModal(
        store.scheduling,
        store.items,
        store.orders,
        store.notifications,
        dismissedNotifications || []
      ),
    isEqual
  )
  const { t } = useTranslation()
  const currentlyOnCallPage = useRouteMatch<{ omsItemID?: string }>(
    t('routes.call')
  )

  if (
    !item ||
    currentlyOnCallPage?.params.omsItemID === item.item_id.toString()
  ) {
    return null
  }

  return (
    <AppointmentReminderModal
      omsItemID={item.item_id}
      sessionStart={item.scheduled_start}
      slotLength={30}
      expertName={item.expert?.display_name || ''}
      onClose={() =>
        setDismissedNotifications((itemIDs) => [
          ...(itemIDs || []),
          item.item_id,
        ])
      }
    />
  )
}

export default SchedulerReminderContainer
