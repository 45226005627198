import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import useBrand from 'hooks/useBrand'
import ModalWrapper, { ModalProps } from '../Wrapper'

import tickIcon from 'assets/images/tick.svg'
import videoPoster from 'assets/images/cio-video-fallback.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      padding: 0,
      marginBottom: 0,
    },
    video: {
      width: '100%',
    },
    content: {
      padding: '1.5em 2em 0em',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: '1.125em',
      fontWeight: 600,
      textAlign: 'start',
      marginBottom: '0.25em',
    },
    copy: {
      fontSize: '1em',
      textAlign: 'start',
      marginBottom: '0.75em',
    },
    featuresTitle: {
      color: theme.palette.text.primary,
      fontSize: '0.75em',
      fontWeight: 700,
      letterSpacing: '1.8px',
      textTransform: 'uppercase',
      textAlign: 'start',
      margin: '0.5em 0',
    },
    features: {
      backgroundColor: '#F6F7FA',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0.75em 0.5em',
    },
    feature: {
      display: 'flex',
      alignItems: 'center',
      margin: '0.25em 0',
      width: '50%',
    },
    featureText: {
      color: theme.palette.text.primary,
      fontSize: '0.875em',
    },
  })
)

const CIOInfoModal: React.FC<ModalProps> = ({ onClose, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brand = useBrand()

  const features: string[] = t(
    'components.Modals.variants.CIOInfoModal.features',
    { returnObjects: true }
  )

  return (
    <ModalWrapper
      showButton={false}
      onClose={onClose}
      ContentProps={{ className: classes.modal }}
      {...props}
    >
      <video
        className={classes.video}
        src="https://videos.ctfassets.net/7thvzrs93dvf/3pxwNkLi11XzenHJsYhQZB/ee5946c68b70a16204c8d18baae7f6dc/Career.io_23-08_2__1_.mp4"
        poster={videoPoster}
        autoPlay
        muted
        controls
        disablePictureInPicture
        controlsList="nodownload noplaybackrate"
        onContextMenu={(e) => e.preventDefault()}
      />
      <Container disableGutters className={classes.content}>
        <Typography className={classes.title}>
          {t('components.Modals.variants.CIOInfoModal.title')}
        </Typography>
        <Typography className={classes.copy}>
          {t(`components.Modals.variants.CIOInfoModal.copy`, {
            brand: brand.name,
          })}
        </Typography>
        <Typography className={classes.featuresTitle}>
          {t(`components.Modals.variants.CIOInfoModal.featuresTitle`)}
        </Typography>
        <Box className={classes.features}>
          {features.map((item) => (
            <Box className={classes.feature}>
              <img src={tickIcon} alt="tick" />
              <Typography className={classes.featureText}>{item}</Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </ModalWrapper>
  )
}

export default CIOInfoModal
