import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import {
  format as dateFormat,
  addMinutes,
  differenceInMinutes,
  formatDistanceToNow,
} from 'date-fns'
import { generatePath } from 'react-router-dom'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import ModalWrapper, { ModalWrapperProps } from '../Wrapper'
import GhostLink from 'components/shared/GhostLink'
import confirmedImg from 'assets/images/confirmed.svg'
import { colors } from 'styles'

interface Props {
  omsItemID: number
  sessionStart: Date
  slotLength: number
  expertName: string
  onClose: () => void
}

const tKey = 'components.Modals.variants.AppointmentReminder'

const AppointmentReminderModal: React.FC<Props> = ({
  omsItemID,
  sessionStart,
  slotLength,
  expertName,
  onClose,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(true)
  const happeningNow = sessionStart < new Date()
  let callToActionProps: ModalWrapperProps['callToActionProps'] = {
    children: t(`${tKey}.cta.dismiss`),
    onClick: () => onClose(),
  }

  if (differenceInMinutes(sessionStart, new Date()) <= 15) {
    callToActionProps = {
      children: t(`${tKey}.cta.join`),
      // @ts-ignore
      component: GhostLink,
      to: generatePath(t('routes.call'), { omsItemID }),
      onClick: () => setShowModal(false),
    }
  }

  if (!showModal) {
    return null
  }

  return (
    <ModalWrapper
      heroImageURL={confirmedImg}
      callToActionProps={callToActionProps}
      onClose={onClose}
    >
      <Typography variant="h2">
        {t(`${tKey}.${happeningNow ? 'titleNow' : 'title'}`, {
          timePeriod: formatDistanceToNow(sessionStart, {
            includeSeconds: false,
          }),
        })}
      </Typography>
      <Typography variant="body2">
        {t(`${tKey}.copy`, { expertName })}
      </Typography>
      <Box className={classes.sessionTime}>
        <CalendarTodayIcon />
        <Typography component="time" dateTime={sessionStart.toISOString()}>
          {t(`${tKey}.sessionTime`, {
            startTime: dateFormat(sessionStart, t(`${tKey}.timeFormat`)),
            endTime: dateFormat(
              addMinutes(sessionStart, slotLength),
              t(`${tKey}.timeFormat`)
            ),
            date: dateFormat(sessionStart, t(`${tKey}.dateFormat`)),
          })}
        </Typography>
      </Box>
    </ModalWrapper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sessionTime: {
      marginTop: '1.5em',
      padding: '1.5em',
      backgroundColor: colors.gray.a600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& > svg': {
        marginRight: '.6em',
        marginTop: '-3px',
      },

      '& > *': {
        fontSize: '.9em',
        color: theme.palette.primary.dark,
        fontWeight: 500,
      },
    },
  })
)

export default AppointmentReminderModal
