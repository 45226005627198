import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles/index'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import GhostLink from 'components/shared/GhostLink'
import { generatePath } from 'react-router-dom'
import { ClientIDWithStatus, ClientStatus } from 'store/orders/types'

interface Props {
  activeNum: number
  total: number
  prevClientID: ClientIDWithStatus | null
  nextClientID: ClientIDWithStatus | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      height: 'auto',
      width: '.9em',
    },
    arrows: {
      width: '2em',
    },
    flex: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0',
    },
    pagination: {
      borderLeft: `1px solid ${colors.gray[600]}`,
      paddingLeft: '1.2em',
      width: '6.3em',
    },
    paginationText: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
  })
)

const OrderNavigation: React.FC<Props> = ({
  activeNum,
  total,
  prevClientID,
  nextClientID,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Container
      className={clsx(classes.flex, classes.pagination)}
      disableGutters
    >
      <Typography className={classes.paginationText} component="p" variant="h4">
        {t('components.Messaging.pagination', {
          number: activeNum,
          total: total,
        })}
      </Typography>
      <Container className={clsx(classes.flex, classes.arrows)} disableGutters>
        <GhostLink
          replace={true}
          to={
            prevClientID && prevClientID.clientID
              ? prevClientID.status === ClientStatus.completed
                ? generatePath(t(`routes.completedMessagesByClient`), {
                    clientID: prevClientID.clientID,
                    status: String(prevClientID.status),
                  })
                : generatePath(t('routes.messagesByClient'), {
                    clientID: prevClientID.clientID,
                  })
              : generatePath(t('routes.messages'))
          }
        >
          <IconButton disabled={activeNum === 1}>
            <ArrowBackIosOutlinedIcon className={classes.arrow} />
          </IconButton>
        </GhostLink>
        <GhostLink
          replace={true}
          to={
            nextClientID && nextClientID.clientID
              ? nextClientID.status === ClientStatus.completed
                ? generatePath(t(`routes.completedMessagesByClient`), {
                    clientID: nextClientID.clientID,
                    status: String(nextClientID.status),
                  })
                : generatePath(t('routes.messagesByClient'), {
                    clientID: nextClientID.clientID,
                  })
              : generatePath(t('routes.messages'))
          }
        >
          <IconButton disabled={activeNum === total}>
            <ArrowForwardIosOutlinedIcon className={classes.arrow} />
          </IconButton>
        </GhostLink>
      </Container>
    </Container>
  )
}

export default OrderNavigation
