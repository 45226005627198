import { useSelector } from 'react-redux'

import { AppState } from 'store'
import {
  userIsAdmin,
  userIsCustomer,
  userIsDeveloper,
} from 'store/user/selectors'
import { getGhostIdentifier } from 'utils/ghosting'
import useUser from 'hooks/useUser'

function useFeatureFlags() {
  // Acquire data for flags
  const isProduction = useSelector<AppState, boolean>(
    (store) => store.config.config?.server_mode === 'production'
  )
  const [isAdmin, isCustomer, isDeveloper] = useSelector<AppState, boolean[]>(
    (store) =>
      [userIsAdmin, userIsCustomer, userIsDeveloper].map((f) => f(store.user))
  )
  const { email: userEmail } = useUser()
  const hasTalentIncEmail = userEmail?.includes('@talentinc.com') || false
  const isGhosting = !!getGhostIdentifier()

  // Create flags
  const canGhost = isDeveloper || isAdmin
  // Allow customers and developers to send messages in all environments
  const sendMessages = isCustomer || isDeveloper
  // Disable marking messages as read for admins in production and developers
  // when ghosting in production
  const markMessagesRead =
    isCustomer || ((!isProduction || !isGhosting) && isDeveloper)
  // Disable all notification related features for admins in production and
  // developers in production when ghosting.
  const notificationsInteraction =
    isCustomer || ((!isProduction || !isGhosting) && isDeveloper)
  // Disable customer email updating aggressively. Only allows customers to do
  // it OR in non-production environments, only allow updating of your own email
  // OR ghosted users with an @talentinc.com email address.
  const emailUpdating =
    isCustomer || (!isProduction && (!isGhosting || hasTalentIncEmail)) || false

  return {
    canGhost,
    sendMessages,
    markMessagesRead,
    notificationsInteraction,
    emailUpdating,
  }
}

export default useFeatureFlags
