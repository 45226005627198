import { IntervalIDs } from './types'
import { AppThunkAction } from 'store'
import { fetchMessagesForUserTimed } from 'store/messages/actions'
import { updateConfigTimed } from 'store/config/actions'

export enum CronActionTypes {
  SET_INTERVAL_IDS = 'SET_INTERVAL_IDS',
  CLEAR_INTERVAL_IDS = 'CLEAR_INTERVAL_IDS',
}

interface SetCronIntervalIDs {
  type: CronActionTypes.SET_INTERVAL_IDS
  intervals: Partial<IntervalIDs>
}

interface ClearCronIntervalIDs {
  type: CronActionTypes.CLEAR_INTERVAL_IDS
  intervals: Partial<IntervalIDs>
}

export function setCronIntervalIDs(
  intervals: Partial<IntervalIDs>
): SetCronIntervalIDs {
  return {
    type: CronActionTypes.SET_INTERVAL_IDS,
    intervals,
  }
}

export function clearCronIntervalIDs(
  intervals: Partial<IntervalIDs>
): ClearCronIntervalIDs {
  return {
    type: CronActionTypes.CLEAR_INTERVAL_IDS,
    intervals,
  }
}

export function initializeCrons(initialize: boolean): AppThunkAction {
  return async function (dispatch, getState) {
    const minute = 1000 * 60
    const intervals: Partial<IntervalIDs> = {
      messages:
        getState().user && getState().user.auth.loggedIn
          ? await dispatch(
              fetchMessagesForUserTimed({
                interval: minute * 2,
                initialize,
              })
            )
          : null,
      appUpdate: await dispatch(
        updateConfigTimed({
          interval: minute,
          initialize,
        })
      ),
    }
    dispatch(setCronIntervalIDs(intervals))
  }
}

export function suspendCrons(): AppThunkAction {
  return async function (dispatch, getState) {
    const intervalsToClear = Object.entries(getState().crons.intervals).reduce(
      (acc, [key, interval]) => {
        // @ts-ignore
        acc[key] = interval
        return acc
      },
      {} as Partial<IntervalIDs>
    )

    Object.values(intervalsToClear).forEach(
      (internal) => !!internal && clearInterval(internal.intervalID)
    )

    for (const key in intervalsToClear) {
      // @ts-ignore
      intervalsToClear[key] = null
    }

    dispatch(clearCronIntervalIDs(intervalsToClear))
  }
}

export type CronActions = SetCronIntervalIDs | ClearCronIntervalIDs
