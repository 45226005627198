import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles/index'
import Box from '@material-ui/core/Box'
import Rating, { RatingProps } from '@material-ui/lab/Rating'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'

interface PropsNoTooltip {
  showToolTip: false
  rating: number | null
  placement?: never
  onClick?: RatingProps['onClick']
}

interface PropsTooltip {
  showToolTip?: true
  placement: TooltipProps['placement']
  rating: number | null | undefined
  onClick?: RatingProps['onClick']
}

export type StarsProps = PropsTooltip | PropsNoTooltip

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      height: '1.6em',
      cursor: 'pointer',
    },
    icon: {
      height: '1em',
    },
    iconEmpty: {
      color: theme.palette.secondary.light,
    },
    rating: {
      color: colors.orange[300],
      cursor: 'pointer',
    },
    ratingLabel: {
      backgroundColor: theme.palette.primary.dark,
    },
  })
)

const Stars: React.FC<StarsProps> = ({
  placement,
  rating,
  showToolTip = true,
  onClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const rateText = t('pages.Home.yourPortfolio.rateDocuments')
  const rateAble = rating === null || rating === undefined

  return (
    <>
      {rateAble && (
        <Tooltip
          arrow
          placement={placement}
          title={rateText}
          disableFocusListener={!showToolTip}
          disableHoverListener={!showToolTip}
          disableTouchListener={!showToolTip}
          onClick={onClick}
        >
          <Box className={classes.box}>
            <Rating
              classes={{
                icon: classes.icon,
                iconEmpty: classes.iconEmpty,
                root: classes.rating,
              }}
              readOnly
            />
          </Box>
        </Tooltip>
      )}
      {!rateAble && (
        <Box>
          <Rating
            classes={{
              icon: classes.icon,
              iconEmpty: classes.iconEmpty,
              root: classes.rating,
            }}
            readOnly
            value={rating}
          />
        </Box>
      )}
    </>
  )
}

export default Stars
