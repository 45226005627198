import React, { useCallback } from 'react'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import useScheduler from './useScheduler'
import Scheduler from './index'

interface Props {
  open: boolean
  handleClose: () => void
  omsItemID: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      padding: '2rem 2rem 2rem 2rem',
    },
  })
)

const ScheduleModal: React.FC<Props> = ({ open, handleClose, omsItemID }) => {
  const classes = useStyles()
  const { cronofyArgs, onSchedule, item } = useScheduler(omsItemID)

  const onScheduleConfirmation = useCallback(
    async (start: Date, end: Date) => {
      await onSchedule(start, end)
      handleClose()
    },
    [onSchedule, handleClose]
  )

  if (!cronofyArgs) {
    return null
  }

  return (
    <Dialog
      open={open}
      scroll="body"
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <Container className={classes.dialog}>
        <Scheduler
          itemID={omsItemID}
          onScheduleConfirmation={onScheduleConfirmation}
          cronofyArgs={cronofyArgs}
          expertName={item?.expert_display_name}
        />
      </Container>
    </Dialog>
  )
}

export default ScheduleModal
