import React from 'react'
import { FaqsDisplayProps } from 'store/orders/types'
import useBrand from 'hooks/useBrand'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'

import FaqGroup from './FaqGroup'
import Question from './Question'
import Box from '@material-ui/core/Box'

interface Props {
  faqProps: FaqsDisplayProps[]
  widget: boolean
  handleQuestionExpand: (key: string, route: string) => void
}

interface Group {
  questions: string
  title: string
}

const FaqGroups: React.FC<Props> = ({
  faqProps,
  widget,
  handleQuestionExpand,
}) => {
  const { t } = useTranslation()
  const brand = useBrand()

  let groups: Group[] = []
  let questions: (string | null)[] = []
  const isTopResume = brand.name === 'TopResume'

  const careerJourney = t('components.Faqs.careerJourney', {
    brand: brand.name,
    returnObjects: true,
    supportEmail: brand.default_support_email,
  })
  const gettingToKnow = t('components.Faqs.gettingToKnow', {
    brand: brand.name,
    returnObjects: true,
    supportEmail: brand.default_support_email,
  })
  const professionalDocuments = t('components.Faqs.professionalDocuments', {
    brand: brand.name,
    returnObjects: true,
    supportEmail: brand.default_support_email,
  })
  const troubleshooting = t('components.Faqs.troubleshooting', {
    brand: brand.name,
    returnObjects: true,
    supportEmail: brand.default_support_email,
  })
  const workingWithWriter = t('components.Faqs.workingWithWriter', {
    brand: brand.name,
    returnObjects: true,
    supportEmail: brand.default_support_email,
  })

  // What groups to show whether on homepage or FAQ page
  if (widget) {
    // Array of questions to be shown
    switch (
      faqProps && faqProps[0] && faqProps[0].currentStatus
        ? faqProps[0].currentStatus.status
        : ''
    ) {
      case 'complete-questionnaire':
        questions = [gettingToKnow[0], gettingToKnow[1], gettingToKnow[3]]
        break
      case 'expert-assignment':
        questions = [
          workingWithWriter[0],
          workingWithWriter[1],
          careerJourney[0],
        ]
        break
      case 'linkedin-due':
        questions = [
          professionalDocuments[1],
          professionalDocuments[2],
          professionalDocuments[3],
        ]
        break
      case 'schedule-phone':
        questions = [
          careerJourney[4],
          professionalDocuments[3],
          isTopResume ? careerJourney[2] : null,
        ]
        break
      case 'phone':
        questions = [
          professionalDocuments[2],
          careerJourney[4],
          careerJourney[3],
        ]
        break
      case 'schedule-interview':
        questions = [
          careerJourney[4],
          professionalDocuments[3],
          isTopResume ? careerJourney[2] : null,
        ]
        break
      case 'interview':
        questions = [
          professionalDocuments[2],
          careerJourney[4],
          isTopResume ? careerJourney[2] : null,
        ]
        break
      case 'first-draft':
        questions = [
          workingWithWriter[0],
          workingWithWriter[1],
          careerJourney[0],
        ]
        break
      case 'revision-period':
        questions = [
          workingWithWriter[2],
          troubleshooting[0],
          workingWithWriter[3],
        ]
        break
      case 'completed':
        questions = [
          professionalDocuments[2],
          isTopResume ? careerJourney[2] : null,
          careerJourney[3],
        ]
        break
      case 'refund-partial':
        questions = [
          workingWithWriter[0],
          workingWithWriter[1],
          careerJourney[0],
        ]
        break
      case 'refund-full':
        questions = [
          workingWithWriter[0],
          workingWithWriter[1],
          careerJourney[0],
        ]
        break
      case 'reassigned':
        questions = [
          workingWithWriter[0],
          workingWithWriter[1],
          careerJourney[0],
        ]
        break
    }
  } else {
    groups = [
      {
        questions: careerJourney,
        title: 'careerJourneyTitle',
      },
      {
        questions: gettingToKnow,
        title: 'gettingToKnowTitle',
      },
      {
        questions: professionalDocuments,
        title: 'professionalDocumentsTitle',
      },
      {
        questions: workingWithWriter,
        title: 'workingWithWriterTitle',
      },
      {
        questions: troubleshooting,
        title: 'troubleshootingTitle',
      },
    ]
  }

  return (
    <Container disableGutters itemScope itemType="https://schema.org/FAQPage">
      {widget && (
        <>
          {questions.map((question: any) => (
            <Box key={question ? question.question : ''}>
              {question && (
                <Question
                  key={question.question}
                  answer={question.answer}
                  question={question.question}
                  handleExpand={handleQuestionExpand}
                />
              )}
            </Box>
          ))}
        </>
      )}
      {!widget && (
        <>
          {groups.map((group: any) => (
            <FaqGroup
              key={group.title}
              questions={group.questions}
              title={t(`components.Faqs.${group.title}`)}
              widget={widget}
              handleQuestionExpand={handleQuestionExpand}
            />
          ))}
        </>
      )}
    </Container>
  )
}

export default FaqGroups
