import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import useUser from 'hooks/useUser'
import { useAppSelector } from 'store'
import { scheduleItemAndRefresh } from 'store/scheduling/thunks'
import {
  itemDetailsToCronofy,
  itemSchedulerState,
  itemForScheduler,
  buyAnotherSessionLink,
  schedulerItemForScheduler,
} from 'store/scheduling/selectors'
import { SchedulerStates } from 'store/scheduling/types'
import { expertForItem } from 'store/items/selectors'

export default function useScheduler(omsItemID: number) {
  const user = useUser()
  //checking for an auth token here in case the user is pat authenticated
  const userIdentifier = user.auth_token || 'me'
  const dispatch = useDispatch()
  const item = useAppSelector<ReturnType<typeof itemForScheduler>>(
    (store) =>
      itemForScheduler(store.scheduling, store.items, store.orders, omsItemID),
    isEqual
  )
  const schedulerItem = useAppSelector<
    ReturnType<typeof schedulerItemForScheduler>
  >((store) => schedulerItemForScheduler(store.scheduling, omsItemID), isEqual)
  const cronofyArgs = useAppSelector<ReturnType<typeof itemDetailsToCronofy>>(
    (store) => itemDetailsToCronofy(store.scheduling, omsItemID),
    isEqual
  )
  const schedulingState = useAppSelector<SchedulerStates>((store) =>
    itemSchedulerState(store.scheduling, store.ratings, store.items, omsItemID)
  )
  const purchaseAnotherSessionURL = useAppSelector<string | null>((state) =>
    buyAnotherSessionLink(state.items, state.user, state.brand, omsItemID)
  )
  const expert = useAppSelector<ReturnType<typeof expertForItem>>(
    (store) =>
      item ? expertForItem(store.items, store.orders, item.id) : null,
    isEqual
  )

  const onSchedule = useCallback(
    async (start: Date, end: Date) => {
      await dispatch(
        scheduleItemAndRefresh(userIdentifier, omsItemID, {
          slot_start: start,
          slot_end: end,
          timezone: user.timezone,
        })
      )
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [omsItemID, user.timezone, userIdentifier]
  )

  return {
    item,
    schedulerItem,
    onSchedule,
    cronofyArgs,
    schedulingState,
    purchaseAnotherSessionURL,
    expert,
  }
}
