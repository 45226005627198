import React, { useCallback, useState } from 'react'
import useShoppingCart from 'hooks/useShoppingCart'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { GhostRedirect } from 'components/shared/GhostLink'

const EmptyCart: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { updateOpen } = useShoppingCart()
  const [redirect, setRedirect] = useState(false)
  const tRef = 'components.Checkout'

  const onShopNowClick = useCallback(async () => {
    updateOpen(false)
    setRedirect(true)
  }, [updateOpen])

  if (redirect) {
    return <GhostRedirect to={generatePath(t(`routes.upgrade`))} push />
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h3" className={classes.text}>
        {t(`${tRef}.empty`)}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onShopNowClick}
      >
        {t(`${tRef}.shopNow`)}
      </Button>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem 1.5rem 2rem 1.5rem',
    },
    text: {
      fontSize: '1.5rem',
      fontWeight: 'normal',
      marginBottom: '1.5rem',
    },
    button: {
      width: '100%',
    },
  })
)

export default EmptyCart
