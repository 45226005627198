import * as ram from '@talentinc/redux-axios-middleware'

import { FetchUserSuccess } from 'store/user/actions'
import { UserIdentifier } from 'store/user/types'
import { UserBrand } from './types'

export enum BrandActions {
  FETCH_BRAND = 'FETCH_BRAND',
  FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS',
  FETCH_BRAND_FAIL = 'FETCH_BRAND_FAIL',
  FETCH_USER_BRAND = 'FETCH_USER_BRAND',
  FETCH_USER_BRAND_SUCCESS = 'FETCH_USER_BRAND_SUCCESS',
  FETCH_USER_BRAND_FAIL = 'FETCH_USER_BRAND_FAIL',
}

interface FetchBrand extends ram.AxiosMiddlewareActionCreator {
  type: BrandActions.FETCH_BRAND
}

interface FetchBrandSuccess
  extends ram.AxiosMiddlewareActionSuccess<UserBrand, FetchBrand> {
  type: BrandActions.FETCH_BRAND_SUCCESS
}

interface FetchBrandFail extends ram.AxiosMiddlewareActionFail<FetchBrand> {
  type: BrandActions.FETCH_BRAND_FAIL
}

export interface FetchUserBrand extends ram.AxiosMiddlewareActionCreator {
  type: BrandActions.FETCH_USER_BRAND
}

export interface FetchUserBrandSuccess
  extends ram.AxiosMiddlewareActionSuccess<UserBrand, FetchUserBrand> {
  type: BrandActions.FETCH_USER_BRAND_SUCCESS
}

export interface FetchUserBrandFail
  extends ram.AxiosMiddlewareActionFail<FetchUserBrand> {
  type: BrandActions.FETCH_USER_BRAND_FAIL
}

export const fetchBrand = (brandID: number, siteID: number): FetchBrand => {
  return {
    type: BrandActions.FETCH_BRAND,
    payload: {
      request: {
        url: `/v2/brands/user/${brandID}?site_id=${siteID}`,
        baseURL: process.env.REACT_APP_PORTAL_API_URL,
      },
    },
  }
}

export const fetchUserBrand = (
  userIdentifier: UserIdentifier
): FetchUserBrand => ({
  type: BrandActions.FETCH_USER_BRAND,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/brand`,
    },
  },
})

export type BrandAction =
  | FetchBrand
  | FetchBrandSuccess
  | FetchBrandFail
  | FetchUserSuccess
  | FetchUserBrand
  | FetchUserBrandSuccess
  | FetchUserBrandFail
