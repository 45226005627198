import React from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'

import { User } from 'store/user/types'
import PersonalInfo from './PersonalInfo'
import LoginInfo from './LoginInfo'
import { OnUpdateUser } from './types'
import Skeleton from 'components/shared/Skeleton'
import PurchaseHistoryInfo from './PurchaseHistoryInfo'
import Box from '@material-ui/core/Box'

interface Props {
  user: Partial<User>
  onUpdateUser: OnUpdateUser
  isUpdatingUser?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1.25em',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    paper: {
      margin: '1em 0',
      padding: '0',
    },
    rightColumn: {
      width: '23em',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    paperPersonalInfo: {
      width: 'calc(100% - 24.5em)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    input: {
      cursor: 'text',
    },
  })
)

const Account: React.FC<Props> = ({ user, onUpdateUser, isUpdatingUser }) => {
  const classes = useStyles()
  const loading = false
  const theme = useTheme()
  const components = useMediaQuery(theme.breakpoints.down('md'))
    ? [
        { height: '30em', width: '100%' },
        { height: '15em', width: '100%' },
      ]
    : [
        { height: '30em', width: '65%' },
        { height: '15em', width: '32%' },
      ]

  return (
    <Container className={classes.container} disableGutters>
      {loading && <Skeleton components={components} />}
      {!loading && (
        <>
          <Paper
            className={clsx(classes.paper, classes.paperPersonalInfo)}
            variant="outlined"
          >
            <PersonalInfo
              user={user}
              onUpdateUser={onUpdateUser}
              isUpdatingUser={isUpdatingUser}
            />
          </Paper>
          <Box className={classes.rightColumn}>
            <Paper className={classes.paper} variant="outlined">
              <LoginInfo user={user} onUpdateUser={onUpdateUser} />
            </Paper>
            <Paper className={classes.paper} variant="outlined">
              <PurchaseHistoryInfo />
            </Paper>
          </Box>
        </>
      )}
    </Container>
  )
}

export default Account
