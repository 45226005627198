import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles/index'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { isIE } from 'react-device-detect'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface Props {
  answer: string
  question: string
  handleExpand: (key: string, route: string) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionDetails: {
      borderTop: `1px solid ${colors.gray[600]}`,
      padding: '1.75em 2em',
    },
    accordionSummary: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      padding: '1.75em 2em 1.75em 1.5em',
    },
    ie11Summary: {
      height: '100px',
    },
    accordionSummaryContent: {
      margin: '0 !important',
    },
    expandIconWrapper: {
      color: theme.palette.primary.main,
      height: 'auto',
      width: '2em',
    },
    h3: {
      marginLeft: '1.5em',
    },
  })
)

const Question: React.FC<Props> = ({ answer, question, handleExpand }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<boolean>(false)
  const location = useLocation()
  const onClick = useCallback(() => {
    if (!expanded) {
      handleExpand(question, location.pathname)
    }
    setExpanded(!expanded)
  }, [expanded, handleExpand, location.pathname, question])

  return (
    <Accordion
      onChange={() => onClick()}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <AccordionSummary
        classes={{
          content: classes.accordionSummaryContent,
          root: clsx(classes.accordionSummary, isIE && classes.ie11Summary),
        }}
        expandIcon={<ExpandMoreIcon className={classes.expandIconWrapper} />}
      >
        <Typography
          className={classes.h3}
          component="h3"
          variant="body1"
          itemProp="name"
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <Typography
          variant="body1"
          itemProp="text"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Question
