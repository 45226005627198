import React from 'react'
import useBrand from 'hooks/useBrand'
import { useTranslation } from 'react-i18next'

import ContentHeader from 'components/Content/ContentHeader'
import FlashErrorBoundary from 'components/shared/Errors'
import PageShell from 'pages/PageShell'
import TextContainer from 'components/Content/TextContainer'
import ArticleTrans from 'components/Content/ArticleTrans'

import ProcessImg from 'assets/images/process-large.png'

const Article1: React.FC = () => {
  const { t } = useTranslation()
  const brand = useBrand()

  return (
    <PageShell content={true} contentDir="column">
      <FlashErrorBoundary>
        <ContentHeader
          date={t([
            `pages.Article1.${brand.name}.date`,
            'pages.Article1.default.date',
          ])}
          img={ProcessImg}
          imgAlt={t([
            `pages.Article1.${brand.name}.imgAlt`,
            'pages.Article1.default.imgAlt',
          ])}
          title={t(
            [
              `pages.Article1.${brand.name}.title`,
              'pages.Article1.default.title',
            ],
            { brand: brand.name }
          )}
        />
        <TextContainer>
          <ArticleTrans
            i18nKeys={[
              `pages.Article1.${brand.name}.text`,
              `pages.Article1.default.text`,
            ]}
          />
        </TextContainer>
      </FlashErrorBoundary>
    </PageShell>
  )
}

export default Article1
