import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useEffectOnce from 'react-use/lib/useEffectOnce'
import isEqual from 'lodash/isEqual'

import { AppState } from 'store'
import { fetchFilesForUser } from 'store/files/actions'
import { documentsForUserFinishedFetching } from 'store/files/selectors'
import {
  activeInterviewPrepDisplayProps,
  completedInterviewPrepDisplayProps,
  userActiveDocumentsDisplayProps,
  userCompletedDocumentsDisplayProps,
} from 'store/items/selectors'
import Documents, { DocumentsLoader } from './index'
import { ClientIDWithStatus, ClientStatus } from 'store/orders/types'
import {
  InterviewPrepDocsDisplayProps,
  UserDocumentsItemProps,
} from 'store/items/types'
import { DocumentsByTypeAndOrderItemID } from 'store/files/types'
import { isInterviewPrepClient } from 'store/orders/selectors'

interface Props {
  clientIDWithStatus: ClientIDWithStatus
  defaultDocumentsTab?: string | null
  showRatingModal: () => void
}

const UserDocumentsContainer: React.FC<Props> = ({
  clientIDWithStatus,
  defaultDocumentsTab = null,
  showRatingModal,
}) => {
  const dispatch = useDispatch()
  const clientID = (clientIDWithStatus && clientIDWithStatus.clientID) || null
  const finishedLoading = useSelector<AppState, boolean>((state) =>
    documentsForUserFinishedFetching(state.files)
  )
  const approveOrderLoading = useSelector<AppState, boolean>(
    (state) => state.orders.approveOrderLoading
  )

  const isCompletedView =
    clientIDWithStatus?.status === ClientStatus.completed &&
    !approveOrderLoading

  const activeDocGroup = useSelector<
    AppState,
    DocumentsByTypeAndOrderItemID<UserDocumentsItemProps> | null
  >(
    (state: AppState) =>
      userActiveDocumentsDisplayProps(
        state.items,
        state.orders,
        state.files,
        state.ratings,
        clientID
      ),
    isEqual
  )

  const completedDocGroup = useSelector<
    AppState,
    DocumentsByTypeAndOrderItemID<UserDocumentsItemProps> | null
  >(
    (state: AppState) =>
      userCompletedDocumentsDisplayProps(
        state.items,
        state.orders,
        state.files,
        state.ratings,
        clientID
      ),
    isEqual
  )

  const isInterviewPrep = useSelector<AppState, boolean>((state) =>
    isInterviewPrepClient(state.orders, clientID)
  )

  const activeInterviewPrepProps = useSelector<
    AppState,
    InterviewPrepDocsDisplayProps | null
  >((state) =>
    activeInterviewPrepDisplayProps(
      state.items,
      state.orders,
      state.files,
      state.scheduling,
      clientID
    )
  )

  const completedInterviewPrepProps = useSelector<
    AppState,
    InterviewPrepDocsDisplayProps | null
  >((state) =>
    completedInterviewPrepDisplayProps(
      state.items,
      state.orders,
      state.files,
      state.scheduling,
      clientID
    )
  )

  const interviewPrepProps = isCompletedView
    ? completedInterviewPrepProps
    : activeInterviewPrepProps

  const docGroup =
    (clientIDWithStatus &&
      clientIDWithStatus.status === ClientStatus.completed) ||
    !activeDocGroup ||
    activeDocGroup.size === 0
      ? completedDocGroup
      : activeDocGroup

  useEffectOnce(() => {
    dispatch(fetchFilesForUser())
  })

  if (!finishedLoading) {
    return <DocumentsLoader />
  }

  if (!docGroup) return null

  return (
    <Documents
      itemsWithDocs={docGroup}
      defaultDocumentsTab={defaultDocumentsTab}
      isCompletedView={isCompletedView}
      showRatingModal={showRatingModal}
      isInterviewPrep={isInterviewPrep}
      interviewPrepProps={interviewPrepProps}
    />
  )
}

export default UserDocumentsContainer
