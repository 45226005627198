import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import GettingStartedModal from './variants/GettingStarted'
import MatchedWithWriterModal from './variants/MatchedWithWriter'
import MatchedWithNewWriterModal from './variants/MatchedWithNewWriter'
import EarlyOrderModal from './variants/EarlyOrder'
import DocumentReadyModal from './variants/DocumentReady'
import LateOrderModal from './variants/LateOrder'
import OrderUpgradedModal from './variants/OrderUpgraded'
// import FreeServiceModal from './variants/FreeService'
import OrderUnassignedModal from './variants/OrderUnassigned'
import OrderClonedModal from './variants/OrderCloned'
import OrderReceivedModal from './variants/OrderReceived'
import FirstDraftModal from './variants/FirstDraft'

// import { joinConversationally } from 'utils/formatting'

import { AppState } from 'store'

import {
  notificationToShowModalFor,
  unreadNotifications,
  isLoadingNotification,
} from 'store/notifications/selectors'
import { expertByID } from 'store/experts/selector'
import {
  itemsByCompletedStatus,
  latestItemDelivery,
} from 'store/items/selectors'

import { NotificationTypes, Notification } from 'store/notifications/types'
import { Expert } from 'store/orders/types'
import { Item, ItemStatusTypes } from 'store/items/types'

import { markNotificationViewed } from 'store/notifications/actions'
import FirstDraftDocumentModal from './variants/FirstDraftDocument'

interface Props {
  notificationID?: number | null
  onModalClose?: () => void
}

/**
 * NotificationModalContainer is a container component that reads from the
 * notifications store and displays modals based upon their state. This is used
 * to automatically show modals, not to launch them on demand.
 *
 * By passing in a notificationID, you can show a modal for a specific
 * notification.
 */
const NotificationModalContainer: React.FC<Props> = ({
  notificationID,
  onModalClose,
}) => {
  const dispatch = useDispatch()

  const notification = useSelector<
    AppState,
    ReturnType<typeof notificationToShowModalFor>
  >(
    (state) =>
      notificationID
        ? state.notifications.notifications[notificationID] || null
        : notificationToShowModalFor(state.notifications),
    isEqual
  )
  const deliveredItems = useSelector<AppState, Item[]>((state) =>
    itemsByCompletedStatus(
      state.items,
      notification?.order_ids,
      ItemStatusTypes.FirstDraft
    )
  )

  const lastDeliveryDate = useSelector<AppState, Date | null | undefined>(
    (state) => latestItemDelivery(state.items, notification?.order_ids)
  )

  const expert = useSelector<AppState, Expert | null>(
    (state) =>
      expertByID(
        state.experts,
        deliveredItems && deliveredItems.length > 0 && deliveredItems[0]
          ? deliveredItems[0].oms_expert_id
          : null
      ),
    isEqual
  )

  const unreadNotifs = useSelector<AppState, Notification[]>((state) =>
    unreadNotifications(state.notifications)
  )

  const isMarkingViewed = useSelector<AppState, boolean>((state) =>
    isLoadingNotification(state.notifications)
  )

  const currentNotificationID = notification ? notification.id : null

  const markMenuNotificationsAsViewed = useCallback(() => {
    if (currentNotificationID) {
      unreadNotifs.forEach((n) => {
        dispatch(markNotificationViewed('me', n.id))
      })
    }

    if (onModalClose) {
      onModalClose()
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentNotificationID, onModalClose])

  const markNotificationAsViewed = useCallback(() => {
    if (currentNotificationID) {
      dispatch(markNotificationViewed('me', currentNotificationID))
    }

    if (onModalClose) {
      onModalClose()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentNotificationID, onModalClose])

  if (!notification || isMarkingViewed) {
    return null
  }

  switch (notification.name) {
    case NotificationTypes.Welcome:
      return (
        <GettingStartedModal
          onClose={markMenuNotificationsAsViewed}
          onComplete={markMenuNotificationsAsViewed}
        />
      )
    case NotificationTypes.ExpertAssigned:
      return (
        <MatchedWithWriterModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{
            onClick: markMenuNotificationsAsViewed,
          }}
        />
      )
    case NotificationTypes.OrderReadyEarly:
      return (
        <EarlyOrderModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{
            onClick: markMenuNotificationsAsViewed,
          }}
        />
      )
    case NotificationTypes.OrderReady:
      return (
        <DocumentReadyModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{
            onClick: markMenuNotificationsAsViewed,
          }}
          attachedDocTypes={notification.variables.attached_doc_types}
        />
      )
    case NotificationTypes.OrderLate:
      return (
        <LateOrderModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{
            onClick: markMenuNotificationsAsViewed,
          }}
        />
      )
    case NotificationTypes.OrderReassigned:
      return (
        <MatchedWithNewWriterModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{
            onClick: markMenuNotificationsAsViewed,
          }}
        />
      )
    case NotificationTypes.OrderItemAdjusted:
      return (
        <OrderUpgradedModal
          onClose={markMenuNotificationsAsViewed}
          upgradedProduct={notification.variables.upgradedProduct}
          originalProduct={notification.variables.originalProduct}
          callToActionProps={{
            onClick: markMenuNotificationsAsViewed,
          }}
        />
      )
    // case NotificationTypes.OrderItemComped:
    //   return (
    //     <FreeServiceModal
    //       onClose={markMenuNotificationsAsViewed}
    //       productName={joinConversationally(notification.variables.itemNames)}
    //       callToActionProps={{
    //         onClick: markMenuNotificationsAsViewed,
    //       }}
    //     />
    //   )
    case NotificationTypes.OrderUnassigned:
      return (
        <OrderUnassignedModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{ onClick: markMenuNotificationsAsViewed }}
        />
      )
    case NotificationTypes.OrderCloned:
      return (
        <OrderClonedModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{ onClick: markMenuNotificationsAsViewed }}
        />
      )
    case NotificationTypes.OrderReceived:
      return (
        <OrderReceivedModal
          onClose={markMenuNotificationsAsViewed}
          callToActionProps={{ onClick: markMenuNotificationsAsViewed }}
          items={notification.variables.items || []}
        />
      )
    case NotificationTypes.FirstDraft:
      return (
        <FirstDraftModal
          onClose={markNotificationAsViewed}
          callToActionProps={{
            onClick: markNotificationAsViewed,
          }}
          expert={expert}
          items={deliveredItems}
          deliveryDate={lastDeliveryDate}
          attachedItemTyes={notification.variables.attached_doc_types}
        />
      )
    case NotificationTypes.FirstDraftDocument:
      return (
        <FirstDraftDocumentModal
          onClose={markNotificationAsViewed}
          callToActionProps={{
            onClick: markNotificationAsViewed,
          }}
          expert={expert}
          items={deliveredItems}
          deliveryDate={lastDeliveryDate}
          docType={notification.variables.doc_type}
        />
      )
    default:
      return null
  }
}

export default NotificationModalContainer
