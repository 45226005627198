import { HashMap } from '@talentinc/state-utils'
import { compareDesc as compareDateDesc } from 'date-fns'

import { MessageState, Message, MessageWithAttachments } from './types'
import { FilesState } from 'store/files/types'
import { MessageActions } from './actions'
import { OrdersState } from 'store/orders/types'
import { activeOrders, activeOrdersByClient } from 'store/orders/selectors'

export const messages = (state: MessageState): Message[] => {
  return Object.values(state.messages)
}

export const activeMessagesByClientID = (
  messagesState: MessageState,
  ordersState: OrdersState
): HashMap<Message[]> => {
  const orders = activeOrders(ordersState)
  if (orders.length === 0) {
    return {}
  }
  const earliestOrder = orders.sort((a, b) =>
    a.sent_to_fulfillment_at && b.sent_to_fulfillment_at
      ? a.sent_to_fulfillment_at?.getTime() - b.sent_to_fulfillment_at.getTime()
      : -1
  )[0]
  const earliestActiveOrderDate = earliestOrder.sent_to_fulfillment_at
    ? earliestOrder.sent_to_fulfillment_at
    : earliestOrder.created_at
  return Object.entries(messagesState.byContextID).reduce(
    (acc, [contextID, messageIDs]) => {
      acc[contextID] = messagesSortedBySentAt(
        Array.from(messageIDs)
          .map((messageID) => messagesState.messages[messageID])
          .filter((m) => m.sent.getTime() >= earliestActiveOrderDate.getTime())
      )
      return acc
    },
    {} as HashMap<Message[]>
  )
}

export const completedMessagesByClientID = (
  messagesState: MessageState,
  ordersState: OrdersState
): HashMap<Message[]> => {
  return Object.entries(messagesState.byContextID).reduce(
    (acc, [contextID, messageIDs]) => {
      const orders = activeOrdersByClient(ordersState, parseInt(contextID))
      const earliestActiveOrderDate =
        !orders || orders.length === 0
          ? null
          : orders.sort(
              (a, b) => a.created_at.getTime() - b.created_at.getTime()
            )[0].created_at
      acc[contextID] = messagesSortedBySentAt(
        Array.from(messageIDs)
          .map((messageID) => messagesState.messages[messageID])
          .filter((m) =>
            earliestActiveOrderDate
              ? m.sent.getTime() < earliestActiveOrderDate.getTime()
              : true
          )
      )
      return acc
    },
    {} as HashMap<Message[]>
  )
}

export const messagesByClientIDWithAttachments = (
  filesState: FilesState,
  messages: HashMap<Message[]>
): HashMap<MessageWithAttachments[]> => {
  return Object.entries(messages).reduce((acc, [clientID, messages]) => {
    acc[clientID] = messages.map((message) => ({
      ...message,
      attachments: message.attachment_ids.length
        ? message.attachment_ids
            .map((fileID) => filesState.files[fileID])
            .filter((f) => !!f)
        : [],
    }))

    return acc
  }, {} as HashMap<MessageWithAttachments[]>)
}

export const activeMessagesByClientIDWithAttachments = (
  messagesState: MessageState,
  filesState: FilesState,
  ordersState: OrdersState
): HashMap<MessageWithAttachments[]> => {
  const messages = activeMessagesByClientID(messagesState, ordersState)
  return messagesByClientIDWithAttachments(filesState, messages)
}

export const completedMessagesWithAttachments = (
  messagesState: MessageState,
  filesState: FilesState,
  ordersState: OrdersState
): HashMap<MessageWithAttachments[]> => {
  const messages = completedMessagesByClientID(messagesState, ordersState)
  return messagesByClientIDWithAttachments(filesState, messages)
}

export const messagesSortedBySentAt = (messages: Message[]) =>
  messages.sort((a, b) => compareDateDesc(a.sent, b.sent))

export const messageIsSending = (state: MessageState): boolean => {
  return state.meta.SEND_MESSAGE.isLoading
}

export const unreadMessages = (state: MessageState) =>
  Object.values(state.messages).filter(
    (message) => !message.outbound && !message.read_at
  )

export const messagesFinishedLoading = (state: MessageState): boolean =>
  state.meta[MessageActions.FETCH_MESSAGES_FOR_USER].loaded ||
  Object.keys(state.messages).length > 0
