import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import Logo, { LogoProps } from 'components/shared/Logo'
import useBrand from 'hooks/useBrand'

const useStyles = makeStyles((theme) =>
  createStyles<string, { name?: string }>({
    button: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    logo: {
      fontSize: '1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '1.2em',

      '& img': {
        height: '46px',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          height: '44px',
        },
        [theme.breakpoints.down('xs')]: {
          height: '36px',
        },
      },
    },
  })
)

interface Props {
  logoGoesToPortal?: boolean
}

const LiteHeader: React.FC<Props> = ({ logoGoesToPortal = true }) => {
  const { t } = useTranslation()
  const brand = useBrand()
  const classes = useStyles({ name: brand.name })
  const logoProps: LogoProps = { to: t('routes.home') }

  if (!logoGoesToPortal && brand.home_url) {
    logoProps.to = undefined
    logoProps.href = brand.home_url
  }

  return (
    <LiteToolbar>
      <Logo className={classes.logo} {...logoProps} />
      <Button className={classes.button} href={brand.home_url} variant="text">
        {t('components.Auth.Login.back')}
      </Button>
    </LiteToolbar>
  )
}

export const LiteToolbar = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    justifyContent: 'space-between',
    padding: '2em 4.5em',
    [theme.breakpoints.down('xs')]: {
      padding: '1em',
    },
  },
}))((props) => <Toolbar disableGutters variant="dense" {...props} />)

export default LiteHeader
