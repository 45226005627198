import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
  title: string
  titleAdornment?: React.ReactNode
}

const OrderWidgetAccordion: React.FC<Props> = ({
  children,
  title,
  titleAdornment,
}) => {
  const classes = useStyles()

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandIconWrapper} />}
        className={classes.accordionSummary}
      >
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {titleAdornment}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      borderBottomWidth: 0,
      '&:before': {
        height: 0,
      },
    },
    accordionSummary: {
      paddingLeft: 0,
    },
    accordionDetails: {
      paddingLeft: 0,
    },
    title: {
      color: theme.palette.secondary.main,
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    expandIconWrapper: {
      fontSize: '2em',
      color: theme.palette.primary.main,
      marginRight: '-.35em',
    },
  })
)

export default OrderWidgetAccordion
