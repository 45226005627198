import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import SvgFlag from 'assets/images/flag.png'
import SvgFlagTop from 'assets/images/flag-top.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    '@keyframes appear': {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 1,
      },
    },
    '@keyframes unfurl': {
      from: {
        opacity: 1,
        transform: 'rotateX(90deg) skewY(-10deg)',
      },
      to: {
        opacity: 1,
        transform: 'rotateX(0deg) skewY(0deg)',
      },
    },
    container: {
      height: '7.45em',
      position: 'absolute',
      right: '2em',
      top: '-.8em',
      width: '4.6em',
    },
    flag: {
      alignItems: 'center',
      animationDelay: '.5s',
      animationDuration: '1.5s',
      animationFillMode: 'forwards',
      animationName: '$unfurl',
      animationTimingFunction: 'ease-out',
      backgroundImage: `url(${SvgFlag})`,
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '6.75em',
      opacity: 0,
      paddingBottom: '1.6em',
      position: 'absolute',
      right: 0,
      transform: 'scaleX(90deg)',
      transformOrigin: 'top left',
      width: '3.8em',
      justifyContent: 'space-evenly',
    },
    flagTop: {
      animationDelay: '.5s',
      animationDuration: '1.5s',
      animationFillMode: 'forwards',
      animationName: '$appear',
      position: 'absolute',
      right: 0,
      top: '1px',
      width: '100%',
    },
  })
)

const DiscountFlag: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles()

  return (
    <>
      {children && (
        <Container className={classes.container} disableGutters>
          <img alt="top of flag" className={classes.flagTop} src={SvgFlagTop} />
          <Container className={classes.flag} disableGutters>
            {children}
          </Container>
        </Container>
      )}
    </>
  )
}

export default DiscountFlag
