import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import useLocalStorage from 'react-use/lib/useLocalStorage'

import useAuth0 from './useAuth0'
import { REDIRECT_CACHE_KEY } from './ProtectedRoute'

const Callback: React.FC = () => {
  const { loggedIn } = useAuth0()
  const [redirectTo, , removeRedirect] = useLocalStorage<string>(
    REDIRECT_CACHE_KEY,
    '/'
  )

  useEffect(() => {
    if (loggedIn) {
      removeRedirect()
    }
  }, [loggedIn, removeRedirect])

  if (loggedIn) {
    return <Redirect to={redirectTo || '/'} />
  }

  // @TODO Show a loader?
  return null
}

export default Callback
