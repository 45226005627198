import { ExperimentsState, ExperimentSearch, ExperimentQuery } from './types'

export const experimentQuery = (
  state: ExperimentsState,
  query: ExperimentQuery
): [boolean, boolean] => [
  userIsInExperimentCase(state, query),
  experimentNeedsLoading(state, query),
]

export const userIsInExperimentCase = (
  state: ExperimentsState,
  query: ExperimentQuery
): boolean => {
  if ('experimentID' in query) {
    return !!Object.values(state.experiments).find(
      (experimentCase) =>
        experimentCase.id === query.caseID &&
        experimentCase.experiment_id === query.experimentID
    )
  }

  if ('caseLabel' in query) {
    return !!Object.values(state.experiments).find(
      (experimentCase) =>
        experimentCase.case_label === query.caseLabel &&
        experimentCase.experiment_label === query.experimentLabel
    )
  }

  if ('caseDescriptor' in query) {
    return !!Object.values(state.experiments).find(
      (experimentCase) =>
        experimentCase.descriptor === query.caseDescriptor &&
        experimentCase.experiment_label === query.experimentLabel
    )
  }

  return false
}

export const experimentIsLoadedByQuery = (
  state: ExperimentsState,
  query: ExperimentQuery
) =>
  !!Object.values(state.experiments).find((experimentCase) => {
    if ('experimentID' in query) {
      return experimentCase.experiment_id === query.experimentID
    }

    if ('caseLabel' in query) {
      return experimentCase.case_label === query.caseLabel
    }

    return false
  })

export const experimentNeedsLoading = (
  state: ExperimentsState,
  query: ExperimentQuery
): boolean => {
  // See if the user has been assigned a case for this query
  if (experimentIsLoadedByQuery(state, query)) {
    return false
  }

  // There is a case where the user hasn't been assigned or the experiment
  // doesn't exist. In that case we need to assign AND if the experiment doesn't
  // exist, we only fetch it once.
  const strQuery = JSON.stringify(queryToSearchBody(query))
  const meta = state.metaByQuery?.[strQuery]

  return !meta || (!meta.loaded && !meta.isLoading)
}

export const queryToSearchBody = (query: ExperimentQuery): ExperimentSearch => {
  const search: ExperimentSearch = {
    active: true,
  }

  if ('experimentLabel' in query) {
    search.labels = [query.experimentLabel]
  }

  if ('experimentID' in query) {
    search.ids = [query.experimentID]
  }

  return search
}
