import React from 'react'
import { ItemTypes } from 'store/items/types'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import PngLetter from 'assets/images/upsell-shopping-letter.png'
import PngLetterThankYou from 'assets/images/upsell-shopping-thank-you-letter.png'
import PngLinkedIn from 'assets/images/upsell-shoping-linkedin.png'
import PngInterviewPrep from 'assets/images/upsell-shopping-interview-prep.png'
import PngRabbit from 'assets/images/upsell-shopping-resume-rabbit.png'
import PngRecruiter from 'assets/images/upsell-shopping-recruiter.png'
import PngGuarantee from 'assets/images/upsell-shopping-guarantee.png'
import PngResume from 'assets/images/upsell-shopping-resume.png'

interface Props {
  itemType: ItemTypes
  itemName: string
  className?: string
}

const CatalogItemIcon: React.FC<Props> = ({
  itemType,
  itemName,
  className,
}) => {
  const classes = useStyles()
  let icon = PngLetter
  switch (itemType) {
    case ItemTypes.InterviewPrep:
      icon = PngInterviewPrep
      break
    case ItemTypes.LinkedInDocument:
      icon = PngLinkedIn
      break
    case ItemTypes.PostingService:
      icon = PngRabbit
      break
    case ItemTypes.RecruiterService:
      icon = PngRecruiter
      break
    case ItemTypes.Guarantee:
      icon = PngGuarantee
      break
    case ItemTypes.Resume:
      icon = PngResume
      break
    case ItemTypes.ThankYouLetter:
      icon = PngLetterThankYou
      break
  }
  return (
    <img
      alt={`${itemName} icon`}
      className={clsx(classes.img, className)}
      src={icon}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: '100%',
      borderRadius: '0.25rem',
    },
  })
)

export default CatalogItemIcon
