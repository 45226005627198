import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { generatePath } from 'react-router'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import PageShell from 'pages/PageShell'
import MessagingContainer from 'components/Messaging/MessagingContainer'
import UserDocumentsContainer from 'components/Documents/UserDocumentsContainer'
import {
  clientIDsWithStatus,
  latestOrderByClient,
} from 'store/orders/selectors'
import { AppState } from 'store'
import { itemNameForItemByOMSItemID } from 'store/items/selectors'
import FlashErrorBoundary, { NoOpErrorBoundary } from 'components/shared/Errors'
import {
  ClientIDWithStatus,
  ClientStatus,
  OrdersState,
} from 'store/orders/types'
import { GhostRedirect } from 'components/shared/GhostLink'
import RatingModalContainer from 'components/Rating/RatingModalContainer'
import { getAvatarInitials } from 'utils/formatting'

const useStyles = makeStyles((theme) =>
  createStyles({
    toggleCta: {
      position: 'fixed',
      marginTop: '5em',
      width: '100%',
      display: 'flex',
      backgroundColor: '#f3f9fe',
      borderStyle: 'solid',
      borderColor: '#E4E8F0',
      borderWidth: '2px 0px',
      borderRadius: 0,
      zIndex: 1,
      justifyContent: 'space-between',
      '&:hover': {
        background: '#fafbfc',
      },
    },
    toggleLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    toggleCtaText: {
      fontWeight: 400,
      fontSize: '1rem',
      color: '#66677d',
      marginLeft: '1rem',
      textAlign: 'left',
    },
    removeMargin: {
      marginTop: '-6.5em',
    },
  })
)

const MessagesPage: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const { clientID, itemID, status } = useParams<{
    clientID?: string
    itemID?: string
    status?: string
  }>()

  const ehClientIDsWithStatus = useSelector<AppState, ClientIDWithStatus[]>(
    (state: AppState) => clientIDsWithStatus(state.orders),
    isEqual
  )

  const defaultDocumentsTab = useSelector<AppState, string | null>((state) =>
    itemID ? itemNameForItemByOMSItemID(state.items, parseInt(itemID)) : null
  )

  const allOrders = useSelector<AppState, OrdersState>(
    (state: AppState) => state.orders,
    isEqual
  )

  // initializing state here insted of in the container since the Documents contaienr is not rendered after rating on mobile
  // and the modal needs to be shown based on user actions within that container
  const [shouldShowRatingModal, setShouldShowRatingModal] = useState(false)

  const displayRatingModal = useCallback(() => {
    setShouldShowRatingModal(true)
  }, [setShouldShowRatingModal])

  const [showDocumentsView, setShowDocumentsView] = useState(!!itemID)

  const toggleDocumentsView = useCallback(
    () => setShowDocumentsView((current) => !current),
    []
  )

  if (clientID && !parseInt(clientID)) {
    return null
  }

  const clientStatus = status || 'active'
  const clientIDStatusKeys = Object.values(ehClientIDsWithStatus).map(
    (c) => c.key
  )
  const curClientKey = clientID + '-' + clientStatus

  let curClientIDIndex = 0
  let ehClientIDWithStatus = ehClientIDsWithStatus[curClientIDIndex]
  let prevClientIDWithStatus: ClientIDWithStatus | null = null
  let nextClientIDWithStatus: ClientIDWithStatus | null = null

  if (clientID) {
    ehClientIDWithStatus = {
      clientID: parseInt(clientID),
      status: String(status) as ClientStatus,
      key: clientID + '-' + status,
    }
    curClientIDIndex = clientIDStatusKeys.indexOf(curClientKey)
  }

  if (clientIDStatusKeys[curClientIDIndex + 1]) {
    nextClientIDWithStatus = ehClientIDsWithStatus[curClientIDIndex + 1]
  }

  if (curClientIDIndex > 0) {
    prevClientIDWithStatus = ehClientIDsWithStatus[curClientIDIndex - 1]
  }

  const latestOrder = latestOrderByClient(
    allOrders,
    ehClientIDWithStatus?.clientID
  )

  // Get initials for avatar
  const avatarInitials = latestOrder?.experts?.[0]?.display_name
    ? getAvatarInitials(latestOrder?.experts?.[0]?.display_name)
    : ''

  // We deep link to messages by client ID in email notifications to the client
  // so that they can respond to the correct order in the event they have
  // multiple orders. BUT once the order goes to completed, this link is still
  // in their inbox, but we like to hide those messages in a completed view. In
  // order for them to still see their messages and dealing with this
  // downstream, we just want to redirect them to the completed messages view.
  if (
    // the url has a clientID in it...
    clientID &&
    // ...and the status param in the url is not provided or is active...
    clientStatus === 'active' &&
    // ...and the clientID is one of a completed order...
    !!ehClientIDsWithStatus.find(
      (group) =>
        group.clientID === parseInt(clientID) && group.status === 'completed'
    ) &&
    // ...and there are not active orders with this clientID
    !ehClientIDsWithStatus.find(
      (group) =>
        group.clientID === parseInt(clientID) && group.status === 'active'
    )
  ) {
    return (
      <GhostRedirect
        to={generatePath(t('routes.completedMessagesByClient'), {
          clientID,
          status: 'completed',
        })}
      />
    )
  }

  return (
    <PageShell
      minimizedNav={!isMobile}
      hideTitle={isMobile && !itemID}
      noTitle={!isMobile || !!itemID}
      pageTitle={
        isMobile && itemID && defaultDocumentsTab
          ? defaultDocumentsTab
          : t('pages.Documents.pageTitle')
      }
    >
      {/*
          On mobile, we want to have dedicated views for messaging and document
          viewing. We accomplish this through routing. If an itemID is provided
          on mobile, just show the document view. If it isn't, just show
          messaging.

          Update: This itemID logic is changed for view and now the view shown is based itemID/showDocumentsView
          Check commit for this line to see these changes.
      */}
      {isMobile && !itemID ? (
        <Button
          className={classes.toggleCta}
          variant="contained"
          color="primary"
          onClick={toggleDocumentsView}
        >
          <Box className={classes.toggleLeft}>
            <Avatar>{avatarInitials}</Avatar>
            <Typography className={classes.toggleCtaText}>
              {showDocumentsView
                ? t(`pages.Documents.cta.viewMessages`)
                : t(`pages.Documents.cta.viewDocuments`)}
            </Typography>
          </Box>
          <ArrowRightIcon color="primary" fontSize="large" />
        </Button>
      ) : (
        // to remove margin taken by above button
        <Box className={classes.removeMargin} />
      )}
      {(!isMobile || !showDocumentsView) && (
        <FlashErrorBoundary>
          <MessagingContainer
            clientIDIndex={curClientIDIndex}
            clientIDWithStatus={ehClientIDWithStatus}
            totalClientIDs={ehClientIDsWithStatus.length}
            prevClientID={prevClientIDWithStatus}
            nextClientID={nextClientIDWithStatus}
          />
        </FlashErrorBoundary>
      )}
      {(!isMobile || showDocumentsView) && (
        <NoOpErrorBoundary>
          <UserDocumentsContainer
            clientIDWithStatus={ehClientIDWithStatus}
            defaultDocumentsTab={defaultDocumentsTab}
            showRatingModal={displayRatingModal}
          />
        </NoOpErrorBoundary>
      )}
      <RatingModalContainer shouldShowRatingModal={shouldShowRatingModal} />
    </PageShell>
  )
}

export default MessagesPage
