import React, { useState, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { ButtonProps } from '@material-ui/core/Button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useTranslation, Trans } from 'react-i18next'
import { format as dateFormat, addMinutes } from 'date-fns'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import { isIE } from 'react-device-detect'

import AsyncComponent from 'components/shared/AsyncComponent'
import PlainLinkButton from 'components/shared/PlainLinkButton'
import {
  ScheduledItem,
  ItemForScheduler,
  UnauthenticatedItemForScheduler,
} from 'store/scheduling/types'
import zoomImg from 'assets/images/zoom.svg'
import inBrowserImg from 'assets/images/call-page.svg'
import disabledInBrowserImg from 'assets/images/call-page-disabled.svg'
import phoneImg from 'assets/images/phone-consultation.svg'
import { colors } from 'styles'
import { initials } from 'utils/formatting'
import useEvents from 'hooks/useEvents'

// Lazy loading this because the Zoom SDK is big and we don't want it in the
// maim bundle AND it requires some stylesheets that nuke the styles for the
// Portal. The call takes over the full page and refreshes the page when it's
// done, so this is okay on both cases.
const InBrowserCall = React.lazy(() => import('./InBrowserCall'))

interface Props {
  item:
    | ItemForScheduler<ScheduledItem>
    | UnauthenticatedItemForScheduler<ScheduledItem>
}

const tKey = 'components.Call.StartSession'

const StartSession: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  const [inBrowserCall, showInBrowserCall] = useState(false)
  const { t } = useTranslation()
  const phoneNumberHref = `tel:${item.zoom_phone_numbers[0].number},${item.meeting_id}#`
  const sendEvent = useEvents()

  const joinCallEvent = useCallback(
    (method: 'Zoom' | 'Phone' | 'Web') =>
      sendEvent({
        event: `Scheduled Call Join - ${method}`,
      }),
    [sendEvent]
  )

  if (inBrowserCall) {
    return (
      <AsyncComponent>
        <InBrowserCall
          signature={item.zoom_signature}
          meetingNumber={item.meeting_id}
          zoomSdkKey={item.zoom_sdk_key}
          hasVideo={item.has_video}
          password={item.zoom_password}
        />
      </AsyncComponent>
    )
  }

  const sessionOptions = [
    <JoinButton
      iconURL={inBrowserImg}
      onClick={() => {
        joinCallEvent('Web')
        showInBrowserCall(true)
      }}
      label={t(`${tKey}.continueOnThisPage`)}
      subtitle={t(`${tKey}.continueOnThisPageNote`)}
      key={1}
    />,
    <JoinButton
      iconURL={phoneImg}
      href={phoneNumberHref}
      label={t(`${tKey}.phone`)}
      onClick={() => joinCallEvent('Phone')}
      subtitle={
        <Trans
          i18nKey={`${tKey}.phoneNote`}
          /* eslint-disable-next-line jsx-a11y/anchor-has-content */
          components={{ a: <a href={phoneNumberHref} /> }}
          values={{
            ...item.zoom_phone_numbers[0],
            meetingID: item.meeting_id,
          }}
        />
      }
      key={2}
    />,
    <JoinButton
      iconURL={zoomImg}
      href={item.zoom_url}
      target="_blank"
      label={t(`${tKey}.app`)}
      subtitle={t(`${tKey}.appNote`)}
      onClick={() => joinCallEvent('Zoom')}
      key={3}
    />,
  ]

  // Can't join the call in the browser with IE
  if (isIE) {
    sessionOptions.shift()
    sessionOptions.push(
      <JoinButton
        iconURL={disabledInBrowserImg}
        disabled
        label={t(`${tKey}.disabledContinueOnThisPage`)}
        subtitle={t(`${tKey}.disabledContinueOnThisPageNote`)}
        key={4}
      />
    )
  }

  return (
    <Paper variant="outlined" className={classes.root}>
      <Box>
        {item.expert && (
          <>
            <Avatar className={classes.avatar}>
              {initials(item.expert.display_name)}
            </Avatar>
            <Typography className={classes.expertName}>
              {item.expert.display_name}
            </Typography>
          </>
        )}
        <Typography variant="h1" className={classes.itemName}>
          {item.name || t(`${tKey}.defaultItemName`)}
        </Typography>
        <Typography
          component="time"
          dateTime={item.scheduled_start.toISOString()}
          className={classes.sessionTime}
        >
          <CalendarIcon className={classes.sessionTimeIcon} />
          {t(`${tKey}.sessionTime`, {
            startTime: dateFormat(
              item.scheduled_start,
              t(`${tKey}.timeFormat`)
            ),
            endTime: dateFormat(
              addMinutes(item.scheduled_start, 30),
              t(`${tKey}.timeFormat`)
            ),
            date: dateFormat(item.scheduled_start, t(`${tKey}.dateFormat`)),
          })}
        </Typography>
      </Box>
      <Box className={classes.joinOptions}>
        <Typography variant="h3" className={classes.howToJoinTitle}>
          {t(`${tKey}.howToJoin`)}
        </Typography>
        {sessionOptions}
      </Box>
    </Paper>
  )
}

interface JoinButtonProps extends ButtonProps {
  iconURL: string
  label: string
  subtitle: string | React.ReactNode
  target?: '_blank'
  disabled?: boolean
}

const JoinButton: React.FC<JoinButtonProps> = ({
  iconURL,
  label,
  subtitle,
  target,
  disabled,
  ...btnProps
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.joinBtnRoot}>
      <img src={iconURL} alt="" className={classes.joinBtnIcon} />
      <Box>
        <PlainLinkButton
          endIcon={<ChevronRightIcon />}
          color="primary"
          className={classes.joinBtn}
          disabled={disabled}
          // @ts-ignore
          target={target}
          {...btnProps}
        >
          {label}
        </PlainLinkButton>
        <Typography className={classes.joinBtnSubtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 0,
      border: 0,

      '& > *': {
        width: '50%',
        padding: '3em 5em',

        [theme.breakpoints.down('md')]: {
          width: '75%',
          paddingLeft: '5%',
          paddingRight: '5%',
          margin: '0 10%',
        },

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: '2em 0',
          margin: 0,
        },
      },

      '& > *:first-child': {
        borderRight: `1px solid ${colors.gray[600]}`,

        [theme.breakpoints.down('md')]: {
          borderRight: 0,
          borderBottom: `1px solid ${colors.gray[600]}`,
        },
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },

      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    avatar: {
      width: '56px',
      height: '56px',
      fontSize: '1.3em',
    },
    expertName: {
      fontSize: '.9em',
      marginTop: '.8em',
    },
    itemName: {
      fontSize: '1.6em',
      margin: '1em 0',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
        margin: 0,
      },
    },
    sessionTime: {
      fontSize: '.9em',
      fontWeight: 500,
    },
    sessionTimeIcon: {
      fontSize: '1em',
      verticalAlign: 'middle',
      marginRight: '.6em',
    },
    howToJoinTitle: {
      fontSize: '1.2em',
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
    joinOptions: {
      '& > *': {
        marginBottom: '3.375em',

        [theme.breakpoints.down('sm')]: {
          marginBottom: '2em',
        },
      },
    },
    joinBtnRoot: {
      display: 'flex',
      flexDirection: 'row',
    },
    joinBtnIcon: {
      width: '45px',
      marginRight: '2em',
    },
    joinBtn: {
      fontSize: '1.1em',
    },
    joinBtnSubtitle: {
      fontSize: '1.1em',
    },
  })
)

export default StartSession
