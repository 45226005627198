import { AppThunkAction } from 'store'
import { UserIdentifier } from 'store/user/types'
import { APIError } from 'utils/axios'
import { fetchBundledUpsells, FetchBundledUpsellsSuccess } from './actions'
import { Upsell } from './types'

export const fetchBundledUpsellsThunk =
  (
    userIdentifier: UserIdentifier,
    bundle: string
  ): AppThunkAction<Upsell[] | APIError> =>
  async (dispatch): Promise<Upsell[] | APIError> => {
    try {
      const val = (await dispatch(
        fetchBundledUpsells(userIdentifier, bundle)
      )) as unknown as FetchBundledUpsellsSuccess

      return val.payload.data
    } catch (e) {
      return e as APIError
    }
  }
