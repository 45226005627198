import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useLocation from 'react-use/lib/useLocation'

import FeedbackModal from 'components/Modals/variants/Feedback'
import { RatingPayload } from 'store/ratings/types'
import useEvents from 'hooks/useEvents'
import { fetchOrdersForUser } from 'store/orders/actions'
import { submitOrderNPS } from 'store/ratings/actions'

interface Props {
  orderID: number
  onClose: () => void
}

const NPSContainer: React.FC<Props> = ({ orderID, onClose }) => {
  const dispatch = useDispatch()
  const sendEvent = useEvents()
  const { pathname } = useLocation()

  const submitFeedback = useCallback(
    async (data: RatingPayload, closeAfterSubmit = true) => {
      await dispatch(submitOrderNPS(data, orderID))

      sendEvent({
        event: 'User Rated Order From Modal',
        variables: {
          pathname,
          order_id: orderID,
        },
      })

      //fetch orders after submiting nps
      dispatch(fetchOrdersForUser())

      if (closeAfterSubmit) {
        onClose()
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [orderID, onClose, sendEvent, pathname]
  )

  return <FeedbackModal submitCallback={submitFeedback} onClose={onClose} />
}

export default NPSContainer
