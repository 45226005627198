import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useForm, useField } from 'react-final-form-hooks'
import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use/lib/useMeasure'

import { SignUpPayload } from 'store/user/types'
import SignUpWrapper from './Wrapper'
import signUpDocumentImage from 'assets/images/sign-up-document.svg'
import PasswordStrengthInput from 'components/shared/PasswordStrengthInput'
import ValidationRule from 'components/shared/ValidationRule'

interface Props {
  initialEmail: string
  brand: string
  onSubmit: (values: SignUpPayload) => Promise<boolean>
  handleResize?: (width: number, height: number) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    body1: {
      color: theme.palette.primary.dark,
      marginTop: '.44em',
    },
    button: {
      marginTop: '1.78em',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '.5em',

      '& > *': {
        marginTop: '1.5em',
      },
    },
    h1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.9em',
      fontWeight: 600,
    },
    img: {
      width: '100%',
    },
    input: {
      marginTop: '1.1em !important',
    },
    inputLabel: {
      color: theme.palette.secondary.main,
    },
    leftColumn: {
      width: 'calc(48.4%)',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    passwordText: {
      marginTop: '.92em',
    },
    passwordValidation: {
      color: theme.palette.success.main,
      '& > svg': {
        height: '11px',
        verticalAlign: 'middle',
      },
    },
    passwordValidationError: {
      color: theme.palette.error.main,
    },
    rightColumn: {
      textAlign: 'center',
      width: 'calc(42.7%)',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    root: {
      alignItems: 'center',
      borderTop: `.31em solid ${theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 auto',
      maxWidth: '800px',
      padding: '2.8em',
      width: '100%',
    },
  })
)

const SignUpForm: React.FC<Props> = ({
  initialEmail,
  brand,
  onSubmit,
  handleResize,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [ref, { width, height }] = useMeasure()
  const { form, handleSubmit, valid, submitting, submitFailed } =
    useForm<SignUpPayload>({
      // If you return any value here, final-form will register that as
      // a submission error
      onSubmit: async (values, form, callback) => {
        if (!(await onSubmit(values))) {
          return 'ugh an error'
        }
      },
      initialValues: {
        login_method: 'email',
        email: initialEmail,
        password: '',
      },
    })
  const email = useField<string, SignUpPayload>('email', form, (value) => {
    if (!value.includes('@')) {
      return t('components.Auth.SignUpForm.validation.invalidEmail')
    }
  })

  // Report the size of the paper to the caller. This is so that this form is
  // a good citizen in the onboarding flow. If we redo onboarding here, please
  // remove this logic.
  //
  // Yes, that is aspirational.
  useEffect(() => {
    if (handleResize) {
      handleResize(width, height)
    }
  }, [width, height, handleResize])

  let explanation: string = t('components.Auth.SignUpForm.explanation')
  if (brand === 'TopInterview') {
    explanation = t('components.Auth.SignUpForm.explanationTopInterview', {
      brand,
    })
  }

  return (
    <SignUpWrapper ref={ref}>
      <Box className={classes.leftColumn}>
        <Typography className={classes.h1} variant="h1">
          {t('components.Auth.SignUpForm.title')}
        </Typography>
        <Typography className={classes.body1}>
          {t('components.Auth.SignUpForm.subtitle', { brand })}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl disabled={!!initialEmail}>
            <InputLabel className={classes.inputLabel} htmlFor="email">
              {t('components.Auth.SignUpForm.emailAddressLabel')}
            </InputLabel>
            <Input
              className={classes.input}
              {...email.input}
              type="email"
              id="email"
            />
            {email.meta.invalid && email.meta.visited && (
              <FormHelperText component="div">
                <ValidationRule invalid={!!email.meta.error}>
                  {email.meta.error}
                </ValidationRule>
              </FormHelperText>
            )}
          </FormControl>
          <PasswordStrengthInput
            form={form}
            fieldName="password"
            email={initialEmail}
            TextFieldProps={{
              label: t('components.Auth.SignUpForm.passwordLabel'),
              InputLabelProps: {
                className: classes.inputLabel,
              },
            }}
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
            disabled={(!valid && !submitFailed) || submitting}
          >
            {t('components.Auth.SignUpForm.continueBtn')}
          </Button>
          {submitFailed && (
            <ValidationRule invalid={true}>
              {t('components.Auth.SignUpForm.submissionFailure')}
            </ValidationRule>
          )}
        </form>
      </Box>
      <Box className={classes.rightColumn}>
        <img
          alt="decorative illustration of a document"
          className={classes.img}
          src={signUpDocumentImage}
        />
        <Typography
          className={classes.passwordText}
          color="secondary"
          variant="body1"
        >
          {explanation}
        </Typography>
      </Box>
    </SignUpWrapper>
  )
}

export default SignUpForm
