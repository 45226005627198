import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'

export default withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.divider,
    height: '1px',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))(Divider)
