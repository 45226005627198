import React from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import StepItem from './StepItem'
import Grid from '@material-ui/core/Grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Box from '@material-ui/core/Box'

import {
  ItemTypeWithHistory,
  ProgressStatus,
  ProgressStepProps,
} from 'store/items/types'

interface StepIconProps {
  status: ProgressStatus
}
const ProgressStepIcon: React.FC<StepIconProps> = ({ status }) => {
  const classes = useStyles()
  switch (status) {
    case ProgressStatus.Full:
      return <CheckCircleIcon className={clsx(classes.icon)} />
    case ProgressStatus.Partial:
      return (
        <Box className={classes.dotsContainer}>
          <MoreHorizIcon className={classes.dots} />
        </Box>
      )
    default:
      return (
        <RadioButtonUncheckedIcon
          className={clsx(classes.unchecked, classes.icon)}
        />
      )
  }
}

const ProgressStep: React.FC<ProgressStepProps> = ({
  step,
  items,
  hideDivider,
}) => {
  const classes = useStyles()
  const getProgressStatus = (
    itemsWithHist: ItemTypeWithHistory[]
  ): ProgressStatus => {
    if (itemsWithHist.filter((i) => !i.lastPurchased).length === 0)
      return ProgressStatus.Full
    if (itemsWithHist.filter((i) => !!i.lastPurchased).length === 0)
      return ProgressStatus.Incomplete
    return ProgressStatus.Partial
  }

  const status = getProgressStatus(items)

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid item xs={4}>
          <Box className={classes.stepContainer}>
            <ProgressStepIcon status={status} />
            <Typography
              variant="overline"
              className={clsx(
                classes.stepText,
                status === ProgressStatus.Incomplete ? classes.stepTextBold : ''
              )}
            >
              {step}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box className={clsx(classes.stepContainer, classes.itemsContainer)}>
            {items.map((item) => (
              <StepItem item={item} key={item.itemType} />
            ))}
          </Box>
        </Grid>
      </Grid>
      {!hideDivider && <Divider />}
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    stepContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    itemsContainer: {
      flexWrap: 'wrap',
    },
    icon: { width: '1.7rem', height: '1.7rem', marginRight: '0.5rem' },
    unchecked: {
      color: colors.blue[700],
      opacity: 0.4,
    },
    dotsContainer: {
      backgroundColor: colors.blue[700],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '2rem',
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '0.5rem',
    },
    dots: {
      color: colors.white[100],
    },
    stepText: {
      fontFamily: 'Proxima Nova',
      fontWeight: 500,
    },
    stepTextBold: {
      color: colors.gray.p100,
      opacity: 0.4,
    },
  })
)

export default ProgressStep
