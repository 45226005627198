import React from 'react'
import { Article } from 'store/content/types'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import GhostLink, { GhostLinkProps } from 'components/shared/GhostLink'

interface Props {
  article: Article
  image: string
  index: number
  GhostLinkProps?: Partial<GhostLinkProps>
}

const useStyles = makeStyles((theme) =>
  createStyles({
    h3: {
      fontWeight: 400,
    },
    img: {
      height: '68.2%',
      objectFit: 'contain',
      transition: '.25s transform ease-out',
    },
    listItem: {
      alignItems: 'flex-start',
      borderTop: `1px solid ${colors.gray[600]}`,
      color: theme.palette.primary.dark,
      padding: '2em',

      '&:hover': {
        '& img': {
          transform: 'scale(1.1)',
        },
      },
    },
    listItemIcon: {
      alignItems: 'center',
      backgroundColor: colors.blue[100],
      borderRadius: '.5em',
      display: 'flex',
      height: '5.5em',
      justifyContent: 'center',
      maxWidth: '40%',
      width: '8.8em',
    },
    listItemText: {
      marginLeft: '1.5em',
    },
    subtitle2: {
      fontWeight: 400,
    },
  })
)

const WidgetItem: React.FC<Props> = ({
  article,
  image,
  index,
  GhostLinkProps,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ListItem
      className={classes.listItem}
      component={(props) => <GhostLink exact {...GhostLinkProps} {...props} />}
      to={t(`routes.article${index + 1}`)}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <img className={classes.img} alt={article.imgAlt} src={image} />
      </ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        primary={<Typography variant="subtitle2">{article.tag}</Typography>}
        secondary={<Typography variant="h3">{article.title}</Typography>}
      />
    </ListItem>
  )
}

export default WidgetItem
