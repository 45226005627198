import React from 'react'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'

import makeStyles from '@material-ui/core/styles/makeStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import { colors } from 'styles'

import EmailIcon from '@material-ui/icons/Email'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import DescriptionIcon from '@material-ui/icons/Description'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import ModalWrapper, { ModalProps } from '../Wrapper'

import { Expert } from 'store/orders/types'
import { Item } from 'store/items/types'

import bgStars from 'assets/images/bg-stars.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    caption: {
      marginRight: '1rem',
    },
    contentContainer: {
      marginBottom: '0rem',
      paddingBottom: '0rem',
    },
    dialog: {
      backgroundImage: `url(${bgStars})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      maxWidth: '45.5em',
      paddingTop: '1.5em',
    },
    dialogLinkedIn: {
      backgroundImage: `url(${bgStars})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      maxWidth: '45.5em',
      paddingTop: '1.5em',
      borderTop: `solid ${colors.blue.a400} 0.75em`,
    },
    deliveryHeader: {
      fontWeight: 'bold',
      fontSize: '.8rem',
    },
    headerContainer: {
      marginBottom: '1rem',
    },
    icon: {
      margin: 'auto',
      width: '65%',
    },
    listContainer: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    listItem: {
      alignItems: 'center',
      paddingLeft: '0px',
    },
    listItemIcon: {
      backgroundColor: colors.gray.a100,
      borderRadius: '100%',
      color: colors.blue.a300,
      height: '1.5em',
      marginRight: '0.5rem',
      marginTop: '0',
      minWidth: '0rem',
      width: '1.5em',
    },
    listItemText: {
      fontSize: '.9rem',
    },
    paper: {
      backgroundColor: 'orange',
    },
    reminderHeader: {
      fontWeight: 'bold',
      fontSize: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    headerIcon: {
      width: '5em',
    },
    headerContainerLinkedIn: {
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  })
)

interface FirstDraftProps extends ModalProps {
  expert: Expert | null
  items: Item[]
  deliveryDate: Date | null | undefined
  attachedItemTyes?: string[]
}

const FirstDraftModal: React.FC<FirstDraftProps> = ({
  callToActionProps,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.FirstDraft.cta'),
        ...callToActionProps,
      }}
      {...props}
      dialogClass={classes.dialog}
      showButton={false}
      wideDialog={true}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        className={classes.contentContainer}
      >
        <Grid container item xs={12} className={classes.headerContainer}>
          <Typography variant="h2">
            {t('components.Modals.variants.FirstDraft.title', {
              expertName:
                props.expert &&
                props.expert.display_name &&
                props.expert.display_name !== ''
                  ? props.expert.display_name
                  : t('components.Modals.variants.FirstDraft.copy.yourWriter'),
            })}
          </Typography>
        </Grid>
        <Grid container item xs={12} className={classes.headerContainer}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
          >
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              sm={4}
            >
              <Grid item>
                <Typography variant="caption" align="left">
                  {t(`components.Modals.variants.FirstDraft.copy.deliveredOn`)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  align="left"
                  className={classes.deliveryHeader}
                >
                  {props.deliveryDate &&
                    dateFormat(
                      props.deliveryDate,
                      t(`components.Modals.variants.FirstDraft.dateFormat`)
                    )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              sm={4}
            >
              <Grid item>
                <Typography variant="caption" align="left">
                  {props.items &&
                    t(
                      `components.Modals.variants.FirstDraft.copy.documentType`,
                      {
                        count: props.attachedItemTyes
                          ? props.attachedItemTyes.length
                          : props.items.length,
                      }
                    )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  align="left"
                  className={classes.deliveryHeader}
                >
                  {props.attachedItemTyes &&
                    props.attachedItemTyes
                      .map((docType) => t(`itemTypes.${docType}`))
                      .join(' | ')}
                  {props.items &&
                    !props.attachedItemTyes &&
                    props.items.map((item) => item.name).join(' | ')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid container item xs={12}>
          <Typography
            variant="body2"
            align="left"
            className={classes.reminderHeader}
          >
            {t(`components.Modals.variants.FirstDraft.copy.reminderHeader`)}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="body2" align="left">
            {t(`components.Modals.variants.FirstDraft.copy.processDescription`)}
          </Typography>
        </Grid>
        <Grid container item xs={12} className={classes.listContainer}>
          <List>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <EmailIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(
                  `components.Modals.variants.FirstDraft.copy.reminders.email`
                )}
              />
            </ListItem>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <WatchLaterIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(
                  `components.Modals.variants.FirstDraft.copy.reminders.speed`
                )}
              />
            </ListItem>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <DescriptionIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(
                  `components.Modals.variants.FirstDraft.copy.reminders.contents`
                )}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="body2" align="left">
            {t(`components.Modals.variants.FirstDraft.copy.support`)}
          </Typography>
        </Grid>
      </Grid>
    </ModalWrapper>
  )
}

export default FirstDraftModal
