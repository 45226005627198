import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'

export const InfoItemWrapper = withStyles(() => ({
  root: {
    alignItems: 'flex-start',
    padding: '2em 0',

    '&:last-of-type': {
      borderBottom: '0',
    },
  },
}))((props) => <ListItem disableGutters divider {...props} />)
