import React from 'react'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

interface Props {
  invalid: boolean
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    passwordValidation: {
      color: theme.palette.success.main,
      fontSize: '1em',
      fontWeight: 500,
      '& > svg': {
        height: '11px',
        verticalAlign: 'middle',
      },
    },
    passwordValidationError: {
      color: theme.palette.error.main,
    },
  })
)

const ValidationRule: React.FC<Props> = ({
  invalid,
  children,
  className = '',
}) => {
  const classes = useStyles()

  return (
    <Typography
      className={clsx([
        classes.passwordValidation,
        invalid && classes.passwordValidationError,
        className,
      ])}
    >
      {invalid ? <CloseIcon /> : <CheckIcon />}
      {children}
    </Typography>
  )
}

export default ValidationRule
