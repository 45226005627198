import React from 'react'
import MobileStepper, {
  MobileStepperProps,
} from '@material-ui/core/MobileStepper'
import withStyles, {
  CreateCSSProperties,
} from '@material-ui/core/styles/withStyles'

import { colors } from 'styles'

type ProgressDotsProps = Pick<MobileStepperProps, 'steps' | 'activeStep'>

const ProgressDots = withStyles((theme) => ({
  root: {
    paddingTop: '7px',
  },
  dot: {
    width: '13px',
    height: '13px',
    backgroundColor: colors.blue[500],
    // WTF is going on here? Glad you asked. Material-UI's MobileStepper does
    // not put a class on completed or future steps, just on the active step.
    // This is because their concept of progress is to highlight the current
    // step you are on. Unfortunately, we want to show all previous steps as
    // blue and the future steps as gray. In order to not build this from
    // scratch, I decided to iteratively generate CSS selectors based upon
    // :nth-child and assign them colors based upon the props passed to the
    // component. I'm figuring that we won't have more than 100 steps, but if
    // that changes, just bump that number up.
    ...Array.from({ length: 100 })
      .map((val, i) => i + 1)
      .reduce((acc, i) => {
        // Each element in this reduce is a :nth-child selector with
        // a backgroundColor property who's value is a function that returns the
        // correct color based upon the props.
        acc[`&:nth-child(${i})`] = {
          backgroundColor: (props: ProgressDotsProps) => {
            if (!props || !props.activeStep) {
              return colors.blue[500]
            }

            return i <= props.activeStep
              ? colors.blue[500]
              : theme.palette.divider
          },
        }

        return acc
      }, {} as CreateCSSProperties<ProgressDotsProps>),
  },
  dotActive: {
    backgroundColor: colors.blue[500],
  },
}))((props: ProgressDotsProps) => (
  <MobileStepper
    backButton={null}
    nextButton={null}
    variant="dots"
    position="static"
    {...props}
  />
))

export default ProgressDots
