import { ItemTypes } from 'store/items/types'
import { NotificationTypes } from 'store/notifications/types'

export default {
  translation: {
    components: {
      Documents: {
        userDefaults: {
          area: 'XX, Your City',
          cityOrState: 'Your City',
        },
        interviewPrep: {
          dateFormat: "EEE, d MMMM 'at' h:mm a",
        },
      },
      Faqs: {
        careerJourney: [
          {
            question:
              'I would like to add another item to my order. Will it affect my order due date?',
            answer:
              'When you add an item to your order,  the due date for your original order will remain unchanged. Any additional items purchased will have new due dates. You will receive your original order first, followed by any additional items you may have purchased.',
          },
          {
            question: 'What is TopInterview?',
            answer:
              'TopInterview is a coaching service designed to help you prepare for job interviews. The core of the interview coaching experience is 1-on-1 sessions with an interview coach. These sessions are conducted over video conference and are tailored to your needs as a jobseeker, with your industry and stage in the job search in mind.',
          },
        ],
        careerJourneyTitle: 'Your Career Journey',
        gettingToKnow: [
          {
            question: 'Why did I have to submit a questionnaire?',
            answer:
              'The questionnaire is a vital part of the writing process. The answers help our experts learn about you, your skills, and your experience outside of what is included in your current CV or in other documents provided',
          },
          {
            question: 'Why did I have to create a {{brand}} account?',
            answer:
              'Creating an account is the easiest way to communicate with your writer and see the documents your writer is working on for you. Creating an account is a quick and secure process, and all of your information is protected.',
          },
          {
            question:
              'Can I speak to my writer instead of answering the questionnaire?',
            answer:
              'The questionnaire is what allows us to match you with the best writer for you. Once you have been matched with a writer, you can communicate with your writer in the messages section of your account.',
          },
          {
            question: 'Can I receive my documents faster?',
            answer:
              'With the 48-hour rush option, you’ll get the first draft of your CV within 48 hours! The sooner you receive your first draft the sooner you can collaborate with your writer and maximise the revision period.',
          },
        ],
        gettingToKnowTitle: 'Getting to Know You',
        professionalDocuments: [
          {
            question: 'When will I get my CV?',
            answer:
              'You can expect to receive your professional documents from us on {{dueDate}}. Check your order messages to ensure your writer has everything they need to craft you winning documents.',
          },
          {
            question: 'When will I get my LinkedIn rewrite?',
            answer:
              'If you have ordered a CV, your writer will work on your CV first to ensure you are happy with how your skills and experience are presented. You should expect to receive your Linkedin profile once you and your writer are satisfied with your CV.',
          },
          {
            question: 'How do I download my documents?',
            answer:
              'On the ‘Portfolio’ page you will see a button on each of your documents that says “Download”. If you click this, you can download your document in MS Word or PDF formats.',
          },
          {
            question: 'How do I upload my documents?',
            answer:
              "Uploading documents and attachments couldn't be easier. If you go to your “Messages”, page there is a small paperclip icon on the bottom left of your screen. If you click this, you will be asked to upload a document you can then send to your expert.",
          },
          {
            question: 'How do I approve my order?',
            answer:
              'There are a few places you can “approve” your order. <br>On the “Portfolio” page under you listed documents on the right side of the screen you will see the “Approve” button. The second place you can “Approve” your order is when you are viewing your documents on the messages page. You will see the “Approve” button located to the top right of your screen.',
          },
        ],
        professionalDocumentsTitle: 'Your Professional Documents',
        troubleshooting: [
          {
            question: 'Why has my writer stopped responding to me?',
            answer:
              'If you haven’t heard from your writer, in most cases this means they are hard at work for you: researching your industry for the latest trends, analysing your questionnaire, and applying change requests to your documents. If you have not heard from your writer in more than 24 hours, please reach out to our support team.',
          },
          {
            question: 'How do I cancel my order?',
            answer:
              'If you would like to cancel your order, please email <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.',
          },
          {
            question: 'How do I request a refund?',
            answer:
              'If you would like to request a refund, please email <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.',
          },
          {
            question: 'Where can I find my order email address?',
            answer:
              'You can communicate with your expert through your messages; we recommend this as it is the most secure and quickest method. But if you would like to use your email to communicate.',
          },
          {
            question: 'How will my writer contact me?',
            answer:
              'You will communicate with your writer by using your {{brand}} account and through the built-in secure messaging feature.',
          },
        ],
        troubleshootingTitle: 'Troubleshooting',
        workingWithWriter: [
          {
            question: "What are my writer's credentials?",
            answer:
              'We have a network of over 1,200 writers, of certified career coaches, former and current hiring managers, HR professionals, recruiters, and individuals with specialised experience in over 65 industries. This ensures we can match you with an expert who is a strong match for your needs and knows the ins and outs of your industry',
          },
          {
            question: 'How many revisions can I make?',
            answer:
              'After you receive your first draft, you have seven days for revisions. Working with your writer is a collaborative effort, and we encourage you to deliver feedback and suggestions to your writer as soon as you can. We recommend consolidating your feedback and sending it to your writer all in one message.<br>We recommend providing your revisions in as few rounds as possible to ensure that your writer is able to consider the impact of your requests on the strategy as a whole. With this approach, we find that few clients need more than 2 rounds of revision.',
          },
          {
            question: 'I am not happy with my first draft. What should I do?',
            answer:
              'While our writers aim to get you a first draft that you can use immediately, they don’t always hit a bulls-eye on the first shot. That is why you have a seven-day revision window to collaborate with your writer to get the best document possible.',
          },
        ],
        workingWithWriterTitle: 'Working With Your Writer',
      },
      LiteFooter: {
        copy: `Our professional CV-writing service has helped over 1 million professionals.<br />
          View our packages and make a purchase to create an account.`,
        critique:
          'Looking for your CV critique? Find it through the original link in your email.',
      },
      Messaging: {
        messageDateFormat: "d/L/y '@' h:mm a",
      },
      Modals: {
        variants: {
          Feedback: {
            nps: {
              optOut: 'I don’t normally recommend CV services to friends',
            },
          },
          FirstDraft: {
            dateFormat: "EEEE, d MMMM 'at' h:mm a",
          },
        },
      },
      Notifications: {
        [NotificationTypes.OrderItemComped]: {
          body: 'Thank you for being so understanding.  We’ve added a gift to your order, and apologise for any inconvenience.',
        },
      },
      OrderWidget: {
        dueDateFormat: 'd MMM',
        closedDateFormat: 'd MMM yyy',
      },
      OrderWidgetItemDetails: {
        items: {
          [ItemTypes.Resume]: {
            name: 'CV',
            description:
              'An expertly written and keyword-optimised CV that sets you apart.',
          },
          [ItemTypes.CurriculumVitae]: {
            description:
              'An expertly written and keyword-optimised academic CV is the key to applying to educational jobs.',
          },
          [ItemTypes.Guarantee]: {
            description:
              'If you are not receiving two times more job interviews within 60 days of receiving your CV, we will write it again.',
          },
          [ItemTypes.RecruiterService]: {
            name: 'Recruiter.com',
            description:
              'Post your CV to our top recruiters. They keep your CV on file and follow up whenever they have an opening that’s a good fit for you.',
          },
        },
      },
      Portfolio: {
        ItemCard: {
          dateTextFormat: 'd/M/yy',
        },
      },
      Rating: {
        HomeWidget: {
          titleDateFormat: 'd MMM yyyy',
        },
      },
      TimelineWidget: {
        Step: {
          dateFormat: 'd MMM',
        },
        DeliveryPopper: {
          expectedDeliveryDateFormat: 'd MMM yyyy',
        },
      },
      Upsells: {
        shortPitch: {
          [ItemTypes.RushOrder]: 'Get your CV faster.',
          [ItemTypes.RushOrder24Hours]: 'Get your CV within 24 hours.',
          [ItemTypes.RushOrder48Hours]: 'Get your CV within 48 hours.',
          [ItemTypes.RushOrder72Hours]: 'Get your CV within 72 hours.',
          [ItemTypes.PhoneCall]:
            "Speak with your writer on the phone about things you'd like to see in your CV.",
          [ItemTypes.PostingService]:
            'Express CV-posting features allow you to post to 50 job sites at once!',
          [ItemTypes.RecruiterService]: 'Post your CV to our top recruiters!',
        },
      },
      VoiceDrivenItemDetails: {
        headline: {
          dateFormat: 'E, d MMM',
        },
      },
    },
    pages: {
      Article1: {
        default: {
          date: 'Updated 8 Oct 2020',
          text: `<section>
            <p>
              Your CV is an important tool in your job search, and working with a professional CV writer will make you three times as likely to land the job. In fact, it's been proven that a CV written by an expert makes you 32% more likely to land a job and earn a higher salary. When you get professionally written CV, recruiters will feel more confident presenting you to a hiring manager or client. Your CV will present your professional journey in one compelling career narrative that highlights your value.
            </p>
            <p>
              We’re excited you’ve decided to partner with us for a shorter, better job search. But what is it actually like to work with a professional CV writer? Here, we've outlined the steps of the {{brand}} process so you know what to expect.
            </p>
          </section>
          <section>
            <h2>Getting to know you</h2>
            <p>
              To write your CV, we need to get to know you. We want to know your story —your career history, accomplishments, the ideal outcomes for your job search— so we can best serve you and your goals.
            </p>
            <p>
              Our questionnaire is one tool we use to learn about you, and we appreciate you filling it out with lots of detail. If there’s any additional information you’d like your writer to know, you can send your writer a message using the {{brand}} dashboard. The more we know about how you hope to use your professional documents, the better we can set you up for success.
            </p>
          </section>
          <section>
            <h2>Meet your match</h2>
            <p>
              Our network features thousands of professional writers across a variety of industries and locations. Your questionnaire will give us insight into your industry, goals, and needs, and we'll use that information to match you with the expert best poised to make your career story shine.
            </p>
            <p>
              Once we’ve matched you with a writer, they will message you through the {{brand}} dashboard to introduce themselves. If you have anything to share, you can send a message to your writer at any time.
            </p>
          </section>
          <section>
            <h2>Sit tight</h2>
            <p>
              After you’ve been matched with your writer, they will work their magic, taking a deep dive into your background to create the most successful documents possible. Expect to receive a message in the dashboard that contains your first draft within seven business days of the submission of your questionnaire. If you need your documents faster, click here to learn more about rush orders.
            </p>
            <p>
              In the meantime, you can <a href="/setting-the-stage-for-a-successful-search">check out these tips</a> for what to do while waiting to set you up for a more successful job search.
            </p>
          </section>
          <UpsellBanner />
          <section>
            <h2>Provide feedback</h2>
            <p>
              Because your voice matters, you will have the opportunity to provide feedback for your writer. Through our dashboard, you have a week to work together on your new CV. We recommend providing your revisions in as few rounds as possible to ensure that your writer is able to consider the impact of your requests on your job search strategy as a whole.
            </p>
            <p>
              With this approach, we are able to deliver a CV that will empower you to accomplish your job search goals efficiently. This way, you can quickly get started with the most important thing of all — your job search.
            </p>
          </section>
          <section>
            <h2>Add some special features</h2>
            <p>
              Throughout the process, you'll be given the option to upgrade your order and further boost your job search. You can add a phone consultation with your writer or thank you letter for when you follow up after an interview, plus extra bonuses like online distribution for your CV. We want to make sure that you have all the tools you need to be successful in your search.
            </p>
          </section>
          <section>
            <h2>Seal the deal</h2>
            <p>
              After collaborating with your writer, the final draft awaits. Download your polished, professional document from the dashboard and get ready for a renewed job search. Your CV is always available for you to access on the {{brand}} dashboard at any time.
            </p>
          </section>
          <section>
            <h2>Land the job</h2>
            <p>
              Armed with a shiny new CV, you're ready to tackle the job search. We wish you the best of luck as you submit your professionally written CV — if you're anything like <a href="//www.{{brand}}.com/success-stories" rel="noopener noreferrer" target="_blank">our past customers</a>, you'll be signing a contract in no time.
            </p>
          </section>`,
          title: 'What to expect when you work with {{brand}}',
        },
      },
      Article2: {
        default: {
          date: 'Updated 8 Oct 2020',
          quote1: {
            quote:
              'Great job done by Thomas. Took my old CV and made it POP. When I sent out my new CV to my recruiters they actually replied back with "Nice looking CV". Or who did this CV for you? I want to use them also.',
            title: 'Working with {{brand}}, you’ll get compliments on your CV',
          },
          quote2: {
            quote:
              'I really appreciate the level of detail Tracy put forth. You could tell she did some research with regards to my employers, took my concerns/suggestions into consideration and focused on what I want to direct my career towards. The end result was stellar. At first I thought, who is this person (me). Then I realised these are all things I’ve done over my career and have been selling myself short. I wish I would have made this investment sooner, I truly feel I would have been further in my career if I had. Thank you',
          },
          quote3: {
            quote:
              'My CV writer, Ryan, was incredibly helpful, responsive, professional and empathetic. He literally transformed my CV from what I felt was an ugly duckling into a beautiful swan. I feel 100% more confident in my job search as a result of Ryan’s help. Thank you, {{brand}}!',
          },
          quote4: {
            quote:
              'I worked with Michelle, and she did an excellent job on my Cover Letter, CV, thank you letter and LinkedIn account! We finished ahead of schedule and I now have an interview in less than a week after it was completed!',
          },
          text: `<section>
            <p>
              When it comes to {{brand}} writing, we at {{brand}} know what we're doing — and that's all thanks to our writers.
            </p>
            <p>
              At {{brand}} we have a worldwide network of career experts that come from a variety of backgrounds. The diverse experiences of our writers allows us to match you with a writer that will know the ins and outs of your profession. Many of our writers come from professional backgrounds just like yours, who now apply that experience to helping others.
            </p>
            <p>
              Our expert CV writers not only edit and rewrite your CV, but they also help you take hold of your career narrative. It's not just about adding a comma, spicing it up with a few keywords, or moving around certain parts of your CV; it's about creating a cohesive career story that will help you land your dream job faster.
            </p>
          </section>
          <section>
          </section>
          <section>
            <h2>Our five-star writers can transform your CV</h2>
            <p>
              You don’t have to just take our word for it, however — what do our past customers have to say? Below, find some first-hand accounts of what it’s like to work with our writers.
            </p>
            <Quotes />
          </section>
          <section>
            <h2>We can’t wait to work with you</h2>
            <p>
              We’re so excited for you to get started and work with our writers, too. We’re confident that you’ll have a great experience, just as our past customers have.
            </p>
            <p>
              Curious about what comes next? Check out <a href="/collaborating-with-your-writer">Collaborating With Your Writer</a> to learn more about working together with your writer, and what you can do to ensure an outstanding final CV.
            </p>
          </section>`,
        },
      },
      Article3: {
        default: {
          date: 'Updated 8 Oct 2020',
          text: `<section>
            <h2>Your new CV is on the way</h2>
            <p>
              We’re thrilled to have you join the 500,000 other job seekers we’ve helped find their next step. Thanks to our long history of helping job seekers, we’ve learned a thing or two about how to be successful in your job search.
            </p>
            <p>
              You can expect a world-class CV at the end of the {{brand}} process. Our writers are fantastic — and we make them even better, teaching them all of the tips, tricks, and secrets we’ve learned along the way.
            </p>
            <p>
              We promote tested tactics that get results. No detail is too small when we are working on your CV. The fonts, character spacing, and layouts have all been tested extensively. They are designed to be irresistible to recruiters and hiring managers while also passing through any automated <a href="//www.topcv.com/career-advice/write-applicant-tracking-system-cv" rel="noopener noreferrer" target="_blank">applicant tracking system</a> that you encounter during your job search.
            </p>
            <p>
              Sometimes our known best practices go against what you might
              expect. For example, <a href="//www.topcv.com/career-advice/explained-shortened-cv" rel="noopener noreferrer" target="_blank">the ideal CV length is two pages</a>— not one, not three. To that end, we might remove content from your existing CV or work to fill in the blanks to help achieve the ideal for you.
            </p>
            <p>
              We’re so excited to be your partner on your career journey, and want to make sure you’re set up for success. While your writer is working, there are steps you can take to ensure that you’re ready to hit the ground running when your final draft is delivered.
            </p>
          </section>
          <section>
            <h2>What to do while you wait</h2>
            <p>
              When you're waiting to receive the first draft of your professionally written CV, it can feel like an eternity — especially when you're itching to land your next job.
            </p>
            <p>
              However, don't consider this time to be a waste. While a well-crafted CV and cover letter are key to a successful search, they're only one piece of the job-search jigsaw puzzle.
            </p>
            <p>
              To land the right job as soon as possible, there are a lot of things you'll need to do before you can start applying for jobs. Use this valuable time to complete the action items below so you can hit the ground running as soon as you've approved the final draft of your CV.
            </p>
            <ul>
              <li>
                <a href="#section1">Write down your goals</a>
              </li>
              <li>
                <a href="#section2">Create a target company list</a>
              </li>
              <li>
                <a href="#section3">Get organised</a>
              </li>
              <li>
                <a href="#section4">Audit your online presence</a>
              </li>
              <li>
                <a href="#section5">Earmark jobs that interest you</a>
              </li>
              <li>
                <a href="#section6">Prep for job applications</a>
              </li>
              <li>
                <a href="#section7">Prioritise networking</a>
              </li>
              <li>
                <a href="#section8">Line up references</a>
              </li>
              <li>
                <a href="#section9">Practice interviewing</a>
              </li>
              <li>
                <a href="#section10">Take some time for yourself</a>
              </li>
            </ul>
          </section>
          <section id="section1">
            <h2>Write down your goals</h2>
            <p>
              Be clear on the <a href="//www.topcv.com/career-advice/how-to-find-your-dream-job" rel="noopener noreferrer" target="_blank">type of job you're seeking</a> and write it down. You are 10 times more likely to accomplish your goal when you do this. Not only that, but this information will influence many other aspects of your search, from the types of employers you target to how you prioritise your networking activities. Consider all aspects of the job, from the work environment and industry to your commute and desired salary. The better defined your goals, the more efficient your job search will be.
            </p>
          </section>
          <section id="section2">
            <h2>Create a target company list</h2>
            <p>
              Landing a new job often requires you to pursue multiple job opportunities at the same time. To streamline the process, it's helpful to build a list of companies that meet your location, industry, size, and corporate-culture needs. Use the information you brainstormed above to create your target list of employers.
            </p>
          </section>
          <UpsellBanner />
          <section id="section3">
            <h2>Get organised</h2>
            <p>
              Take a little time to figure out the best way for you to keep your
              job-search activities organised:
            </p>
            <ul>
              <li>
                <strong>Communication</strong>: Consider creating a professional-looking, new email address with Gmail that's reserved for your job-search activities. Also, if you decide to upload a picture to your email account, make sure it's appropriate for your job search. Revisit the voicemail message on your cell phone, as well, to confirm that it includes your name so employers know they've called the right person.
              </li>
              <li>
                <strong>Job applications</strong>: Whether you're interested in a web-based solution like <a href="//huntr.co/" rel="noopener noreferrer" target="_blank">Huntr</a> or <a href="//gojobhero.com/" rel="noopener noreferrer" target="_blank">JobHero</a>, or you prefer to go old school with an Excel sheet or Word document, it's important to find a way to track your job applications and other job-search activities. Keep track of the company name, job title and location, job listing URL, where you found the job, the point of contact (if applicable), the date you applied, and the name of the CV file you used for the application. As you start applying for jobs, be sure to document the date you followed up on your application and if you secured an interview.
              </li>
              <li>
                <strong>Job boards</strong>: Run some Google searches to uncover niche job boards in your field or industry (e.g. marketing job boards). Make a list of all the job boards you'd like to use for your job search and start creating your profile on each so all you have left to do is upload your new CV.
              </li>
            </ul>
            <p>
              In addition, <a href="//www.topcv.com/career-advice/best-job-search-sites" rel="noopener noreferrer"target="_blank">upload apps to your smart device</a> that will help you stay organised and search on the go.
            </p>
          </section>
          <section id="section4">
            <h2>Audit your online presence</h2>
            <p>
              Over 90 percent of recruiters admit to looking at candidates' online profiles — regardless of whether the candidates provided this information. If you're not actively managing your online presence, you may be hurting your chances of landing the job. <a href="//www.topcv.com/career-advice/maintain-online-brand">Google your name</a> to get a sense of what employers will find when they search for you and set up Google Alerts for your name to actively monitor it going forward.
            </p>
            <p>
              Then, deactivate or update any profiles you have on job boards, social media platforms, online directories, and so forth to ensure employers will find the same professional online that they will meet face-to-face. Finally, increase the security settings on personal accounts you don't want employers to find.
            </p>
          </section>
          <section id="section5">
            <h2>Earmark jobs that interest you</h2>
            <p>
              Start searching for job opportunities online that you're
              interested in and qualified to do.
                <span>
                  Before you add a job to your list, <a href="//www.topcv.com/career-advice/guide-to-informational-interviews" rel="noopener noreferrer" target="_blank">ask yourself these questions</a> to make sure the job application will be worthwhile.
                </span>
            </p>
            <p>
              In addition to adding the job to whatever system you've chosen to organise your applications, be sure to save a copy of the job description in a Word or Google document so you have it for future reference.
            </p>
            <p>
              Oftentimes, a company will take down its job posting once it's received enough applicants, so you may not have access to this information when it's time to prepare for the interview.
            </p>
          </section>
          <section id="section6">
            <h2>Prep for your job applications</h2>
            <p>
              CV – and cover letters – work best when they are tailored to specific job openings or employers. While your professionally written CV will be in great shape, you'll still need to make minor tweaks to the keywords to customise it for a specific job listing.
            </p>
            <p>
              These small optimisations will increase your application's chances of getting past the hiring bots and on to someone in HR for review.
            </p>
            <p>
              Jot down the keywords that are used repeatedly in the job description, and make a note of any skills or qualifications that seem very important to the employer. That way, you can be sure to highlight this information in your cover letter and CV when the time comes.
            </p>
            <p>
              Also, if there isn't a name associated with the job listing, do a little online sleuthing to uncover the name of the hiring manager or recruiter responsible for the role. That way, you can properly address your cover letter.
            </p>
          </section>
          <section id="section7">
            <h2>Make networking a priority</h2>
            <p>
              There's no denying the power a strong professional network can have over your career success — especially when you're looking for work. In fact, you're 10 times more likely to land a job when your application is accompanied by a referral. Use this time to ramp up your networking efforts
            </p>
            <p>
              Take a second look at your existing contacts and prioritise them based on their connection to the companies, fields, and industries that interest you. Start reconnecting with these people and setting up coffee dates and phone calls to <a href="//www.topcv.com/career-advice/references-for-job-search" rel="noopener noreferrer" target="_blank">ask for help with your job search</a>.
            </p>
            <p>Also, explore new professional associations to join or events to attend that sound interesting and are relevant to your goals.</p>
          </section>
          <section id="section8">
            <h2>Line up references</h2>
            <p>
              <a href="//www.topcv.com/career-advice/references-for-job-search" rel="noopener noreferrer" target="_blank">References may not belong on your {{product}}</a>, but they play an important role in your job search. Line up at least three references from people with whom you currently or previously worked. The idea is to identify people who have insight into your skills and capabilities and who you trust to say good things about you and your performance.
            </p>
            <p>
              In other words, target people who are willing to advocate for your candidacy. Then, contact these people and make sure they're willing to be a reference for you when the time comes. Once your professional CV is ready, be sure to send each of your references a copy so they have a better sense of your current goals and qualifications.
            </p>
          </section>
          <section id="section9">
            <h2>Work on your interview skills</h2>
            <p>
              We know that professionally written CV help you land interviews quickly, so it’s never too early to start sharpening your skills for when you get to the face-to-face portion of your job search.
            </p>
            <p>
              It’s the rare individual that doesn’t get nervous when interviewing for a job — and it’s the last place you want to be nervous, given that many interviewers will decide almost immediately if they want to hire you. Practicing your elevator pitch and the job interview questions that you know might trip you up can go a long way towards improving your interviewing skills and confidence.
            </p>
            <p>
              You may also want to <a href="//www.topinterview.co.uk/" rel="noopener noreferrer" target="_blank">work one on one with an interview coach</a> to improve your interview skills. When you work with an interview coach you will receive guidance and feedback to ensure that the time and effort you put into your interview preparation will actually benefit you.
            </p>
            <p>
              With an interview coach, you won’t waste your time. Instead, you’ll get coaching targeted towards your weaknesses and tailored to your industry.
            </p>
          </section>
          <section id="section10">
            <h2>Take some time for you</h2>
            <p>
              Finally, we understand that the job search is emotionally draining. Maintain a positive attitude, don't give up, and be patient and kind to yourself.
            </p>
            <p>
              While there’s a lot you can do to advance your job search while you wait for your CV, there’s no shame in sitting back and letting us handle the work for now. We’re your partner in job search success — we’re working for you so you can breathe easy.
            </p>
            <p>
              And if you need us at any time, you can always send us a message via our chat tool.
            </p>
          </section>`,
        },
      },
      Article4: {
        default: {
          date: 'Updated 8 Oct 2020',
          text: `<section>
            <h2>Your success is our success</h2>
            <p>
              As a job seeker, the details matter. When you’re applying for a
              job, the picture your CV paints of you is often all a
              hiring manager has to form a first impression. And with the advent
              of applicant tracking technology, if your CV is missing a
              crucial keyword, you’re at risk of getting passed over completely.
            </p>
            <p>
              We are invested in your success, and we want to make sure that
              anyone reviewing your professional materials sees a masterpiece.
              Your close collaboration with your writer ensures that you receive
              the best possible product for your job search.
            </p>
            <p>
              Here, we’ll share some tips on how we can work together to deliver
              the best service to you.
            </p>
          </section>
          <section>
            <h2>We like it when you overshare</h2>
            <p>
              Sharing your career goal with us is critical to receiving a
              finished CV that will help you achieve it. We want to know
              everything, no detail is too small, so we can incorporate that
              information into the final product.
            </p>
            <p>
              You can start sharing what’s important to you as soon as you’ve
              purchased with us — you don’t need to wait until you’ve been
              matched with a writer.
            </p>
            <h3>
              Keep us focused on the right next step for you
            </h3>
            <p>
              Sometimes, our onboarding process doesn’t capture your single most
              important career next step with us — the questionnaire can capture
              a number of different career options. Help us stay focused on your
              most important career goal.
            </p>
            <h3>Don’t forget to brag</h3>
            <p>
              The job search isn’t the time to be modest. Feel free to share
              supporting materials, such as awards, certificates, and
              references, with us — the earlier in the process, the better!
              Remember, you can upload any documentation to the {{brand}}
              portal as soon as you start working with us, even before you
              receive your first draft.
            </p>
            <h3>No detail is too small</h3>
            <p>
              The more details you can provide about who you are and what you
              want to achieve, the better. Don’t hesitate to communicate with
              us. We want to hear from you, and you can start sharing with us
              immediately — you don’t have to wait until there’s been a writer
              match.
            </p>
          </section>
          <UpsellBanner />
          <section>
            <h3>Our week of working together</h3>
            <p>
              You’ve told us about yourself, you’ve been matched with a writer,
              and now you have a first draft ready for your review. Once you
              have your first draft, you and your writer have a week — seven
              days — to put the finishing touches on your documents. If there’s
              any point during the week where you feel like your needs aren’t
              being met, you should contact us and let us know so we can make it
              right.
            </p>
            <p>
              In our experience, a week is the perfect length of time to get it
              right. It allows you and your writer to revise your CV
              together, but isn’t so long that it prevents you from putting your
              new CV to work.
            </p>
            <p>
              At the end of the day, we want you to be able to use your
              documents to apply for jobs as quickly as possible!
            </p>
            <h3>Need more time?</h3>
            <p>
              That said, we know that not everyone’s circumstances are identical
              — what works for most may not work for you.
            </p>
            <p>
              For example, if your career goal changes or you simply find that
              you don’t have time to to dedicate to your project over the seven
              days, you can continue to collaborate on your CV with your
              writer on more revisions for an additional charge. We want to give
              everyone amazing service, and keeping the revision window time
              bound ensures all of our customers get the help they need.
            </p>
            <p>
              If you need to put the entire process on hold, let us know. It can
              be challenging for us to stop the writing process, but we will
              always work with you to find a solution that works for everyone.
            </p>
            <h3>Final approval</h3>
            <p>
              As soon as you’re happy with your CV, you can approve your
              documents and receive a download of the final copy. Once you’re
              ready to start using your CV, we’re here to help you use
              it, too.
            </p>
            <p>
              Our article “Taking the Next Step” is a great place to start
              learning about how to use your expertly written CV for job
              search success
            </p>
          </section>
          <section>
            <h2>
              Our writers would love to hear from you
            </h2>
            <p>
              Once you have your finished CV, we would love to get your
              feedback on the experience and for you to share us with your
              friends.
            </p>
            <p>
              Our writers love to hear what you think — a great review for your
              writer is the equivalent of giving a great tip to your waiter or
              waitress at a restaurant. Your comments and reflections on the
              CV writing process help us improve, too.
            </p>
          </section>`,
        },
      },
      Article5: {
        default: {
          date: 'Updated 8 Oct 2020',
          text: `<section>
            <h2>My CV is done. What’s next?</h2>
            <p>
              You’ve shared your career goal with us, you’ve collaborated with your writer, and now your CV is ready for the job search.
            </p>
            <p>
              When you’re happy with your CV, you can approve the document at any time, and the finished file will always be available for you to access on your {{brand}} dashboard.
            </p>
            <p>
              Now you’re ready to take the next step: conquering the job hunt and securing your next position.
            </p>
            <p>
              Hunting for a job doesn't have to be overwhelming. We’ve broken down the job search into steps that will help make the experience as pain-free as possible.
            </p>
          </section>
          <section id="section1">
            <h2>The job search</h2>
            <p>
              Amassing job leads is the name of the game when you're in active job-search mode, but solely applying for jobs online isn't going to cut it if you want to be successful.
            </p>
            <p>
              Instead, our career expert, Amanda Augustine, suggests what she calls “harnessing the 'power of three.'” Come again?
            </p>
            <p>
              Essentially, the most successful job seekers are those who use multiple methods (the three below) to search for opportunities.
            </p>
            <p>
              Combine their powers, and you'll maximise the number of leads you can pursue, which may very well maximise your success.
            </p>
            <section id="section1a">
              <h3>Applications and follow-up</h3>
              <p>
                Searching online, applying online, application after application… it can get exhausting, and it's hard to know if the jobs you're applying to are even <a href="//www.topcv.com/career-advice/identify-job-scams" rel="noopener noreferrer" target="_blank">worth the effort</a>. And then you find a job listing that seems, for lack of a better word, perfect.
              </p>
              <p>You apply. You're excited.</p>
              <p>
                And then you wait. And wait. And wait some more. Did your application get lost? Did they already fill the position? Can you reach out to follow up?
              </p>
              <p>
                The answers: maybe, maybe, and yes. So, how exactly do you follow up?
              </p>
              <p>
                The short answer: Wait approximately 7–10 days, and if you're still hearing crickets, turn to the job posting and LinkedIn. Does the job posting have any contact information? If yes, you're golden. If not, check out the company's LinkedIn page and search for someone in HR or recruiting.
              </p>
            </section>
            <section id="section1b">
              <h3>Utilising recruiters</h3>
              <p>
                Have you <a href="//www.topresume.com/career-advice/5-tips-for-effectively-working-with-a-recruiter" rel="noopener noreferrer" target="_blank">worked with recruiters</a> in the past? Or if you haven't, are you interested in going that route this time around? While it's not for everyone, don't forget the “power of three” and explore every avenue.
              </p>
              <p>
                Working with an <a href="//www.topresume.com/career-advice/how-to-select-the-right-recruiter" rel="noopener noreferrer" target="_blank">external recruiter</a> can be a great resource for finding new job leads and gaining company insight. First step: Go back to your online presence and make sure it is as strong as possible so <a href={"//www.topcv.com/career-advice/how-recruiters-find-you-online" rel="noopener noreferrer" target="_blank">recruiters can find you</a>.
              </p>
            </section>
            <section id="section1c">
              <h3>Networking</h3>
              <p>
                You knew we'd get to this point, and we know you didn't want us to unless you're a social butterfly. <a href="//www.topresume.com/career-advice/importance-of-networking-for-career-success" rel="noopener noreferrer" target="_blank">Networking</a> is the part of “search” that could very well (and most likely will) be the way you find your next position.
              </p>
              <p>
                Networking means nurturing existing relationships and leveraging your personal and professional connections. Your next job could come from your roommate's sister's co-worker — you never know.
              </p>
              <p>
                Did you know you're 10 times more likely to land a job with an employee referral? <a href="//www.topresume.com/career-advice/7-networking-tips-for-job-seekers" rel="noopener noreferrer" target="_blank">Tap into that network!</a> Reconnect,<a href="//www.topcv.com/career-advice/how-to-use-linkedin-for-job-search" rel="noopener noreferrer" target="_blank"> reach out on LinkedIn</a>, and tell everyone you know you're on the hunt (except your current employer).
              </p>
              <p>
                If you're feeling like your network is lacking, check out online groups, meetup groups, conferences — they're all great ways to expand your network.
              </p>
            </section>
          </section>
          <UpsellBanner />
          <section id="section2">
            <h2>Seal the deal</h2>
            <p>
              You have prepped, you have searched, and now you have an interview! Get this next section just right, and you are going to close.
            </p>
            <section id="section2a">
              <h3>Interviews and follow-up</h3>
              <p>
                Your hard work has gotten you here, and you're ready for your interview.
              </p>
              <p>
                Here's a quick checklist of interview to-dos and objectives:
              </p>
              <ul>
                <li>
                  <strong>Dress for success.</strong> Look at the company's website and social media presence to get a sense of how employees dress.
                </li>
                <li>
                  <strong>Have your <a href="//www.topcv.com/career-advice/tell-me-about-yourself-interview-answer" rel="noopener noreferrer" target="_blank">elevator pitch</a> prepped and ready to go.</strong> Just don't over-rehearse, and definitely don't just regurgitate your CV. Keep it short and sweet, and give the interviewer a glimpse of why you're the right candidate while highlighting your achievements and personality. You've got this.
                </li>
                <li>
                  <strong>Practice answering the questions that make you nervous.</strong>You know the ones. The mere thought of them makes your skin crawl. We hate to be the bearer of bad news, but you'll definitely get asked them. Prepare, prepare some more, and then practice in front of the mirror. If you're still feeling like your delivery could use some ironing out, enlist the help of professional interview coaches at
                  <a href="//www.topinterview.co.uk/" rel="noopener noreferrer" target="_blank">TopInterview</a>. Your coach will create a personalised blueprint for interview success, targeting specific questions and interview styles relevant to the position you’re interviewing for. You only get one chance to impress in an interview, and interview coaching can help ensure you bring your best self forward.
                </li>
                <li><strong>Prepare a list of questions to ask your interviewers.</strong> On the topic of questions to ask, when they ask you, “
                  <a href="//www.topcv.com/career-advice/best-questions-to-ask-hiring-manager" rel="noopener noreferrer" target="_blank">Do you have any questions for me?</a>” you'll want to be prepared to give an enthusiastic “Yes!” Have a few of these jotted down in a notebook ahead of time, and you'll look extra prepared, engaged, and genuinely interested in the role. Asking questions also will help you decide if the job and company are right for you.
                </li>
              </ul>
              <p>
                Think you're done once you exit the interview room? Think again.
              </p>
              <p>
                Next step: <a href="//www.topcv.co.uk/career-advice/thank-you-after-interview" rel="noopener noreferrer" target="_blank">follow up</a>. And do so within 24 hours. It doesn't need to be a flashy note— just be sure to thank each interviewer for their time, personalse the message, reiterate your sincere interest, and end it with a question.
              </p>
              <p>
                Something along the lines of, “What do the next steps in this process look like?” will suffice.
              </p>
            </section>
            <section id="section2b">
              <h3>Negotiation</h3>
              <p>
                One important last step in the process is negotiation. Like any skill, you can learn to be a better negotiator over time — it just takes <a href="//www.topcv.com/career-advice/salary-negotiation-tips-avoid-phrases" rel="noopener noreferrer" target="_blank">a little research and a lot of practice</a>. Start with a little online research to make sure the salaryyou're asking for is backed up by data.
              </p>
              <p>
                Next, be clear on your minimum requirements and have a strategy for dealing with counter-offers. Remember, negotiation shouldn't be a win-lose situation; your goal is to settle on a number that all parties are happy with.
              </p>
              <p>
                In addition, a compensation package isn't just about pay. You can <a href="https://www.topresume.com/career-advice/guide-to-negotiating-the-best-overall-compensation-package" rel="noopener noreferrer" target="_blank">negotiate any number of items</a>, from your job title to your number of vacation days, and much more.
              </p>
            </section>
          </section>
          <section>
            <h2>We’re here to help you make the job search a success</h2>
            <p>
              At {{brand}}, we are committed to your job search success. Everything we do is for you: we want to shorten your job search, tell your career story in a powerful and compelling way, help you impress an interviewer, secure a higher salary.
            </p>
            <p>
              If you need any support, feel free to chat with us and we’ll do our best to help.
            </p>
          </section>`,
        },
      },
      Documents: {
        draftDateFormat: 'EEE, d MMM, yyyy, h:mm aaa',
        responseDateFormat: 'd/M/yy',
        dateFormat: 'd MMM',
      },
      itemTypes: {
        [ItemTypes.Resume]: 'CV',
      },
      Portfolio: {
        purchasedOnFormat: 'd LLLL yyyy',
      },
      products: {
        resume: 'CV',
      },
      PurchaseHistory: {
        dateFormat: 'dd/MM/yy',
      },
    },
  },
}
