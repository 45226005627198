import React, { useCallback, useState } from 'react'
import {
  ErrorBoundary,
  FallbackProps,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary'
import * as Sentry from '@sentry/browser'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import PlainLinkButton from 'components/shared/PlainLinkButton'
import FlashMessage from 'components/shared/FlashMessage'

interface FlashErrorBoundaryProps {
  className?: string
}

/**
 * Error boundary that captures exceptions, sends them to Sentry, and then shows
 * a flash message that the user can inspect and copy the details of when
 * messaging support.
 */
export const FlashErrorBoundary: React.FC<FlashErrorBoundaryProps> = ({
  children,
  ...props
}) => {
  const [sentryID, setSentryID] = useState<string | null>(null)
  const errorHandler = useCallback(
    (error: Error) => setSentryID(Sentry.captureException(error)),
    []
  )

  return (
    <ErrorBoundary
      fallbackRender={(renderProps) => (
        <ErrorFlashMessage sentryID={sentryID} {...renderProps} {...props} />
      )}
      onError={errorHandler}
    >
      {children}
    </ErrorBoundary>
  )
}

/**
 * Error boundary that captures exceptions, sends them to Sentry, and hides the
 * children.
 */
export const NoOpErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const errorHandler = useCallback(
    (error: Error) => Sentry.captureException(error),
    []
  )

  return (
    <ErrorBoundary fallbackRender={() => null} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  )
}

/**
 * Error boundary that captures exceptions, sends them to Sentry, and hides the
 * children.
 */
export const FallbackErrorBoundary: React.FC<ErrorBoundaryPropsWithRender> = ({
  children,
  onError,
  ...props
}) => {
  const errorHandler = useCallback(
    (error: Error, componentStack: string) => {
      Sentry.captureException(error)

      if (onError) {
        onError(error, componentStack)
      }
    },
    [onError]
  )

  return (
    <ErrorBoundary onError={errorHandler} {...props}>
      {children}
    </ErrorBoundary>
  )
}

interface ErrorFlashMessageProps
  extends FallbackProps,
    FlashErrorBoundaryProps {
  sentryID?: string | null
}

export const ErrorFlashMessage: React.FC<ErrorFlashMessageProps> = ({
  error,
  componentStack,
  className,
  sentryID,
}) => {
  const classes = useStyles()
  const [showDetails, setShowDetails] = useState(false)
  const { t } = useTranslation()

  if (!error) {
    return null
  }

  return (
    <FlashMessage level="error" className={className}>
      <>
        <Typography variant="body2" className={classes.errorTitle}>
          {t('components.Errors.FlashMessage.errorOccured')}{' '}
          <PlainLinkButton
            onClick={(e) => {
              e.preventDefault()
              setShowDetails((s) => !s)
            }}
          >
            {t(
              `components.Errors.FlashMessage.${
                !showDetails ? 'showReport' : 'hideReport'
              }`
            )}
          </PlainLinkButton>
        </Typography>
        {showDetails && (
          <Paper
            // The following `component` prop throws the following TS error while building:
            // TS2322: Type '"pre"' is not assignable to type 'ElementType<HTMLAttributes<HTMLElement>> | undefined'.
            // This should be resolved once we upgrade Mui to v5
            // @ts-ignore
            component="pre"
            className={classes.errorDetails}
            variant="outlined"
          >
            {sentryID && `Sentry ID: ${sentryID}`}
            {'\n'}
            {error.message}
            {componentStack}
          </Paper>
        )}
      </>
    </FlashMessage>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    errorTitle: {},
    errorDetails: {
      width: '100%',
      fontFamily: 'monospace',
    },
  })
)

export default FlashErrorBoundary
