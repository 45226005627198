import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'
import clsx from 'clsx'
import { formattedPrice } from 'utils/formatting'

import { colors } from 'styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AnimatedUpsellIncon from './AnimatedUpsellIcon'

import DiscountFlag from 'components/Upsells/DiscountFlag'
import { CountUpText } from 'components/shared/CountUpText'
import Price from 'components/shared/Price'

import SvgUpsellResumeRefreshBG from 'assets/images/upsell-resume-refresh-background.svg'
import JPGExpert from 'assets/images/amanda-expert.jpg'

import { Upsell } from 'store/upsells/types'
import { ItemTypes } from 'store/items/types'

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.1em',
    },
    animatedBodyContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      fontSize: '1rem',
    },
    icon: {
      maxWidth: '100%',
      width: '12.1em',
    },
    originalPrice: {
      color: 'white',
      fontSize: '1.13em',
    },
    originalPriceContainer: {
      position: 'relative',
    },
    price: {
      color: 'white',
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '2.5em 2em',
      textAlign: 'center',
      width: '100%',
    },
    strikethrough: {
      backgroundColor: theme.palette.error.main,
      height: '.1em',
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
    },
    animatedPaper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '2.5em 2em',
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      backgroundColor: colors.blue.a500,
    },
    recommend: {
      fontWeight: 400,
    },
    offer: {
      fontWeight: 400,
      marginBottom: '0.5rem',
      fontSize: '1rem',
    },
    shortPitch: {
      fontWeight: 'normal',
      fontSize: '1rem',
      color: theme.palette.secondary.main,
      marginBottom: '1rem',
      // This is needed for IE11
      maxWidth: '100%',
    },
    upsellName: {
      fontWeight: 600,
      lineHeight: 1.5,
      margin: '.5em 0 .5em',
      maxWidth: '100%',
      fontSize: '1.25rem',
    },
    veryShortPitch: {
      fontWeight: 800,
      lineHeight: 1.5,
      margin: '.5em 0 .5em',
      maxWidth: '100%',
      fontSize: '1.5rem',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    timerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    accordian: {
      marginTop: '1.5rem',
      paddingLeft: '0em',
      paddingRight: '0em',
      borderRadius: '0.5rem',
    },
    accordianSummaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    accordianSummary: {
      backgroundColor: colors.blue.r100,
      backgroundImage: `url(${SvgUpsellResumeRefreshBG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -8em bottom -1em',
      [theme.breakpoints.down('md')]: {
        backgroundPosition: 'right -2.5em bottom -1em',
      },
      [theme.breakpoints.down('xs')]: {
        backgroundPosition: 'right -8em bottom -1em',
      },
      padding: '0 0 0 0',
      marginTop: '1.5rem',
    },
    accordianSummaryBoarder: {
      borderRadius: '0.25rem',
    },
    accordianSummaryBoarderExp: {
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
    },
    accordianButton: {
      color: 'white',
      fontWeight: 'normal',
      '&:hover, &:active': {
        color: `${colors.gray[600]} !important`,
      },
    },
    accordianButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    contentLayer: {
      position: 'absolute',
      top: '0em',
      left: '0em',
    },
    accordianSummaryHeader: {
      color: 'white',
      fontWeight: 600,
      marginBottom: '1rem',
      marginTop: '1rem',
    },
    accordianSummaryBody: {
      fontSize: '1rem',
      fontWeight: -100,
      marginBottom: '1rem',
      color: 'white',
    },
    purchaseButton: {
      fontWeight: 'normal',
      marginBottom: '7rem',
      backgroundColor: 'white',
      color: theme.palette.secondary.main,
      '&:hover, &:active': {
        backgroundColor: `${colors.gray[600]} !important`,
      },
    },
    accordianDetailsHeader: {
      textAlign: 'center',
      fontWeight: 800,
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    accordianDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    studiesContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '1rem',
    },
    studiesPercentageContainer: {
      flexBasis: '25%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: colors.blue.r200,
      borderTopLeftRadius: '0.5em',
      borderBottomLeftRadius: '0.5em',
      [theme.breakpoints.down('md')]: {
        flexBasis: '10%',
      },
    },
    studiesCaptionContainer: {
      flexBasis: '75%',
      backgroundColor: colors.blue[100],
      borderTopRightRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        flexBasis: '90%',
      },
    },
    studiesPercentage: {
      color: 'white',
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
      [theme.breakpoints.down('md')]: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
      },
    },
    studiesCaption: {
      marginTop: '1rem',
      marginBottom: '1rem',
      fontSize: '1rem',
    },
    bulletHeading: {
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    bullet: {
      fontSize: '1rem',
      marginBottom: '1rem',
      textAlign: 'left',
    },
    bulletContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignContent: 'flex-start',
    },
    expertContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '1.5rem',
    },
    expertQuoteContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0rem',
      },
    },
    avatar: {
      width: '3em',
      height: '3em',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    expertQuote: {
      fontSize: '1rem',
      marginBottom: '0.75rem',
    },
    expertName: {
      fontSize: '0.8rem',
    },
    addToOrderButton: {
      color: 'white',
      fontWeight: 'normal',
      width: '100%',
    },
  })
)

interface Props {
  upsell: Upsell
  purchaseURL: string | null
  expirationDate: Date | null
  itemsInOrder?: number
}

const ExpandableHomeUpsellCard: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const tKey = 'components.Upsells.expandableBillboard'
  const itemTypeKey = props.upsell.items[0]?.item_type_key || ''

  const [expanded, setExpanded] = React.useState(false)

  //This is required to prevent the accordian from
  //expanding when the 'Get Started' button is clicked
  const purchaseRedirect = useCallback(
    (event) => {
      event.stopPropagation()
      if (props.purchaseURL) {
        window.location.assign(props.purchaseURL)
      }
    },
    [props.purchaseURL]
  )

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <Accordion className={classes.accordian} expanded={expanded}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={clsx(
          classes.accordianSummary,
          expanded
            ? classes.accordianSummaryBoarderExp
            : classes.accordianSummaryBoarder
        )}
      >
        <Container className={classes.accordianSummaryContainer}>
          <Typography variant="h3" className={classes.accordianSummaryHeader}>
            {t(`${tKey}.title.${itemTypeKey}`)}
          </Typography>
          <Typography variant="body1" className={classes.accordianSummaryBody}>
            {t(`${tKey}.shortPitch.${itemTypeKey}`)}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className={classes.purchaseButton}
            onClick={(e) => purchaseRedirect(e)}
          >
            {t(`${tKey}.cta.${itemTypeKey}`)}
          </Button>
          <Container
            disableGutters
            className={classes.accordianButtonContainer}
          >
            <Button
              color="primary"
              variant="text"
              className={classes.accordianButton}
              onClick={() => toggleExpanded()}
            >
              {t(`${tKey}.toggle.${itemTypeKey}`)}&nbsp;
              <ExpandMoreIcon />
            </Button>
          </Container>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        <Container disableGutters className={classes.accordianDetailsContainer}>
          <Typography variant="h3" className={classes.accordianDetailsHeader}>
            {t(`${tKey}.expanded.title.${itemTypeKey}`)}
          </Typography>
          <Container className={classes.studiesContainer} disableGutters>
            <Container className={classes.studiesPercentageContainer}>
              <Typography variant="h1" className={classes.studiesPercentage}>
                {t(`${tKey}.expanded.percentage.${itemTypeKey}`)}
              </Typography>
            </Container>
            <Container
              disableGutters
              className={classes.studiesCaptionContainer}
            >
              <Typography variant="body1" className={classes.studiesCaption}>
                {t(`${tKey}.expanded.shortPitch.${itemTypeKey}`)}
              </Typography>
            </Container>
          </Container>
          <Container disableGutters className={classes.bulletContainer}>
            <Typography variant="body1" className={classes.bullet}>
              <strong>
                {t(`${tKey}.expanded.bullets.${itemTypeKey}.one.bold`)}
              </strong>
              {t(`${tKey}.expanded.bullets.${itemTypeKey}.one.normal`)}
            </Typography>
            <Typography variant="body1" className={classes.bullet}>
              <strong>
                {t(`${tKey}.expanded.bullets.${itemTypeKey}.two.bold`)}
              </strong>
              {t(`${tKey}.expanded.bullets.${itemTypeKey}.two.normal`)}
            </Typography>
            <Typography variant="body1" className={classes.bullet}>
              <strong>
                {t(`${tKey}.expanded.bullets.${itemTypeKey}.three.bold`)}
              </strong>
              {t(`${tKey}.expanded.bullets.${itemTypeKey}.three.normal`)}
            </Typography>
          </Container>

          <Container disableGutters className={classes.expertContainer}>
            <Avatar className={classes.avatar} src={JPGExpert} />
            <Container disableGutters className={classes.expertQuoteContainer}>
              <Typography variant="body1" className={classes.expertQuote}>
                {t(`${tKey}.expanded.expert.quote.${itemTypeKey}`)}
              </Typography>
              <Typography variant="body1" className={classes.expertName}>
                {t(`${tKey}.expanded.expert.name.${itemTypeKey}`)}
              </Typography>
            </Container>
          </Container>
          <Button
            color="primary"
            variant="contained"
            className={classes.addToOrderButton}
            href={props.purchaseURL || '#'}
          >
            {t(`${tKey}.expanded.cta.${itemTypeKey}`)}
          </Button>
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}

const DefaultHomeUpsellCard: React.FC<Props> = ({
  upsell,
  purchaseURL,
  expirationDate,
  itemsInOrder = 1,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const typeKey = upsell.items[0]?.item_type_key || ''
  const hours48 = 1000 * 60 * 60 * 48
  const rushDate = new Date(new Date().getTime() + hours48)
  const isGuarantee = upsell.items[0].item_type_key === ItemTypes.Guarantee
  const isDiscounted = !!(
    upsell.original_price &&
    upsell.original_price > 0 &&
    upsell.original_price > upsell.price
  )

  return (
    <Paper className={classes.animatedPaper} variant="outlined">
      <Container disableGutters>
        <Container disableGutters className={classes.timerContainer}>
          <AnimatedUpsellIncon
            expirationDate={expirationDate}
            itemName={upsell.name}
            itemType={typeKey}
            itemsInOrder={itemsInOrder}
          />
        </Container>
      </Container>
      <Container disableGutters className={classes.animatedBodyContainer}>
        <Typography variant="h2" className={classes.veryShortPitch}>
          {isGuarantee && <CountUpText end={60} />}
          {t(`components.Upsells.billboard.headline.${typeKey}`)}
        </Typography>
        <Typography
          variant="h6"
          className={classes.shortPitch}
          dangerouslySetInnerHTML={{
            __html: t(`components.Upsells.billboard.shortPitch.${typeKey}`, {
              price: formattedPrice(
                upsell.currency_code,
                upsell.currency_symbol,
                upsell.price
              ),
              rushDate:
                '<b>' +
                dateFormat(
                  rushDate,
                  t(`components.Upsells.shortPitchDateFormat`)
                ) +
                '</b>',
              itemName: `<b>${t(`itemTypes.${typeKey}`)}</b>`,
            }),
          }}
        />

        <Button
          color="primary"
          variant="text"
          className={classes.button}
          href={purchaseURL || '#'}
        >
          {t(`components.Upsells.billboard.cta.${typeKey}`)}
        </Button>
      </Container>
      <DiscountFlag>
        {isDiscounted && (
          <Container className={classes.originalPriceContainer} disableGutters>
            <Typography
              className={classes.originalPrice}
              component="p"
              variant="subtitle1"
            >
              <Price
                currencyCode={upsell.currency_code}
                currencySymbol={upsell.currency_symbol}
                amount={upsell.original_price || 0}
                className={classes.price}
              />
            </Typography>
            <div className={classes.strikethrough}></div>
          </Container>
        )}
        <Typography className={classes.price} component="p" variant="subtitle1">
          <Price
            currencyCode={upsell.currency_code}
            currencySymbol={upsell.currency_symbol}
            amount={upsell.price}
            className={classes.price}
          />
        </Typography>
      </DiscountFlag>
    </Paper>
  )
}

const HomeUpsellCard: React.FC<Props> = ({ ...props }) => {
  if (props.upsell.items[0]?.item_type_key === ItemTypes.Resume) {
    return <ExpandableHomeUpsellCard {...props} />
  }

  return <DefaultHomeUpsellCard {...props} />
}

export default HomeUpsellCard
