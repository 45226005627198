import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import useEvents from 'hooks/useEvents'

const PasswordResetCallbackPage: React.FC = () => {
  const sendEvent = useEvents()
  const { pat } = useParams<{ pat: string }>()

  useEffect(() => {
    if (pat) {
      sendEvent(
        {
          event: 'Password Reset Confirmation',
        },
        pat
      )
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pat])

  return <Redirect to="/" />
}

export default PasswordResetCallbackPage
