import React, { useCallback, useState } from 'react'

import DownloadModal from 'components/Modals/variants/Download'
import ApproveOrderModalContainer from 'components/Rating/ApproveOrderModalContainer'
import NPSContainer from 'components/Rating/NPSContainer'
import { File } from 'store/files/types'
import { generateGhostURL } from 'components/shared/GhostLink'

function useModals(
  selectedDocument: File | null,
  orderID?: number | null,
  orderIDs?: number[],
  onActionComplete?: () => void
) {
  let modal = null
  const [activeModal, setActiveModal] = useState<
    null | 'download' | 'rate' | 'approve'
  >(null)

  switch (activeModal) {
    case 'download':
      if (selectedDocument) {
        modal = (
          <DownloadModal
            wordURL={generateGhostURL(selectedDocument.download_url)}
            pdfURL={
              selectedDocument.pdf_url
                ? generateGhostURL(selectedDocument.pdf_url)
                : selectedDocument.pdf_url
            }
            disableWord={selectedDocument?.filename.endsWith('.pdf')}
            onClose={() => setActiveModal(null)}
          />
        )
      }

      break
    case 'rate':
      if (orderID) {
        modal = (
          <NPSContainer
            orderID={orderID}
            onClose={() => setActiveModal(null)}
          />
        )
      }
      break
    case 'approve':
      if (orderIDs) {
        modal = (
          <ApproveOrderModalContainer
            orderIDs={orderIDs}
            onClose={() => setActiveModal(null)}
            onActionComplete={() => {
              if (onActionComplete) onActionComplete()
            }}
          />
        )
      }
      break
  }

  return {
    modal,
    onDownloadClick: useCallback(() => setActiveModal('download'), []),
    onRateClick: useCallback(() => setActiveModal('rate'), []),
    onApproveClick: useCallback(() => setActiveModal('approve'), []),
    closeModals: useCallback(() => setActiveModal(null), []),
  }
}

export default useModals
