import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { ItemWithDocuments, phoneTypes } from 'store/items/types'
import ItemCard from './ItemCard'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AppState } from 'store'
import CIOBanner from './CIOBanner'
import useUser from 'hooks/useUser'

interface Props {
  items: ItemWithDocuments[]
  handleViewClick: (itemID: number | null) => void
}

/**
 * PastItems is a display structure for the Portfolio.
 * It shows past items and documents in previously fulfilled orders
 * It allows document item view, download, and rating.
 */
const PastItems: React.FC<Props> = ({ items, handleViewClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { allowed_in_cio: allowedInCIO } = useUser()
  const approveOrderLoading = useSelector<AppState, boolean>(
    (state) => state.orders.approveOrderLoading
  )

  // remove phone type items, if there is no document
  items = items.filter((item) => {
    const latestDocument = item.documents?.[0] || null
    if (phoneTypes.has(item.item_type) && !latestDocument) {
      return false
    }
    return true
  })

  return (
    <Container className={classes.cardContainer}>
      <Typography variant="subtitle1" className={classes.h2}>
        {t('components.Portfolio.PastItems.title')}
      </Typography>
      {approveOrderLoading ? (
        <Container className={classes.loading}>
          <CircularProgress size={32} />
          <Typography className={classes.loadingText}>
            {t('components.Portfolio.PastItems.loading')}
          </Typography>
        </Container>
      ) : (
        <>
          {allowedInCIO ? <CIOBanner /> : null}
          <Container className={classes.itemContainer}>
            {items.map((item) => (
              <ItemCard
                key={item.id}
                item={item}
                handleViewClick={handleViewClick}
                ringClass={
                  item.dueForRefresh
                    ? classes.itemBorderDueForUpdate
                    : classes.itemBorder
                }
                dueForUpdateToolTip={item.dueForRefresh}
                isPastItem={true}
              />
            ))}
          </Container>
        </>
      )}
    </Container>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    h2: {
      textAlign: 'left',
      color: '#052a4d',
      fontSize: '1.4em',
      width: '100%',
    },
    cardContainer: {
      marginTop: '4em',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0',
      [theme.breakpoints.down('xs')]: {
        padding: '2em',
        marginTop: '2em',
      },
    },
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-.75em',
      padding: '0',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        marginLeft: '0',
      },
    },
    itemBorder: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    itemBorderDueForUpdate: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    loading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '2.5rem 0',
    },
    loadingText: {
      margin: '1rem 0',
    },
  })
)

export default PastItems
