import { HashMap } from '@talentinc/state-utils'
import { Expert } from 'store/orders/types'

export interface ExpertsState {
  experts: HashMap<Expert>
}

export const initialState: ExpertsState = {
  experts: {},
}
