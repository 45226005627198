import { useSelector } from 'react-redux'

import { AppState } from 'store'
import { unreadMessages } from 'store/messages/selectors'
import { Message } from 'store/messages/types'

function useUnreadMessages() {
  const messages = useSelector<AppState, Message[]>((state) =>
    unreadMessages(state.messages)
  )

  return {
    unreadMessages: messages,
    unreadMessageCount: messages.length,
  }
}

export default useUnreadMessages
