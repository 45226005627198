import React from 'react'
import { FormApi } from 'final-form'
import { useField } from 'react-final-form-hooks'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

import ValidationRule from 'components/shared/ValidationRule'
import checkPasswordStrength from 'utils/passwordStrength'

interface Props {
  form: FormApi<any>
  fieldName: string
  TextFieldProps?: TextFieldProps
  email?: string | null
}

const PasswordStrengthInput: React.FC<Props> = ({
  form,
  fieldName,
  email,
  TextFieldProps = {},
}) => {
  const { t } = useTranslation()
  const field = useField<string>(fieldName, form, (formPassword) => {
    const password = formPassword ?? ''
    // Any errors are a true value, valid is false
    // Confusing? Sorta, but it does match the variable name.
    const validationIssues = {
      strength: checkPasswordStrength(password),
      length: password.length < 8,
      // Blowfish has a limit of 72 characters. In order to use something
      // longer in Auth0, we would need to flip a switch.
      tooLong: password.length > 72,
      symbol: !/\W/.test(password),
      number: !/\d/.test(password),
      lowercase: !/[a-z]/.test(password),
      uppercase: !/[A-Z]/.test(password),
    }

    // If there are no errors, return nothing
    const hasValidationIssues = !!Object.values(validationIssues).find(
      (vi) => vi === true || vi === 'weak'
    )

    return hasValidationIssues ? validationIssues : null
  })
  const passwordStrength = checkPasswordStrength(field.input.value)
  let helperText = null

  if (field.meta.dirty) {
    helperText = (
      <>
        <ValidationRule invalid={field.meta.error?.strength === 'weak'}>
          {t('components.Auth.SignUpForm.validation.strength', {
            strength: passwordStrength,
          })}
        </ValidationRule>
        {!!field.meta.error && (
          <>
            <ValidationRule invalid={!!field.meta.error?.length}>
              {t('components.Auth.SignUpForm.validation.length')}
            </ValidationRule>
            <ValidationRule invalid={!!field.meta.error?.uppercase}>
              {t('components.Auth.SignUpForm.validation.uppercase')}
            </ValidationRule>
            <ValidationRule invalid={!!field.meta.error?.lowercase}>
              {t('components.Auth.SignUpForm.validation.lowercase')}
            </ValidationRule>
            {!!field.meta.error?.tooLong && (
              <ValidationRule invalid={true}>
                {t('components.Auth.SignUpForm.validation.tooLong')}
              </ValidationRule>
            )}
            <ValidationRule invalid={!!field.meta.error?.symbol}>
              {t('components.Auth.SignUpForm.validation.symbol')}
            </ValidationRule>
            <ValidationRule invalid={!!field.meta.error?.number}>
              {t('components.Auth.SignUpForm.validation.number')}
            </ValidationRule>
          </>
        )}
      </>
    )
  }

  return (
    <TextField
      type="password"
      helperText={helperText}
      id={fieldName}
      FormHelperTextProps={{
        // @ts-ignore
        component: 'div',
      }}
      {...field.input}
      {...TextFieldProps}
    />
  )
}

export default PasswordStrengthInput
