import { CronState, initialState } from './types'
import { CronActions, CronActionTypes } from './actions'
import { produce } from 'immer'

export default function cronReducer(
  state: CronState = initialState,
  action: CronActions
): CronState {
  return produce(state, (draft) => {
    switch (action.type) {
      case CronActionTypes.SET_INTERVAL_IDS:
      case CronActionTypes.CLEAR_INTERVAL_IDS:
        draft.intervals = { ...state.intervals, ...action.intervals }
        break
    }
  })
}
