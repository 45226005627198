import React from 'react'
import { useParams } from 'react-router-dom'

import ProductDetailsContainer from 'components/Product/ProductDetailsContainer'
import ProductPageShell from 'pages/PageShell/ProductPageShell'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { useSearchParam } from 'react-use'

const ProductPage: React.FC = () => {
  const { planCode, bundle } = useParams<{
    planCode?: string
    bundle?: string
  }>()

  const pat = useSearchParam('pat')

  const isLoggedin = useSelector<AppState, boolean>(
    (state) => state.user.auth.loggedIn
  )

  return (
    <ProductPageShell isLoggedIn={isLoggedin}>
      <ProductDetailsContainer
        planCode={planCode}
        bundleCode={bundle}
        pat={pat}
        isLoggedIn={isLoggedin}
      />
    </ProductPageShell>
  )
}

export default ProductPage
