import React, { useState, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { format as dateFormat, parseISO } from 'date-fns'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import CircularProgress from '@material-ui/core/CircularProgress'

import AvailabilityViewer from './AvailabilityViewer'
import {
  CronofyAvailablityViewerArgs,
  CronofyAvailablityViewerNotification,
  CronofyNotifcationTypes,
} from 'store/scheduling/types'
import { useWrapperStyles } from './Wrapper'
import { colors } from 'styles'
import ValidationRule from 'components/shared/ValidationRule'

interface Props {
  cronofyArgs: CronofyAvailablityViewerArgs
  expertName?: string | null
  onScheduleConfirmation: (start: Date, end: Date) => Promise<void>
  itemID: number
}

const transKey = 'components.Scheduler.Scheduler'

const Scheduler: React.FC<Props> = ({
  cronofyArgs,
  expertName,
  onScheduleConfirmation,
  itemID,
}) => {
  const classes = useStyles()
  const wrapperClasses = useWrapperStyles()
  const { t } = useTranslation()
  const [selectedSlot, setSelectedSlot] = useState<null | [Date, Date]>(null)

  const cronfoyCallback = useCallback(
    (event: CronofyAvailablityViewerNotification) => {
      if (event.notification.type === CronofyNotifcationTypes.SlotSelected) {
        setSelectedSlot([
          parseISO(event.notification.slot.start),
          parseISO(event.notification.slot.end),
        ])
      }
    },
    []
  )

  const handleBack = useCallback(() => setSelectedSlot(null), [])
  return (
    <>
      <Typography variant="h1" className={wrapperClasses.bodyHeader}>
        {t(`${transKey}.${expertName ? 'header' : 'headerNoExpert'}`, {
          expertName: expertName,
        })}
      </Typography>
      {!selectedSlot && (
        <AvailabilityViewer
          className={classes.calendar}
          itemID={itemID}
          callback={cronfoyCallback}
          {...cronofyArgs}
        />
      )}
      {selectedSlot && (
        <Confirmation
          start={selectedSlot[0]}
          end={selectedSlot[1]}
          onConfirmation={onScheduleConfirmation}
          onBack={handleBack}
        />
      )}
    </>
  )
}

interface ConfirmationProps {
  start: Date
  end: Date
  onConfirmation: Props['onScheduleConfirmation']
  onBack: () => void
}

const Confirmation: React.FC<ConfirmationProps> = ({
  start,
  end,
  onConfirmation,
  onBack,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [submissionState, handleSubmit] = useAsyncFn(onConfirmation, [
    onConfirmation,
  ])

  return (
    <Box className={classes.confirmContainer}>
      <Box className={classes.confirmLeftColumn}>
        <Button
          onClick={onBack}
          color="primary"
          startIcon={<ChevronLeftIcon />}
          className={classes.backBtn}
        >
          {t(`${transKey}.back`)}
        </Button>
        <Typography className={classes.meetingDetails}>
          {t(`${transKey}.meetingDetails`)}
        </Typography>
        <Typography
          variant="h2"
          component="time"
          dateTime={start.toISOString()}
        >
          {t(`${transKey}.meetingTimeFormat`, {
            startTime: dateFormat(start, t(`${transKey}.timeFormat`)),
            endTime: dateFormat(end, t(`${transKey}.timeFormat`)),
            date: dateFormat(end, t(`${transKey}.dateFormat`)),
          })}
        </Typography>
      </Box>
      <Box className={classes.confirmRightColumn}>
        <Typography className={classes.warningText}>
          {t(`${transKey}.warning`)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(start, end)}
          disabled={submissionState.loading}
          endIcon={
            submissionState.loading && <CircularProgress size="1.13em" />
          }
        >
          {t(`${transKey}.confirmMeeting`)}
        </Button>
        {submissionState.error && (
          <ValidationRule invalid>{t(`${transKey}.error`)}</ValidationRule>
        )}
      </Box>
    </Box>
  )
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    calendar: {
      marginTop: '1.5em',
    },
    confirmContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '1.5em',
      minHeight: '365px',

      '& > *': {
        width: '48%',
      },
    },
    confirmLeftColumn: {
      paddingRight: '2em',
      borderRight: `1px solid ${colors.gray[600]}`,
    },
    confirmRightColumn: {
      textAlign: 'center',
      paddingTop: '2.6em',
      paddingLeft: '2em',
    },
    meetingDetails: {
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '.9em',
    },
    backBtn: {
      marginBottom: '1em',
    },
    warningText: {
      marginBottom: '1.5em',
    },
  })
)

export default Scheduler
