import { AppThunkAction } from 'store'
import { UserIdentifier } from 'store/user/types'
import {
  fetchScheduledItems,
  fetchScheduledItemDetails,
  scheduleItem,
} from './actions'
import { scheduledItemIDsNeedingDetails } from './selectors'
import { ScheduleItemRequest } from './types'

export const fetchSchedulableItemsAndDetails =
  (userIdentifier: UserIdentifier): AppThunkAction =>
  async (dispatch, getState) => {
    await dispatch(fetchScheduledItems(userIdentifier))

    scheduledItemIDsNeedingDetails(getState().scheduling).forEach((itemID) =>
      dispatch(fetchScheduledItemDetails(userIdentifier, itemID))
    )
  }

export const scheduleItemAndRefresh =
  (
    userIdentifier: UserIdentifier,
    itemID: number,
    data: ScheduleItemRequest
  ): AppThunkAction =>
  async (dispatch) => {
    await dispatch(scheduleItem(userIdentifier, itemID, data))
    dispatch(fetchScheduledItems(userIdentifier))
  }
