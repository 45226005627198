import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepContent from '@material-ui/core/StepContent'
import StepLabel, { StepLabelProps } from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'

import { FullOrderStatus, OrderStatusTypes } from 'store/orders/types'
import { colors } from 'styles'
import GhostLink from 'components/shared/GhostLink'
import ProgressDots from 'components/shared/ProgressDots'
import PlainLinkButton from 'components/shared/PlainLinkButton'
import NPSContainer from 'components/Rating/NPSContainer'
import OrderWidgetAccordion from './Accordion'
import SvgCheckCircle from 'assets/images/check-circle.svg'
import SvgGreyCheckCircle from 'assets/images/grey-check-circle.svg'

interface Props {
  orderID: number
  statuses: FullOrderStatus
  expertName: string | null
  canRate: boolean
}

const tKey = 'components.OrderProgress'

function showCTA(status: OrderStatusTypes, canRate: boolean) {
  return status !== OrderStatusTypes.Completed || canRate
}

// This will contain the order progress accordion
const OrderWidgetProgress: React.FC<Props> = ({
  statuses,
  expertName,
  orderID,
  canRate,
}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const { active: activeStatuses, completed: completedStatuses } =
    filterStatuses(statuses)
  let interviewSessionCounter = 1

  return (
    <>
      <OrderWidgetAccordion
        title={t(`${tKey}.title`)}
        titleAdornment={
          <ProgressDots
            steps={completedStatuses.length + activeStatuses.length}
            activeStep={Math.max(completedStatuses.length, 1)}
          />
        }
      >
        <Stepper
          orientation="vertical"
          activeStep={statuses.completed.length - 1}
          className={classes.stepper}
          connector={<CustomStepConnector />}
        >
          {completedStatuses.map(({ status, ...item }, idx) => (
            <CustomStep key={`${status}-${item.purchase_item_id}`}>
              <CustomStepLabel completed={true}>
                {t(`${tKey}.statuses.${status}.completedTitle`, {
                  expertName,
                  interviewSessionNumber:
                    status === OrderStatusTypes.Interview
                      ? interviewSessionCounter++
                      : 0,
                })}
              </CustomStepLabel>
              {idx === statuses.completed.length - 1 &&
                i18n.exists(`${tKey}.statuses.${status}.activeCopy`, {
                  expertName,
                }) &&
                showCTA(status, canRate) && (
                  <CustomStepContent>
                    <Container className={classes.stepContent}>
                      <Typography variant="body2" className={classes.stepText}>
                        {t(`${tKey}.statuses.${status}.activeCopy`, {
                          expertName,
                        })}
                      </Typography>
                      {t(`${tKey}.statuses.${status}.activeCTA`, {
                        defaultValue: false,
                      }) && (
                        <Typography variant="body2">
                          <Trans
                            i18nKey={`${tKey}.statuses.${status}.activeCTA`}
                            values={{ expertName }}
                            components={{
                              messageLink: (
                                <GhostLink
                                  to={t('routes.messages')}
                                  className={classes.cta}
                                />
                              ),
                              feedbackModal: (
                                <PlainLinkButton
                                  onClick={() => setShowFeedbackModal(true)}
                                />
                              ),
                            }}
                          />
                        </Typography>
                      )}
                    </Container>
                  </CustomStepContent>
                )}
            </CustomStep>
          ))}
          {activeStatuses.map(({ status, ...item }) => (
            <CustomStep key={`${status}-${item.purchase_item_id}`}>
              <CustomStepLabel>
                {t(`${tKey}.statuses.${status}.futureTitle`, {
                  interviewSessionNumber:
                    status === OrderStatusTypes.Interview
                      ? interviewSessionCounter++
                      : 0,
                })}
              </CustomStepLabel>
            </CustomStep>
          ))}
        </Stepper>
      </OrderWidgetAccordion>
      {showFeedbackModal && (
        <NPSContainer
          orderID={orderID}
          onClose={() => setShowFeedbackModal(false)}
        />
      )}
    </>
  )
}

// Why filter statuses here? We introduced a new status, RevisionPeriodStart,
// that goes to completed when the FirstDraft is marked as completed. We want to
// show that status because it's easier for the customer to understand what they
// need to do. Because we didn't backfill orders to have a RevisionPeriodStart,
// we must still show it for those orders, even if they are completed.
const filterStatuses = (statuses: FullOrderStatus): FullOrderStatus => {
  const hasRevisionPeriodStart =
    statuses.active.find(
      ({ status }) => status === OrderStatusTypes.RevisionPeriodStart
    ) ||
    statuses.completed.find(
      ({ status }) => status === OrderStatusTypes.RevisionPeriodStart
    )

  if (!hasRevisionPeriodStart) {
    return statuses
  }

  // If we have a RevisionPeriodStart status, filter out the RevisionPeriod
  // status so that the timeline makes sense.
  return {
    active: statuses.active.filter(
      ({ status }) => status !== OrderStatusTypes.RevisionPeriod
    ),
    completed: statuses.completed.filter(
      ({ status }) => status !== OrderStatusTypes.RevisionPeriod
    ),
  }
}

const CustomStep = withStyles((theme) => ({
  root: {
    color: colors.blue[500],
    marginTop: 0,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  completed: {
    color: colors.blue[500],
  },
}))(Step)

const CustomStepLabel = withStyles((theme) => ({
  root: {
    flexDirection: 'row',
    textAlign: 'left',
    '&.Mui-disabled, &.Mui-disabled .MuiStepLabel-label': {
      color: colors.gray.a500,
    },
  },
  iconContainer: {
    width: '25px',
    height: '24px',
    fontSize: '1.5em',
    marginRight: '1em',

    '& > img': {
      width: '25px',
      height: '24px',
    },

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },

  label: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1.4em',
    },
  },

  labelContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}))((props: StepLabelProps & { completed?: boolean }) => (
  <StepLabel
    StepIconProps={{
      ...(props.StepIconProps || {}),
      icon: (
        <img
          alt="checkmark icon"
          src={props.completed ? SvgCheckCircle : SvgGreyCheckCircle}
        />
      ),
    }}
    {...props}
  />
))

const CustomStepConnector = withStyles((theme) => ({
  root: {
    display: 'block',
    borderColor: theme.palette.divider,
    marginLeft: '.65em',
  },
  vertical: {
    padding: 0,
  },
  lineVertical: {
    borderLeftStyle: 'solid',
    borderLeftWidth: '.25em',
  },
  completed: {
    borderColor: theme.palette.divider,
  },
  active: {
    borderColor: theme.palette.divider,
  },
}))(StepConnector)

const CustomStepContent = withStyles((theme) => ({
  root: {
    marginTop: 0,
    borderLeft: `.25em solid ${theme.palette.divider}`,
    marginLeft: '.65em',
    width: '100%',
  },
  last: {
    borderLeft: 0,
  },
}))(StepContent)

const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      borderBottomWidth: 0,
      '&:before': {
        height: 0,
      },
    },
    accordionSummary: {
      paddingLeft: 0,
    },
    accordionDetails: {
      paddingLeft: 0,
    },
    title: {
      color: theme.palette.secondary.main,
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    expandIconWrapper: {
      fontSize: '2em',
      color: theme.palette.primary.main,
    },
    stepper: {
      marginTop: 0,
      width: '100%',
    },
    stepContent: {
      backgroundColor: colors.blue[100],
      borderRadius: '6px',
      width: '100%',
      marginLeft: '1em',
      paddingTop: '1em',
      paddingBottom: '1em',
      marginTop: '1.25em',
      fontSize: '0.8em',
    },
    stepText: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
      lineHeight: '2em',
    },
    cta: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      lineHeight: '2em',
    },
  })
)

export default OrderWidgetProgress
