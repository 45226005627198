import { produce } from 'immer'
import * as stateUtils from '@talentinc/state-utils'

import { RatingActions, RatingAction } from './actions'
import { initialState, RatingState } from './types'

export default function ratingsReducer(
  state: RatingState = initialState,
  action: RatingAction
): RatingState {
  return produce(state, (draft) => {
    switch (action.type) {
      case RatingActions.FETCH_ORDER_NPS:
      case RatingActions.SUBMIT_ORDER_NPS: {
        const { orderID, type } = action
        draft.meta[type][orderID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }

      case RatingActions.FETCH_ORDER_NPS_FAIL:
      case RatingActions.SUBMIT_ORDER_NPS_FAIL: {
        const { orderID, type } = action.meta.previousAction
        draft.meta[type][orderID] = stateUtils.setErrorState(
          state.meta[type][orderID],
          action.error
        )
        break
      }

      case RatingActions.FETCH_ORDER_NPS_SUCCESS: {
        const { type, orderID } = action.meta.previousAction
        draft.nps[orderID] = action.payload.data
        draft.meta[type][orderID] = stateUtils.setSuccessState(
          state.meta[type][orderID]
        )
        break
      }

      case RatingActions.SUBMIT_ORDER_NPS_SUCCESS: {
        const { type, orderID } = action.meta.previousAction
        draft.nps[orderID] = {
          can_submit: false,
          results: [action.payload.data],
        }

        draft.meta[type][orderID] = stateUtils.setSuccessState(
          state.meta[type][orderID]
        )
      }
    }
  })
}
