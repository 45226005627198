import React from 'react'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import { colors } from 'styles/index'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import MessageAvatar from 'components/Messaging/MessageAvatar'
import Header from 'components/Messaging/Header'
import { Item } from 'store/items/types'
import { ClientIDWithStatus } from 'store/orders/types'
import { getAvatarInitials } from '../../utils/formatting'

interface Props {
  dueDate: any
  items: Item[]
  open: boolean
  packageName: string
  product: string
  setInfoOpen: any
  supportEmail: string
  supportPhone: string
  writer: any
  prevClientID: ClientIDWithStatus | null
  nextClientID: ClientIDWithStatus | null
  isInterviewPrep?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingBottom: '1.5em',
      paddingTop: '1.5em',
    },
    h1: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7em',
      },
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      color: theme.palette.secondary.main,
      marginTop: '1.25em',
    },
    h5Dark: {
      color: theme.palette.primary.dark,
    },
    h5Light: {
      fontWeight: 400,
    },
    p: {
      marginTop: '1.25em',
    },
    paper: {
      borderRadius: '0',
      borderBottom: 'none',
      borderLeft: '0',
      borderTop: 'none',
      height: '100vh',
      marginTop: '-5px',
      overflow: 'scroll',
      position: 'fixed',
      padding: '0',
      width: 'calc(100vw - 55.9em)',
      [theme.breakpoints.down('md')]: {
        width: '24.1em',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-4.5px',
        marginTop: '.5px',
        width: '100vw',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 'calc(100vw - 55.9em)',
        width: 'calc((100vw - 1280px)/2 + 1280px - 41.6em)',
      },
    },
    popper: {
      zIndex: 100,
    },
    supportText: {
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    thickBorder: {
      borderBottom: `3px solid ${colors.gray[600]}`,
    },
    thinBorder: {
      borderBottom: `1px solid ${colors.gray[600]}`,
    },
    writerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1.3em',
    },
  })
)

const InfoPopper: React.FC<Props> = ({
  dueDate,
  items,
  open,
  packageName,
  product,
  setInfoOpen,
  supportEmail,
  supportPhone,
  writer,
  prevClientID,
  nextClientID,
  isInterviewPrep = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const writerDesc = isInterviewPrep
    ? t('components.Messaging.expert')
    : t('components.Messaging.writer')
  const infoKey = isInterviewPrep ? 'interviewPrep' : 'default'

  // Get initials for avatar
  const avatarInitials =
    writer && writer.display_name ? getAvatarInitials(writer.display_name) : ''

  // Create copy for items
  let itemsCopy = ''
  items.map((item: Item, index: number) => {
    if (index === 0) {
      itemsCopy = itemsCopy.concat(item.name)
    } else {
      itemsCopy = itemsCopy.concat(` · ${item.name}`)
    }
    return null
  })

  return (
    <Popper
      anchorEl={document.getElementById('message-container')}
      className={classes.popper}
      open={open}
      placement={isMobile ? 'top-start' : 'bottom-start'}
    >
      <Paper className={classes.paper} variant="outlined">
        <Header
          open={open}
          subHeaderText={packageName}
          popper={true}
          headerText={product}
          setInfoOpen={setInfoOpen}
          prevClientID={prevClientID}
          nextClientID={nextClientID}
          isCompletedView={false}
        />
        <Container>
          <Container
            className={clsx(classes.container, classes.thickBorder)}
            disableGutters
          >
            <Typography className={classes.h1} variant="h1">
              {packageName}
            </Typography>
            <Typography className={classes.h4} variant="h4">
              {dueDate
                ? t('components.Messaging.revision', {
                    date: dateFormat(new Date(dueDate), 'L/d/y'),
                  })
                : ''}
            </Typography>
          </Container>
          <Container
            className={clsx(classes.container, classes.thinBorder)}
            disableGutters
          >
            <Typography className={classes.h3} variant="h3">
              {t('components.Messaging.orderDetails')}
            </Typography>
            <Container className={classes.writerContainer} disableGutters>
              <Container disableGutters>
                <Typography className={classes.h5Dark} variant="h5">
                  {writerDesc}
                </Typography>
                <Typography className={classes.h5Light} variant="h5">
                  {writer && writer.display_name ? writer.display_name : ''}
                </Typography>
              </Container>
              <MessageAvatar avatarInitials={avatarInitials} />
            </Container>
          </Container>
          <Container
            className={clsx(classes.container, classes.thickBorder)}
            disableGutters
          >
            <Typography className={classes.h5Dark} variant="h5">
              {t('components.Messaging.items')}
            </Typography>
            <Typography className={classes.h5Light} variant="h5">
              {itemsCopy}
            </Typography>
          </Container>
          <Container
            className={clsx(classes.container, classes.thickBorder)}
            disableGutters
          >
            <Typography className={classes.h3} variant="h3">
              {t('components.Messaging.howTo.header', { expert: writerDesc })}
            </Typography>
            {!isInterviewPrep && (
              <Typography
                className={clsx(classes.h5Light, classes.p)}
                variant="h5"
                component="p"
              >
                {t(`components.Messaging.howTo.steps.${infoKey}.howToText1`)}
              </Typography>
            )}
            <Typography
              className={clsx(classes.h5Light, classes.p)}
              variant="h5"
              component="p"
            >
              {t(`components.Messaging.howTo.steps.${infoKey}.howToText2`)}
            </Typography>
            <Typography
              className={clsx(classes.h5Light, classes.p)}
              variant="h5"
              component="p"
            >
              {t(`components.Messaging.howTo.steps.${infoKey}.howToText3`)}
            </Typography>
            <Typography
              className={clsx(classes.h5Light, classes.p)}
              variant="h5"
              component="p"
            >
              {t(`components.Messaging.howTo.steps.${infoKey}.howToText4`)}
            </Typography>
          </Container>
          <Container className={classes.container} disableGutters>
            <Typography className={classes.h3} variant="h3">
              {t('components.Messaging.support')}
            </Typography>
            <Typography
              className={clsx(classes.h5Light, classes.p, classes.supportText)}
              dangerouslySetInnerHTML={{
                __html: t('components.Messaging.supportText', {
                  email: supportEmail,
                  phone: supportPhone,
                }),
              }}
              component="p"
              variant="h5"
            />
          </Container>
        </Container>
      </Paper>
    </Popper>
  )
}

export default InfoPopper
