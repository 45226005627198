import React from 'react'
import { useParams } from 'react-router-dom'

import MinimalPageShell from 'pages/PageShell/Minimal'
import AuthenticatedCallPage from './Authenticated'
import UnauthenticatedCallPage from './Unauthenticated'
import useAuth0 from 'components/Auth/useAuth0'

const CallPage: React.FC = () => {
  const { omsItemID: omsItemIDStr } = useParams<{ omsItemID: string }>()
  const omsItemID = parseInt(omsItemIDStr)
  const { loggedIn } = useAuth0()

  return (
    <MinimalPageShell>
      {loggedIn ? (
        <AuthenticatedCallPage omsItemID={omsItemID} />
      ) : (
        <UnauthenticatedCallPage omsItemID={omsItemID} />
      )}
    </MinimalPageShell>
  )
}

export default CallPage
