import {
  HashMap,
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'

import { MessageActions } from './actions'
import { File } from 'store/files/types'

export enum ContextTypes {
  Client = 'client',
}

export interface ExpertInfo {
  id: number
  signature: string
}

export interface Message {
  id: number
  sender: string
  to: string
  body: string
  message_text: string
  context: ContextTypes
  context_id: number
  subject: string
  variables: Record<string, any>
  attachment_ids: number[]
  outbound: boolean
  expert: ExpertInfo | null
  sent_by: number | null
  sent: Date
  read_at: Date | null
  message_id: string | null
  reply_message_id: string | null
}

export interface MessageWithAttachments extends Message {
  attachments: File[]
}

export interface MessageStatus {
  id: number
  message_id: number
  start_at: Date
  end_at: Date | null
  status: string
  user_id: number | null
  created_at: Date
  updated_at: Date
}

export interface SendMessagePayload {
  expert_hub_client_id: number
  subject: string
  body: string
  reply_message_id: string
  portal_attachment_ids: number[]
}

export interface MessageState {
  messages: HashMap<Message>
  byExpertID: HashMap<Set<number>>
  byContextID: HashMap<Set<number>>
  byOutbound: Set<number>
  meta: {
    [MessageActions.FETCH_MESSAGES_FOR_USER]: LoadingLoadedErrorState
    [MessageActions.SEND_MESSAGE]: LoadingLoadedErrorState
    // The ID of this is the message ID
    [MessageActions.MARK_MESSAGE_AS_READ]: HashMap<LoadingLoadedErrorState>
  }
}

export const initialState: MessageState = Object.freeze({
  messages: {},
  byExpertID: {},
  byContextID: {},
  byOutbound: new Set<number>(),
  meta: {
    [MessageActions.FETCH_MESSAGES_FOR_USER]: {
      ...initialLoadedLoadingErrorState,
    },
    [MessageActions.SEND_MESSAGE]: {
      ...initialLoadedLoadingErrorState,
    },
    [MessageActions.MARK_MESSAGE_AS_READ]: {},
  },
})
