import { produce } from 'immer'
import * as stateUtils from '@talentinc/state-utils'

import { MessageState, initialState } from './types'
import { MessagesAction, MessageActions } from './actions'

export default function messagesReducer(
  state: MessageState = initialState,
  action: MessagesAction
): MessageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case MessageActions.SEND_MESSAGE:
      case MessageActions.FETCH_MESSAGES_FOR_USER:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break

      case MessageActions.MARK_MESSAGE_AS_READ:
        draft.meta[action.type][action.messageID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break

      case MessageActions.MARK_MESSAGE_AS_READ_FAIL: {
        const previousAction = action.meta.previousAction
        draft.meta[previousAction.type][previousAction.messageID] =
          stateUtils.setErrorState(
            state.meta[previousAction.type][previousAction.messageID],
            action.error
          )
        break
      }

      case MessageActions.SEND_MESSAGE_FAIL:
      case MessageActions.FETCH_MESSAGES_FOR_USER_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }

      case MessageActions.FETCH_MESSAGES_FOR_USER_SUCCESS: {
        action.payload.data.forEach((message) => {
          draft.messages[message.id] = message
          if (message.expert !== null) {
            if (!draft.byExpertID[message.expert.id]) {
              draft.byExpertID[message.expert.id] = new Set<number>()
            }
            draft.byExpertID[message.expert.id].add(message.id)
          }
          if (message.context_id !== null) {
            if (!draft.byContextID[message.context_id]) {
              draft.byContextID[message.context_id] = new Set<number>()
            }
            draft.byContextID[message.context_id].add(message.id)
          }
          if (message.outbound === true) {
            if (!draft.byOutbound) {
              draft.byOutbound = new Set<number>()
            }
            draft.byOutbound.add(message.id)
          }
        })

        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        break
      }

      case MessageActions.SEND_MESSAGE_SUCCESS: {
        const message = action.payload.data

        draft.meta.SEND_MESSAGE = stateUtils.setSuccessState(
          state.meta.SEND_MESSAGE
        )
        draft.messages[action.payload.data.id] = action.payload.data
        if (!draft.byContextID[message.context_id]) {
          draft.byContextID[message.context_id] = new Set<number>()
        }
        draft.byContextID[message.context_id].add(message.id)
        draft.byOutbound.add(message.id)
        break
      }

      case MessageActions.MARK_MESSAGE_AS_READ_SUCCESS: {
        const { previousAction } = action.meta

        draft.meta[previousAction.type][previousAction.messageID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.messageID]
          )
        draft.messages[action.payload.data.message_id].read_at =
          action.payload.data.start_at
        break
      }
    }
  })
}
