import * as ram from '@talentinc/redux-axios-middleware'

import { UserIdentifier } from 'store/user/types'
import { ExperimentCase, ExperimentQuery } from './types'
import { queryToSearchBody } from './selectors'

export enum ExperimentActions {
  FETCH_EXPERIMENTS_FOR_PORTAL = 'FETCH_EXPERIMENTS_FOR_PORTAL',
  FETCH_EXPERIMENTS_FOR_PORTAL_SUCCESS = 'FETCH_EXPERIMENTS_FOR_PORTAL_SUCCESS',
  FETCH_EXPERIMENTS_FOR_PORTAL_FAIL = 'FETCH_EXPERIMENTS_FOR_PORTAL_FAIL',
  FETCH_EXPERIMENTS_FOR_QUERY = 'FETCH_EXPERIMENTS_FOR_QUERY',
  FETCH_EXPERIMENTS_FOR_QUERY_SUCCESS = 'FETCH_EXPERIMENTS_FOR_QUERY_SUCCESS',
  FETCH_EXPERIMENTS_FOR_QUERY_FAIL = 'FETCH_EXPERIMENTS_FOR_QUERY_FAIL',
}

interface FetchExperimentsForPortal extends ram.AxiosMiddlewareActionCreator {
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL
}

interface FetchExperimentsForPortalSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    ExperimentCase[],
    FetchExperimentsForPortal
  > {
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL_SUCCESS
}

interface FetchExperimentsForPortalFail
  extends ram.AxiosMiddlewareActionFail<FetchExperimentsForPortal> {
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL_FAIL
}

export const fetchExperimentsForPortal = (
  userIdentifier: UserIdentifier = 'me'
): FetchExperimentsForPortal => ({
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/experiments/application/portal`,
      method: 'POST',
    },
  },
})

interface FetchExperimentsForQuery extends ram.AxiosMiddlewareActionCreator {
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY
  query: string
}

interface FetchExperimentsForQuerySuccess
  extends ram.AxiosMiddlewareActionSuccess<
    ExperimentCase[],
    FetchExperimentsForQuery
  > {
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY_SUCCESS
}

interface FetchExperimentsForQueryFail
  extends ram.AxiosMiddlewareActionFail<FetchExperimentsForQuery> {
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY_FAIL
}

export const fetchExperimentsForQuery = (
  userIdentifier: UserIdentifier = 'me',
  query: ExperimentQuery
): FetchExperimentsForQuery => ({
  type: ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY,
  query: JSON.stringify(queryToSearchBody(query)),
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/experiments`,
      method: 'POST',
      data: queryToSearchBody(query),
    },
  },
})

export type ExperimentAction =
  | FetchExperimentsForPortal
  | FetchExperimentsForPortalSuccess
  | FetchExperimentsForPortalFail
  | FetchExperimentsForQuery
  | FetchExperimentsForQuerySuccess
  | FetchExperimentsForQueryFail
