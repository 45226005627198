import {
  OrdersState,
  OrderStatusTypes,
  OrderTypes,
  OrderWidgetDisplayProps,
} from './types'
import {
  allOrders,
  expertsForOrder,
  upcomingVoiceDrivenItems,
  reassignedOrderIDs,
  completedOrders,
} from './selectors'
import {
  emailFulfilledTypes,
  ItemsState,
  phoneTypes,
  addOnTypes,
} from 'store/items/types'
import { itemsForOrder, documentItemsForOrderID } from 'store/items/selectors'
import { canRateOrder } from 'store/ratings/selectors'
import { RatingState } from 'store/ratings/types'

export const orderWidgetDisplayProps = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  ratingState: RatingState
): OrderWidgetDisplayProps[] =>
  ordersForWidget(ordersState, itemsState).map((order) => ({
    ...order,
    items: itemsForOrder(itemsState, order.id),
    experts: expertsForOrder(ordersState, order.id),
    upcomingVoiceDrivenItems: upcomingVoiceDrivenItems(
      ordersState,
      itemsState,
      order.id
    ),
    canRate: canRateOrder(ratingState, order.id),
    orderType: orderTypeByID(itemsState, order.id),
    isDueForRefresh: isDueForRefresh(ordersState, order.id),
  }))

export const ordersForWidget = (
  ordersState: OrdersState,
  itemsState: ItemsState
) => {
  const invalidOrderIDs = reassignedOrderIDs(ordersState)
  const completedOrderIDs = completedOrders(ordersState).map(
    (order) => order.id
  )

  return (
    allOrders(ordersState)
      // No reassignments
      .filter((order) => !invalidOrderIDs.includes(order.id))
      // Orders with the most amount of document items first, tie breaking on
      // the newest orders first.
      .sort((a, b) => {
        const aCompleted = completedOrderIDs.includes(a.id)
        const aItemCount = documentItemsForOrderID(
          a.id,
          itemsState,
          ordersState
        ).length

        const bCompleted = completedOrderIDs.includes(b.id)
        const bItemCount = documentItemsForOrderID(
          b.id,
          itemsState,
          ordersState
        ).length

        if (aCompleted && bCompleted) {
          if (aItemCount === bItemCount) {
            return b.id - a.id
          }

          return bItemCount - aItemCount
        }

        if (aCompleted) {
          return 1
        }

        if (bCompleted) {
          return -1
        }

        if (aItemCount === bItemCount) {
          return b.id - a.id
        }

        return bItemCount - aItemCount
      })
  )
}

export const isEmailFulfilledOrder = (
  itemState: ItemsState,
  orderID: number
) => {
  const allItems = itemsForOrder(itemState, orderID)
  return (
    allItems.length === 1 &&
    !allItems[0].requires_document &&
    emailFulfilledTypes.has(allItems[0].item_type)
  )
}

export const isAddonOrder = (itemState: ItemsState, orderID: number) => {
  const allItems = itemsForOrder(itemState, orderID)

  const isAddOn: boolean =
    allItems.length === 1 && addOnTypes.has(allItems[0].item_type)
  return isAddOn
}

export const isVoiceOrder = (itemState: ItemsState, orderID: number): boolean =>
  itemsForOrder(itemState, orderID).filter((i) => phoneTypes.has(i.item_type))
    .length === itemsForOrder(itemState, orderID).length

export const orderTypeByID = (itemState: ItemsState, orderID: number) => {
  if (isEmailFulfilledOrder(itemState, orderID)) return OrderTypes.EmailOnly
  else if (isAddonOrder(itemState, orderID)) return OrderTypes.AddOnOnly
  else if (isVoiceOrder(itemState, orderID)) return OrderTypes.VoiceOnly
  else return OrderTypes.Standard
}

//returns true if the order has been closed more than 366 days ago based on order status
export const isDueForRefresh = (state: OrdersState, orderID: number) => {
  const order = state.orders[orderID]
  if (!order) return false
  const completedStatus = order.status.completed.find(
    (s) => s.status === OrderStatusTypes.Completed
  )
  if (!completedStatus) return false
  const oneYear = 1000 * 60 * 60 * 24 * 30

  //The day the world changed: Products's definition of what is an order was deployed to production
  const ordersLaunchDay = new Date('06/26/2020')

  const daysAgo365 = new Date(new Date().getTime() - oneYear)
  let orderClosedDate = completedStatus.completed_at.getTime()

  //All order status before ordersLaunchDay is generated on the fly and so may not have the correct dates
  //Therefore the order.closed_at/order.created_at should be used
  if (order.created_at.getTime() < ordersLaunchDay.getTime()) {
    orderClosedDate = order.closed_at?.getTime() || order.created_at.getTime()
  }

  return completedStatus.completed_at && orderClosedDate < daysAgo365.getTime()
}
