import React from 'react'

import LiteHeader from 'components/shared/LiteHeader'
import LiteFooter from 'components/shared/LiteFooter'
import ScrollToTop from 'components/shared/ScrollToTop'

interface Props {
  footer?: boolean
  logoGoesToPortal?: boolean
}

const UnauthenticatedPageShell: React.FC<Props> = ({
  children,
  footer = true,
  logoGoesToPortal = true,
}) => {
  return (
    <>
      <ScrollToTop />
      <LiteHeader logoGoesToPortal={logoGoesToPortal} />
      {children}
      {footer && <LiteFooter />}
    </>
  )
}

export default UnauthenticatedPageShell
