import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import { User } from 'store/user/types'
import PersonalInfoItem from './PersonalInfoItem'
import { OnUpdateUser } from './types'
import useFeatureFlags from 'hooks/useFeatureFlags'
import LanguageSelector from './LanguageSelector'

interface Props {
  user: Partial<User>
  onUpdateUser: OnUpdateUser
  isUpdatingUser?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    h2: {
      padding: '1.5em 1.6em 1.3em',
    },
    list: {
      padding: '0 2em',
    },
  })
)

const PersonalInfo: React.FC<Props> = ({
  user,
  onUpdateUser,
  isUpdatingUser,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { emailUpdating: emailUpdatingFeatureFlag } = useFeatureFlags()

  return (
    <>
      <Typography className={classes.h2} variant="h2">
        {t('pages.Account.personalInfo')}
      </Typography>
      <Divider />
      <List className={classes.list}>
        <PersonalInfoItem
          fieldName="name"
          formFields={[
            {
              label: 'first_name',
              value: user.first_name,
            },
            {
              label: 'last_name',
              value: user.last_name,
            },
          ]}
          displayValue={`${user.first_name} ${user.last_name}`}
          onUpdateUser={onUpdateUser}
          isUpdatingUser={isUpdatingUser}
        />
        <PersonalInfoItem
          fieldName="email"
          formFields={
            emailUpdatingFeatureFlag
              ? [
                  {
                    label: 'email',
                    value: user.email,
                    type: 'email',
                  },
                ]
              : []
          }
          displayValue={user.email}
          onUpdateUser={onUpdateUser}
          isUpdatingUser={isUpdatingUser}
        />
        <PersonalInfoItem
          fieldName="phone"
          formFields={[
            {
              label: 'phone',
              value: user.phone,
              type: 'tel',
            },
          ]}
          displayValue={user.phone}
          onUpdateUser={onUpdateUser}
          isUpdatingUser={isUpdatingUser}
        />
        {/* This field isn't populated anywhere and is ugly when it's empty
        <PersonalInfoItem
          fieldName="address"
          formFields={[]}
          displayValue={`${user.address_line_1} ${user.address_line_2} ${user.city}, ${user.state} ${user.postal_code}, ${user.country}`}
          onUpdateUser={onUpdateUser}
        />
        */}
        <PersonalInfoItem
          fieldName="timezone"
          formFields={[]}
          displayValue={user.timezone}
          onUpdateUser={onUpdateUser}
          isUpdatingUser={isUpdatingUser}
        />
        <LanguageSelector />
      </List>
    </>
  )
}

export default PersonalInfo
