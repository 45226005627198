import {
  HashMap,
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'

export interface ExperimentCase {
  id: number
  experiment_id: number
  experiment_label: string
  case_label: string
  descriptor: string
  weight: number
  active: boolean
  created_at: Date | null
  updated_at: Date | null
}

interface ExperimentQueryByID {
  experimentID: number
  caseID: number
}

interface ExperimentQueryByLabel {
  experimentLabel: string
  caseLabel: string
}

interface ExperimentQueryByDescriptor {
  experimentLabel: string
  caseDescriptor: string
}

export type ExperimentQuery =
  | ExperimentQueryByID
  | ExperimentQueryByLabel
  | ExperimentQueryByDescriptor

export interface ExperimentsState {
  experiments: HashMap<ExperimentCase>
  byLabel: HashMap<number>
  meta: LoadingLoadedErrorState
  metaByQuery: HashMap<LoadingLoadedErrorState>
}

export interface ExperimentSearch {
  ids?: number[]
  labels?: string[]
  applications?: string[]
  active?: boolean
}

export const initialState: ExperimentsState = Object.freeze({
  experiments: {},
  byLabel: {},
  meta: { ...initialLoadedLoadingErrorState },
  metaByQuery: {},
})
