import Reschedule from 'components/Modals/variants/Reschedule'
import React, { useCallback, useState } from 'react'
import ScheduleModal from './ScheduleModal'

interface Props {
  omsItemID: number
  onClose: () => void
}

const RescheduleModalContainer: React.FC<Props> = ({ omsItemID, onClose }) => {
  const [availabilityOpen, setAvailabilityOpen] = useState(false)
  const [scheduleModalOpen, setScheduleModalOpen] = useState(true)

  const openAvailabilityModal = useCallback(async () => {
    setScheduleModalOpen(false)
    setAvailabilityOpen(true)
  }, [])

  const closeAvailabilityModal = useCallback(async () => {
    setAvailabilityOpen(false)
    onClose()
  }, [onClose])

  return (
    <>
      {scheduleModalOpen && (
        <Reschedule
          onClose={() => onClose()}
          callToActionProps={{
            onClick: openAvailabilityModal,
          }}
        />
      )}

      <ScheduleModal
        open={availabilityOpen}
        handleClose={closeAvailabilityModal}
        omsItemID={omsItemID}
      />
    </>
  )
}

export default RescheduleModalContainer
