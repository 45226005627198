import { initialState, TransactionsState } from './types'
import { TransactionAction, TransactionsActions } from './actions'
import * as stateUtils from '@talentinc/state-utils'
import { produce } from 'immer'

export default function transactionsReducer(
  state: TransactionsState = initialState,
  action: TransactionAction
): TransactionsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case TransactionsActions.FETCH_USER_TRANSACTIONS:
        draft.meta[action.type] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      case TransactionsActions.FETCH_USER_TRANSACTIONS_SUCCESS:
        draft.meta[action.meta.previousAction.type] =
          stateUtils.setSuccessState(
            state.meta[action.meta.previousAction.type]
          )
        draft.userTransactions = action.payload.data
        break
      case TransactionsActions.FETCH_USER_TRANSACTIONS_FAIL:
        draft.meta[action.meta.previousAction.type] = stateUtils.setErrorState(
          state.meta[action.meta.previousAction.type],
          action.error
        )
        break
    }
  })
}
