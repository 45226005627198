import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { differenceInDays } from 'date-fns'
import Chip from '@material-ui/core/Chip'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import SyncIcon from '@material-ui/icons/Sync'
import PhoneIcon from '@material-ui/icons/PhoneInTalk'

import { ItemStatusTypes } from 'store/items/types'
import SvgFeedback from 'assets/images/feedback.svg'
import Svg1Days from 'assets/images/1-days.svg'
import Svg2Days from 'assets/images/2-days.svg'
import Svg3Days from 'assets/images/3-days.svg'
import Svg4Days from 'assets/images/4-days.svg'
import Svg5Days from 'assets/images/5-days.svg'
import Svg6Days from 'assets/images/6-days.svg'
import Svg7Days from 'assets/images/7-days.svg'

interface Props {
  deadline: any
  status: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chipApproval: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    chipHurry: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
    },
    chipIconHurry: {
      color: theme.palette.warning.main,
    },
    chipScale: {
      transform: 'scale(1.4)',
    },
  })
)

const ProductChip: React.FC<Props> = ({ deadline, status }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  let chipClass
  let chipIconClass = classes.chipScale
  let chipIcon = <SyncIcon />

  const revisionIcons = [
    Svg1Days,
    Svg2Days,
    Svg3Days,
    Svg4Days,
    Svg5Days,
    Svg6Days,
    Svg7Days,
  ]

  // @TODO This might need to take into account minutes/hours
  const timeDifference = Math.max(differenceInDays(deadline, new Date()), 0)

  switch (status) {
    case 'approval':
      chipClass = classes.chipApproval
      chipIcon = <RotateRightIcon />
      break
    case ItemStatusTypes.AwaitingReply:
    case 'feedback':
      chipClass = classes.chipHurry
      chipIconClass = ''
      chipIcon = <img src={SvgFeedback} alt="Feedback icon" />
      break
    case ItemStatusTypes.RevisionMode:
      chipClass = classes.chipHurry
      chipIconClass = ''
      chipIcon = (
        <img
          src={revisionIcons[Math.max(timeDifference - 1, 0)]}
          alt={`${timeDifference} days left icon`}
        />
      )
      break
    case ItemStatusTypes.ConductCall:
    case ItemStatusTypes.ScheduleInterview:
    case ItemStatusTypes.ConductInterview:
    case ItemStatusTypes.AwaitingPhoneCall:
    case ItemStatusTypes.SchedulePhone:
      chipIcon = <PhoneIcon />
      break
    case ItemStatusTypes.Complete:
      return null
  }

  return (
    <Chip
      classes={{ icon: chipIconClass }}
      className={chipClass}
      icon={chipIcon}
      label={t(`status.${status}`, {
        count: timeDifference,
        daysLeft: timeDifference,
      })}
    />
  )
}

export default ProductChip
