import { produce } from 'immer'
import {
  setStartState,
  setSuccessState,
  setErrorState,
} from '@talentinc/state-utils'

import { FilesState, initialState } from './types'
import { FileActions, FileAction } from './actions'
import { MessageActions } from 'store/messages/actions'

export default function filesReducer(
  state: FilesState = initialState,
  action: FileAction
): FilesState {
  return produce(state, (draft) => {
    switch (action.type) {
      case FileActions.FETCH_FILES_FOR_USER:
        draft.meta[action.type] = setStartState(state.meta[action.type])
        break

      case FileActions.FETCH_FILES_FOR_USER_SUCCESS: {
        const files = action.payload.data

        files.forEach((f) => {
          draft.files[f.id] = f

          if (f.order_item_id) {
            if (!draft.byOrderItemID[f.order_item_id]) {
              draft.byOrderItemID[f.order_item_id] = new Set<number>()
            }

            draft.byOrderItemID[f.order_item_id].add(f.id)
          }

          if (f.order_id) {
            if (!draft.byOrderID[f.order_id]) {
              draft.byOrderID[f.order_id] = new Set<number>()
            }

            draft.byOrderID[f.order_id].add(f.id)
          }

          if (f.context === 'client') {
            if (!draft.byClientID[f.context_id]) {
              draft.byClientID[f.context_id] = new Set<number>()
            }
            draft.byClientID[f.context_id].add(f.id)
          }
        })

        draft.meta[action.meta.previousAction.type] = setSuccessState(
          state.meta[action.meta.previousAction.type]
        )
        break
      }

      case FileActions.FETCH_FILES_FOR_USER_FAIL:
        draft.meta[action.meta.previousAction.type] = setErrorState(
          state.meta[action.meta.previousAction.type],
          action.error
        )
        break

      case MessageActions.FETCH_MESSAGES_FOR_USER_SUCCESS: {
        action.payload.data.forEach((message) => {
          if (message.attachment_ids.length > 0) {
            draft.byMessageID[message.id] = new Set<number>(
              message.attachment_ids
            )
          }
        })
        break
      }

      case MessageActions.SEND_MESSAGE_SUCCESS: {
        const message = action.payload.data

        if (message.attachment_ids.length > 0) {
          draft.byMessageID[message.id] = new Set<number>(
            message.attachment_ids
          )
        }
        break
      }

      case FileActions.FETCH_LINKEDIN_FOR_ITEM_SUCCESS: {
        const linkedin = action.payload.data
        const omsItemID = action.meta.previousAction.omsItemID

        if (linkedin) {
          draft.linkedin[omsItemID] = linkedin
        }
        break
      }
    }
  })
}
