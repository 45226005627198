import React from 'react'
import isEqual from 'lodash/isEqual'

import { AppState, useAppSelector } from 'store'
import { SchedulerStates } from 'store/scheduling/types'
import { itemHasCronofy } from 'store/scheduling/selectors'
import {
  formattedOrderIDForSchedulerItem,
  itemByOMSItemID,
} from 'store/items/selectors'
// import PreAssignment from './PreAssignment'
import Scheduler from './index'
import Scheduled, { MinimalScheduled } from './Scheduled'
import Completed, { MinimalCompleted } from './Completed'
import Wrapper from './Wrapper'
import MissedCall from './MissedCall'
import useScheduler from './useScheduler'
import Loading from './Loading'
import { Item } from 'store/items/types'
import { useSelector } from 'react-redux'
import NoCalendar from './NoCalendar'

interface Props {
  omsItemID: number
  isPATAuthenticated?: boolean
}

const SchedulerContainer: React.FC<Props> = ({
  omsItemID,
  isPATAuthenticated,
}) => {
  if (isPATAuthenticated) {
    return <MinimalSchedulerContainer omsItemID={omsItemID} />
  }
  return <DefaultSchedulerContainer omsItemID={omsItemID} />
}

const MinimalSchedulerContainer: React.FC<Props> = ({ omsItemID }) => {
  const { schedulerItem, schedulingState, cronofyArgs, onSchedule } =
    useScheduler(omsItemID)

  if (!schedulerItem) {
    return null
  }

  switch (schedulingState) {
    case SchedulerStates.Loading:
    case SchedulerStates.PreAssignment:
      return (
        <Wrapper itemName={schedulerItem.name}>
          <Loading />
        </Wrapper>
      )
    case SchedulerStates.Schedule:
      if (!schedulerItem) {
        return null
      }

      if (!cronofyArgs) {
        return (
          <Wrapper itemName={schedulerItem.name}>
            <NoCalendar expertName={schedulerItem.expert_display_name} />
          </Wrapper>
        )
      }

      return (
        <Wrapper itemName={schedulerItem.name}>
          <Scheduler
            expertName={schedulerItem.expert_display_name}
            cronofyArgs={cronofyArgs}
            onScheduleConfirmation={onSchedule}
            itemID={schedulerItem.item_id}
          />
        </Wrapper>
      )
    case SchedulerStates.Scheduled:
      if (!schedulerItem || !schedulerItem.scheduled_start || !cronofyArgs) {
        return null
      }

      return (
        <Wrapper itemName={schedulerItem.name}>
          <MinimalScheduled
            sessionStart={schedulerItem.scheduled_start}
            omsItemID={omsItemID}
            expertName={schedulerItem.expert_display_name}
          />
        </Wrapper>
      )
    case SchedulerStates.Missed:
      if (!schedulerItem || !schedulerItem.scheduled_start) {
        return null
      }

      return (
        <Wrapper itemName={schedulerItem.name}>
          <MissedCall
            expertName={schedulerItem.expert_display_name}
            sessionTime={schedulerItem.scheduled_start}
            omsItemID={schedulerItem.item_id}
          />
        </Wrapper>
      )
    case SchedulerStates.Rate:
    case SchedulerStates.Completed:
      if (!schedulerItem || !schedulerItem.scheduled_start) {
        return null
      }

      return (
        <Wrapper itemName={schedulerItem.name}>
          <MinimalCompleted
            sessionTime={schedulerItem.scheduled_start}
            expertName={schedulerItem.expert_display_name}
          />
        </Wrapper>
      )
    default:
      return null
  }
}

const DefaultSchedulerContainer: React.FC<Props> = ({ omsItemID }) => {
  const {
    item,
    schedulingState,
    cronofyArgs,
    onSchedule,
    expert,
    purchaseAnotherSessionURL,
  } = useScheduler(omsItemID)

  const orderItem = useSelector<AppState, Item | null>((state) =>
    itemByOMSItemID(state.items, omsItemID)
  )

  const itemID = item?.id || orderItem?.id

  const formattedOrderID = useAppSelector<string>((store) =>
    itemID
      ? formattedOrderIDForSchedulerItem(store.orders, store.items, itemID)
      : ''
  )

  // @TODO All these return nulls should probably be some sort of loader
  if (!item) {
    return null
  }

  switch (schedulingState) {
    //@TODO We are hiding this until we roll out scheduling 100% on the expert
    //hub side, as we don't know pre-assignment which scheduling experience
    //they are getting.
    /*case SchedulerStates.PreAssignment:
      return (
        <Wrapper itemName={item.name} orderID={formattedOrderID}>
          <PreAssignment />
        </Wrapper>
      )*/
    case SchedulerStates.Schedule:
      if (!cronofyArgs || !item) {
        return null
      }

      return (
        <Wrapper itemName={item.name} orderID={formattedOrderID}>
          <Scheduler
            expertName={item.expert_display_name}
            cronofyArgs={cronofyArgs}
            onScheduleConfirmation={onSchedule}
            itemID={item.id}
          />
        </Wrapper>
      )
    case SchedulerStates.Scheduled:
      if (
        !item ||
        !item.scheduled_start ||
        !cronofyArgs ||
        !item.oms_client_id
      ) {
        return null
      }

      return (
        <Wrapper itemName={item.name} orderID={formattedOrderID}>
          <Scheduled
            sessionStart={item.scheduled_start}
            omsItemID={omsItemID}
            omsClientID={item.oms_client_id}
            itemType={item.item_type}
            expertName={item.expert_display_name}
          />
        </Wrapper>
      )
    case SchedulerStates.Missed:
      if (
        !item ||
        !expert ||
        !item.scheduled_start ||
        !item.oms_order_item_id
      ) {
        return null
      }

      return (
        <Wrapper itemName={item.name} orderID={formattedOrderID}>
          <MissedCall
            expertName={item.expert_display_name}
            sessionTime={item.scheduled_start}
            omsItemID={item.oms_order_item_id}
          />
        </Wrapper>
      )
    case SchedulerStates.Rate:
    case SchedulerStates.Completed:
      if (
        !item ||
        !item.scheduled_start ||
        !item.oms_order_id ||
        !purchaseAnotherSessionURL
      ) {
        return null
      }

      return (
        <Wrapper itemName={item.name} orderID={formattedOrderID}>
          <Completed
            sessionTime={item.scheduled_start}
            rateable={schedulingState === SchedulerStates.Rate}
            orderID={item.orderID}
            purchaseURL={purchaseAnotherSessionURL}
          />
        </Wrapper>
      )
    default:
      return null
  }
}

export const SchedulersContainer: React.FC = () => {
  const schedulingItemIDs = useAppSelector<number[]>(
    (store) =>
      Object.keys(store.scheduling.scheduledItems)
        .map((id) => parseInt(id))
        .filter((id) => itemHasCronofy(store.scheduling, id)),
    isEqual
  )

  return (
    <>
      {schedulingItemIDs.map((itemID) => (
        <SchedulerContainer key={itemID} omsItemID={itemID} />
      ))}
    </>
  )
}

export default SchedulerContainer
