import React, { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import ProductHeroImage from './ProductHeroImage'
import Price from 'components/shared/Price'
import BundleItems from './BundleItems'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppState } from 'store'
import {
  purchaseUrlWithEmailQueryParams,
  upsellByPlanCode,
  upsellsByBundle,
  upsellsFinishedLoading,
} from 'store/upsells/selectors'
import { Bundles, Upsell } from 'store/upsells/types'
import { colors } from 'styles'

import useShoppingCart from 'hooks/useShoppingCart'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Paper from '@material-ui/core/Paper'
import { joinConversationally } from 'utils/formatting'
import { addEmailQueryParam, fetchUpsells } from 'store/upsells/actions'
import { GhostRedirect } from 'components/shared/GhostLink'
import { generatePath } from 'react-router-dom'
import Skeleton from 'components/shared/Skeleton'
import { useEffectOnce } from 'react-use'
import useEvents from 'hooks/useEvents'

interface Props {
  planCode?: string
  bundleCode?: string
  pat?: string | null
  isLoggedIn: boolean
}

const ProductDetailsContainer: React.FC<Props> = ({
  planCode,
  bundleCode,
  pat,
  isLoggedIn,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sendEvent = useEvents()

  const queryParams = new URLSearchParams(window.location.search)

  const sku = planCode || bundleCode

  const upsell = useSelector<AppState, Upsell | null>(
    (state) => upsellByPlanCode(state.upsells, sku),
    isEqual
  )

  const bundleUpsells = useSelector<AppState, Upsell[] | null>(
    (state) => upsellsByBundle(state.upsells, sku),
    isEqual
  )

  const upsellsLoaded = useSelector<AppState, boolean>((state) =>
    upsellsFinishedLoading(state.upsells)
  )

  let selectedUpsell: Upsell | null = upsell
  let bundle: Bundles | null = null

  if (sku && bundleUpsells) {
    selectedUpsell = bundleUpsells[0]
    bundle = sku as Bundles
  }

  useEffect(() => {
    if (!upsellsLoaded) {
      dispatch(fetchUpsells(pat || 'me'))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [upsellsLoaded, pat])

  useEffect(() => {
    //if a pat is present, the user likely arrived here by clicking on one of our upsell emails
    if (pat) {
      sendEvent(
        {
          event: `Portal - Email Upsell Clicked`,
          variables: {
            plan_code: planCode || '',
            bundle: bundleCode || '',
          },
        },
        pat
      )
    }
  }, [pat, planCode, bundleCode, sendEvent])

  useEffectOnce(() => {
    if (queryParams) {
      queryParams.forEach((value, key) => {
        dispatch(addEmailQueryParam(key, value))
      })
    }
  })

  if (!upsellsLoaded) {
    return <Skeleton components={[{ height: '30em', width: '100%' }]} />
  }

  //if there are no upsells available for this sku, redirect to the upgrade page
  if (!selectedUpsell) {
    return <GhostRedirect to={generatePath(t(`routes.upgrade`))} />
  }

  return (
    <ProductDetails
      upsell={selectedUpsell}
      bundle={bundle}
      isLoggedIn={isLoggedIn}
    />
  )
}

interface DetailsProps {
  upsell: Upsell
  bundle?: Bundles | null
  isLoggedIn: boolean
}

interface ButtonProps {
  isLoggedIn: boolean
  addToCart?: (planCode: string) => void
  checkoutUrl?: string | null
  planCode: string
}

const CheckoutButton: React.FC<ButtonProps> = ({
  isLoggedIn,
  addToCart,
  checkoutUrl,
  planCode,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (!isLoggedIn) {
    return (
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        href={checkoutUrl || ''}
      >
        {t(`components.Upsells.shoppingCart.checkoutModal.checkout`)}
      </Button>
    )
  }

  return (
    <Button
      className={classes.button}
      color="primary"
      onClick={() => {
        if (addToCart) {
          addToCart(planCode)
        }
      }}
      variant="contained"
    >
      {t(`components.Upsells.shoppingCart.addToCart`)}
    </Button>
  )
}

const ProductDetails: React.FC<DetailsProps> = ({
  upsell,
  bundle,
  isLoggedIn,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { cartPlanCodes, addToCart, updateOpen } = useShoppingCart()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const tRef = 'components.Product'

  const upsellUrl = useSelector<AppState, string | null>((state) =>
    purchaseUrlWithEmailQueryParams(
      state.user,
      state.brand,
      state.upsells,
      [upsell.plan_code],
      'product'
    )
  )

  const item = upsell.items[0]
  const isDiscounted = !!(
    upsell.original_price &&
    upsell.original_price > 0 &&
    upsell.original_price > upsell.price
  )

  const originalPrice = Math.floor(
    isDiscounted ? upsell.original_price || upsell.price : upsell.price
  )

  let title = t(`components.Upsells.upsellName.${item.item_type_key}`)
  let shortPitch = t(`${tRef}.items.copy.${item.item_type_key}`)
  if (upsell.bundle && upsell.items.length > 1) {
    title = t(`${tRef}.bundles.title.${upsell.bundle}`)
    shortPitch = t(`${tRef}.bundles.copy.${upsell.bundle}`)
  } else if (upsell.items.length > 1) {
    title = upsell.name || ''
    shortPitch = upsell.short_pitch || ''
  }

  const itemList = joinConversationally(
    upsell.items.map((u) => u.item_type_key),
    true,
    'components.Upsells.upsellName.'
  )

  const copy =
    upsell.items.length > 1
      ? t(`components.Product.itemsList`, {
          copy: shortPitch,
          items: itemList,
        })
      : shortPitch

  const getPercentOff = (upsellItem: Upsell) => {
    if (!upsellItem.original_price || upsellItem.original_price === 0) return 0
    return Math.floor(
      ((upsellItem.original_price - upsellItem.price) /
        upsellItem.original_price) *
        100
    )
  }

  const onAddToCartClick = useCallback(
    async (planCode: string) => {
      if (!cartPlanCodes.includes(planCode)) {
        addToCart(planCode, 'product-page')
      }
      updateOpen(true)
    },
    [updateOpen, cartPlanCodes, addToCart]
  )

  //scrool to the top of the page when this component
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box className={classes.container}>
      <Box className={classes.heroImageContainer}>
        <ProductHeroImage
          itemType={item.item_type_key}
          bundle={bundle}
          itemName={item.name}
        />
      </Box>
      <Box
        className={clsx(
          classes.detailsContainer,
          !isMobile ? classes.scrollable : ''
        )}
      >
        <Container disableGutters>
          <Box className={classes.chipContainer}>
            {isDiscounted && !isMobile && (
              <Chip
                label={getPercentOff(upsell) + '% OFF'}
                className={classes.chip}
              />
            )}
          </Box>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>

          <Container disableGutters className={classes.priceContainer}>
            {isMobile && isDiscounted && (
              <Chip
                label={getPercentOff(upsell) + '% OFF'}
                className={clsx(classes.chip, classes.mobileChip)}
              />
            )}
            <Price
              currencyCode={upsell.currency_code}
              currencySymbol={upsell.currency_symbol}
              amount={originalPrice}
              className={clsx(
                classes.price,
                isDiscounted ? classes.originalPrice : ''
              )}
            />
            {isDiscounted && (
              <Price
                currencyCode={upsell.currency_code}
                currencySymbol={upsell.currency_symbol}
                amount={upsell.price}
                className={clsx(classes.price, classes.salePrice)}
              />
            )}
          </Container>
          <Typography
            variant="body1"
            className={classes.shortPitch}
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
          {!isMobile && (
            <CheckoutButton
              isLoggedIn={isLoggedIn}
              checkoutUrl={upsellUrl}
              addToCart={onAddToCartClick}
              planCode={upsell.plan_code}
            />
          )}

          {upsell.items.length > 1 && <BundleItems items={upsell.items} />}
          {isMobile && (
            <Paper className={classes.floatingAddToCartContainer}>
              <CheckoutButton
                isLoggedIn={isLoggedIn}
                checkoutUrl={upsellUrl}
                addToCart={onAddToCartClick}
                planCode={upsell.plan_code}
              />
            </Paper>
          )}
        </Container>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    heroImageContainer: {
      flexBasis: '50%',
      maxWidth: '70rem',
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0rem',
        maxHeight: '30rem',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '15rem',
      },
    },
    detailsContainer: {
      flexBasis: '50%',
      paddingLeft: '2rem',
      paddingRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '2rem',
        paddingLeft: '0rem',
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: '1rem',
        paddingLeft: '1rem',
        paddingBottom: '7rem',
      },
    },
    shortPitch: {
      fontSize: '1rem',
      marginTop: '0.5rem',
    },
    price: {
      fontSize: '1.25rem',
    },
    originalPrice: {
      textDecoration: 'line-through',
      fontSize: '1.25rem',
    },
    salePrice: {
      color: colors.green[600],
      marginLeft: '0.5rem',
    },
    priceContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0.5rem',
      },
    },
    title: {
      fontSize: '2rem',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
      },
    },
    button: {
      marginTop: '1em',
      width: '100%',
    },
    chipContainer: {
      [theme.breakpoints.up('sm')]: {
        minHeight: '2rem',
        marginBottom: '1rem',
      },
    },
    chip: {
      borderRadius: '3em',
      backgroundColor: colors.green[600],
      color: colors.white[100],
      padding: '0rem 0.3rem 0rem 0.3rem',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      letterSpacing: '0.5',
    },
    mobileChip: {
      marginRight: '0.5rem',
    },
    floatingAddToCartContainer: {
      position: 'fixed',
      bottom: '0rem',
      right: '0rem',
      width: '100%',
      zIndex: 100,
      padding: '0.5rem 1rem 1rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      borderTop: `solid ${colors.blue.u100} 1px`,
    },
    scrollable: {
      maxHeight: 'calc(100vh - 10rem)',
      overflow: 'auto',
    },
  })
)

export default ProductDetailsContainer
