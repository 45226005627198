import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { GhostLinkButton } from 'components/shared/GhostLink'

/**
 * NoPortfolioPackage is a display structure seen when a User goes to Portfolio page
 * but doesn't have any active order items to display
 */

const NoPortfolioPackage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <Typography variant="subtitle1" className={classes.h2}>
        {t('components.Portfolio.NoPackage.noDocuments')}
      </Typography>
      <Typography className={classes.h3} variant="h3">
        {t('components.Portfolio.NoPackage.careerJourney')}
      </Typography>
      <GhostLinkButton
        color="primary"
        className={classes.button}
        variant="contained"
        to={t('routes.upgrade')}
      >
        {t('components.Portfolio.NoPackage.viewServices')}
      </GhostLinkButton>
    </Container>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    h3: {
      marginTop: '1.5em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
        fontWeight: 400,
      },
    },
    container: {
      padding: '0',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0',
        padding: '2em 5%',
      },
    },
    h2: {
      marginTop: '1.75em',
      textAlign: 'left',
      color: '#052a4d',
      fontSize: '1.4em',
    },
    button: {
      marginTop: '1.5em',
    },
  })
)

export default NoPortfolioPackage
