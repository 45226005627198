import { envConfig } from './selectors'

export interface Auth0Config {
  clientID: string
  domain: string
}

export interface Config {
  api_version: string
  auth0: Auth0Config
  server_mode: 'dev' | 'staging' | 'production' | string
  brand: {
    brand_id: number
    logo_url: string
    name: string
    theme_name: string
    auth_config: 'talentinc' | 'zipjob'
  }
  site_id: number
  sentry: {
    dsn: string
    environment: string
    release: string
  }
  intercom: {
    app_id: string
  }
}

// Subset of config provided by environment variables
export type EnvConfig = Partial<Config>

export interface ConfigState {
  config: Config | EnvConfig | null
  runningVersion: string | null
  needsUpdate: boolean
}

export interface IntercomConfigVariables {
  expert_hub_client_url?: string
  expert_hub_ghost_url?: string
}

export const initialConfigState: ConfigState = {
  config: envConfig(),
  runningVersion: null,
  needsUpdate: false,
}
