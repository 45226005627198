import React, { useState } from 'react'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface Props {
  children: (selectedTabName: string) => React.ReactNode
  tabNames: string[]
  defaultDocumentsTab?: string | null
}

const DocumentTabs: React.FC<Props> = ({
  children,
  tabNames,
  defaultDocumentsTab,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    Math.max(tabNames.indexOf(defaultDocumentsTab || ''), 0)
  )

  return (
    <>
      {!isMobile && (
        <Tabs
          onChange={(_, tabIndex) => setSelectedTabIndex(tabIndex)}
          value={selectedTabIndex}
          variant="fullWidth"
        >
          {tabNames.map((tabName) => (
            <Tab
              key={tabName}
              label={
                <Typography variant="body2">
                  {tabName.toLowerCase().includes('linkedin')
                    ? 'LinkedIn'
                    : tabName}
                </Typography>
              }
            />
          ))}
        </Tabs>
      )}
      {children(tabNames[selectedTabIndex])}
    </>
  )
}

export default DocumentTabs
