import React from 'react'
import { Trans, TransProps } from 'react-i18next'
import { useTranslation } from 'react-i18next'

import UpsellBanner from 'components/Content/UpsellBanner'
import useBrand from 'hooks/useBrand'

interface Props extends Pick<TransProps<any>, 'values' | 'components'> {
  i18nKeys: string | string[]
}

const ArticleTrans: React.FC<Props> = ({
  i18nKeys,
  values = {},
  components = {},
}) => {
  const { t } = useTranslation()
  const brand = useBrand()
  let product
  if (brand.default_resume_product) {
    product =
      brand.default_resume_product === 'CV'
        ? brand.default_resume_product
        : brand.default_resume_product.toLowerCase()
  }

  return (
    <Trans
      components={{
        /* eslint-disable jsx-a11y/heading-has-content */
        /* eslint-disable jsx-a11y/anchor-has-content */
        /* eslint-disable jsx-a11y/anchor-is-valid */
        p: <p />,
        section: <section />,
        h2: <h2 />,
        h3: <h3 />,
        a: <a />,
        ul: <ul />,
        ol: <ol />,
        li: <li />,
        span: <span />,
        dd: <dd />,
        dt: <dt />,
        UpsellBanner: <UpsellBanner />,
        /* eslint-enable jsx-a11y/heading-has-content */
        /* eslint-enable jsx-a11y/anchor-has-content */
        /* eslint-disable jsx-a11y/anchor-is-valid */
        ...components,
      }}
    >
      {t(i18nKeys, {
        brand: brand.name,
        product,
        ...values,
      })}
    </Trans>
  )
}

export default ArticleTrans
