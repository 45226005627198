import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import {
  GhostLinkButton,
  GhostLinkButtonProps,
} from 'components/shared/GhostLink'

interface BaseProps {
  buttonCopy: string
  copy: string
}

interface ButtonProps extends BaseProps {
  onClick: MuiButtonProps['onClick']
}

interface LinkProps extends BaseProps {
  to: GhostLinkButtonProps['to']
}

type Props = ButtonProps | LinkProps

const useStyles = makeStyles((theme) =>
  createStyles({
    body1: {
      maxWidth: '60%',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1em',
        maxWidth: 'initial',
        width: '100%',
      },
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1.9em',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
  })
)

const CTA: React.FC<Props> = ({ buttonCopy, copy, ...props }) => {
  const classes = useStyles()

  return (
    <>
      <Divider />
      <Container className={classes.container}>
        <Typography className={classes.body1} variant="body1">
          {copy}
        </Typography>
        {'onClick' in props && (
          <Button color="primary" variant="contained" onClick={props.onClick}>
            {buttonCopy}
          </Button>
        )}
        {'to' in props && (
          <GhostLinkButton color="primary" variant="contained" to={props.to}>
            {buttonCopy}
          </GhostLinkButton>
        )}
      </Container>
    </>
  )
}

export default CTA
