import * as ram from '@talentinc/redux-axios-middleware'
import {
  AddToCartPayload,
  CartItem,
  UpdateQuantityPayload,
  Upsell,
} from './types'
import { UserIdentifier } from 'store/user/types'

export enum UpsellsActions {
  FETCH_UPSELLS = 'FETCH_UPSELLS',
  FETCH_UPSELLS_SUCCESS = 'FETCH_UPSELLS_SUCCESS',
  FETCH_UPSELLS_FAIL = 'FETCH_UPSELLS_FAIL',
  UPDATE_CART_OPEN = 'UPDATE_CART_OPEN',
  FETCH_BUNDLED_UPSELLS = 'FETCH_BUNDLED_UPSELLS',
  FETCH_BUNDLED_UPSELLS_SUCCESS = 'FETCH_BUNDLED_UPSELLS_SUCCESS',
  FETCH_BUNDLED_UPSELLS_FAIL = 'FETCH_BUNDLED_UPSELLS_FAIL',
  ADD_EMAIL_QUERY_PARAM = 'ADD_EMAIL_QUERY_PARAM',
  FETCH_CART_ITEMS = 'FETCH_CART_ITEMS',
  FETCH_CART_ITEMS_SUCCESS = 'FETCH_CART_ITEMS_SUCCESS',
  FETCH_CART_ITEMS_FAIL = 'FETCH_CART_ITEMS_FAIL',
  ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART',
  ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS',
  ADD_ITEM_TO_CART_FAIL = 'ADD_ITEM_TO_CART_FAIL',
  REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART',
  REMOVE_ITEM_FROM_CART_SUCCESS = 'REMOVE_ITEM_FROM_CART_SUCCESS',
  REMOVE_ITEM_FROM_CART_FAIL = 'REMOVE_ITEM_FROM_CART_FAIL',
  UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY',
  UPDATE_CART_ITEM_QUANTITY_SUCCESS = 'UPDATE_CART_ITEM_QUANTITY_SUCCESS',
  UPDATE_CART_ITEM_QUANTITY_FAIL = 'UPDATE_CART_ITEM_QUANTITY_FAIL',
}

export interface FetchUpsellsSuccess
  extends ram.AxiosMiddlewareActionSuccess<Upsell[], FetchUpsells> {
  type: typeof UpsellsActions.FETCH_UPSELLS_SUCCESS
}

export interface FetchUpsellsFail
  extends ram.AxiosMiddlewareActionFail<FetchUpsells> {
  type: typeof UpsellsActions.FETCH_UPSELLS_FAIL
}

export interface FetchUpsells extends ram.AxiosMiddlewareActionCreator {
  type: UpsellsActions.FETCH_UPSELLS
  userIdentifier: UserIdentifier
}

export interface FetchBundledUpsells extends ram.AxiosMiddlewareActionCreator {
  type: UpsellsActions.FETCH_BUNDLED_UPSELLS
  userIdentifier: UserIdentifier
  bundle: string
}

export interface FetchBundledUpsellsSuccess
  extends ram.AxiosMiddlewareActionSuccess<Upsell[], FetchBundledUpsells> {
  type: typeof UpsellsActions.FETCH_BUNDLED_UPSELLS_SUCCESS
}

export interface FetchBundledUpsellsFail
  extends ram.AxiosMiddlewareActionFail<FetchBundledUpsells> {
  type: typeof UpsellsActions.FETCH_BUNDLED_UPSELLS_FAIL
}

export interface FetchCartItems extends ram.AxiosMiddlewareActionCreator {
  type: UpsellsActions.FETCH_CART_ITEMS
  userIdentifier: UserIdentifier
  siteID: number
}
export interface FetchCartItemsSuccess
  extends ram.AxiosMiddlewareActionSuccess<CartItem[], FetchCartItems> {
  type: typeof UpsellsActions.FETCH_CART_ITEMS_SUCCESS
}

export interface FetchCartItemsFail
  extends ram.AxiosMiddlewareActionFail<FetchCartItems> {
  type: typeof UpsellsActions.FETCH_CART_ITEMS_FAIL
}

export interface AddItemToCart extends ram.AxiosMiddlewareActionCreator {
  type: UpsellsActions.ADD_ITEM_TO_CART
  userIdentifier: UserIdentifier
  siteID: number
  data: AddToCartPayload
}
export interface AddItemToCartSuccess
  extends ram.AxiosMiddlewareActionSuccess<CartItem, AddItemToCart> {
  type: typeof UpsellsActions.ADD_ITEM_TO_CART_SUCCESS
}

export interface AddItemToCartFail
  extends ram.AxiosMiddlewareActionFail<AddItemToCart> {
  type: typeof UpsellsActions.ADD_ITEM_TO_CART_FAIL
}

export interface RemoveItemFromCart extends ram.AxiosMiddlewareActionCreator {
  type: UpsellsActions.REMOVE_ITEM_FROM_CART
  userIdentifier: UserIdentifier
  siteID: number
  cartItemID: number
}
export interface RemoveItemFromCartSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, RemoveItemFromCart> {
  type: typeof UpsellsActions.REMOVE_ITEM_FROM_CART_SUCCESS
}

export interface RemoveItemFromCartFail
  extends ram.AxiosMiddlewareActionFail<RemoveItemFromCart> {
  type: typeof UpsellsActions.REMOVE_ITEM_FROM_CART_FAIL
}

export interface UpdateItemQuantity extends ram.AxiosMiddlewareActionCreator {
  type: UpsellsActions.UPDATE_CART_ITEM_QUANTITY
  userIdentifier: UserIdentifier
  siteID: number
  cartItemID: number
  data: UpdateQuantityPayload
}
export interface UpdateItemQuantitySuccess
  extends ram.AxiosMiddlewareActionSuccess<CartItem, UpdateItemQuantity> {
  type: typeof UpsellsActions.UPDATE_CART_ITEM_QUANTITY_SUCCESS
}

export interface UpdateItemQuantityFail
  extends ram.AxiosMiddlewareActionFail<UpdateItemQuantity> {
  type: typeof UpsellsActions.UPDATE_CART_ITEM_QUANTITY_FAIL
}

export function fetchUpsells(
  userIdentifier: UserIdentifier = 'me'
): FetchUpsells {
  return {
    type: UpsellsActions.FETCH_UPSELLS,
    userIdentifier,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}/upsells?type=portal`,
      },
    },
  }
}

export function fetchBundledUpsells(
  userIdentifier: UserIdentifier = 'me',
  bundle: string
): FetchBundledUpsells {
  return {
    type: UpsellsActions.FETCH_BUNDLED_UPSELLS,
    bundle,
    userIdentifier,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}/upsells?bundle=${bundle}`,
      },
    },
  }
}

export function fetchCartItems(
  userIdentifier: UserIdentifier = 'me',
  siteID: number
): FetchCartItems {
  return {
    type: UpsellsActions.FETCH_CART_ITEMS,
    siteID,
    userIdentifier,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}/cart/${siteID}`,
      },
    },
  }
}

export function addItemToCart(
  userIdentifier: UserIdentifier = 'me',
  siteID: number,
  data: AddToCartPayload
): AddItemToCart {
  return {
    type: UpsellsActions.ADD_ITEM_TO_CART,
    siteID,
    userIdentifier,
    data,
    payload: {
      request: {
        method: 'POST',
        url: `/v2/users/${userIdentifier}/cart/${siteID}`,
        data,
      },
    },
  }
}

export function removeItemFromCart(
  userIdentifier: UserIdentifier = 'me',
  siteID: number,
  cartItemID: number
): RemoveItemFromCart {
  return {
    type: UpsellsActions.REMOVE_ITEM_FROM_CART,
    siteID,
    userIdentifier,
    cartItemID,
    payload: {
      request: {
        method: 'DELETE',
        url: `/v2/users/${userIdentifier}/cart/${siteID}/${cartItemID}`,
      },
    },
  }
}

export function updateCartItemQuantity(
  userIdentifier: UserIdentifier = 'me',
  siteID: number,
  cartItemID: number,
  data: UpdateQuantityPayload
): UpdateItemQuantity {
  return {
    type: UpsellsActions.UPDATE_CART_ITEM_QUANTITY,
    siteID,
    userIdentifier,
    data,
    cartItemID,
    payload: {
      request: {
        method: 'PATCH',
        url: `/v2/users/${userIdentifier}/cart/${siteID}/${cartItemID}`,
        data,
      },
    },
  }
}

export interface UpdateCartOpen {
  type: UpsellsActions.UPDATE_CART_OPEN
  open: boolean
}

export const updateCartOpen = (open: boolean): UpdateCartOpen => ({
  type: UpsellsActions.UPDATE_CART_OPEN,
  open,
})

export interface AddEmailQueryParam {
  type: UpsellsActions.ADD_EMAIL_QUERY_PARAM
  param: string
  value: string
}

export const addEmailQueryParam = (
  param: string,
  value: string
): AddEmailQueryParam => ({
  type: UpsellsActions.ADD_EMAIL_QUERY_PARAM,
  param,
  value,
})

export type UpsellsAction =
  | FetchUpsells
  | FetchUpsellsSuccess
  | FetchUpsellsFail
  | UpdateCartOpen
  | AddEmailQueryParam
  | FetchCartItems
  | FetchCartItemsFail
  | FetchCartItemsSuccess
  | AddItemToCart
  | AddItemToCartSuccess
  | AddItemToCartFail
  | RemoveItemFromCart
  | RemoveItemFromCartSuccess
  | RemoveItemFromCartFail
  | UpdateItemQuantity
  | UpdateItemQuantitySuccess
  | UpdateItemQuantityFail
