import { produce } from 'immer'
import * as stateUtils from '@talentinc/state-utils'

import { AttachmentsAction, AttachmentActions } from './actions'
import { AttachmentsState, initialState } from './types'

export default function attachmentsReducer(
  state: AttachmentsState = initialState,
  action: AttachmentsAction
): AttachmentsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case AttachmentActions.CLEAR_ATTACHMENTS:
        draft.attachments = {}
        break

      case AttachmentActions.REMOVE_ATTACHMENT:
        delete draft.attachments[action.filename]
        delete draft.toAttachmentID[action.filename]
        break

      case AttachmentActions.UPLOAD_ATTACHMENT:
        const filename = action.file.name

        draft.attachments[filename] = {
          error: null,
          attachment: {
            id: null,
            user_id: null,
            file_name: filename,
            file_size: action.file.size,
          },
        }
        draft.meta[action.type][action.file.name] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break

      case AttachmentActions.UPLOAD_ATTACHMENT_SUCCESS: {
        const filename = action.meta.previousAction.file.name
        const prevActionType = action.meta.previousAction.type

        draft.attachments[filename] = action.payload.data
        draft.toAttachmentID[filename] = action.payload.data.attachment.id
        draft.meta[prevActionType][filename] = stateUtils.setSuccessState(
          state.meta[prevActionType][filename]
        )
        break
      }

      case AttachmentActions.UPLOAD_ATTACHMENT_FAIL: {
        const filename = action.meta.previousAction.file.name
        const prevActionType = action.meta.previousAction.type
        const error = action.error.response?.data.error || 'generic'

        draft.attachments[filename].error = error
        draft.meta[prevActionType][filename] = stateUtils.setErrorState(
          state.meta[prevActionType][filename],
          error
        )
        break
      }
    }
  })
}
