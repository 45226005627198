import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import Stars, { StarsProps } from './Stars'
import NPSContainer from './NPSContainer'
import { AppState } from 'store'
import { orderIDForItemID } from 'store/items/selectors'

interface OrderProps {
  orderID: number
  itemID?: never
  StarsProps: StarsProps
}

interface ItemProps {
  itemID: number
  orderID?: never
  StarsProps: StarsProps
}

const StarWidgetContainer: React.FC<OrderProps | ItemProps> = ({
  orderID,
  itemID,
  StarsProps,
}) => {
  const [showModal, setShowModal] = useState(false)
  const computedOrderID = useSelector<AppState, number | null>((state) =>
    orderID && !itemID
      ? orderID
      : orderIDForItemID(state.items, itemID as number)
  )

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setShowModal(true)
    },
    [setShowModal]
  )

  if (!computedOrderID) {
    return null
  }

  return (
    <>
      <Stars onClick={handleClick} {...StarsProps} />
      {showModal && (
        <NPSContainer
          orderID={computedOrderID}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default StarWidgetContainer
