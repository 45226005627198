import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton'
import clsx from 'clsx'

interface Props {
  components: SkeletonProps[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    skeleton: {
      marginTop: '2em',
    },
  })
)

const SkeletonLoading: React.FC<Props> = ({ components }) => {
  const classes = useStyles()
  return (
    <>
      {components.map((component, i) => (
        <Skeleton
          key={i}
          animation="wave"
          variant="rect"
          {...component}
          className={clsx(classes.skeleton, component.className)}
        />
      ))}
    </>
  )
}

export default SkeletonLoading
