import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { format as dateFormat, formatDistanceStrict } from 'date-fns'
import clsx from 'clsx'

import isEqual from 'lodash/isEqual'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import Price from 'components/shared/Price'

import { AppState } from 'store'
import { upsellExpiryDate } from 'store/upsells/selectors'
import { Upsell } from 'store/upsells/types'
import { Expert } from 'store/orders/types'
import useShoppingCart from 'hooks/useShoppingCart'
import useExperiment from 'hooks/useExperiment'
import useEvents from 'hooks/useEvents'
import { GhostRedirect } from 'components/shared/GhostLink'
import { generatePath } from 'react-router-dom'

interface Props {
  expert: Expert | null
  updateUpsells: (isSelected: boolean, planCode: string) => void
  upsell: Upsell
}

const LastChanceItem: React.FC<Props> = ({ expert, updateUpsells, upsell }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { cartPlanCodes } = useShoppingCart()
  const isSelected = cartPlanCodes.includes(upsell.plan_code)
  const sendEvent = useEvents()

  // Product Page Experiment
  const isInProductPageExperiment = useExperiment({
    experimentLabel: 'Product Pages Portal',
    caseDescriptor: 'test-product-page',
  })

  const [showDetails, setShowDetails] = useState(false)

  const expiryDate = useSelector<AppState, Date | null>(
    (state) =>
      upsellExpiryDate(
        state.orders,
        upsell && upsell.items.length > 0 ? upsell.items[0].item_type_key : null
      ),
    isEqual
  )

  const handleButtonClick = useCallback(
    (e) => {
      e.stopPropagation()
      updateUpsells(isSelected, upsell.plan_code)
    },
    [isSelected, updateUpsells, upsell.plan_code]
  )

  const handleUpsellClick = useCallback(async () => {
    if (isInProductPageExperiment) {
      setShowDetails(true)
      sendEvent({
        event: `Portal - Product Page Viewed`,
        variables: {
          plan_code: upsell.plan_code,
        },
      })
    }
  }, [sendEvent, upsell.plan_code, isInProductPageExperiment])

  const itemName =
    upsell.items.length > 0
      ? t(`components.Upsells.upsellName.${upsell.items[0].item_type_key}`)
      : upsell.name

  const hours48 = 1000 * 60 * 60 * 48
  const rushDate = new Date(new Date().getTime() + hours48)

  const isDiscounted = !!(
    upsell.original_price &&
    upsell.original_price > 0 &&
    upsell.original_price > upsell.price
  )

  //when the user clicks the image, redirect to the product details page
  if (showDetails && isInProductPageExperiment) {
    return (
      <GhostRedirect
        to={generatePath(t(`routes.productUpgrades`), {
          planCode: upsell.plan_code,
        })}
        push
      />
    )
  }

  return (
    <Container
      className={clsx(
        classes.itemContainer,
        isSelected
          ? classes.itemContainerSelected
          : classes.itemContainerNormal,
        isInProductPageExperiment ? classes.pointer : ''
      )}
      onClick={handleUpsellClick}
    >
      <Container disableGutters className={classes.itemDetailsRow}>
        <Typography variant="body1" className={classes.upsellName}>
          {itemName}
        </Typography>
        <Typography variant="body1" className={classes.expiring}>
          {expiryDate &&
            'Expires in ' + formatDistanceStrict(new Date(), expiryDate)}
        </Typography>
      </Container>
      <Typography variant="body1" className={classes.shortPitch}>
        {t(
          `components.Upsells.shoppingCart.shortPitch.${upsell.items[0].item_type_key}`,
          {
            date: dateFormat(
              rushDate,
              t(`components.Upsells.shortPitchDateFormat`)
            ),
            expertName: expert?.display_name || t(`shared.YourExpert`),
          }
        )}
      </Typography>
      <Container disableGutters className={classes.itemDetailsRowLeft}>
        <Container disableGutters className={classes.pricesContainer}>
          <Typography variant="body1">+</Typography>
          <Price
            currencyCode={upsell.currency_code}
            currencySymbol={upsell.currency_symbol}
            amount={
              isDiscounted
                ? upsell.original_price || upsell.price
                : upsell.price
            }
            className={clsx(
              classes.price,
              isDiscounted ? classes.originalPrice : ''
            )}
          />
          {isDiscounted && (
            <Price
              currencyCode={upsell.currency_code}
              currencySymbol={upsell.currency_symbol}
              amount={upsell.price}
              className={clsx(classes.price, classes.salePrice)}
            />
          )}
        </Container>

        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          startIcon={!isSelected && <AddIcon />}
          onClick={(e) => handleButtonClick(e)}
        >
          {isSelected && t(`components.Upsells.shoppingCart.remove`)}
          {!isSelected && t(`components.Upsells.shoppingCart.add`)}
        </Button>
      </Container>
    </Container>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      fontSize: '1rem',
      height: '2.2rem',
    },
    shortPitch: {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      fontSize: '1rem',
    },
    upsellName: {
      fontSize: '1rem',
      fontWeight: 600,
      color: theme.palette.primary.dark,
    },
    expiring: {
      fontSize: '.9rem',
      color: theme.palette.warning.main,
      fontWeight: 500,
    },
    itemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'left',

      padding: '1rem',
      marginTop: '1rem',
      borderRadius: '6px',
    },
    itemContainerNormal: {
      backgroundColor: theme.palette.background.default,
    },
    itemContainerSelected: {
      border: `solid ${theme.palette.primary.main} 3px`,
      backgroundColor: colors.blue.a100,
    },
    itemDetailsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    itemDetailsRowLeft: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    itemSelected: {
      border: `solid ${theme.palette.primary.main} 3px`,
      backgroundColor: colors.blue.a100,
    },
    pricesContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    price: {
      fontWeight: 600,
    },
    originalPrice: {
      textDecoration: 'line-through',
    },
    salePrice: {
      color: colors.green[600],
      marginLeft: '0.5rem',
    },
    pointer: {
      cursor: 'pointer',
    },
  })
)

export default LastChanceItem
