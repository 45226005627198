import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Skeleton from 'components/shared/Skeleton'
import SvgAddIcon from 'assets/images/icon-add.svg'
import { Upsell } from 'store/upsells/types'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: '.75em',
      marginRight: '.75em',
      padding: '1.5em',
      width: '20.2em',
      borderStyle: 'dashed',
      borderWidth: '0.2em',
      minHeight: '344px',
      textDecoration: 'none',

      [theme.breakpoints.down('sm')]: {
        marginLeft: '.5em',
        marginRight: '.5em',
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: '.38em',
        marginLeft: '0',
        marginRight: '0',
        width: '100%',
      },
      '&:hover': {
        background: theme.palette.background.default,
        cursor: 'pointer',
      },
    },
    addIcon: {
      marginTop: '3em',
      marginBottom: '2em',
    },
    h3: {
      alignItems: 'center',
      color: theme.palette.primary.dark,
      display: 'flex',
    },
    body: {
      fontSize: '1rem',
      color: theme.palette.secondary.main,
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'center',
      marginTop: '1em',
      marginBottom: '3em',
      maxWidth: 'calc(20.2em - 3em)',
    },
  })
)

interface Props {
  upsell: Upsell | null
  finishedLoading: boolean
  hasError: boolean
  purchaseUrl: string
}

const PortfolioUpsellCard: React.FC<Props> = ({
  upsell,
  finishedLoading,
  hasError,
  purchaseUrl,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  if (upsell === null || hasError) {
    return null
  }

  if (!finishedLoading) {
    return <Skeleton components={[{ height: '60em', width: '100%' }]} />
  }

  return (
    <Paper
      className={classes.paper}
      variant="outlined"
      // The following `component` prop throws the following TS error while building:
      // TS2322: Type '"a"' is not assignable to type 'ElementType<HTMLAttributes<HTMLElement>> | undefined'.
      // This should be resolved once we upgrade Mui to v5
      // @ts-ignore
      component="a"
      // @ts-ignore
      href={purchaseUrl}
    >
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <Grid item>
          <img
            src={SvgAddIcon}
            className={classes.addIcon}
            alt={t('pages.Upgrade.add')}
          />
        </Grid>
        <Grid
          item
          component={(props) => (
            <Typography
              className={classes.h3}
              component="h3"
              variant="body1"
              {...props}
            >
              {upsell.name}
            </Typography>
          )}
        />
        {upsell.very_short_pitch && (
          <Grid
            item
            alignContent="center"
            className={classes.body}
            component={(props) => (
              <Typography
                component="p"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: upsell.very_short_pitch,
                }}
                {...props}
              />
            )}
          />
        )}
      </Grid>
    </Paper>
  )
}

export default PortfolioUpsellCard
