import {
  format as dateFormat,
  add as dateAdd,
  differenceInMinutes,
  addMinutes,
  differenceInHours,
} from 'date-fns'

import {
  SchedulingState,
  CronofyAvailablityViewerArgs,
  SchedulerStates,
  ItemForScheduler,
  UnauthenticatedItemForScheduler,
  ScheduledItem,
  SchedulableItem,
} from './types'
import { ItemsState, ItemTypes, Item, ItemStatusTypes } from 'store/items/types'
import {
  itemByOMSItemID,
  expertForItem,
  orderIDForItemID,
} from 'store/items/selectors'
import { OrdersState } from 'store/orders/types'
import { UserState } from 'store/user/types'
import { purchaseUrl } from 'store/upsells/selectors'
import { RatingState } from 'store/ratings/types'
import { npsForItem } from 'store/ratings/selectors'
import { NotificationState } from 'store/notifications/types'
import { notificationToShowModalFor } from 'store/notifications/selectors'
import { BrandState } from 'store/brand/types'

export const itemDetailsToCronofy = (
  state: SchedulingState,
  itemID: number
): CronofyAvailablityViewerArgs | null => {
  const details = state.details[itemID]
  if (!details || !details.element_token) {
    return null
  }

  return {
    element_token: details.element_token,
    config: {
      start_time: '08:00',
      end_time: '23:00',
      mode: 'no_confirm',
      week_start_day: dateFormat(new Date(), 'eeee').toLowerCase(),
    },
    availability_query: {
      participants: [
        {
          required: 'all',
          members: details.expert_cronofy_calendars.map(({ sub, type }) => ({
            sub,
            managed_availability: type === 'default',
          })),
        },
      ],
      required_duration: {
        minutes: details.slot_length,
      },
      query_periods: [
        {
          start: dateAdd(new Date(), {
            hours: details.min_notice,
          }),
          end: dateAdd(new Date(), {
            days: 14,
          }),
        },
      ],
      buffer: {
        before: { minutes: details.buffer },
        after: { minutes: details.buffer },
      },
    },
  }
}

export const itemSchedulerState = (
  schedulingState: SchedulingState,
  ratingState: RatingState,
  itemsState: ItemsState,
  omsItemID: number
): SchedulerStates => {
  const item = schedulingState.scheduledItems[omsItemID]
  const schedulingDetails = schedulingState.details[omsItemID]

  if (!item) {
    return SchedulerStates.Loading
  }

  if (!item.item_assigned) {
    return SchedulerStates.PreAssignment
  }

  if (schedulingDetails?.element_token && !item.scheduled_start) {
    return SchedulerStates.Schedule
  }

  if (item.session_end) {
    // @TODO Switch this to the item rating once we make that flow
    if (npsForItem(ratingState, itemsState, item.item_id)) {
      return SchedulerStates.Completed
    }

    return SchedulerStates.Rate
  }

  if (item.scheduled_start) {
    const missedCall =
      differenceInMinutes(new Date(), item.scheduled_start) >= 30 &&
      !item.session_start

    if (missedCall) {
      return SchedulerStates.Missed
    }

    return SchedulerStates.Scheduled
  }

  return SchedulerStates.Schedule
}

export const scheduledItemIDsNeedingDetails = (
  state: SchedulingState
): number[] =>
  Object.values(state.scheduledItems)
    .filter((item) => item.item_assigned)
    .map((item) => item.item_id)

export const itemForScheduler = (
  schedulingState: SchedulingState,
  itemsState: ItemsState,
  ordersState: OrdersState,
  omsItemID: number
): ItemForScheduler | null => {
  const item = itemByOMSItemID(itemsState, omsItemID)
  const scheduledItem = schedulingState.scheduledItems[omsItemID]

  if (!item || !scheduledItem) {
    return null
  }

  return {
    ...item,
    ...scheduledItem,
    expert: expertForItem(itemsState, ordersState, item.id),
    orderID: orderIDForItemID(itemsState, item.id) as number,
  }
}

export const schedulerItemForScheduler = (
  schedulingState: SchedulingState,
  omsItemID: number
): SchedulableItem => {
  return schedulingState.scheduledItems[omsItemID]
}

export const unauthenticatedItemForScheduler = (
  state: SchedulingState,
  omsItemID: number
): UnauthenticatedItemForScheduler | null =>
  state.scheduledItems[omsItemID] || null

export const itemsForAppointmentReminderModal = (
  schedulingState: SchedulingState,
  itemsState: ItemsState,
  ordersState: OrdersState,
  notificationState: NotificationState,
  dismissedItemIDs: number[]
): ItemForScheduler<ScheduledItem> | null => {
  // If we have any modals to currently show, skip this modal until there are no
  // unread modals.
  if (notificationToShowModalFor(notificationState)) {
    return null
  }

  return (
    Object.values(schedulingState.scheduledItems)
      .filter(
        ({ scheduled_start: start, item_id: itemID }) =>
          start &&
          addMinutes(start, 30) > new Date() &&
          (differenceInMinutes(start, new Date()) < 15 ||
            (differenceInHours(start, new Date()) < 6 &&
              !dismissedItemIDs.includes(itemID)))
      )
      .map(({ item_id: itemID }): ItemForScheduler<ScheduledItem> | null =>
        // @ts-ignore
        itemForScheduler(schedulingState, itemsState, ordersState, itemID)
      )?.[0] || null
  )
}

export const buyAnotherSessionLink = (
  itemsState: ItemsState,
  userState: UserState,
  brandState: BrandState,
  omsItemID: number
): string | null => {
  const item = itemByOMSItemID(itemsState, omsItemID)

  if (!item) {
    return null
  }

  return purchaseUrl(
    userState,
    brandState,
    [item.item_type === ItemTypes.PhoneCall ? 'lvBKW3T' : 'IGUorl'],
    'scheduler_post_session'
  )
}

export const itemHasCronofy = (
  state: SchedulingState,
  omsItemID: number
): boolean => !!state.details[omsItemID]?.element_token

export const itemCanBeRescheduledByStatus = (item: Item) =>
  !!item.status.completed.find(
    ({ state }) => state === ItemStatusTypes.AwaitingScheduling
  ) &&
  !item.status.completed.find(
    ({ state }) => state === ItemStatusTypes.ConductCall
  )

export const schedulableItemsLoaded = (state: SchedulingState) =>
  state.meta.FETCH_SCHEDULED_ITEMS && state.meta.FETCH_SCHEDULED_ITEMS.loaded

export const itemDetailsLoaded = (state: SchedulingState) => {
  const itemStates = Object.values(state.meta.FETCH_SCHEDULED_ITEM_DETAILS)
  if (itemStates.length === 0) return false
  return itemStates.filter((iState) => !iState.loaded).length <= 0
}
