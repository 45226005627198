import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import MultiStepModal, { Step, MultiStepModalProps } from '../MultiStepModal'
import useUser from 'hooks/useUser'
import docsSVG from 'assets/images/docs.svg'
import iphoneSVG from 'assets/images/iphone.svg'
import messagesSVG from 'assets/images/messages.svg'
//import careerTipsSVG from 'assets/images/career-tips.svg'
import useBrand from 'hooks/useBrand'

type Props = Pick<
  MultiStepModalProps,
  'onStart' | 'onAdvance' | 'onComplete' | 'onClose'
>

const GettingStartedModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const user = useUser()
  const brandName = useBrand().name

  /* TODO: Add this step back to the list above when we have some content in the portal
    <Step heroImageURL={careerTipsSVG}>
            <Typography variant="h2">
              {t('components.Modals.variants.GettingStarted.tipsTitle')}
            </Typography>
            <Typography variant="body2">
              {t('components.Modals.variants.GettingStarted.tipsCopy')}
            </Typography>
          </Step>,
   */
  let trackCopy: string = t(
    'components.Modals.variants.GettingStarted.trackCopy',
    {
      brandName,
    }
  )
  let workCopy: string = t('components.Modals.variants.GettingStarted.workCopy')
  if (brandName === 'TopInterview') {
    trackCopy = t(
      'components.Modals.variants.GettingStarted.trackCopyTopInterview',
      {
        brandName,
      }
    )
    workCopy = t(
      'components.Modals.variants.GettingStarted.workCopyTopInterview',
      {
        brandName,
      }
    )
  }

  return (
    <MultiStepModal
      {...props}
      steps={[
        <Step heroImageURL={docsSVG}>
          <Typography variant="h2">
            {t(
              `components.Modals.variants.GettingStarted.welcomeTitle${
                user.first_name ? '' : 'NoName'
              }`,
              { user }
            )}
          </Typography>
          <Typography variant="body2">
            {t('components.Modals.variants.GettingStarted.welcomeCopy')}
          </Typography>
        </Step>,
        <Step heroImageURL={iphoneSVG}>
          <Typography variant="h2">
            {t('components.Modals.variants.GettingStarted.trackTitle')}
          </Typography>
          <Typography variant="body2">{trackCopy}</Typography>
        </Step>,
        <Step heroImageURL={messagesSVG}>
          <Typography variant="h2">
            {t('components.Modals.variants.GettingStarted.workTitle')}
          </Typography>
          <Typography variant="body2">{workCopy}</Typography>
        </Step>,
      ]}
    />
  )
}

export default GettingStartedModal
