import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import { useWrapperStyles } from './Wrapper'

const Loading: React.FC = () => {
  const classes = useWrapperStyles()
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t('components.Scheduler.Loading.header')}
      </Typography>
      <Typography className={classes.bodyCopy}>
        {t('components.Scheduler.Loading.copy')}
      </Typography>
    </>
  )
}

export default Loading
