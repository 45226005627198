import { AppThunkAction } from 'store'
import { UserIdentifier } from 'store/user/types'
import { APIError } from 'utils/axios'
import { fetchUserBrand, FetchUserBrandSuccess } from './actions'
import { UserBrand } from './types'

export const fetchUserBrandThunk =
  (userIdentifier: UserIdentifier): AppThunkAction<UserBrand | APIError> =>
  async (dispatch): Promise<UserBrand | APIError> => {
    try {
      const val = (await dispatch(
        fetchUserBrand(userIdentifier)
      )) as unknown as FetchUserBrandSuccess

      return val.payload.data
    } catch (e) {
      return e as APIError
    }
  }
