import React, { useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'

import { menuNotifications } from 'store/notifications/selectors'
import { AppState } from 'store'
import { Notification } from 'store/notifications/types'
import NotificationModalContainer from 'components/Modals/NotificationModalContainer'
import NotificationMenu from 'components/Notifications/NotificationMenu'

const useStyles = makeStyles((theme) =>
  createStyles({
    badge: {
      right: '20%',
      top: '20%',
    },
    button: {
      color: theme.palette.primary.dark,
    },
    label: {
      flexDirection: 'column',
    },
    NotificationsIcon: {
      height: '2.1em',
      width: '2em',
      [theme.breakpoints.down('sm')]: {
        height: '1.8em',
        width: '1.5em',
      },
    },
    bellOpen: {
      transform: 'rotate(30deg)',
    },
    text: {
      color: theme.palette.primary.dark,
      fontSize: '.75em',
      fontWeight: 600,
    },
  })
)

const NotificationsMenu: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [notificationIDForModal, setNotificationIDForModal] = useState<
    number | null
  >(null)

  const notifications = useSelector<AppState, Notification[]>((state) =>
    menuNotifications(state.notifications)
  )

  const handleClick = () => setOpen((o) => !o)
  const handleClose = () => {
    setOpen(false)
  }

  const hasUnread = (notifications: Notification[]) => {
    return Object.values(notifications).filter((n) => !n.viewed_at).length > 0
  }

  const handleNotificationClick = useCallback(
    (notificationID: number) => {
      setNotificationIDForModal(notificationID)
      setOpen(false)
    },
    [setNotificationIDForModal]
  )

  const handleModalClose = useCallback(
    () => setNotificationIDForModal(null),
    [setNotificationIDForModal]
  )

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        classes={{ root: classes.button, label: classes.label }}
        onClick={handleClick}
        ref={anchorRef}
      >
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          classes={{ anchorOriginTopRightRectangle: classes.badge }}
          color="error"
          variant={hasUnread(notifications) ? 'dot' : 'standard'}
          className={clsx({
            [classes.bellOpen]: open,
          })}
        >
          <NotificationsIcon className={classes.NotificationsIcon} />
        </Badge>
        <Typography className={classes.text}>
          {t(`components.Navigation.updates`)}
        </Typography>
      </IconButton>
      {/*
        @TODO This needs some mobile love. I think we can get this close with
        the current structure.
        @TODO Do we want to animate this popping out? Material UI has some good
        transition components that work with the Popper. You can see some stuff
        below.
        https://material-ui.com/components/menus/#menulist-composition
      */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 10000,
        }}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: 'scrollParent',
          },
          // @TODO The Popper can do the arrowing
          // https://material-ui.com/components/popper/#scroll-playground
          // arrow: {
          //   enabled: true,
          //   element: arrowRef.current,
          // },
        }}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={10}>
            <NotificationMenu
              notifications={notifications}
              onNotificationClick={handleNotificationClick}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
      <NotificationModalContainer
        notificationID={notificationIDForModal}
        onModalClose={handleModalClose}
      />
    </>
  )
}

export default NotificationsMenu
