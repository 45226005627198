import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { File } from 'store/files/types'
import { formatBytes } from 'utils/formatting'
import paperClipImg from 'assets/images/attach-file.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      '& > *': {
        width: '48%',
      },
    },
    attachment: {
      display: 'flex',
      flexDirection: 'row',
      textDecoration: 'none',
      padding: '0.5rem',

      '&:hover, &:focus': {
        backgroundColor: theme.palette.background.default,
      },
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
      width: '40px',
      height: '40px',
      borderRadius: '4px',
      marginRight: '1rem',
    },
    text: {
      overflow: 'hidden',
    },
    filename: {
      textOverflow: 'ellipsis',
      fontSize: '0.9rem',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    fileSize: {
      fontSize: '0.8rem',
    },
  })
)

const MessageAttachment: React.FC<File> = (attachment) => {
  const classes = useStyles()

  return (
    <Paper
      variant="outlined"
      // The following `component` prop throws the following TS error while building:
      // TS2322: Type '"a"' is not assignable to type 'ElementType<HTMLAttributes<HTMLElement>> | undefined'.
      // This should be resolved once we upgrade Mui to v5
      // @ts-ignore
      component="a"
      className={classes.attachment}
      // @ts-ignore
      href={`/api/v2/files/download/oms/${attachment.id}`}
    >
      <img src={paperClipImg} className={classes.icon} alt="paper clip icon" />
      <Box className={classes.text}>
        <Typography className={classes.filename}>
          {attachment.filename}
        </Typography>
        <Typography className={classes.fileSize}>
          {formatBytes(attachment.size)}
        </Typography>
      </Box>
    </Paper>
  )
}

interface Props {
  attachments: File[]
}

const MessageAttachments: React.FC<Props> = ({ attachments }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {attachments.map((attachment) => (
        <MessageAttachment key={attachment.id} {...attachment} />
      ))}
    </Box>
  )
}

export default MessageAttachments
