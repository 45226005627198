import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { format as dateFormat } from 'date-fns'

import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { colors } from 'styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import UpsellIcon from './UpsellIcon'

import SvgTwoDoc from 'assets/images/upsell-anim-two-doc.svg'
import SvgOneDoc from 'assets/images/upsell-anim-one-doc.svg'
import SvgRecruiter from 'assets/images/upsell-anim-recruiter.svg'
import PngTopInterview from 'assets/images/upsell-anim-top-interview.png'
import SvgResumeRabbit from 'assets/images/upsell-anim-resume-rabbit.svg'
import SvgBackground from 'assets/images/upsell-anim-countdown-background.svg'
import SvgBackgroundLinkedin from 'assets/images/upsell-anim-linkedin-background.svg'
import SvgMiniLinkedin from 'assets/images/upsell-anim-linkedin-mini.svg'
import SvgPlusSign from 'assets/images/upsell-anim-plus-sign.svg'

import { ItemTypes, timeSensitiveTypes } from 'store/items/types'
import { CountUpText } from 'components/shared/CountUpText'

interface Props {
  expirationDate: Date | null
  itemType: ItemTypes
  itemName: string
  itemsInOrder?: number
}

interface CountdownProps {
  endDate: Date | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      maxHeight: 'inherit',
      maxWidth: 'inherit',
      width: '100%',
      height: 'auto',
    },
    docs: {
      paddingLeft: '4rem',
      paddingRight: '4rem',
    },
    topInterview: {
      paddingLeft: '-1rem',
      paddingRight: '-1rem',
      maxWidth: '25em',
    },
    countdownContainer: {
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '15rem',
    },
    digitContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      marginLeft: '0.3rem',
      marginRight: '0.3rem',
    },
    digitPaper: {
      width: '100%',
      height: '4rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blue[100],
    },
    digit: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    units: {
      fontSize: '0.8rem',
      marginTop: '0.5rem',
    },

    timer: {
      maxWidth: '10rem',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundImage: `url(${SvgBackground})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    offer: {
      fontWeight: 400,
      marginBottom: '0.5rem',
      fontSize: '1rem',
    },
    linkedInIconContainer: {
      border: `7px solid ${colors.blue.a400}`,
      borderRadius: '100%',
      width: '7em',
      height: '7em',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
    },
    linkedInContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundImage: `url(${SvgBackgroundLinkedin})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    miniLinkedin: {
      position: 'absolute',
      top: '0em',
      left: '4.5em',
      width: '1.8em',
      height: '1.8em',
    },
    linkedinPercent: {
      color: colors.blue.a400,
    },
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    plusSign: {
      animation: `$spin 1.5s infinite cubic-bezier(0,1,1,0)`,
      width: '2em',
      height: '2em',
    },
    docIconContainer: {
      position: 'relative',
      width: 'initial',
    },
    docImg: {
      width: '15em',
    },
    plusSignContainer: {
      position: 'absolute',
      top: '30%',
      left: '54.8%',
      width: '2em',
      height: '2em',
    },
    plusSignContainerMultiDoc: {
      position: 'absolute',
      top: '28%',
      left: '41.3%',
      width: '2em',
      height: '2em',
    },
  })
)

export const AnimatedLinkedinIcon: React.FC = () => {
  const classes = useStyles()
  return (
    <Container disableGutters className={classes.linkedInContainer}>
      <Container disableGutters className={classes.linkedInIconContainer}>
        <Typography variant="h1" className={classes.linkedinPercent}>
          <CountUpText end={97} />%
        </Typography>
        <img
          src={SvgMiniLinkedin}
          className={classes.miniLinkedin}
          alt="linkedin"
        />
      </Container>
    </Container>
  )
}

const AnimatedDocumentIcon: React.FC = () => {
  const classes = useStyles()

  return (
    <Container disableGutters className={classes.docIconContainer}>
      <img src={SvgOneDoc} className={classes.docImg} alt="document" />
      <div className={classes.plusSignContainer}>
        <img src={SvgPlusSign} className={classes.plusSign} alt="plus sign" />
      </div>
    </Container>
  )
}

const AnimatedMultiDocumentIcon: React.FC = () => {
  const classes = useStyles()

  return (
    <Container disableGutters className={classes.docIconContainer}>
      <img src={SvgTwoDoc} className={classes.docImg} alt="document" />
      <div className={classes.plusSignContainerMultiDoc}>
        <img src={SvgPlusSign} className={classes.plusSign} alt="plus sign" />
      </div>
    </Container>
  )
}

export const CountdownTimer: React.FC<CountdownProps> = ({ endDate }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [diff, setDiff] = useState(
    (endDate || new Date()).getTime() - new Date().getTime()
  )

  const nDays = Math.floor(diff / (1000 * 60 * 60 * 24))
  const nHours = Math.floor((diff / (1000 * 60 * 60)) % 24)
  const nMinutes = Math.floor((diff / 1000 / 60) % 60)
  const nSeconds = Math.floor((diff / 1000) % 60)

  useEffect(() => {
    let intervalID: NodeJS.Timeout
    if (endDate) {
      intervalID = setInterval(() => {
        setDiff(endDate.getTime() - new Date().getTime())
      }, 1000)
    }
    return function cleanup() {
      if (intervalID) clearInterval(intervalID)
    }
  }, [endDate])

  return (
    <Container disableGutters className={classes.container}>
      <Typography variant="body1" align="center" className={classes.offer}>
        {t(`components.Upsells.actFast`)}&nbsp;
        {dateFormat(
          endDate || new Date(),
          t('components.Upsells.expirationDateFormat')
        )}
      </Typography>
      <Container disableGutters className={classes.countdownContainer}>
        <Container disableGutters className={classes.digitContainer}>
          <Paper className={classes.digitPaper} elevation={5}>
            <Typography variant="h2" className={classes.digit}>
              {nDays}
            </Typography>
          </Paper>
          <Typography variant="body1" className={classes.units}>
            {t(`components.Upsells.units.day`)}
          </Typography>
        </Container>
        <Container disableGutters className={classes.digitContainer}>
          <Paper className={classes.digitPaper} elevation={5}>
            <Typography variant="h2" className={classes.digit}>
              {nHours}
            </Typography>
          </Paper>
          <Typography variant="body1" className={classes.units}>
            {t(`components.Upsells.units.hour`)}
          </Typography>
        </Container>
        <Container disableGutters className={classes.digitContainer}>
          <Paper className={classes.digitPaper} elevation={5}>
            <Typography variant="h2" className={classes.digit}>
              {nMinutes}
            </Typography>
          </Paper>
          <Typography variant="body1" className={classes.units}>
            {t(`components.Upsells.units.minute`)}
          </Typography>
        </Container>
        <Container disableGutters className={classes.digitContainer}>
          <Paper className={classes.digitPaper} elevation={5}>
            <Typography variant="h2" className={classes.digit}>
              {nSeconds}
            </Typography>
          </Paper>
          <Typography variant="body1" className={classes.units}>
            {t(`components.Upsells.units.second`)}
          </Typography>
        </Container>
      </Container>
    </Container>
  )
}

const AnimatedUpsellIncon: React.FC<Props> = ({
  expirationDate,
  itemType,
  itemName,
  itemsInOrder = 1,
}) => {
  const classes = useStyles()
  const now = new Date()
  const diff = expirationDate || now.getTime() - now.getTime()

  let icon: string | null = null
  let className = ''

  switch (itemType) {
    case ItemTypes.RecruiterService:
      icon = SvgRecruiter
      break
    case ItemTypes.InterviewPrep:
      icon = PngTopInterview
      className = classes.topInterview
      break
    case ItemTypes.PostingService:
      icon = SvgResumeRabbit
      break
    case ItemTypes.ThankYouLetter:
    case ItemTypes.CoverLetter:
      icon = itemsInOrder > 1 ? SvgTwoDoc : SvgOneDoc
      className = classes.docs
      break
  }

  if (expirationDate && diff > 0 && timeSensitiveTypes.has(itemType)) {
    return <CountdownTimer endDate={expirationDate} />
  }
  if (itemType === ItemTypes.LinkedInDocument) {
    return <AnimatedLinkedinIcon />
  }
  if (
    itemType === ItemTypes.CoverLetter ||
    itemType === ItemTypes.ThankYouLetter
  ) {
    if (itemsInOrder > 1) {
      return <AnimatedMultiDocumentIcon />
    }
    return <AnimatedDocumentIcon />
  }

  if (icon) {
    return (
      <Container disableGutters>
        <img
          alt={`${itemName} icon`}
          className={clsx(classes.img, className)}
          src={icon}
        />
      </Container>
    )
  }

  return <UpsellIcon itemType={itemType} itemName={itemName} />
}

export default AnimatedUpsellIncon
