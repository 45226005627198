import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

const Wrapper = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderTop: `.31em solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: '800px',
    padding: '2.8em',
    width: '100%',
  },
}))(Paper)

export default Wrapper
