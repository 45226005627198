import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useUser from 'hooks/useUser'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import UpsellIcon from './UpsellIcon'
import LetterPlaceHolder from 'components/Documents/LetterPlaceholder'
import LinkedinPlaceholder from 'components/Documents/LinkedinPlaceholder'

import { AppState } from 'store'
import { purchaseUrl } from 'store/upsells/selectors'
import { Upsell } from 'store/upsells/types'

import { ItemTypes } from 'store/items/types'

interface Props {
  upsell: Upsell
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      display: 'flex',

      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },

      padding: '1rem',
    },
    icon: {
      alignSelf: 'center',
      maxWidth: '14.25em',
      width: '50%',
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '0.5rem',

      marginBottom: '0.5rem',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
      },
      [theme.breakpoints.down('md')]: {
        flexShrink: 3,
      },
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    upsellName: {
      fontSize: '1.63rem',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    shortPitch: {
      color: theme.palette.secondary.main,
      fontSize: '1rem',
      lineHeight: 1.56,
      marginTop: '0.2rem',
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
    },
    button: {
      fontSize: '1rem',
      marginTop: '2rem',
      width: '25%',
    },
    caption: {
      fontSize: '0.8rem',
      color: theme.palette.secondary.main,
      lineHeight: 1.56,
      marginTop: '0.5rem',
    },
  })
)

const DocumentUpsell: React.FC<Props> = ({ upsell }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const planCodes = upsell ? [upsell.plan_code] : []
  const upsellUrl = useSelector<AppState, string | null>((state) =>
    purchaseUrl(state.user, state.brand, planCodes, 'document')
  )
  const shortPitch =
    t(`components.Upsells.shortPitch.${upsell.items[0].item_type_key}`) ||
    upsell.short_pitch

  const isLinkedIn =
    (upsell.items[0]?.item_type_key || '') === ItemTypes.LinkedInDocument
  return (
    <Container disableGutters>
      <Container disableGutters className={classes.iconContainer}>
        <UpsellIcon
          itemType={upsell.items[0].item_type_key}
          itemName={upsell.items[0].name}
          className={classes.icon}
        />
      </Container>
      <Container disableGutters className={classes.bodyContainer}>
        <Typography variant="h2" align="center" className={classes.upsellName}>
          {upsell.name}
        </Typography>
        <Typography variant="caption" className={classes.shortPitch}>
          {shortPitch}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          href={upsellUrl || '#'}
        >
          {t(`components.Upsells.addToOrder`)}
        </Button>
        <Typography variant="caption" className={classes.caption}>
          {t('components.Upsells.notTooLate')}
        </Typography>
        <Container disableGutters>
          {isLinkedIn && <LinkedinPlaceholder user={user} />}

          {!isLinkedIn && <LetterPlaceHolder user={user} />}
        </Container>
      </Container>
    </Container>
  )
}

export default DocumentUpsell
