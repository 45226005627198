import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useEffectOnce from 'react-use/lib/useEffectOnce'
import * as Sentry from '@sentry/browser'

import UnauthenticatedPageShell from 'pages/PageShell/Unauthenticated'
import GhostLink from 'components/shared/GhostLink'
import PlainLinkButton from 'components/shared/PlainLinkButton'
import ComputerErrorIcon from 'components/shared/icons/ErrorComputer'

interface Props {
  code: number
}

const ErrorPage: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  useEffectOnce(() => {
    Sentry.captureMessage('404 page rendered')
  })

  return (
    <UnauthenticatedPageShell>
      <Box className={classes.root}>
        <Typography variant="h1">{t('pages.Error.title')}</Typography>
        <Typography variant="body1">
          {t('pages.Error.copy1', { code })}
        </Typography>
        <Typography variant="body1">
          <Trans
            i18nKey="pages.Error.copy2"
            components={{
              back: (
                <PlainLinkButton
                  onClick={() => history.goBack()}
                  className={classes.link}
                />
              ),
              home: <GhostLink to="/" className={classes.link} />,
            }}
          />
        </Typography>
        <ComputerErrorIcon errorCode={code} className={classes.icon} />
      </Box>
    </UnauthenticatedPageShell>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
    },
    icon: {
      width: '100%',
    },
  })
)

export default ErrorPage
