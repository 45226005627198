import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Input from '@material-ui/core/Input'

interface Props {
  amount: number
  updateQuantity: (quantity: number) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      fontSize: '1.2em',
      minWidth: 'initial !important',
      padding: '0 .5em !important',
    },
    container: {
      marginLeft: 'initial',
      marginRight: 'initial',
    },
    containerButtons: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    containerMain: {
      height: '2em',
      position: 'relative',
      width: '5em',
    },
    input: {
      height: '100%',
      padding: '0',
      textAlign: 'center',
    },
    inputComponent: {
      border: `1px solid ${colors.gray.b600}`,
      borderRadius: '6px',
      boxShadow: 'none',
      height: '100%',
      padding: '0 1em 0 1.5em',
    },
  })
)

const NumberInput: React.FC<Props> = ({ amount, updateQuantity }) => {
  const classes = useStyles()

  return (
    <Container
      className={clsx(classes.container, classes.containerMain)}
      disableGutters
    >
      <Input
        classes={{ input: classes.input, root: classes.inputComponent }}
        type="number"
        value={amount}
      />
      <Container
        className={clsx(classes.container, classes.containerButtons)}
        disableGutters
      >
        <Button
          className={classes.button}
          color="primary"
          disabled={amount < 2}
          onClick={() => updateQuantity(amount - 1)}
          variant="text"
        >
          -
        </Button>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => updateQuantity(amount + 1)}
          variant="text"
        >
          +
        </Button>
      </Container>
    </Container>
  )
}

export default NumberInput
