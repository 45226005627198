import React from 'react'
import { ItemTypes } from 'store/items/types'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import PngAccelerateSearch from 'assets/images/bundle-accelerate-search-rect.png'
import PngNextStep from 'assets/images/bundle-next-step-rect.png'
import PngDigitallySavvy from 'assets/images/bundle-digitally-savvy-rect.png'
import PngWinInterview from 'assets/images/bundle-win-interview-rect.png'
import PngGetWordOut from 'assets/images/bundle-get-word-out-rect.png'
import PngExpertConnect from 'assets/images/bundle-expert-connect-rect.png'
import PngWriterExpertise from 'assets/images/bundle-writer-expertise-rect.png'
import SingleItemHeroImage from 'components/Product/SingleItemHeroImage'

import { Bundles } from 'store/upsells/types'
import { HashMap } from '@talentinc/state-utils'

interface Props {
  itemType: ItemTypes
  itemName: string
  bundle: Bundles | null
  className?: string
}

const iconMap: HashMap<string> = {
  [Bundles.NextStep]: PngNextStep,
  [Bundles.DigitallySavvy]: PngDigitallySavvy,
  [Bundles.AccelerateSearch]: PngAccelerateSearch,
  [Bundles.WinInterview]: PngWinInterview,
  [Bundles.ExpertConnect]: PngExpertConnect,
  [Bundles.GetWordOut]: PngGetWordOut,
  [Bundles.WriterExpertise]: PngWriterExpertise,
}

const CatalogBundleItemIcon: React.FC<Props> = ({
  itemType,
  itemName,
  bundle,
  className,
}) => {
  const classes = useStyles()

  const icon = iconMap[bundle || '']

  if (!icon)
    return (
      <SingleItemHeroImage
        itemType={itemType || ItemTypes.Resume}
        itemName={itemName}
      />
    )
  return (
    <img
      alt={`${itemName} icon`}
      className={clsx(classes.img, className)}
      src={icon}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: '100%',
    },
  })
)

export default CatalogBundleItemIcon
