import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import rematchedImage from 'assets/images/rematched.svg'

const MatchedWithNewWriterModal: React.FC<ModalProps> = ({
  callToActionProps,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.MatchedWithNewWriter.cta'),
        ...callToActionProps,
      }}
      heroImageURL={rematchedImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.MatchedWithNewWriter.title')}
      </Typography>
      <Typography variant="body2">
        {t('components.Modals.variants.MatchedWithNewWriter.copy')}
      </Typography>
    </ModalWrapper>
  )
}

export default MatchedWithNewWriterModal
