import React, { useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { useTranslation } from 'react-i18next'

import { File } from 'store/files/types'
import { AppState } from 'store'
import {
  latestDraftForOrderItem,
  documentIsFromAutoLinkedin,
} from 'store/files/selectors'
import DocumentImages from 'components/Documents/DocumentImages'
import LinkedInPreviewContainer from 'components/Documents/LinkedInPreviewContainer'
import { FallbackErrorBoundary } from 'components/shared/Errors'

interface Props {
  open: boolean
  handleClose: () => void
  itemID: number | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      margin: '2.5em 0 3em',
      '&:nth-of-type(1)': {
        display: 'none',
      },
    },
    img: {
      marginBottom: '1em',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
        boxShadow: theme.shadows[6],
      },
    },
    paper: {
      borderRadius: '0 0 6px 6px',
      marginBottom: '1.6em',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        marginTop: '0',
      },
    },
    title: {
      marginTop: '0',
      marginBottom: '0',
    },
  })
)

export const DocumentModal: React.FC<Props> = ({
  open,
  handleClose,
  itemID,
}) => {
  const classes = useStyles()
  const [viewMode, setViewMode] = useState<'images' | 'preview'>('images')
  const [linkedinFailedToRender, setLinkedinFailedToRender] = useState(false)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const doc = useSelector<AppState, File | null>((state) =>
    latestDraftForOrderItem(state.files, itemID)
  )

  const handleChange = useCallback((value: 'images' | 'preview' | '') => {
    if (value) {
      setViewMode(value)

      if (scrollRef.current) {
        Array.from(scrollRef.current.querySelectorAll('div')).forEach((el) =>
          el.scroll(0, 0)
        )
      }
    }
  }, [])

  if (!itemID) {
    return null
  }

  if (!doc) {
    return null
  }

  const isAutoLinkedin =
    documentIsFromAutoLinkedin(doc) && !linkedinFailedToRender

  return (
    <>
      <Dialog
        open={open}
        scroll="body"
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        ref={scrollRef}
      >
        <DialogTitle disableTypography className={classes.title}>
          <Grid container justify="flex-end" direction="row">
            <Grid item>
              <IconButton component="span" onClick={handleClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {!isAutoLinkedin && <DocumentImages documentImageURLs={doc.pages} />}
        {isAutoLinkedin && doc.order_item_id && (
          <FallbackErrorBoundary
            fallbackRender={() => (
              <DocumentImages documentImageURLs={doc.pages} />
            )}
            onError={() => setLinkedinFailedToRender(true)}
          >
            {viewMode === 'preview' ? (
              <LinkedInPreviewContainer omsItemID={doc.order_item_id} />
            ) : (
              <DocumentImages documentImageURLs={doc.pages} />
            )}
          </FallbackErrorBoundary>
        )}
      </Dialog>
      {isAutoLinkedin && (
        <ViewSelectorToggle
          exclusive
          value={viewMode}
          onChange={(_, value) => handleChange(value)}
          id="blah"
        >
          <ViewSelectorButton value="images">
            {t('components.LinkedInPreview.tabs.howTo')}
          </ViewSelectorButton>
          <ViewSelectorButton value="preview">
            {t('components.LinkedInPreview.tabs.preview')}
          </ViewSelectorButton>
        </ViewSelectorToggle>
      )}
    </>
  )
}

const ViewSelectorToggle = withStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1400,
    bottom: '2em',
    // @TODO Change this calculation once you get icons
    left: 'calc(50% - (349px / 2))',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '.5em',
  },
}))(ToggleButtonGroup)

const ViewSelectorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: 'transparent',
    textTransform: 'initial',
    border: 0,
  },
  selected: {
    color: 'white !important',
    backgroundColor: '#898989 !important',
  },
}))(ToggleButton)

export default DocumentModal
