import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import PageShell from 'pages/PageShell'
import ShoppingCartContainer from 'components/ShoppingCart/ShoppingCartContainer'

const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      width: '72em',
    },
  })
)

const UpgradePage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PageShell
      containerClass={classes.contentContainer}
      pageTitle={t('pages.Upgrade.pageTitle')}
    >
      <ShoppingCartContainer />
    </PageShell>
  )
}

export default UpgradePage
