import { AppThunkAction } from 'store'
import { APIError } from 'utils/axios'
import { fetchExperimentsForQuery } from './actions'
import { experimentNeedsLoading } from './selectors'
import { ExperimentQuery } from './types'

export const fetchExperimentsForQueryThunk =
  (query: ExperimentQuery): AppThunkAction<null | APIError> =>
  async (dispatch, getState): Promise<null | APIError> => {
    try {
      if (experimentNeedsLoading(getState().experiments, query)) {
        dispatch(fetchExperimentsForQuery('me', query))
      }
      return null
    } catch (e) {
      return e as APIError
    }
  }
