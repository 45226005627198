import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import { User } from 'store/user/types'
import PersonalInfoItem from './PersonalInfoItem'
import { OnUpdateUser } from './types'

interface Props {
  user: Partial<User>
  onUpdateUser: OnUpdateUser
}

const useStyles = makeStyles((theme) =>
  createStyles({
    h2: {
      padding: '1.5em 1.6em 1.3em',
    },
    list: {
      padding: '0 2em',
    },
  })
)

const LoginInfo: React.FC<Props> = ({ user, onUpdateUser }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.h2} variant="h2">
        {t('pages.Account.loginTitle')}
      </Typography>
      <Divider />
      <List className={classes.list}>
        <PersonalInfoItem
          fieldName="password"
          onUpdateUser={onUpdateUser}
          formFields={[
            {
              label: 'current_password',
              value: '',
              type: 'password',
            },
            {
              label: 'new_password',
              value: '',
              type: 'password-strength',
            },
            {
              label: 'new_password_verification',
              value: '',
              type: 'password',
            },
          ]}
        />
      </List>
    </>
  )
}

export default LoginInfo
