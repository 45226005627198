import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Box from '@material-ui/core/Box'

import NotificationItem from './NotificationItem'
import { Notification } from 'store/notifications/types'

interface NotificationMenuProps {
  notifications: Notification[]
  onNotificationClick: (id: number) => void
}

//placeholder styles
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: 1000,
    },
    list: {
      maxHeight: '80vh',
      overflow: 'scroll',
    },
    notificationHeader: {
      lineHeight: '40px',
      fontWeight: 'bold',
    },
  })
)

const NotificationMenu: React.FC<NotificationMenuProps> = ({
  notifications,
  onNotificationClick,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <List
      className={classes.list}
      subheader={
        <Box display="flex" justifyContent="center">
          <ListSubheader className={classes.notificationHeader}>
            {t(`components.Notifications.menu_title`)}
          </ListSubheader>
        </Box>
      }
    >
      <>
        {!notifications.length && (
          <ListItem>{t(`components.Notifications.no_notifications`)}</ListItem>
        )}
        {notifications.map((n) => (
          <NotificationItem
            key={n.id}
            notification={n}
            onClick={() => onNotificationClick(n.id)}
          />
        ))}
      </>
    </List>
  )
}

export default NotificationMenu
