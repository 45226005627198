import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/Help'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { NextStepCTAProps, PreparednessStatus } from 'store/items/types'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { nextStepCTAProps } from 'store/items/selectors'

import useShoppingCart from 'hooks/useShoppingCart'

interface Props {
  preparednessStatus: PreparednessStatus
}

const NextStepCTA: React.FC<Props> = ({ preparednessStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const tRef = 'components.ProgressWidget.cta'
  const updateRequired = preparednessStatus === PreparednessStatus.UpdateNeeded

  const { cartPlanCodes, addToCart, updateOpen } = useShoppingCart()

  const onHeaderClick = useCallback(
    async (planCode: string) => {
      if (cartPlanCodes.includes(planCode)) {
        updateOpen(true)
        return
      }
      addToCart(planCode, 'progress-widget')
      updateOpen(true)
    },
    [cartPlanCodes, updateOpen, addToCart]
  )

  const ctaProps = useSelector<AppState, NextStepCTAProps>((state) =>
    nextStepCTAProps(
      state.items,
      state.upsells,
      state.orders,
      preparednessStatus
    )
  )

  let paperClass = classes.defaultPaper
  let textClass = classes.defaultText

  if (preparednessStatus === PreparednessStatus.UpdateNeeded) {
    paperClass = classes.updateNeededPaper
    textClass = classes.updateNeededText
  }

  if (!ctaProps.itemType && !updateRequired) return null

  return (
    <Box className={classes.container}>
      <Paper className={clsx(classes.paper, paperClass)}>
        <Box className={clsx(classes.headerContainer)}>
          {!updateRequired && ctaProps.upsell && (
            <Button
              variant="text"
              onClick={() => {
                if (ctaProps.upsell) onHeaderClick(ctaProps.upsell.plan_code)
              }}
            >
              <HelpIcon className={clsx(classes.icon, textClass)} />
              <Typography
                variant="overline"
                className={clsx(classes.header, textClass)}
              >
                {t(`${tRef}.headerText.${ctaProps.itemType}`)}
              </Typography>
            </Button>
          )}
          {updateRequired && (
            <>
              <HelpIcon className={clsx(classes.icon, textClass)} />
              <Typography
                variant="overline"
                className={clsx(classes.header, textClass)}
              >
                {t(`${tRef}.headerText.updateRequired`)}
              </Typography>
            </>
          )}
        </Box>
        <Divider />
        <Typography
          variant="body1"
          className={clsx(classes.description, textClass)}
        >
          {updateRequired
            ? t(`${tRef}.bodyText.updateRequired`)
            : t(`${tRef}.bodyText.${ctaProps.itemType}`)}
        </Typography>
      </Paper>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '1.5rem',
    },
    paper: {
      padding: '1rem',
    },
    defaultPaper: {
      backgroundColor: colors.blue.p100,
    },
    updateNeededPaper: {
      backgroundColor: colors.gray.p100,
    },
    description: {
      fontSize: '0.9rem',
      marginTop: '1rem',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '0.5rem',
    },
    header: {
      fontWeight: 600,
      lineHeight: '1rem',
      textAlign: 'left',
    },
    defaultText: {
      color: colors.blue.a400,
    },
    updateNeededText: {
      color: colors.white[100],
    },
    icon: {
      marginRight: '0.5rem',
      width: '1.5rem',
      height: '1.5rem',
    },
  })
)

export default NextStepCTA
