import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import EmptyMessages from 'components/Messaging/EmptyMessages'
import MessageComp from 'components/Messaging/Message'
import { MessageWithAttachments } from 'store/messages/types'
import { User } from 'store/user/types'

interface Props {
  messages: MessageWithAttachments[]
  user: Partial<User>
  markMessageAsRead: (messageID: number) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'initial',
      flex: 1,
      overflow: 'scroll',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        paddingBottom: '12em',
      },
    },
  })
)

const Messages: React.FC<Props> = ({ messages, user, markMessageAsRead }) => {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      {messages.length > 0 &&
        messages.map((message, index) => (
          <MessageComp
            message={message}
            user={user}
            key={message.id}
            expandedByDefault={index === 0}
            markMessageAsRead={markMessageAsRead}
          />
        ))}
      {messages.length === 0 && <EmptyMessages />}
    </Container>
  )
}

export default Messages
