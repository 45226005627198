import { useTranslation } from 'react-i18next'
import PageShell from '../PageShell'
import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import PurchaseHistoryContainer from '../../components/PurchaseHistory/PurchaseHistoryContainer'

const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      width: '72em',
    },
  })
)
const PurchaseHistoryPage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PageShell
      containerClass={classes.contentContainer}
      pageTitle={t('pages.PurchaseHistory.pageTitle')}
    >
      <PurchaseHistoryContainer />
    </PageShell>
  )
}

export default PurchaseHistoryPage
