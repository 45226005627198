import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useShoppingCart from 'hooks/useShoppingCart'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SvgShoppingBag from 'components/shared/icons/ShoppingBag'

const useStyles = makeStyles((theme) =>
  createStyles({
    badge: {
      right: '20%',
      top: '20%',
    },
    button: {
      color: theme.palette.primary.dark,
      marginLeft: '1.6em',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '.6em',
      },
    },
    label: {
      flexDirection: 'column',
    },
    ShoppingBasketIcon: {
      height: '2.1em',
      paddingBottom: '.3em',
      width: '1.3em',
      [theme.breakpoints.down('sm')]: {
        height: '1.8em',
        width: '1em',
      },
    },
    text: {
      color: theme.palette.primary.dark,
      fontSize: '.75em',
      fontWeight: 600,
    },
  })
)

interface Props {
  onCheckoutClick?: () => void
}

const Checkout: React.FC<Props> = ({ onCheckoutClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const { cartPlanCodes, updateOpen } = useShoppingCart()

  const onIconClick = useCallback(async () => {
    updateOpen(true)
    if (onCheckoutClick) {
      onCheckoutClick()
    }
  }, [updateOpen, onCheckoutClick])

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        classes={{ root: classes.button, label: classes.label }}
        onClick={onIconClick}
        ref={anchorRef}
      >
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          classes={{ anchorOriginTopRightRectangle: classes.badge }}
          color="error"
          variant={cartPlanCodes.length > 0 ? 'dot' : 'standard'}
        >
          <SvgShoppingBag className={classes.ShoppingBasketIcon} />
        </Badge>
        <Typography className={classes.text}>
          {t(`components.Navigation.cart`)}
        </Typography>
      </IconButton>
    </>
  )
}

export default Checkout
