import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'

import configureStore from 'store'
import { MUITheme } from 'styles'
import { Auth0LockProvider } from 'components/Auth/useAuth0'

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <MuiThemeProvider theme={MUITheme}>
      <CssBaseline />
      <ReduxProvider store={configureStore()}>
        <Auth0LockProvider>{children}</Auth0LockProvider>
      </ReduxProvider>
    </MuiThemeProvider>
  )
}

export default AppProvider
