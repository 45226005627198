import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import PageShell from 'pages/PageShell'
import PortfolioContainer from 'components/Portfolio/Container'
import FlashErrorBoundary from 'components/shared/Errors'

const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      width: '72em',
    },
  })
)

const PortfolioPage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PageShell
      pageTitle={t('pages.Portfolio.pageTitle')}
      containerClass={classes.contentContainer}
    >
      <FlashErrorBoundary>
        <PortfolioContainer />
      </FlashErrorBoundary>
    </PageShell>
  )
}

export default PortfolioPage
