import React from 'react'

const SvgShoppingBag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="34"
    viewBox="0 0 27 34"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#052A4D" fillRule="nonzero">
        <g>
          <path
            d="M13.84 1c-1.915-.093-3.784.603-5.171 1.926C7.282 4.25 6.498 6.083 6.5 8v2h-5c-.552 0-1 .448-1 1v17c0 1.326.527 2.598 1.464 3.536C2.902 32.473 4.174 33 5.5 33h16c1.326 0 2.598-.527 3.536-1.464.937-.938 1.464-2.21 1.464-3.536V11c0-.552-.448-1-1-1h-5V8.2c.045-3.788-2.88-6.95-6.66-7.2zm2.66 9h-6V8c0-1.657 1.343-3 3-3s3 1.343 3 3v2z"
            transform="translate(-1370 -47) translate(1370 47.5)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgShoppingBag
