import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import NextStepCTA from './NextStepCTA'
import PreparationStatusChip from './PreparationStatusChip'
import ProgressStep from './ProgressStep'

import {
  PreparednessStatus,
  ProgressStepProps,
  ProgressWidgetProps,
} from 'store/items/types'
import { progressWidgetProps } from 'store/items/selectors'
import { AppState } from 'store'
import { upsellsFinishedLoading } from 'store/upsells/selectors'
import { ordersFinishedLoading } from 'store/orders/selectors'
import Skeleton from 'components/shared/Skeleton'

const ProgressWidgetContainer: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const widgetProps = useSelector<AppState, ProgressWidgetProps>((state) =>
    progressWidgetProps(state.items, state.orders, state.upsells)
  )

  const upsellsReady = useSelector<AppState, boolean>((state) =>
    upsellsFinishedLoading(state.upsells)
  )

  const ordersReady = useSelector<AppState, boolean>((state) =>
    ordersFinishedLoading(state.orders)
  )

  const getPreparedNessStatus = (
    steps: ProgressStepProps[]
  ): PreparednessStatus => {
    const daysAgo180 = new Date()
    daysAgo180.setDate(daysAgo180.getDate() - 180)

    if (
      steps.filter(
        (step) =>
          step.items.filter(
            (item) =>
              item.lastPurchased &&
              item.lastPurchased.getTime() < daysAgo180.getTime()
          ).length > 0
      ).length > 0
    )
      return PreparednessStatus.UpdateNeeded

    if (
      steps.filter(
        (step) =>
          step.items.filter((item) => !item.lastPurchased).length ===
          step.items.length
      ).length > 0
    )
      return PreparednessStatus.UnderPrepared

    if (
      steps.filter(
        (step) =>
          step.items.filter(
            (item) =>
              item.lastPurchased &&
              item.lastPurchased.getTime() > daysAgo180.getTime()
          ).length === step.items.length
      ).length === steps.length
    )
      return PreparednessStatus.FullyPrepared

    return PreparednessStatus.SomewhatPrepared
  }

  const preparedNessStatus = getPreparedNessStatus(widgetProps.steps)

  if (!upsellsReady || !ordersReady)
    return <Skeleton components={[{ height: '30em', width: '100%' }]} />

  return (
    <Paper className={classes.paper}>
      <Box className={classes.header}>
        <Typography variant="h2" className={classes.headerText}>
          {t(`components.ProgressWidget.title`)}
        </Typography>
        <PreparationStatusChip preparedNessStatus={preparedNessStatus} />
      </Box>
      <Divider />
      <Box className={classes.stepsContainer}>
        {widgetProps.steps.map((step, index) => (
          <ProgressStep
            step={step.step}
            items={step.items}
            hideDivider={widgetProps.steps.length - 1 === index}
            key={step.step}
          />
        ))}
      </Box>
      <Divider />
      <NextStepCTA preparednessStatus={preparedNessStatus} />
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: '1.5rem',
    },
    header: {
      padding: '1.5rem 1rem 1.5rem 1rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerText: {
      fontSize: '1.25rem',
    },
    stepsContainer: {
      padding: '0rem 1rem 0rem 1rem',
    },
  })
)

export default ProgressWidgetContainer
