import React from 'react'
import { useSelector } from 'react-redux'

import isEqual from 'lodash/isEqual'

import { AppState } from 'store'
import { Notification } from 'store/notifications/types'

import { unReadDocumentNotifications } from 'store/notifications/selectors'
import NotificationModalContainer from 'components/Modals/NotificationModalContainer'

interface Props {
  documentType: string
  active: boolean
}

const DocumentNotifications: React.FC<Props> = ({ documentType, active }) => {
  const notifications = useSelector<AppState, Notification[]>(
    (state) => unReadDocumentNotifications(state.notifications, documentType),
    isEqual
  )

  if (notifications.length === 0) {
    return null
  }

  if (!active) {
    return null
  }
  return <NotificationModalContainer notificationID={notifications[0].id} />
}

export default DocumentNotifications
