import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button'

import {
  GhostLinkButton,
  GhostLinkButtonProps,
} from 'components/shared/GhostLink'

// Sometimes you need a button that looks like a link to make aria happy.
const PlainLinkButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: 'inherit',
    textDecoration: 'none',
    lineHeight: 'inherit',

    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}))((props: ButtonProps) => (
  <Button
    variant="text"
    disableRipple={true}
    disableFocusRipple={true}
    focusRipple={false}
    {...props}
  />
))

export const PlainGhostLinkButton: React.FC<GhostLinkButtonProps> = (props) => (
  <PlainLinkButton
    // @ts-ignore
    component={(props) => <GhostLinkButton {...props} />}
    {...props}
  />
)

export default PlainLinkButton
