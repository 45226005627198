import { produce } from 'immer'

import { ExpertsState, initialState } from './types'
import { ExpertAction } from './actions'
import { OrderActions } from 'store/orders/actions'

export default function expertsReducer(
  state: ExpertsState = initialState,
  action: ExpertAction
): ExpertsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OrderActions.FETCH_ORDERS_FOR_USER_SUCCESS: {
        action.payload.data.forEach((order) => {
          order.experts.forEach((expert) => (draft.experts[expert.id] = expert))
        })
      }
    }
  })
}
