import * as ram from '@talentinc/redux-axios-middleware'

import { EventPayload } from './types'
import { UserIdentifier } from 'store/user/types'

export enum EventActions {
  SEND_EVENT = 'SEND_EVENT',
  SEND_EVENT_SUCCESS = 'SEND_EVENT_SUCCESS',
  SEND_EVENT_FAIL = 'SEND_EVENT_FAIL',
}

interface SendEvent extends ram.AxiosMiddlewareActionCreator {
  type: EventActions.SEND_EVENT
}

interface SendEventSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, SendEvent> {
  type: EventActions.SEND_EVENT_SUCCESS
}

interface SendEventFail extends ram.AxiosMiddlewareActionFail<SendEvent> {
  type: EventActions.SEND_EVENT_FAIL
}

export const sendEvent = (
  data: EventPayload,
  userIdentifier?: UserIdentifier
): SendEvent => ({
  type: EventActions.SEND_EVENT,
  payload: {
    request: {
      url: userIdentifier ? `/v2/users/${userIdentifier}/events` : '/v2/events',
      method: 'POST',
      data: {
        variables: {},
        system: 'portal',
        ...data,
      },
    },
  },
})

export type EventAction = SendEvent | SendEventSuccess | SendEventFail
