import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import SharedCTA from 'components/shared/Cta'
import { OrderStatusTypes, OrderStatus } from 'store/orders/types'
import { ApproveOrderCTA } from 'components/Rating/ApproveOrderModalContainer'
import { completedFirstDraft } from 'store/orders/selectors'
import { AppState } from 'store'
import { useSelector } from 'react-redux'
import { hasUnreadNotificationsByType } from 'store/notifications/selectors'
import { NotificationTypes } from 'store/notifications/types'

const FirstDraftCTA: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SharedCTA
      copy={t(
        `components.TimelineWidget.CTA.${OrderStatusTypes.FirstDraft}.reminder`
      )}
      buttonCopy={t(
        `components.TimelineWidget.CTA.${OrderStatusTypes.FirstDraft}.button`
      )}
      to={t(`${isMobile ? 'routes.portfolio' : 'routes.messages'}`)}
    />
  )
}

interface MultiProps {
  completedFD: boolean
}

const MultipleOrderApprovalCTA: React.FC<MultiProps> = ({ completedFD }) => {
  const { t } = useTranslation()

  let approveText = t('components.TimelineWidget.CTA.OrderApproval.button')
  if (!completedFD) {
    approveText = t(
      'components.TimelineWidget.CTA.OrderApproval.buttonDocuments'
    )
  }

  return (
    <SharedCTA
      copy={t('components.TimelineWidget.CTA.OrderApproval.reminder')}
      buttonCopy={approveText}
      to={t('routes.messages')}
    />
  )
}

interface Props {
  orderIDs: Set<number> | number[]
  status: OrderStatus
  variantContext: 'home' | 'portfolio'
}

const CTA: React.FC<Props> = ({ orderIDs, status, variantContext }) => {
  // only prompt for order approval if the first draft has been delivered and the customer has not viewed the documents yet
  const completedFD = useSelector<AppState, boolean>((state: AppState) =>
    completedFirstDraft(state.orders, state.items)
  )
  const hasUnreadFDNotification = useSelector<AppState, boolean>(
    (state: AppState) =>
      hasUnreadNotificationsByType(
        state.notifications,
        NotificationTypes.FirstDraft
      )
  )

  switch (status.status) {
    case OrderStatusTypes.RevisionPeriod:
      // If the customer just got their draft, but has not viewed them (i.e. if the first draft notification is unread)
      // encourage them to view their docs.
      //
      // Also, if this is being shown on the home page, only show the review
      // docs buttons so that the user has to look at their items before
      // approval.
      if (variantContext === 'home' && completedFD && hasUnreadFDNotification) {
        return <FirstDraftCTA />
      }

      // If the customer got their docs more than 24 hours ago AND has multiple
      // orders through upsells, direct them to the messaging pane to approve
      // their order so that this component doesn't need to worry about which
      // orders the user means to approve.
      // also, if not all documents first-drafts have been delivered
      if (
        (Array.isArray(orderIDs) ? orderIDs.length : orderIDs.size) > 1 ||
        completedFD === false
      ) {
        return <MultipleOrderApprovalCTA completedFD={completedFD} />
      }

      // If one order and the customer got their documents more than 24 hours
      // ago, give them a button to launch the order approval modal.
      // only show, if all first-drafts are completed
      return <ApproveOrderCTA orderIDs={Array.from(orderIDs)} />
  }

  return null
}

export default CTA
