import { produce } from 'immer'
import * as stateUtils from '@talentinc/state-utils'

import { SchedulingAction, SchedulingActions } from './actions'
import { SchedulingState, initalState } from './types'

export default function schedulingReducer(
  state: SchedulingState = initalState,
  action: SchedulingAction
): SchedulingState {
  return produce(state, (draft) => {
    switch (action.type) {
      case SchedulingActions.FETCH_SCHEDULED_ITEMS:
        draft.meta[action.type] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break

      case SchedulingActions.FETCH_SCHEDULED_ITEMS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach(
          (item) => (draft.scheduledItems[item.item_id] = item)
        )
        break
      }

      case SchedulingActions.FETCH_SCHEDULED_ITEMS_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }

      case SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS:
        draft.meta[action.type][action.itemID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break

      case SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS_SUCCESS: {
        const { itemID, type: previousActionType } = action.meta.previousAction

        draft.details[itemID] = action.payload.data
        draft.meta[previousActionType][itemID] = stateUtils.setSuccessState(
          state.meta[previousActionType][itemID]
        )
        break
      }

      case SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS_FAIL: {
        const { itemID, type: previousActionType } = action.meta.previousAction
        draft.meta[previousActionType][itemID] = stateUtils.setErrorState(
          state.meta[previousActionType][itemID],
          action.error
        )
        break
      }

      case SchedulingActions.SCHEDULE_ITEM:
        draft.meta[action.type][action.itemID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break

      case SchedulingActions.SCHEDULE_ITEM_SUCCESS: {
        const {
          itemID,
          type: previousActionType,
          start,
        } = action.meta.previousAction
        draft.scheduledItems[itemID].scheduled_start = start
        draft.meta[previousActionType][itemID] = stateUtils.setSuccessState(
          state.meta[previousActionType][itemID]
        )
        break
      }

      case SchedulingActions.SCHEDULE_ITEM_FAIL: {
        const { itemID, type: previousActionType } = action.meta.previousAction
        draft.meta[previousActionType][itemID] = stateUtils.setErrorState(
          state.meta[previousActionType][itemID],
          action.error
        )
        break
      }
    }
  })
}
