import * as ram from '@talentinc/redux-axios-middleware'

import {
  ItemSchedulingDetails,
  ScheduleItemRequest,
  SchedulableItem,
} from './types'
import { UserIdentifier } from 'store/user/types'

export enum SchedulingActions {
  FETCH_SCHEDULED_ITEMS = 'FETCH_SCHEDULED_ITEMS',
  FETCH_SCHEDULED_ITEMS_SUCCESS = 'FETCH_SCHEDULED_ITEMS_SUCCESS',
  FETCH_SCHEDULED_ITEMS_FAIL = 'FETCH_SCHEDULED_ITEMS_FAIL',

  FETCH_SCHEDULED_ITEM_DETAILS = 'FETCH_SCHEDULED_ITEM_DETAILS',
  FETCH_SCHEDULED_ITEM_DETAILS_SUCCESS = 'FETCH_SCHEDULED_ITEM_DETAILS_SUCCESS',
  FETCH_SCHEDULED_ITEM_DETAILS_FAIL = 'FETCH_SCHEDULED_ITEM_DETAILS_FAIL',

  SCHEDULE_ITEM = 'SCHEDULE_ITEM',
  SCHEDULE_ITEM_SUCCESS = 'SCHEDULE_ITEM_SUCCESS',
  SCHEDULE_ITEM_FAIL = 'SCHEDULE_ITEM_FAIL',
}

interface FetchScheduledItems extends ram.AxiosMiddlewareActionCreator {
  type: SchedulingActions.FETCH_SCHEDULED_ITEMS
}

interface FetchScheduledItemsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    SchedulableItem[],
    FetchScheduledItems
  > {
  type: SchedulingActions.FETCH_SCHEDULED_ITEMS_SUCCESS
}

interface FetchScheduledItemsFail
  extends ram.AxiosMiddlewareActionFail<FetchScheduledItems> {
  type: SchedulingActions.FETCH_SCHEDULED_ITEMS_FAIL
}

export const fetchScheduledItems = (
  userIdentifier: UserIdentifier
): FetchScheduledItems => ({
  type: SchedulingActions.FETCH_SCHEDULED_ITEMS,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/scheduling/items`,
    },
  },
})

interface FetchScheduledItemDetails extends ram.AxiosMiddlewareActionCreator {
  type: SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS
  itemID: number
}

interface FetchScheduledItemDetailsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    ItemSchedulingDetails,
    FetchScheduledItemDetails
  > {
  type: SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS_SUCCESS
}

interface FetchScheduledItemDetailsFail
  extends ram.AxiosMiddlewareActionFail<FetchScheduledItemDetails> {
  type: SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS_FAIL
}

export const fetchScheduledItemDetails = (
  userIdentifier: UserIdentifier,
  omsItemID: number
): FetchScheduledItemDetails => ({
  type: SchedulingActions.FETCH_SCHEDULED_ITEM_DETAILS,
  itemID: omsItemID,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/scheduling/items/${omsItemID}`,
    },
  },
})

interface ScheduleItem extends ram.AxiosMiddlewareActionCreator {
  type: SchedulingActions.SCHEDULE_ITEM
  itemID: number
  start: Date
}

interface ScheduleItemSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    Record<string, never>,
    ScheduleItem
  > {
  type: SchedulingActions.SCHEDULE_ITEM_SUCCESS
}

interface ScheduleItemFail extends ram.AxiosMiddlewareActionFail<ScheduleItem> {
  type: SchedulingActions.SCHEDULE_ITEM_FAIL
}

export const scheduleItem = (
  userIdentifier: UserIdentifier,
  itemID: number,
  data: ScheduleItemRequest
): ScheduleItem => ({
  type: SchedulingActions.SCHEDULE_ITEM,
  itemID,
  start: data.slot_start,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/scheduling/items/${itemID}`,
      method: 'POST',
      data,
    },
  },
})

export type SchedulingAction =
  | FetchScheduledItems
  | FetchScheduledItemsSuccess
  | FetchScheduledItemsFail
  | FetchScheduledItemDetails
  | FetchScheduledItemDetailsSuccess
  | FetchScheduledItemDetailsFail
  | ScheduleItem
  | ScheduleItemSuccess
  | ScheduleItemFail
