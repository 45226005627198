import React from 'react'

interface Props extends Omit<React.SuspenseProps, 'fallback'> {
  fallback?: React.SuspenseProps['fallback']
}

const AsyncComponent: React.FC<Props> = ({ children, fallback = null }) => {
  return <React.Suspense fallback={fallback}>{children}</React.Suspense>
}

export default AsyncComponent
