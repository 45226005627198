import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useForm, useField } from 'react-final-form-hooks'

import { InfoItemWrapper } from './wrappers'
import TextItem from './TextItem'

interface LanguageInputProps {
  onCancel: () => void
}

const LanguageInput: React.FC<LanguageInputProps> = ({ onCancel }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { form, handleSubmit } = useForm<{ lng: string }>({
    initialValues: {
      lng: i18n.language,
    },
    onSubmit: ({ lng }) => {
      i18n.changeLanguage(lng)
      onCancel()

      return undefined
    },
  })
  const field = useField<string, { lng: string }>('lng', form)
  const lngs = {
    'en-US': t('pages.Account.LanguageInput.languages.en-US'),
    'en-GB': t('pages.Account.LanguageInput.languages.en-GB'),
  }

  return (
    <Container
      className={classes.enclosingContainer}
      disableGutters
      component="form"
      onSubmit={handleSubmit}
    >
      <Container className={classes.formContainer} disableGutters>
        <FormControl>
          <InputLabel id="language" className={classes.label} shrink={false}>
            Language
          </InputLabel>
          <Select
            classes={{ select: classes.selectInner }}
            className={classes.select}
            labelId="language"
            {...field.input}
          >
            {Object.entries(lngs).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        type="submit"
      >
        {t('pages.Account.save')}
      </Button>
      <Button
        className={classes.cancel}
        color="primary"
        onClick={onCancel}
        variant="text"
      >
        {t('pages.Account.cancel')}
      </Button>
    </Container>
  )
}

const LanguageSelector: React.FC = () => {
  const [editing, setEditing] = useState(false)
  const { t, i18n } = useTranslation()

  return (
    <InfoItemWrapper>
      {!editing ? (
        <TextItem
          fieldName="language"
          handleClick={() => setEditing(true)}
          value={t(`pages.Account.LanguageInput.languages.${i18n.language}`)}
        />
      ) : (
        <LanguageInput onCancel={() => setEditing(false)} />
      )}
    </InfoItemWrapper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      float: 'right',
      marginTop: '0',
      minWidth: '9.8em',
      width: 'initial',
    },
    cancel: {
      lineHeight: '1',
      position: 'absolute',
      right: '0',
      top: '1.78em',
    },
    enclosingContainer: {
      display: 'initial',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    formContainerColumn: {
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    label: {
      fontSize: '1.3em',
      fontWeight: 500,
      marginTop: '-1em',
    },
    select: {
      color: '#66677d',
      height: '2.78em',
      boxShadow: 'none',
      marginTop: '2.75em !important',
      paddingTop: '.5em',
      borderColor: '#e4e8f0',
      paddingBottom: '.5em',
    },
    selectInner: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
)

export default LanguageSelector
