import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AppState } from 'store'
import FlashMessage from 'components/shared/FlashMessage'
import PlainLinkButton from 'components/shared/PlainLinkButton'

interface Props {
  className?: string
}

const AppUpgradeContainer: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()
  const needsUpgrade = useSelector<AppState, boolean>(
    (state) => state.config.needsUpdate
  )

  if (!needsUpgrade) {
    return null
  }

  return (
    <FlashMessage level="info" className={className}>
      {t('components.AppUpgrade.title')}{' '}
      <PlainLinkButton onClick={() => window.location.reload()}>
        {t('components.AppUpgrade.button')}
      </PlainLinkButton>
    </FlashMessage>
  )
}

export default AppUpgradeContainer
