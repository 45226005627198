import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100vh',
    },
    loader: {
      margin: '25vh auto 0',
    },
  })
)

// @TODO This is a very temporary loader
const Loader: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} size={200} />
    </div>
  )
}

export default Loader
