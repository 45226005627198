import { produce } from 'immer'

import * as stateUtils from '@talentinc/state-utils'

import { UpsellsAction, UpsellsActions } from 'store/upsells/actions'
import { UpsellsState, initialUpsellsState } from 'store/upsells/types'

function upsellsReducer(
  state: UpsellsState = initialUpsellsState,
  action: UpsellsAction
): UpsellsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case UpsellsActions.FETCH_UPSELLS:
      case UpsellsActions.FETCH_CART_ITEMS:
      case UpsellsActions.ADD_ITEM_TO_CART:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break
      case UpsellsActions.FETCH_UPSELLS_SUCCESS:
        draft.meta.FETCH_UPSELLS = stateUtils.setSuccessState(
          state.meta.FETCH_UPSELLS
        )
        const upsells = action.payload.data
        upsells.forEach((upsell) => {
          draft.upsells[upsell.id] = upsell
          draft.byPlanCode[upsell.plan_code] = upsell.id
          if (upsell.bundle_groups) {
            upsell.bundle_groups.forEach((bundle) => {
              if (!draft.byBundleGroup[bundle]) {
                draft.byBundleGroup[bundle] = []
              }
              draft.byBundleGroup[bundle].push(upsell.id)
            })
          }
          if (upsell.bundle) {
            if (!draft.byBundle[upsell.bundle]) {
              draft.byBundle[upsell.bundle] = []
            }
            draft.byBundle[upsell.bundle].push(upsell.id)
          }
        })
        break
      case UpsellsActions.FETCH_CART_ITEMS_FAIL:
      case UpsellsActions.FETCH_UPSELLS_FAIL:
      case UpsellsActions.ADD_ITEM_TO_CART_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }
      case UpsellsActions.FETCH_CART_ITEMS_SUCCESS:
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        const items = action.payload.data
        items.forEach((item) => {
          draft.cart[item.id] = item
          draft.cartItemsBySkuID[item.sku_id] = item.id
        })
        break
      case UpsellsActions.ADD_ITEM_TO_CART_SUCCESS:
        draft.meta.FETCH_UPSELLS = stateUtils.setSuccessState(
          state.meta.FETCH_UPSELLS
        )
        const item = action.payload.data
        draft.cart[item.id] = item
        draft.cartItemsBySkuID[item.sku_id] = item.id
        break
      case UpsellsActions.UPDATE_CART_ITEM_QUANTITY:
      case UpsellsActions.REMOVE_ITEM_FROM_CART: {
        draft.meta[action.type][action.cartItemID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }
      case UpsellsActions.UPDATE_CART_ITEM_QUANTITY_FAIL:
      case UpsellsActions.REMOVE_ITEM_FROM_CART_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][action.meta.previousAction.cartItemID] =
          stateUtils.setErrorState(
            state.meta[previousActionType][
              action.meta.previousAction.cartItemID
            ],
            action.error
          )
        break
      }
      case UpsellsActions.REMOVE_ITEM_FROM_CART_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.cartItemID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.cartItemID]
          )
        delete draft.cart[previousAction.cartItemID]

        break
      }
      case UpsellsActions.UPDATE_CART_ITEM_QUANTITY_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.cartItemID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.cartItemID]
          )
        const item = action.payload.data
        draft.cart[item.id] = item

        break
      }
      case UpsellsActions.UPDATE_CART_OPEN:
        draft.shoppingCartOpen = action.open
        break
      case UpsellsActions.ADD_EMAIL_QUERY_PARAM:
        draft.emailQueryParams[action.param] = action.value
        break
    }
  })
}

export default upsellsReducer
