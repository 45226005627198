import { produce } from 'immer'
import * as stateUtils from '@talentinc/state-utils'

import { ExperimentsState, initialState } from './types'
import { ExperimentAction, ExperimentActions } from './actions'

export default function experimentsReducer(
  state: ExperimentsState = initialState,
  action: ExperimentAction
): ExperimentsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL:
        draft.meta = stateUtils.setStartState(state.meta)
        break

      case ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL_SUCCESS:
        action.payload.data.forEach((experimentCase) => {
          draft.experiments[experimentCase.experiment_id] = experimentCase
        })
        draft.meta = stateUtils.setSuccessState(state.meta)
        break

      case ExperimentActions.FETCH_EXPERIMENTS_FOR_PORTAL_FAIL:
        draft.meta = stateUtils.setErrorState(state.meta, action.error)
        break

      case ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY:
        draft.metaByQuery[action.query] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break

      case ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY_SUCCESS: {
        const { query } = action.meta.previousAction
        action.payload.data.forEach((experimentCase) => {
          draft.experiments[experimentCase.experiment_id] = experimentCase
        })
        draft.metaByQuery[query] = stateUtils.setSuccessState(
          state.metaByQuery[query]
        )
        break
      }

      case ExperimentActions.FETCH_EXPERIMENTS_FOR_QUERY_FAIL: {
        const { query } = action.meta.previousAction
        draft.metaByQuery[query] = stateUtils.setErrorState(
          state.metaByQuery[query],
          action.error
        )
        break
      }
    }
  })
}
