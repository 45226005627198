import React from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'

import { AttachmentStateItem } from 'store/attachments/types'
import { formatBytes } from 'utils/formatting'
import useMessageDraft from 'hooks/useMessageDraft'
import fileUploadErrorIcon from 'assets/images/file-upload-error.svg'

interface StyleProps {
  isUploading: boolean
  completed: boolean
  error: AttachmentStateItem['error']
}

const useStyles = makeStyles((theme) =>
  createStyles<string, Partial<StyleProps>>({
    container: {
      margin: '1rem',
    },
    paper: {
      fontSize: '.8em',
      padding: '.3rem .6rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: ({ completed }) =>
        completed ? theme.palette.primary.dark : theme.palette.secondary.light,
    },
    filename: {
      fontWeight: 'bold',
      maxWidth: '60%',
      textOverflow: 'ellipsis',
      color: ({ error }) =>
        !error ? theme.palette.primary.dark : theme.palette.error.dark,
    },
    progress: {
      marginLeft: 'auto',
      width: '30%',
    },
    close: {
      marginLeft: ({ isUploading }) => (isUploading ? '.3rem' : 'auto'),
      color: ({ completed }) =>
        completed ? theme.palette.primary.dark : theme.palette.secondary.light,
    },
    errorChip: {
      width: '100%',
      fontSize: '.8rem',
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
      justifyContent: 'left',
      marginTop: '.6rem',
    },
    errorChipIcon: {
      width: '1rem',
      height: '1rem',
    },
  })
)

interface AttachmentProps {
  attachment: AttachmentStateItem
  removeAttachment: () => void
}

const Attachment: React.FC<AttachmentProps> = ({
  attachment: { attachment, error },
  removeAttachment,
}) => {
  const isUploading = !error && !attachment?.id
  const completed = !error && !!attachment?.id
  const classes = useStyles({ isUploading, error, completed })
  const { t } = useTranslation()

  return (
    <>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h5" className={classes.filename}>
          {attachment?.file_name} ({formatBytes(attachment?.file_size || 0)})
        </Typography>
        {isUploading && <LinearProgress className={classes.progress} />}
        <IconButton className={classes.close} onClick={removeAttachment}>
          <CloseIcon />
        </IconButton>
      </Paper>
      {error && (
        <Chip
          icon={
            <img
              className={classes.errorChipIcon}
              src={fileUploadErrorIcon}
              alt="error sign"
            />
          }
          label={t(`components.Messaging.Attachments.errors.${error}`)}
          className={classes.errorChip}
        />
      )}
    </>
  )
}

const AttachmentsContainer: React.FC = () => {
  const { attachments, removeAttachment } = useMessageDraft()
  const classes = useStyles({})

  if (!attachments.length) {
    return null
  }

  return (
    <Box className={classes.container}>
      {attachments.map((attachment) => (
        <Attachment
          key={attachment.attachment?.file_name}
          attachment={attachment}
          removeAttachment={() =>
            removeAttachment(attachment.attachment?.file_name || '')
          }
        />
      ))}
    </Box>
  )
}

export default AttachmentsContainer
