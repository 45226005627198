export const getGhostIdentifier = (): string | null => {
  const queryParams = new URLSearchParams(window.location.search)
  const ghostID = queryParams.get('ghost')
  return ghostID || null
}

export const getGhostSearchParams = (): Record<string, string> => {
  const queryParams = new URLSearchParams(window.location.search)
  const ghostID = queryParams.get('ghost')
  const dict: Record<string, string> = {}

  if (ghostID) {
    dict.ghost = ghostID
  }

  // @TODO This is a stop gap to get language overrides working
  const lng = queryParams.get('lng')

  if (lng) {
    dict.lng = lng
  }

  return dict
}
