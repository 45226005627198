import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Container from '@material-ui/core/Container'
import { isFirefox } from 'react-device-detect'
import { useSelector } from 'react-redux'

import { OrderStatusTypes } from 'store/orders/types'
import { allItemsInOrderHaveFirstDraft } from 'store/items/selectors'
import { Item, ItemStatusTypes } from 'store/items/types'
import { colors } from 'styles/index'
import { AppState } from 'store'

interface Props {
  item: Item
  rating: number | null
  status: OrderStatusTypes | ItemStatusTypes
  onDownloadClick?: () => void
  onPrintClick?: () => void
  onRateClick?: () => void
  onApproveClick?: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginBottom: '.67em',
      minWidth: '9.8em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
        minWidth: 'initial',
        width: '49%',
      },
    },
    tooltipSpacerSpan: {
      [theme.breakpoints.down('sm')]: {
        width: '49%',

        '& > button': {
          width: '100%',
        },
      },
    },
    buttonFullwidth: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '.67em',
        width: '100%',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: 'initial',
      width: 'initial',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
        borderTop: `1px solid ${colors.gray[600]}`,
        bottom: '0',
        flexDirection: 'row',
        left: '0',
        padding: '1em 1em .33em',
        position: 'fixed',
        width: '100%',
      },
    },
  })
)

const DoubleButton: React.FC<Props> = ({
  item,
  rating,
  status,
  onDownloadClick,
  onPrintClick,
  onApproveClick,
  onRateClick,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // only allow order approval if all first-drafts for the Item's OMS Client ID
  // have been delivered
  const completedFD = useSelector<AppState, boolean>((state: AppState) =>
    item.oms_client_id
      ? allItemsInOrderHaveFirstDraft(state.items, item.oms_client_id)
      : true
  )
  const placement = 'bottom'
  const tooltipText: string = t(`pages.Documents.extraButton.approveDisabled`)

  let extraButton: any | null = (
    <Tooltip
      arrow
      placement={placement}
      title={tooltipText}
      disableFocusListener={completedFD}
      disableHoverListener={completedFD}
      disableTouchListener={completedFD}
    >
      <span className={classes.tooltipSpacerSpan}>
        {/*
          Because this <Button /> can be disabled, we need a spacer <span> to
          allow the tooltip to still work.
          https://material-ui.com/components/tooltips/#disabled-elements

          Fun fact: This comment must be inside the <span> because it will
          require React.Fragment to work outside, which somehow breaks the
          tooltip event listening. Disabled buttons play by their own rules,
          huh?
        */}
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={onApproveClick}
          disabled={!completedFD}
        >
          {t(`pages.Documents.extraButton.approve`)}
        </Button>
      </span>
    </Tooltip>
  )

  if (status === ItemStatusTypes.Complete) {
    if (rating !== null) {
      extraButton = (
        <Button
          className={classes.button}
          onClick={onPrintClick}
          variant="outlined"
        >
          {t(`pages.Documents.extraButton.print`)}
        </Button>
      )

      // Printing doesn't make sense on mobile
      // Printing with print-js doesn't work in Firefox
      // Printing relies on a PDF URL being defined, if it isn't, don't show the
      // print button.
      if (isMobile || isFirefox || !onPrintClick) {
        extraButton = null
      }
    } else {
      extraButton = (
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={onRateClick}
        >
          {t(`pages.Documents.extraButton.${status}`)}
        </Button>
      )
    }
  }

  return (
    <Container className={classes.container} disableGutters>
      <Button
        className={extraButton ? classes.button : classes.buttonFullwidth}
        color="primary"
        variant="contained"
        onClick={onDownloadClick}
      >
        {t('pages.Documents.download')}
      </Button>
      {extraButton}
    </Container>
  )
}

export default DoubleButton
