import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

interface CountupProps {
  interval?: number
  start?: number
  end: number
}

export const CountUpText: React.FC<CountupProps> = ({
  interval = 10,
  start = 0,
  end,
}) => {
  const [value, setValue] = useState(start)
  useEffect(() => {
    let intervalID: NodeJS.Timeout
    if (value < end) {
      intervalID = setTimeout(() => setValue(value + 1), interval)
    }
    return function cleanup() {
      if (intervalID) clearInterval(intervalID)
    }
  }, [value, interval, end])
  return <>{value}</>
}
