import { AppThunkAction } from 'store'
import { UserIdentifier } from 'store/user/types'
import { fetchFilesForUser, fetchLinkedInForItem } from './actions'
import { autoLinkedinItemID } from './selectors'

export const fetchFilesForUserAndRelatedRecords =
  (userIdentifier: UserIdentifier = 'me'): AppThunkAction =>
  async (dispatch, getState) => {
    await dispatch(fetchFilesForUser(userIdentifier))

    const linkedinItemID = autoLinkedinItemID(getState().files)

    if (linkedinItemID) {
      dispatch(fetchLinkedInForItem(linkedinItemID))
    }
  }
