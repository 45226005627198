import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import useUser from 'hooks/useUser'
import confirmedImage from 'assets/images/confirmed.svg'
import { joinConversationally } from 'utils/formatting'

interface Props extends ModalProps {
  items: string[]
}

const OrderReceivedModal: React.FC<Props> = ({
  callToActionProps,
  ...props
}) => {
  const user = useUser()
  const { t } = useTranslation()
  const combinedItems =
    props.items.length > 0
      ? joinConversationally(
          props.items.map((itemType) => t(`itemTypes.${itemType}`))
        )
      : ''
  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.OrderReceived.cta'),
        ...callToActionProps,
      }}
      heroImageURL={confirmedImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.OrderReceived.title')}
      </Typography>
      <Typography variant="body2">
        {t(
          `components.Modals.variants.OrderReceived.copy${
            combinedItems !== '' ? (user.first_name ? '' : 'NoName') : 'NoItems'
          }`,
          {
            name: user.first_name,
            productName: combinedItems,
          }
        )}
      </Typography>
    </ModalWrapper>
  )
}

export default OrderReceivedModal
