import React from 'react'
import { useField, FieldRenderProps } from 'react-final-form-hooks'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles/index'
import clsx from 'clsx'
import TextField from '@material-ui/core/TextField'
import omit from 'lodash/omit'

import { FormFieldType } from './FormItem'
import PasswordStrengthInput from 'components/shared/PasswordStrengthInput'
import useUser from 'hooks/useUser'

interface Props {
  fieldName: string
  form: FormApi
  inputNum: number
  type?: FormFieldType
}

const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: '2em',
      marginTop: '2em',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: 'calc(50% - .5em)',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    fieldColumn: {
      marginBottom: '1em',
    },
    fieldMulti: {
      width: 'calc(50% - .5em)',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    fieldPassword: {
      '&:nth-of-type(1)': {
        [theme.breakpoints.down('md')]: {
          marginRight: '50%',
        },
        [theme.breakpoints.down('xs')]: {
          marginRight: '0',
        },
      },
    },
    input: {
      borderColor: colors.gray[600],
      boxShadow: 'none',
      color: theme.palette.secondary.main,
      height: '2.78em',
      marginTop: '.22em !important',
      paddingBottom: '.5em',
      paddingTop: '.5em',
    },
    inputFocused: {
      borderColor: theme.palette.primary.main,
    },
    label: {
      marginTop: '-3em',
    },
    labelFocused: {
      color: `${theme.palette.secondary.main} !important`,
    },
  })
)

const helperTextFromFieldErrors = (t: TFunction, field: FieldRenderProps) => {
  const errors: string[] = []

  if (!field.meta.error && !field.meta.submitError) {
    return null
  }

  ;[field.meta.error, field.meta.submitError].forEach((errs) => {
    if (Array.isArray(errs)) {
      errs.forEach((err) => {
        errors.push(t(`pages.Account.validation.${err}`))
      })
    }
  })

  return errors
}

const GenericPersonalInfoInput: React.FC<Props> = ({
  fieldName,
  form,
  inputNum,
  type = 'text',
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const field = useField<string>(fieldName, form)

  // Styling if there are multiple inputs in one form item
  const typeClass =
    type !== 'password' && inputNum > 1
      ? classes.fieldMulti
      : classes.fieldPassword

  return (
    <TextField
      className={clsx(classes.field, typeClass)}
      error={
        (!!field.meta.error || !!field.meta.submitError) && field.meta.touched
      }
      helperText={field.meta.touched && helperTextFromFieldErrors(t, field)}
      id={fieldName}
      InputLabelProps={{
        classes: { focused: classes.labelFocused },
        className: classes.label,
        shrink: false,
      }}
      InputProps={{
        classes: { focused: classes.inputFocused, root: classes.input },
      }}
      inputProps={{
        maxLength: type === 'tel' ? 20 : null,
      }}
      label={t(`pages.Account.${fieldName}`)}
      name={fieldName}
      type={type}
      {...omit(field.input, ['name'])}
    />
  )
}

const PersonalInfoInput: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { email } = useUser()

  if (props.type === 'password-strength') {
    return (
      <PasswordStrengthInput
        form={props.form}
        fieldName={props.fieldName}
        email={email}
        TextFieldProps={{
          className: classes.field,
          InputLabelProps: {
            classes: { focused: classes.labelFocused },
            className: classes.label,
            shrink: false,
          },
          InputProps: {
            classes: {
              focused: classes.inputFocused,
              root: classes.input,
            },
          },
          label: t(`pages.Account.${props.fieldName}`),
        }}
      />
    )
  }

  return <GenericPersonalInfoInput {...props} />
}

export default PersonalInfoInput
