import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSearchParam from 'react-use/lib/useSearchParam'
import { LoadingLoadedErrorState } from '@talentinc/state-utils'

import useUser from './useUser'
import { AppState } from 'store'
import { preloadUserByProductsAccessToken } from 'store/preload/actions'

/**
 * usePATAuth will provide a user's products access token when a user is not
 * logged in. This is done by providing a query param of `pat` to any
 * unauthenticated view. This hook will fetch the user record and set some
 * variables in Redux that instructs the app that the user authenticated with
 * a `pat`.
 */
export default function usePATAuth() {
  const pat = useSearchParam('pat')
  const dispatch = useDispatch()
  const user = useUser()
  const { loaded, error, isLoading } = useSelector<
    AppState,
    LoadingLoadedErrorState
  >((store) => store.preload.patPreloadStatus)
  const { isLoading: userIsLoading } = useSelector<
    AppState,
    LoadingLoadedErrorState
  >((store) => store.user.meta.FETCH_USER)

  useEffect(() => {
    if (pat && !user.id && !userIsLoading) {
      dispatch(preloadUserByProductsAccessToken(pat))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pat, user.id, userIsLoading])

  return {
    hasPATParam: !!pat,
    pat: pat || user.auth_token || null,
    loaded,
    error,
    isLoading,
  }
}
