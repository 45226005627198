import * as Sentry from '@sentry/browser'

import { UserState, Groups } from './types'

export const sentryUserFromStore = (state: UserState): Sentry.User => {
  const { currentUser, auth } = state
  const sentryUser: Sentry.User = {}

  if (currentUser) {
    sentryUser.id = currentUser.id.toString()
  }

  if (
    auth.identity &&
    auth.identity['https://products.talentinc.com/user_id'] !== currentUser?.id
  ) {
    sentryUser.userGhosting =
      auth.identity['https://products.talentinc.com/user_id']
  }

  return sentryUser
}

export const userGroups = (state: UserState): Groups[] =>
  state.auth.identity?.['https://products.talentinc.com/groups'] || []

export const userIsDeveloper = (state: UserState) =>
  userGroups(state).includes(Groups.Developer)

export const userIsAdmin = (state: UserState) =>
  userGroups(state).includes(Groups.Admin) &&
  !userGroups(state).includes(Groups.Developer)

export const userIsCustomer = (state: UserState) =>
  userGroups(state).length === 0
