import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  Action,
} from 'redux'
import thunk, { ThunkAction } from 'redux-thunk'
import { setAutoFreeze, enableMapSet } from 'immer'
import { isIE } from 'react-device-detect'
import { useSelector, TypedUseSelectorHook } from 'react-redux'

import { createAxiosMiddleware } from 'utils/axios'
import attachmentsReducer from './attachments/reducer'
import brandReducer from './brand/reducer'
import configReducer from './config/reducer'
import cronReducer from './crons/reducers'
import eventReducer from './events/reducer'
import experimentsReducer from './experiments/reducer'
import expertsReducer from './experts/reducers'
import filesReducer from './files/reducer'
import itemsReducer from './items/reducer'
import messagesReducer from './messages/reducers'
import notificationReducer from './notifications/reducers'
import ordersReducer from './orders/reducer'
import preloadReducer from './preload/reducer'
import ratingsReducer from './ratings/reducer'
import schedulingReducer from './scheduling/reducer'
import transactionsReducer from './transactions/reducer'
import upsellsReducer from './upsells/reducer'
import userReducer from './user/reducer'

setAutoFreeze(process.env.NODE_ENV !== 'production' && !isIE)
enableMapSet()

const createRootReducer = () =>
  combineReducers({
    attachments: attachmentsReducer,
    brand: brandReducer,
    config: configReducer,
    crons: cronReducer,
    events: eventReducer,
    experiments: experimentsReducer,
    experts: expertsReducer,
    files: filesReducer,
    items: itemsReducer,
    messages: messagesReducer,
    notifications: notificationReducer,
    orders: ordersReducer,
    preload: preloadReducer,
    ratings: ratingsReducer,
    scheduling: schedulingReducer,
    transactions: transactionsReducer,
    upsells: upsellsReducer,
    user: userReducer,
  })

const middleware = [thunk, createAxiosMiddleware()]

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore() {
  return createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(...middleware))
  )
}

// This is the shape of our global state object.
export type AppState = ReturnType<ReturnType<typeof createRootReducer>>

// This is a generic type for Thunk actions in this app.
export type AppThunkAction<
  ThunkReturnType = void,
  ExtraArgument = unknown
> = ThunkAction<
  Promise<ThunkReturnType>,
  AppState,
  ExtraArgument,
  Action<string>
>

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
