import React from 'react'
import { useSelector } from 'react-redux'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import CatalogItem from './CatalogItem'
import LastChanceItems from './LastChanceItems'

import { AppState } from 'store'
import { Expert, Order } from 'store/orders/types'
import { CartUpsellProps } from 'store/upsells/types'

import {
  earliestActiveOrder,
  latestOrder,
  packageDisplayProps,
} from 'store/orders/selectors'
import { cartUpsells } from 'store/upsells/selectors'
import useShoppingCart from 'hooks/useShoppingCart'
import useExperiment from 'hooks/useExperiment'
import { onlyInterview, filteredUpsells } from 'utils/upsells'

import { isEqual } from 'lodash'

const CatalogContainer: React.FC = () => {
  const classes = useStyles()
  const { cartPlanCodes, addToCart, removeFromCart, updateOpen } =
    useShoppingCart()
  // Product Page Experiment
  const isInProductPageExperiment = useExperiment({
    experimentLabel: 'Product Pages Portal',
    caseDescriptor: 'test-product-page',
  })

  const upsells = useSelector<AppState, CartUpsellProps>(
    (state) =>
      cartUpsells(state.upsells, state.orders, isInProductPageExperiment),
    isEqual
  )

  const activeOrder = useSelector<AppState, Order | null>(
    (state) => earliestActiveOrder(state.orders),
    isEqual
  )

  const lastOrder = useSelector<AppState, Order | null>(
    (state) => latestOrder(state.orders),
    isEqual
  )

  const order = activeOrder || lastOrder
  const expert: Expert | null =
    order?.experts && order.experts.length > 0 ? order.experts[0] : null

  // Function for updating selected upsells & opening checkout drawer
  const updateUpsells = (isSelected: boolean, planCode: string) => {
    let updatedUpsells
    if (isSelected) {
      updatedUpsells = Array.from(cartPlanCodes)
      updatedUpsells.splice(cartPlanCodes.indexOf(planCode), 1)
    } else {
      updatedUpsells = [...cartPlanCodes, planCode]
    }
    if (!isSelected) {
      addToCart(planCode, 'upgrade-page')
    } else {
      removeFromCart(planCode, 'upgrade-page')
    }
    updateOpen(true)
  }

  const currentPackages = useSelector<
    AppState,
    ReturnType<typeof packageDisplayProps>
  >(
    (state) =>
      packageDisplayProps(
        state.orders,
        state.items,
        state.files,
        state.ratings
      ),
    isEqual
  )

  return (
    <Container disableGutters className={classes.parentContainer}>
      <Container disableGutters className={classes.catalogContainer}>
        {filteredUpsells(upsells.primary, currentPackages).map(
          (upsell, index) => (
            <CatalogItem
              key={index}
              updateUpsells={updateUpsells}
              upsell={upsell}
            />
          )
        )}
      </Container>
      {order &&
        !onlyInterview(currentPackages) &&
        upsells.order_enhancement.length > 0 && (
          <Container disableGutters className={classes.lastChanceContainer}>
            <LastChanceItems
              expert={expert}
              packageName={order.packages[0].name}
              updateUpsells={updateUpsells}
              upsells={filteredUpsells(
                upsells.order_enhancement,
                currentPackages
              )}
            />
          </Container>
        )}

      <Container disableGutters className={classes.catalogContainer}>
        {filteredUpsells(upsells.secondary, currentPackages).map(
          (upsell, index) => (
            <CatalogItem
              key={index}
              updateUpsells={updateUpsells}
              upsell={upsell}
            />
          )
        )}
      </Container>
    </Container>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    parentContainer: {
      position: 'relative',
    },
    spacer: {
      flexGrow: 1,
      flexShrink: 1,
      height: '1px',
    },
    catalogContainer: {
      marginTop: '2rem',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'wrap',
        marginLeft: '-0.5rem',
        marginRight: '-0.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        alignItems: 'center',
      },
    },
    lastChanceContainer: {
      marginLeft: '-0.25rem',
      paddingRight: '0.5rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0rem',
        paddingRight: '0rem',
      },
    },
    bundleTitle: {
      fontSize: '2rem',
      marginTop: '3rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '2rem',
        marginBottom: '1rem',
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      },
    },
  })
)

export default CatalogContainer
