import { CreditCardType } from '../../utils/creditCard'

import amexSvg from 'assets/images/amex.svg'
import discoverSvg from 'assets/images/discover.svg'
import mastercardSvg from 'assets/images/mastercard.svg'
import visaSvg from 'assets/images/visa.svg'
import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
  type: CreditCardType
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: '31px',
      height: '20px',
    },
  })
)

export const CreditCardLogo: React.FC<Props> = ({ type }) => {
  const classes = useStyles()

  switch (type) {
    case CreditCardType.AmericanExpress:
      return <img src={amexSvg} alt="" className={classes.img} />
    case CreditCardType.Discover:
      return <img src={discoverSvg} alt="" className={classes.img} />
    case CreditCardType.MasterCard:
      return <img src={mastercardSvg} alt="" className={classes.img} />
    case CreditCardType.Visa:
      return <img src={visaSvg} alt="" className={classes.img} />
    default:
      return null
  }
}

export default CreditCardLogo
