import React, { useRef, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import DraftListItem from 'components/Documents/DraftListItem'
import { File } from 'store/files/types'

interface Props {
  documents: File[]
  selectedDocument: number
  setSelectedDocument: (active: number) => void
  closeModals: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      height: '100%',
      width: '100%',
    },
    iconButton: {
      borderRadius: '6px',
      color: theme.palette.primary.dark,
      height: '1.75em',
      marginLeft: '.5em',
      width: '2em',
    },
    popper: {
      marginTop: '.5em',
    },
  })
)

const DraftList: React.FC<Props> = ({
  documents,
  selectedDocument,
  setSelectedDocument,
  closeModals,
}) => {
  const classes = useStyles()
  const popperRef = useRef<HTMLButtonElement>(null)
  const [popperOpen, setPopperOpen] = useState(false)
  return (
    <>
      <IconButton
        className={classes.iconButton}
        onClick={() => setPopperOpen((o) => !o)}
        ref={popperRef}
      >
        <KeyboardArrowDownIcon className={classes.icon} />
      </IconButton>
      <Popper
        anchorEl={popperRef.current}
        className={classes.popper}
        open={popperOpen}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
          <Paper elevation={6}>
            <List>
              {documents.map((draft: File, index: number) => (
                <DraftListItem
                  key={draft.id}
                  documents={documents}
                  draft={draft}
                  index={index}
                  popperOpen={popperOpen}
                  selectedDocument={selectedDocument}
                  setPopperOpen={setPopperOpen}
                  setSelectedDocument={setSelectedDocument}
                  closeModals={closeModals}
                />
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default DraftList
