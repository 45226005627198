import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { NavLinkProps } from 'react-router-dom'

import useBrand from 'hooks/useBrand'
import GhostLink, { GhostLinkProps } from 'components/shared/GhostLink'
import Avatar from '@material-ui/core/Avatar'

export interface LogoProps extends Omit<GhostLinkProps, 'to' | 'children'> {
  drawerMax?: boolean
  minimized?: boolean
  to?: NavLinkProps['to']
}

interface StyleProps extends LogoProps {
  avatarBackgroundColor: string
  avatarTextColor: string
}

const useStyles = makeStyles((theme) =>
  createStyles<string, StyleProps>({
    circle: {
      backgroundColor: (props) => props.avatarBackgroundColor,
      color: (props) => props.avatarTextColor,
      fontSize: '1.5em',
      textDecoration: 'none',
    },
  })
)

const Logo: React.FC<LogoProps> = ({ minimized = false, ...props }) => {
  const { t } = useTranslation()
  const brand = useBrand()
  const classes = useStyles({
    avatarBackgroundColor: brand?.colors?.[0] || 'black',
    avatarTextColor: brand?.colors?.[1] || 'white',
    ...props,
  })
  const linkProps = {
    to: t('routes.home'),
    ...props,
  }

  return (
    <GhostLink {...linkProps}>
      {brand.logo &&
        (!minimized ? (
          <img alt={`${brand.name} icon`} src={brand.logo} />
        ) : (
          <Avatar classes={{ circle: classes.circle }}>
            {brand.name ? brand.name.charAt(0) : '...'}
          </Avatar>
        ))}
    </GhostLink>
  )
}

export default Logo
