import * as ram from '@talentinc/redux-axios-middleware'
import { AxiosError } from 'axios'

import { AttachmentSuccessResponse, AttachmentErrorResponse } from './types'
import { UserIdentifier } from 'store/user/types'

export enum AttachmentActions {
  UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT',
  UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_FAIL = 'UPLOAD_ATTACHMENT_FAIL',
  CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENT',
  REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT',
}

interface UploadAttachment extends ram.AxiosMiddlewareActionCreator {
  type: AttachmentActions.UPLOAD_ATTACHMENT
  file: File
}

export interface UploadAttachmentSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    AttachmentSuccessResponse,
    UploadAttachment
  > {
  type: AttachmentActions.UPLOAD_ATTACHMENT_SUCCESS
}

interface UploadAttachmentFail
  extends Omit<ram.AxiosMiddlewareActionFail<UploadAttachment>, 'error'> {
  type: AttachmentActions.UPLOAD_ATTACHMENT_FAIL
  error: AxiosError<AttachmentErrorResponse>
}

export const uploadAttachment = (
  file: File,
  userIdentifier: UserIdentifier = 'me'
): UploadAttachment => {
  const data = new FormData()
  // FormData.set does not work in ancient Chrome versions
  data.append('file', file)

  return {
    type: AttachmentActions.UPLOAD_ATTACHMENT,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}/files/attachment`,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    },
    file,
  }
}

interface ClearAttachments {
  type: AttachmentActions.CLEAR_ATTACHMENTS
}

export const clearAttachments = (): ClearAttachments => ({
  type: AttachmentActions.CLEAR_ATTACHMENTS,
})

interface RemoveAttachment {
  type: AttachmentActions.REMOVE_ATTACHMENT
  filename: string
}

export const removeAttachment = (filename: string): RemoveAttachment => ({
  type: AttachmentActions.REMOVE_ATTACHMENT,
  filename,
})

export type AttachmentsAction =
  | UploadAttachment
  | UploadAttachmentSuccess
  | UploadAttachmentFail
  | ClearAttachments
  | RemoveAttachment
