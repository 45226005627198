import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAsync from 'react-use/lib/useAsync'
import useSearchParam from 'react-use/lib/useSearchParam'
import useEffectOnce from 'react-use/lib/useEffectOnce'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import SignUpForm from 'components/Auth/SignUp'
import Loader from 'components/shared/Loader'
import { signUpThunk, fetchUser, FetchUserSuccess } from 'store/user/actions'
import { SignUpPayload } from 'store/user/types'
import useEvents from 'hooks/useEvents'
import useUser from 'hooks/useUser'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Logo from 'components/shared/Logo'
import Box from '@material-ui/core/Box'
import { Redirect } from 'react-router-dom'
import { preloadUser } from 'store/preload/actions'

const useStyles = makeStyles((theme) =>
  createStyles({
    auth0Container: {
      '& .auth0-lock-header-logo': {
        height: 'auto !important',
        width: '60% !important',
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '30em',
      },
    },
    button: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    header: {
      fontSize: '1.13em',
      color: '#052a4d',
      textAlign: 'center',
      fontWeight: 'bold',
      paddingBottom: '1em',
    },
    logo: {
      fontSize: '1em',
      width: '6.9em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '1.2em',

      '& img': {
        maxHeight: '2.2em',
        margin: '0 auto',
      },
    },
    paper: {
      padding: '1.5em',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '36em',
      },
    },
    root: {
      alignItems: 'flex-start',
      alignContent: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      paddingTop: '2.5em',
    },
    toolbar: {
      alignItems: 'center',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      justifyContent: 'space-between',
      padding: '2em 4.5em',
      [theme.breakpoints.down('xs')]: {
        padding: '1em',
      },
    },
  })
)

/**
 * The SignUpPage, for phase 1, will be embedded into Customer Onboarding via an
 * <iframe>. Most of the data needed for first render will be provided by query
 * params to the <iframe>. Once the user has successfully signed up, this page
 * will postMessage a message to the parent page that the user has successfully
 * signed up.
 */
const SignUpPage: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const productsAccessToken = useSearchParam('pat')
  const brandID = useSearchParam('brand_id')
  const siteID = useSearchParam('site_id') || ''
  const user = useUser()
  // Because of how loosely the users table is defined, theoretically, a user
  // could hit this step without providing an email. If that is the case, let
  // the user enter their email in the form below. In practice, this is probably
  // unlikely to happen, as you need to enter an email to purchase, but better
  // safe than sorry.
  const email = useSearchParam('email') || user.email || ''
  const brandName =
    useSearchParam('brandName') || user.brand?.name || 'TopResume'
  const sendEvent = useEvents()
  const [signedUp, setSignedUp] = useState(false)

  const onSubmit = useCallback(
    async (values: SignUpPayload) => {
      if (!productsAccessToken || !brandID) {
        return false
      }

      const result = await dispatch(
        signUpThunk(productsAccessToken, brandID, siteID, values)
      )

      // @ts-ignore
      if (result?.error || result?.errors) {
        return false
      }

      window.parent.postMessage({ action: 'signup-completed' }, '*')
      setSignedUp(true)
      return true
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [productsAccessToken]
  )

  const handleResize = useCallback((width: number, height: number) => {
    window.parent.postMessage(
      { action: 'login-form-resize', width, height },
      '*'
    )
  }, [])

  useEffectOnce(() => {
    if (productsAccessToken) {
      sendEvent(
        {
          event: 'Portal Sign Up - Viewed',
        },
        productsAccessToken
      )
    }
  })

  // Fetch the user from their PAT
  const userAlreadySignedUp = useAsync(async () => {
    if (productsAccessToken) {
      const userResponse = (await dispatch(
        fetchUser(productsAccessToken)
      )) as unknown as FetchUserSuccess

      return !!userResponse.payload.data.has_portal_account
    }

    return false
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [productsAccessToken])

  // If ther user already has a profile, tell the parent iframe that all is
  // good.
  useEffect(() => {
    if (userAlreadySignedUp.value) {
      setTimeout(() => {
        window.parent.postMessage({ action: 'signup-completed' }, '*')
      }, 1000 * 5)
    }
  }, [userAlreadySignedUp.value])

  if (!productsAccessToken || !user.id) {
    return <Loader />
  }
  if (signedUp) {
    dispatch(preloadUser())
    return <Redirect to="/" />
  }
  if (userAlreadySignedUp.value) {
    return <Redirect to="/" />
  }
  return (
    <>
      <Helmet>
        <title>{t('pages.Auth.SignUp.pageTitle')}</title>
      </Helmet>
      {}

      <>
        <Toolbar className={classes.toolbar} disableGutters variant="dense">
          <Logo className={classes.logo} />
        </Toolbar>
        <Box className={classes.root}>
          <SignUpForm
            initialEmail={email}
            brand={brandName}
            onSubmit={onSubmit}
            handleResize={handleResize}
          />
        </Box>
      </>
    </>
  )
}

export default SignUpPage
