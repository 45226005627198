import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: `${theme.palette.secondary.main} !important`,
      [theme.breakpoints.down('sm')]: {
        display: 'none !important',
      },
    },
  })
)

const Carousel: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles()

  const settings = {
    dots: true,
    infinite: true,
    nextArrow: <ArrowForwardIosIcon classes={{ root: classes.arrow }} />,
    prevArrow: <ArrowBackIosIcon classes={{ root: classes.arrow }} />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return <Slider {...settings}>{children}</Slider>
}

export default Carousel
