import { ErrorType } from '@talentinc/state-utils'

import { AppThunkAction } from 'store'
import { fetchConfigAndConfigure } from 'store/config/actions'
import { fetchUserAndConfigure } from 'store/user/thunks'
import { fetchNotifications } from 'store/notifications/actions'
import { fetchBrand } from 'store/brand/actions'
import { fetchOrdersAndOtherMetadataForUser } from 'store/orders/thunks'
import { fetchUpsells } from 'store/upsells/actions'
import { fetchFilesForUserAndRelatedRecords } from 'store/files/thunks'
import { fetchMessagesForUser } from 'store/messages/actions'
import { fetchExperimentsForPortal } from 'store/experiments/actions'
import { initializeCrons } from 'store/crons/actions'

export enum PreloadActions {
  PRELOAD_FINISHED = 'PRELOAD_FINISHED',
  PRELOAD_FAILED = 'PRELOAD_FAILED',
  PAT_PRELOAD_FINISHED = 'PAT_PRELOAD_FINISHED',
  PAT_PRELOAD_FAILED = 'PAT_PRELOAD_FAILED',
}

/**
 * preloadApp is a thunk that dispatches all requests needed for the first
 * interaction to happen.
 */
export const preloadApp = (): AppThunkAction => async (dispatch, getState) => {
  try {
    // Fetch the Auth0 config
    dispatch(fetchConfigAndConfigure())
      .then(() => {
        const { config } = getState().config

        if (config && config.brand && config.site_id) {
          dispatch(fetchBrand(config.brand.brand_id, config.site_id))
        }
      })
      .catch((error) =>
        dispatch({
          type: PreloadActions.PRELOAD_FAILED,
          error,
        })
      )

    dispatch({ type: PreloadActions.PRELOAD_FINISHED })
    dispatch(initializeCrons(true))
  } catch (error) {
    dispatch({
      type: PreloadActions.PRELOAD_FAILED,
      error,
    })
  }
}

/**
 * preloadUser is a thunk to fetch all the data on the user for the first
 * meaningful interaction. This is separate from preloadApp because there are
 * cases where you don't want to fetch the user as a part of the preload
 * (ex: authentication).
 */
export const preloadUser =
  (userIdentifier = 'me'): AppThunkAction =>
  async (dispatch) => {
    dispatch(fetchUserAndConfigure(userIdentifier))
    dispatch(fetchOrdersAndOtherMetadataForUser(userIdentifier))
    dispatch(fetchFilesForUserAndRelatedRecords(userIdentifier))
    dispatch(fetchNotifications(userIdentifier))
    dispatch(fetchUpsells(userIdentifier))
    dispatch(fetchMessagesForUser(userIdentifier))
    dispatch(fetchExperimentsForPortal(userIdentifier))
  }

export const preloadUserByProductsAccessToken =
  (productsAccessToken: string): AppThunkAction =>
  async (dispatch) => {
    try {
      await dispatch(fetchUserAndConfigure(productsAccessToken))
      dispatch({ type: PreloadActions.PAT_PRELOAD_FINISHED })
    } catch (e) {
      dispatch({ type: PreloadActions.PAT_PRELOAD_FAILED })
    }
  }

interface PreloadFinished {
  type: PreloadActions.PRELOAD_FINISHED
}

interface PreloadFailed {
  type: PreloadActions.PRELOAD_FAILED
  error: ErrorType
}

export interface PATPreloadFinished {
  type: PreloadActions.PAT_PRELOAD_FINISHED
}

export interface PATPreloadFailed {
  type: PreloadActions.PAT_PRELOAD_FAILED
  error: ErrorType
}

export type PreloadAction =
  | PreloadFinished
  | PreloadFailed
  | PATPreloadFinished
  | PATPreloadFailed
