import React, { useCallback, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import ModalWrapper from '../Wrapper'
import { ItemWithLatestDraftDate } from 'store/items/types'

import documentImg from 'assets/images/document-sample.png'
import checkImg from 'assets/images/check-circle-fill.svg'

interface Props {
  expertName: string
  items: ItemWithLatestDraftDate[]
  onClose: () => void
  onSubmit: () => Promise<void> | void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      padding: 0,
    },
    content: {
      padding: '1.5em 2.5em 0em',
      display: 'flex',
      flexDirection: 'column',
    },
    docContainer: {
      position: 'relative',
      width: '100%',
      height: '12.75em',
      backgroundColor: '#EAF6FF',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
    },
    imgBox: {
      position: 'relative',
      marginTop: '2em',
      padding: '0.5em',
      height: 'calc(90%-2em)',
      border: '2px solid #E7EAF4',
      borderRadius: '6px 6px 0px 0px',
      borderBottom: 'none',
    },
    docImg: {
      height: '100%',
    },
    checkImg: {
      position: 'absolute',
      zIndex: 1,
      right: -18,
      bottom: 20,
    },
    gradient: {
      position: 'absolute',
      width: '100%',
      background:
        'linear-gradient(0deg, #EAF6FF 0%, rgba(234, 246, 255, 0) 50%)',
      top: 0,
      bottom: 0,
    },
    itemPaper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '0.8rem',
      padding: '0.75rem',
      marginTop: '1rem',
    },
  })
)

const tKey = 'components.Modals.variants.ApproveOrder'

const ApproveOrderModal: React.FC<Props> = ({
  expertName,
  items,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const handleSubmit = useCallback(async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)
    onClose()
  }, [onSubmit, onClose])

  return (
    <ModalWrapper
      onClose={onClose}
      ContentProps={{ className: classes.modal }}
      callToActionProps={{
        children: loading ? <CircularProgress size={28} /> : t(`${tKey}.cta`),
        disabled: loading,
        onClick: handleSubmit,
      }}
    >
      <Container disableGutters className={classes.docContainer}>
        <Box className={classes.imgBox}>
          <img src={documentImg} alt="document" className={classes.docImg} />
          <img src={checkImg} alt="check" className={classes.checkImg} />
        </Box>
        <Box className={classes.gradient} />
      </Container>
      <Container disableGutters className={classes.content}>
        <Typography variant="h2">{t(`${tKey}.title`)}</Typography>
        <Typography variant="body2">
          {t(`${tKey}.copy`, { expertName })}
        </Typography>
        {items.map((item) => (
          <Paper key={item.id} variant="outlined" className={classes.itemPaper}>
            <Typography variant="h2">{item.name}</Typography>
            <Typography
              component="time"
              dateTime={item.latestDraftDate.toISOString()}
            >
              {dateFormat(item.latestDraftDate, t(`${tKey}.draftFormat`, item))}
            </Typography>
          </Paper>
        ))}
      </Container>
    </ModalWrapper>
  )
}

export default ApproveOrderModal
