import React, { useCallback, useState } from 'react'
import { format as dateFormat } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useModals from 'hooks/useModals'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'

import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EmailIcon from '@material-ui/icons/Email'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import DescriptionIcon from '@material-ui/icons/Description'
import Button from '@material-ui/core/Button'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import HistoryIcon from '@material-ui/icons/History'

import pendingLaptopSVG from 'assets/images/pending-laptop.svg'

import { AppState } from 'store'
import {
  InterviewPrepDocsDisplayProps,
  ItemStatusTypes,
} from 'store/items/types'

import { currentItemStatus, hasCompletedInterview } from 'store/items/selectors'
import { purchaseUrl } from 'store/upsells/selectors'
import { DocumentModal } from './DocumentModal'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import GhostLink from 'components/shared/GhostLink'
import { generatePath } from 'react-router-dom'
import RescheduleModalContainer from 'components/Scheduler/RescheduleModalContainer'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '70%',
      paddingRight: '1rem',
    },
    headerIconContainer: {
      flexBasis: '30%',
      paddingLeft: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    statusHeading: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    headingDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    headingDetailsInfo: {
      fontSize: '.9rem',
      fontWeight: 'bold',
      color: colors.gray.a400,
      [theme.breakpoints.down('md')]: {
        marginBottom: '1rem',
      },
    },
    headingDetailsCaption: {
      fontSize: '.9rem',
      color: theme.palette.grey[400],
    },
    divider: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
    },
    headerIcon: {
      [theme.breakpoints.up('lg')]: {
        width: '11rem',
      },
      [theme.breakpoints.down('md')]: {
        width: '8rem',
      },

      height: 'auto',
    },
    icon: {
      margin: 'auto',
      width: '65%',
    },
    listContainer: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    listItem: {
      alignItems: 'flex-start',
      paddingLeft: '0px',
    },
    listItemIcon: {
      backgroundColor: colors.gray.a100,
      borderRadius: '100%',
      color: colors.blue.a300,
      height: '1.5em',
      marginRight: '0.5rem',
      marginTop: '0.4rem',
      minWidth: '0rem',
      width: '1.5em',
    },
    listItemText: {
      fontSize: '.9rem',
    },
    reminderHeader: {
      fontWeight: 600,
      fontSize: '1.2rem',
      color: colors.gray.a400,
    },
    documentStatus: {
      fontWeight: 600,
      fontSize: '1.3rem',
      color: colors.gray.a400,
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    button: {
      flexBasis: '48%',
    },
    purchaseButton: {
      margin: '2rem 0 1rem',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    takeAwayHeader: {
      fontSize: '1rem',
      marginBottom: '1rem',
      color: theme.palette.primary.dark,
      fontWeight: 600,
      marginTop: '1.2rem',
    },
    takewayDetails: {
      fontSize: '1rem',
    },
    historyIcon: {
      marginRight: '0.5rem',
      width: 'auto',
      height: '2rem',
      color: theme.palette.grey[400],
    },
    prevSessionsCaption: {
      marginBottom: '1rem',
    },
    prevSessionPortfolio: {},
    popper: {
      zIndex: 1,
      width: '20rem',
    },
    paper: {
      border: `1px solid ${theme.palette.secondary.light}`,
      padding: '1.5em',
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
      marginBottom: '1rem',
    },
    rescheduleCaption: {
      marginBottom: '2rem',
      fontWeight: 600,
      fontSize: '1.2rem',
      color: colors.gray.a400,
    },
    schedulingButton: {
      flexBasis: '45%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
)

const InterviewPrepPreApt: React.FC<InterviewPrepDocsDisplayProps> = ({
  item,
  expert,
  hasMultipleSessions,
  hasScheduler,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const status = currentItemStatus(item)
  const tKey = 'components.Documents.interviewPrep'
  const expertName = expert?.display_name || ''
  const toolTipText = t(`${tKey}.preAppointment.cta.status.pastDocs`)

  const canReschedule = hasScheduler && expert && status?.due_at

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)

  const toggleScheduleModal = useCallback(async () => {
    setScheduleModalOpen(!scheduleModalOpen)
  }, [scheduleModalOpen])

  return (
    <>
      <Container className={classes.cardContainer}>
        <Container disableGutters className={classes.headerContainer}>
          <Container disableGutters className={classes.headerTextContainer}>
            <Typography variant="h2" className={classes.statusHeading}>
              {expert
                ? t(`${tKey}.preAppointment.heading.postMatch`, {
                    expertName: expertName,
                  })
                : t(`${tKey}.preAppointment.heading.preMatch`)}
            </Typography>

            <Container
              disableGutters
              className={classes.headingDetailsContainer}
            >
              <Container disableGutters>
                <Typography
                  variant="body2"
                  className={classes.headingDetailsCaption}
                >
                  {t(`${tKey}.dateAndTime`)}
                </Typography>
                <Typography variant="h6" className={classes.headingDetailsInfo}>
                  {expert && status?.due_at
                    ? dateFormat(status?.due_at, t(`${tKey}.dateFormat`)) ||
                      'Pending'
                    : 'Pending'}
                </Typography>
              </Container>
              <Container disableGutters>
                <Typography
                  variant="body2"
                  className={classes.headingDetailsCaption}
                >
                  {t(`${tKey}.sessionType`)}
                </Typography>
                <Typography variant="h6" className={classes.headingDetailsInfo}>
                  {item.name}
                </Typography>
              </Container>
            </Container>
          </Container>
          <Container disableGutters className={classes.headerIconContainer}>
            <img src={pendingLaptopSVG} alt="" className={classes.headerIcon} />
          </Container>
        </Container>
        <Divider className={classes.divider} />
        <Container disableGutters className={classes.footerContainer}>
          <Container disableGutters className={classes.statusContainer}>
            <Typography
              variant="body2"
              align="left"
              className={classes.reminderHeader}
            >
              {t(`${tKey}.preAppointment.body.reminderHeader`)}
            </Typography>
            {hasMultipleSessions && (
              <Tooltip arrow placement="top" title={toolTipText}>
                <HelpOutlineIcon className={classes.historyIcon} />
              </Tooltip>
            )}
          </Container>

          <Typography variant="body2" align="left">
            {t(`${tKey}.preAppointment.body.processDescription`)}
          </Typography>
          <List className={classes.list}>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <EmailIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(`${tKey}.preAppointment.body.reminders.email`)}
              />
            </ListItem>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <WatchLaterIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(`${tKey}.preAppointment.body.reminders.speed`)}
              />
            </ListItem>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <DescriptionIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(`${tKey}.preAppointment.body.reminders.contents`)}
              />
            </ListItem>
          </List>
          <Typography variant="body2" align="left">
            {t(`${tKey}.preAppointment.body.support`)}
          </Typography>
          {canReschedule && (
            <>
              <Divider className={classes.divider} />
              <Typography
                variant="body2"
                align="left"
                className={classes.rescheduleCaption}
              >
                {t(`${tKey}.preAppointment.scheduling.cantMakeAppointment`)}
              </Typography>
              <Container disableGutters className={classes.row}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                  onClick={toggleScheduleModal}
                >
                  {t(`${tKey}.preAppointment.scheduling.reSchedule`)}
                </Button>
              </Container>
            </>
          )}
        </Container>
      </Container>
      {canReschedule && scheduleModalOpen && (
        <RescheduleModalContainer
          omsItemID={item.oms_order_item_id || 0}
          onClose={toggleScheduleModal}
        />
      )}
    </>
  )
}

const InterviewPrepPostApt: React.FC<InterviewPrepDocsDisplayProps> = ({
  item,
  expert,
  doc,
  hasMultipleSessions,
  isCompleted,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { modal, onDownloadClick } = useModals(doc)
  const tKey = 'components.Documents.interviewPrep.postAppointment'
  const toolTipText = t(
    `components.Documents.interviewPrep.preAppointment.cta.status.pastDocs`
  )
  const expertName = expert?.display_name || t(`shared.yourExpert`)
  const completedDate = item.status.completed.find(
    (s) => s.state === ItemStatusTypes.ConductInterview
  )?.due_at
  const purchaseLink = useSelector<AppState, string | null>((state) =>
    purchaseUrl(state.user, state.brand, ['vifomLy'], 'document')
  )
  const [openDocView, setOpenDocView] = useState(false)
  const [selectedItemID, setSelectedItemID] = useState<null | number>(null)
  const [popperOpen, setPopperOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  // On click open or close popper
  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    if (!popperOpen && event) {
      setAnchorEl(event.currentTarget)
      setPopperOpen(!popperOpen)
    } else {
      setPopperOpen(false)
    }
  }

  const handleClose = useCallback(() => {
    setOpenDocView(false)
    setSelectedItemID(null)
  }, [])

  const handlePastDocView = useCallback((itemID: number | null) => {
    setSelectedItemID(itemID)
    setOpenDocView(true)
  }, [])

  return (
    <>
      <Container className={classes.cardContainer}>
        <Container disableGutters className={classes.headerContainer}>
          <Container disableGutters className={classes.headerTextContainer}>
            <Typography variant="h2" className={classes.statusHeading}>
              {t(`${tKey}.heading`, {
                expertName: expertName,
              })}
            </Typography>

            <Container
              disableGutters
              className={classes.headingDetailsContainer}
            >
              <Container disableGutters>
                <Typography
                  variant="body2"
                  className={classes.headingDetailsCaption}
                >
                  {t(`components.Documents.interviewPrep.dateAndTime`)}
                </Typography>
                <Typography variant="h6" className={classes.headingDetailsInfo}>
                  {completedDate
                    ? dateFormat(
                        completedDate,
                        t(`components.Documents.interviewPrep.dateFormat`)
                      )
                    : 'Pending'}
                </Typography>
              </Container>
              <Container disableGutters>
                <Typography
                  variant="body2"
                  className={classes.headingDetailsCaption}
                >
                  {t(`components.Documents.interviewPrep.sessionType`)}
                </Typography>
                <Typography variant="h6" className={classes.headingDetailsInfo}>
                  {item.name}
                </Typography>
              </Container>
            </Container>
          </Container>
          <Container disableGutters className={classes.headerIconContainer}>
            <img src={pendingLaptopSVG} alt="" className={classes.headerIcon} />
          </Container>
        </Container>
        <Divider className={classes.divider} />
        <Container disableGutters>
          <Container disableGutters className={classes.statusContainer}>
            <Typography
              variant="body2"
              align="left"
              className={classes.documentStatus}
            >
              {doc
                ? t(`${tKey}.cta.status.doc`)
                : t(`${tKey}.cta.status.noDoc`, {
                    expertName: expertName,
                  })}
            </Typography>
            {hasMultipleSessions && !isCompleted && (
              <Tooltip arrow placement="top" title={toolTipText}>
                <HelpOutlineIcon className={classes.historyIcon} />
              </Tooltip>
            )}
            {hasMultipleSessions && isCompleted && (
              <IconButton aria-label="past items" onClick={handleClick}>
                <HistoryIcon className={classes.historyIcon} />
              </IconButton>
            )}
          </Container>

          <Container disableGutters className={classes.buttonContainer}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              disabled={!doc}
              onClick={() => onDownloadClick()}
            >
              {t(`${tKey}.cta.download`)}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              disabled={!doc}
              onClick={() => handlePastDocView(item.oms_order_item_id)}
            >
              {t(`${tKey}.cta.view`)}
            </Button>
          </Container>
        </Container>
        <Divider className={classes.divider} />
        <Container disableGutters className={classes.footerContainer}>
          <Typography
            variant="body2"
            align="left"
            className={classes.reminderHeader}
          >
            {t(`${tKey}.body.reminderHeader`)}
          </Typography>
          <Typography variant="body2" align="left">
            {t(`${tKey}.body.reminderDetails`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takeAwayHeader}
          >
            {t(`${tKey}.body.takeaways.header.elevator`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takewayDetails}
          >
            {t(`${tKey}.body.takeaways.details.elevator`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takeAwayHeader}
          >
            {t(`${tKey}.body.takeaways.header.behavior`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takewayDetails}
          >
            {t(`${tKey}.body.takeaways.details.behavior`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takeAwayHeader}
          >
            {t(`${tKey}.body.takeaways.header.unstructured`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takewayDetails}
          >
            {t(`${tKey}.body.takeaways.details.unstructured`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takeAwayHeader}
          >
            {t(`${tKey}.body.takeaways.header.stress`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takewayDetails}
          >
            {t(`${tKey}.body.takeaways.details.stress`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takeAwayHeader}
          >
            {t(`${tKey}.body.takeaways.header.salary`)}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.takewayDetails}
          >
            {t(`${tKey}.body.takeaways.details.salary`)}
          </Typography>

          <Button
            className={classes.purchaseButton}
            color="primary"
            variant="contained"
            href={purchaseLink || ''}
          >
            {t(`${tKey}.cta.purchase`)}
          </Button>
        </Container>
      </Container>
      {modal}
      <DocumentModal
        open={openDocView}
        handleClose={handleClose}
        itemID={selectedItemID}
      />
      <Popper
        anchorEl={anchorEl}
        className={classes.popper}
        open={popperOpen}
        placement="right"
      >
        <Paper className={classes.paper} elevation={6}>
          <Typography
            variant="body2"
            align="left"
            className={classes.prevSessionsCaption}
          >
            {t(`${tKey}.cta.status.pastDocs`)}
          </Typography>

          <Button
            color="primary"
            component={GhostLink}
            to={generatePath(t('routes.portfolio'))}
            variant="contained"
          >
            {t(`components.Messaging.viewPortfolio`)}
          </Button>
          <ClickAwayListener onClickAway={() => handleClick()}>
            <Button
              color="primary"
              onClick={handleClick}
              variant="text"
              className={classes.prevSessionPortfolio}
            >
              {t('components.TimelineWidget.DeliveryPopper.gotIt')}
            </Button>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}

const InterviewPrepDocument: React.FC<InterviewPrepDocsDisplayProps> = ({
  expert,
  item,
  doc,
  hasMultipleSessions,
  isCompleted,
  hasScheduler,
}) => {
  const finishedInterview = useSelector<AppState, boolean>((state) =>
    hasCompletedInterview(state.items, item.id)
  )
  if (finishedInterview) {
    return (
      <InterviewPrepPostApt
        expert={expert}
        item={item}
        doc={doc}
        hasMultipleSessions={hasMultipleSessions}
        isCompleted={isCompleted}
        hasScheduler={false}
      />
    )
  }
  return (
    <InterviewPrepPreApt
      expert={expert}
      item={item}
      doc={doc}
      hasMultipleSessions={hasMultipleSessions}
      isCompleted={isCompleted}
      hasScheduler={hasScheduler}
    />
  )
}

export default InterviewPrepDocument
