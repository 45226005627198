import { produce } from 'immer'

import { ItemsState, initialState, Item } from './types'
import { ItemAction } from './actions'
import { OrderActions } from 'store/orders/actions'

export default function itemsReducer(
  state: ItemsState = initialState,
  action: ItemAction
): ItemsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case OrderActions.FETCH_ORDERS_FOR_USER_SUCCESS: {
        const reassignedOrderIDs = action.payload.data.flatMap(
          (o) => o.original_order_id || []
        )
        action.payload.data
          .filter((o) => !reassignedOrderIDs.includes(o.id))
          .forEach((order) =>
            order.packages.forEach((pkg) => {
              if (pkg.items) {
                pkg.items.forEach((item) =>
                  storeItemInDraft(item, order.id, pkg.id, draft)
                )
              } else {
                pkg.items = []
              }
            })
          )
        break
      }

      case OrderActions.FETCH_ORDER_FOR_USER_SUCCESS: {
        const order = action.payload.data
        if (!order.original_order_id) {
          order.packages.forEach((pkg) => {
            if (pkg.items) {
              pkg.items.forEach((item) =>
                storeItemInDraft(item, order.id, pkg.id, draft)
              )
            } else {
              pkg.items = []
            }
          })
        }

        break
      }

      case OrderActions.APPROVE_ORDER_SUCCESS: {
        const order = action.payload.data

        order.packages.forEach((pkg) => {
          if (pkg.items) {
            pkg.items.forEach((item) =>
              storeItemInDraft(item, order.id, pkg.id, draft)
            )
          } else {
            pkg.items = []
          }
        })

        break
      }
    }
  })
}

const storeItemInDraft = (
  item: Item,
  orderID: number,
  pkgID: number,
  draft: ItemsState
) => {
  // Set the item
  draft.items[item.id] = item

  // Precalculate some common associations so look ups are easier
  if (!draft.byOrderID[orderID]) {
    draft.byOrderID[orderID] = new Set<number>()
  }

  draft.byOrderID[orderID].add(item.id)

  if (!draft.byPackageID[pkgID]) {
    draft.byPackageID[pkgID] = new Set<number>()
  }

  draft.byPackageID[pkgID].add(item.id)

  if (item.oms_order_item_id) {
    draft.byOMSOrderItemID[item.oms_order_item_id] = item.id
  }

  if (item.oms_client_id) {
    if (!draft.byOMSClientID[item.oms_client_id]) {
      draft.byOMSClientID[item.oms_client_id] = new Set<number>()
    }

    draft.byOMSClientID[item.oms_client_id].add(item.id)
  }
}
