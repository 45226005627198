import React from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import BundleItem from './BundleItem'

import { UpsellItem } from 'store/upsells/types'

interface Props {
  items: UpsellItem[]
}

const BundleItems: React.FC<Props> = ({ items }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.container}>
      <Typography variant="body2" className={classes.caption}>
        {t(`components.Product.whatsInYourBundle`)}
      </Typography>
      {items.map((item) => (
        <BundleItem itemType={item.item_type_key} itemName={item.name} />
      ))}
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3rem',
    },
    caption: {
      fontSize: '1rem',
      color: colors.blue[700],
      marginBottom: '1.5rem',
    },
  })
)

export default BundleItems
