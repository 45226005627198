import * as ram from '@talentinc/redux-axios-middleware'
import { Notification } from './types'

export enum NotificationActions {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL',

  MARK_VIEWED = 'MARK_VIEWED',
  MARK_VIEWED_SUCCESS = 'MARK_VIEWED_SUCCESS',
  MARK_VIEWED_FAIL = 'MARK_VIEWED_FAIL',

  ADD_TO_UI_LIST = 'ADD_TO_UI_LIST',
  REMOVE_FROM_UI_LIST = 'REMOVE_FROM_UI_LIST',

  SHOW_MODAL = 'SHOW_MODAL',
  DISMISS_MODAL = 'DISMISS_MODAL',
}

export interface FetchNotificationsSuccess
  extends ram.AxiosMiddlewareActionSuccess<Notification[], FetchNotifications> {
  type: typeof NotificationActions.FETCH_NOTIFICATIONS_SUCCESS
}

export interface FetchNotificationsFail extends ram.AxiosMiddlewareActionFail {
  type: typeof NotificationActions.FETCH_NOTIFICATIONS_FAIL
}

export interface FetchNotifications extends ram.AxiosMiddlewareActionCreator {
  type: NotificationActions.FETCH_NOTIFICATIONS
  userIdentifier: string
}

export function fetchNotifications(userIdentifier: string): FetchNotifications {
  return {
    type: NotificationActions.FETCH_NOTIFICATIONS,
    userIdentifier,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}/notifications`,
      },
    },
  }
}

export interface MarkNotificationViewed
  extends ram.AxiosMiddlewareActionCreator {
  type: NotificationActions.MARK_VIEWED
  userIdentifier: string
  notificationID: number
}

export interface MarkNotificationViewedSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    Notification,
    MarkNotificationViewed
  > {
  type: typeof NotificationActions.MARK_VIEWED_SUCCESS
}

export interface MarkNotificationViewedFail
  extends ram.AxiosMiddlewareActionFail<MarkNotificationViewed> {
  type: NotificationActions.MARK_VIEWED_FAIL
}

export function markNotificationViewed(
  userIdentifier: string,
  notificationID: number
): MarkNotificationViewed {
  return {
    type: NotificationActions.MARK_VIEWED,
    userIdentifier,
    notificationID,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}/notifications/${notificationID}`,
        method: 'PATCH',
      },
    },
  }
}

export type NotificationAction =
  | FetchNotifications
  | FetchNotificationsSuccess
  | FetchNotificationsFail
  | MarkNotificationViewed
  | MarkNotificationViewedSuccess
  | MarkNotificationViewedFail
