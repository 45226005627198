import React from 'react'
import useBrand from 'hooks/useBrand'
import { Article } from 'store/content/types'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import WidgetItem from 'components/Content/WidgetItem'
import CollaboratingImg from 'assets/images/collaborating-small.png'
import GetToKnowImg from 'assets/images/get-to-know-small.png'
import NextStepImg from 'assets/images/next-step-small.png'
import ProcessImg from 'assets/images/process-small.png'
import SettingTheStageImg from 'assets/images/setting-the-stage-small.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    h2: {
      padding: '1.5em 1.6em 1.4em',
    },
    paper: {
      padding: '0',
    },
  })
)

const ContentWidget: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const brand = useBrand()
  const articles: Article[] = t('components.Content.articles', {
    returnObjects: true,
    brand: brand.name,
  })
  const images: string[] = [
    ProcessImg,
    GetToKnowImg,
    SettingTheStageImg,
    CollaboratingImg,
    NextStepImg,
  ]

  return (
    <Paper className={classes.paper} variant="outlined">
      <Typography className={classes.h2} variant="h2">
        {t('components.Content.widgetTitle')}
      </Typography>
      <List>
        {articles.map((article, index) => (
          <WidgetItem
            key={index}
            article={article}
            image={images[index]}
            index={index}
          />
        ))}
      </List>
    </Paper>
  )
}

export default ContentWidget
