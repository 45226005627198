import React from 'react'

import ProductChip from 'components/OrderItems/ProductChip'
import Stars, { StarsProps } from 'components/Rating/Stars'
import StarWidgetContainer from 'components/Rating/StarWidgetContainer'
import { ItemStatusTypes } from 'store/items/types'
import { OldOrderStatus, OrderStatusTypes } from 'store/orders/types'

interface Props {
  deadline: any
  rating: number | null | undefined
  // @TODO remove strings when I update the document components
  status: ItemStatusTypes | OldOrderStatus | OrderStatusTypes
  tooltipPlacement: StarsProps['placement']
  itemID?: number
}

const ProductStatus: React.FC<Props> = ({
  deadline,
  rating,
  status,
  tooltipPlacement,
  itemID,
}) => {
  if (status === ItemStatusTypes.Complete || status === 'rate') {
    if (itemID) {
      return (
        <StarWidgetContainer
          itemID={itemID}
          StarsProps={{
            placement: tooltipPlacement,
            rating,
          }}
        />
      )
    }

    return <Stars placement={tooltipPlacement} rating={rating} />
  }

  return <ProductChip deadline={deadline} status={status} />
}

export default ProductStatus
