import React, { useCallback, useState } from 'react'
import { format as dateFormat } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EmailIcon from '@material-ui/icons/Email'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import DescriptionIcon from '@material-ui/icons/Description'
import Button from '@material-ui/core/Button'

import pendingLaptopSVG from 'assets/images/pending-laptop.svg'
import { colors } from 'styles'
import { InterviewPrepDocsDisplayProps } from 'store/items/types'
import { currentItemStatus } from 'store/items/selectors'
import RescheduleModalContainer from 'components/Scheduler/RescheduleModalContainer'
import { itemCanBeRescheduledByStatus } from 'store/scheduling/selectors'
import useBrand from 'hooks/useBrand'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '70%',
      paddingRight: '1rem',
    },
    headerIconContainer: {
      flexBasis: '30%',
      paddingLeft: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    statusHeading: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    headingDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    headingDetailsInfo: {
      fontSize: '.9rem',
      fontWeight: 'bold',
      color: colors.gray.a400,
      [theme.breakpoints.down('md')]: {
        marginBottom: '1rem',
      },
    },
    headingDetailsCaption: {
      fontSize: '.9rem',
      color: theme.palette.grey[400],
    },
    divider: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
    },
    headerIcon: {
      [theme.breakpoints.up('lg')]: {
        width: '11rem',
      },
      [theme.breakpoints.down('md')]: {
        width: '8rem',
      },

      height: 'auto',
    },
    icon: {
      margin: 'auto',
      width: '65%',
    },
    listItem: {
      alignItems: 'flex-start',
      paddingLeft: '0px',
    },
    listItemIcon: {
      backgroundColor: colors.gray.a100,
      borderRadius: '100%',
      color: colors.blue.a300,
      height: '1.5em',
      marginRight: '0.5rem',
      marginTop: '0.4rem',
      minWidth: '0rem',
      width: '1.5em',
    },
    listItemText: {
      fontSize: '.9rem',
    },
    reminderHeader: {
      fontWeight: 600,
      fontSize: '1.2rem',
      color: colors.gray.a400,
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    button: {
      flexBasis: '48%',
    },
    historyIcon: {
      marginRight: '0.5rem',
      width: 'auto',
      height: '2rem',
      color: theme.palette.grey[400],
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
      marginBottom: '1rem',
    },
    rescheduleCaption: {
      marginBottom: '2rem',
      fontWeight: 600,
      fontSize: '1.2rem',
      color: colors.gray.a400,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
)

const PhoneCallPrep: React.FC<
  Omit<InterviewPrepDocsDisplayProps, 'hasMultipleSessions' | 'hasScheduler'>
> = ({ item, expert }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const status = currentItemStatus(item)
  const tKey = 'components.Documents.phoneCallPrep'
  const expertName = expert?.display_name || ''
  const brand = useBrand()

  const canReschedule =
    itemCanBeRescheduledByStatus(item) && expert && status?.due_at

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)

  const toggleScheduleModal = useCallback(async () => {
    setScheduleModalOpen(!scheduleModalOpen)
  }, [scheduleModalOpen])

  return (
    <>
      <Container className={classes.cardContainer}>
        <Container disableGutters className={classes.headerContainer}>
          <Container disableGutters className={classes.headerTextContainer}>
            <Typography variant="h2" className={classes.statusHeading}>
              {expert
                ? t(`${tKey}.heading.postMatch`, {
                    expertName: expertName,
                  })
                : t(`${tKey}.heading.preMatch`)}
            </Typography>

            <Container
              disableGutters
              className={classes.headingDetailsContainer}
            >
              <Container disableGutters>
                <Typography
                  variant="body2"
                  className={classes.headingDetailsCaption}
                >
                  {t(`${tKey}.dateAndTime`)}
                </Typography>
                <Typography variant="h6" className={classes.headingDetailsInfo}>
                  {expert && status?.due_at
                    ? dateFormat(status?.due_at, t(`${tKey}.dateFormat`)) ||
                      'Pending'
                    : 'Pending'}
                </Typography>
              </Container>
              <Container disableGutters>
                <Typography
                  variant="body2"
                  className={classes.headingDetailsCaption}
                >
                  {t(`${tKey}.sessionType`)}
                </Typography>
                <Typography variant="h6" className={classes.headingDetailsInfo}>
                  {item.name}
                </Typography>
              </Container>
            </Container>
          </Container>
          <Container disableGutters className={classes.headerIconContainer}>
            <img src={pendingLaptopSVG} alt="" className={classes.headerIcon} />
          </Container>
        </Container>
        <Divider className={classes.divider} />
        <Container disableGutters className={classes.footerContainer}>
          <Container disableGutters className={classes.statusContainer}>
            <Typography
              variant="body2"
              align="left"
              className={classes.reminderHeader}
            >
              {t(`${tKey}.body.reminderHeader`)}
            </Typography>
          </Container>

          <Typography variant="body2" align="left">
            {t(`${tKey}.body.processDescription`, { brand: brand.name || '' })}
          </Typography>
          <List className={classes.list}>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <EmailIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(`${tKey}.body.reminders.0`)}
              />
            </ListItem>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <WatchLaterIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(`${tKey}.body.reminders.1`)}
              />
            </ListItem>
            <ListItem className={classes.listItem} alignItems="flex-start">
              <ListItemIcon className={classes.listItemIcon}>
                <DescriptionIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t(`${tKey}.body.reminders.2`)}
              />
            </ListItem>
          </List>
          <Typography variant="body2" align="left">
            {t(`${tKey}.body.support`)}
          </Typography>
          {canReschedule && (
            <>
              <Divider className={classes.divider} />
              <Typography
                variant="body2"
                align="left"
                className={classes.rescheduleCaption}
              >
                {t(`${tKey}.scheduling.cantMakeAppointment`)}
              </Typography>
              <Container disableGutters className={classes.row}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                  onClick={toggleScheduleModal}
                >
                  {t(`${tKey}.scheduling.reSchedule`)}
                </Button>
              </Container>
            </>
          )}
        </Container>
      </Container>
      {canReschedule && scheduleModalOpen && (
        <RescheduleModalContainer
          omsItemID={item.oms_order_item_id || 0}
          onClose={toggleScheduleModal}
        />
      )}
    </>
  )
}

export default PhoneCallPrep
