import React from 'react'
import useEffectOnce from 'react-use/lib/useEffectOnce'

// https://reactrouter.com/web/guides/scroll-restoration
const ScrollToTop: React.FC = () => {
  useEffectOnce(() => {
    window.scrollTo(0, 0)
  })

  return null
}

export default ScrollToTop
