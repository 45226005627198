import { HashMap, LoadingLoadedErrorState } from '@talentinc/state-utils'

import { AttachmentActions } from './actions'

export interface Attachment {
  id: number
  user_id: number
  file_name: string
  file_size: number
}

export interface PendingAttachment extends Omit<Attachment, 'user_id' | 'id'> {
  id: null
  user_id: null
}

export interface AttachmentSuccessResponse<T = Attachment> {
  error: null
  attachment: T
}

export interface AttachmentErrorResponse {
  error: 'size' | 'virus' | 'generic'
  attachment: null
}

export type AttachmentResponse =
  | AttachmentSuccessResponse
  | AttachmentErrorResponse

export type AttachmentStateItem =
  | AttachmentResponse
  | AttachmentSuccessResponse<PendingAttachment>

export interface AttachmentsState {
  // The keys in these hashmaps are the names of the files, which is the only
  // identifier we have preupload.
  attachments: HashMap<AttachmentStateItem>
  meta: {
    [AttachmentActions.UPLOAD_ATTACHMENT]: HashMap<LoadingLoadedErrorState>
  }
  // If you need to lookup a file by the created attachment ID, you want to go
  // through here.
  toAttachmentID: HashMap<number>
}

export const initialState: AttachmentsState = Object.freeze({
  attachments: {},
  toAttachmentID: {},
  meta: {
    [AttachmentActions.UPLOAD_ATTACHMENT]: {},
  },
})
