import React from 'react'
import { ItemTypes } from 'store/items/types'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import PngLetter from 'assets/images/upsell-shopping-letter-square.png'
import PngLetterThankYou from 'assets/images/upsell-shopping-thank-you-letter-square.png'
import PngLinkedIn from 'assets/images/upsell-shoping-linkedin-square.png'
import PngInterviewPrep from 'assets/images/upsell-shopping-interview-prep-square.png'
import PngRabbit from 'assets/images/upsell-shopping-resume-rabbit-square.png'
import PngRecruiter from 'assets/images/upsell-shopping-recruiter-square.png'
import PngGuarantee from 'assets/images/upsell-shopping-guarantee-square.png'
import PngResume from 'assets/images/upsell-shopping-resume-square.png'
import { HashMap } from '@talentinc/state-utils'

interface Props {
  itemType: ItemTypes
  itemName: string
  className?: string
}

const iconMap: HashMap<string> = {
  [ItemTypes.InterviewPrep]: PngInterviewPrep,
  [ItemTypes.LinkedInDocument]: PngLinkedIn,
  [ItemTypes.PostingService]: PngRabbit,
  [ItemTypes.RecruiterService]: PngRecruiter,
  [ItemTypes.Guarantee]: PngGuarantee,
  [ItemTypes.Resume]: PngResume,
  [ItemTypes.ThankYouLetter]: PngLetterThankYou,
}

const BundleItemIcon: React.FC<Props> = ({ itemType, itemName, className }) => {
  const classes = useStyles()
  let icon = PngLetter

  if (iconMap[itemType]) {
    icon = iconMap[itemType]
  }

  return (
    <img
      alt={`${itemName} icon`}
      className={clsx(classes.img, className)}
      src={icon}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      borderRadius: '0.25rem',
    },
  })
)

export default BundleItemIcon
