import { HashMap } from '@talentinc/state-utils'

import { FileActions } from './actions'
import {
  FilesState,
  DocumentsByTypeAndOrderItemID,
  File,
  DocumentOrder,
} from './types'
import {
  documentTabUpsellTypes,
  ItemsState,
  UserDocumentsItemProps,
} from 'store/items/types'
import { UpsellsState } from 'store/upsells/types'
import { getUpsells } from 'store/upsells/selectors'

export const documentsGroupedByOrderItemID = (state: FilesState) => {
  return Object.entries(state.byOrderItemID).reduce(
    (acc, [orderItemID, fileIDs]) => {
      acc[orderItemID] = Array.from(fileIDs).map(
        (fileID) => state.files[fileID]
      )

      return acc
    },
    {} as HashMap<File[]>
  )
}

export const documentsByOrderItemID = (
  state: FilesState,
  orderItemID: number
) =>
  Array.from(state.byOrderItemID[orderItemID] || [])
    .map((fileID) => state.files[fileID])
    .sort((a, b) => b.created_at.getTime() - a.created_at.getTime())

export const documentsByOMSClientID = (state: FilesState, clientID: number) =>
  Array.from(state.byClientID[clientID] || []).map(
    (fileID) => state.files[fileID]
  )

// Get documents by the Portal's Item ID
export const documentsByItemID = (
  filesState: FilesState,
  itemsState: ItemsState,
  itemID: number
) => {
  const item = itemsState.items[itemID]

  if (!item.oms_order_item_id) {
    return []
  }

  return Array.from(filesState.byOrderItemID[item.oms_order_item_id]).map(
    (fileID) => filesState.files[fileID]
  )
}

export const documentsByIDs = (
  state: FilesState,
  fileIDs: number[] | Set<number>
) => {
  if (fileIDs instanceof Set) {
    return Array.from(fileIDs).map((id) => state.files[id])
  }

  return fileIDs.map((id) => state.files[id])
}

export const documentGroupedByType = (state: FilesState, clientID: number) =>
  sortDocumentsIntoMap(documentsByIDs(state, state.byClientID[clientID]))

export const documentsGroupedByTypeAndClient = (state: FilesState) =>
  Object.values(state.byClientID).map((ids) =>
    sortDocumentsIntoMap(documentsByIDs(state, ids))
  )

export const documentsGroupedByTypeAndOrderItemID = (state: FilesState) =>
  sortDocumentsIntoMap(Object.values(state.files))

export const documentsByClientIDGroupedByType = (
  state: FilesState,
  clientID: number
) => sortDocumentsIntoMap(documentsByOMSClientID(state, clientID))

// Given a list of Files, group them by document type (resume, CV, linkedin,
// etc.) and then by OMS order item ID, and place files in reverse chronological
// order. This will give you a list of items and their documents in the correct
// order for rendering in the UI
export const sortDocumentsIntoMap = (
  files: File[]
): DocumentsByTypeAndOrderItemID => {
  // Collect all the files into a hash map tiered by
  // doc_type -> order_item_id -> files array
  // JS objects aren't sorted by insertion order, so we'll sort after we get the
  // data looking like we want.
  const unsortedGrouping = files.reduce((acc, file) => {
    if (!file.order_item_id || !file.doc_type) {
      return acc
    }

    if (!acc[file.doc_type]) {
      acc[file.doc_type] = {}
    }

    if (!acc[file.doc_type][file.order_item_id]) {
      acc[file.doc_type][file.order_item_id] = []
    }

    acc[file.doc_type][file.order_item_id] = [
      ...acc[file.doc_type][file.order_item_id],
      file,
    ]

    return acc
  }, {} as HashMap<HashMap<File[]>>)

  /**
   * Loop through the object created above, cast it's keys into arrays that are
   * sortable, and then insert the values into a Map, which has it's values
   * sorted by insertion order.
   */
  const result: DocumentsByTypeAndOrderItemID = new Map()
  const sortedGrouping = Object.keys(unsortedGrouping)
    .sort(
      // Sort the document types by a fixed array so that resumes and CVs show
      // up first.
      (a, b) => DocumentOrder.indexOf(a) - DocumentOrder.indexOf(b)
    )
    .reduce((acc, docType) => {
      const sortedOrderItemIDs = Object.keys(unsortedGrouping[docType])
        .map((id) => parseInt(id))
        .sort((a, b) => b - a)
      const sortedOrderItems = new Map<number, File[]>()

      sortedOrderItemIDs.forEach((orderItemID) => {
        sortedOrderItems.set(
          orderItemID,
          // Sort the files is descending order so that the newest files are
          // higher up in the list.
          unsortedGrouping[docType][orderItemID].sort((a, b) => b.id - a.id)
        )
      })

      acc.set(docType, sortedOrderItems)
      return acc
    }, result)

  return sortedGrouping
}

export const documentsForUserFinishedFetching = (state: FilesState): boolean =>
  state.meta[FileActions.FETCH_FILES_FOR_USER].loaded ||
  Object.keys(state.files).length > 0

export const latestDraftForOrderItem = (
  state: FilesState,
  orderItemID: number | null
): File | null => {
  if (!orderItemID) {
    return null
  }
  return (
    documentsByOrderItemID(state, orderItemID).sort(
      (a, b) => b.created_at.getTime() - a.created_at.getTime()
    )?.[0] || null
  )
}

export const activeDocumentTabs = (
  upsellState: UpsellsState,
  items: DocumentsByTypeAndOrderItemID<UserDocumentsItemProps>
): string[] => {
  const upsellItemNames = getUpsells(upsellState, [])
    .filter((u) =>
      u.items.find((i) => documentTabUpsellTypes.has(i.item_type_key))
    )
    .map((u) => u.items[0].name)
  const itemTypes = Array.from(items.keys())
  return [...itemTypes, ...upsellItemNames]
}

export const autoLinkedinItemID = (state: FilesState): number | null => {
  const possibleAutoLinkedinItemIDs = Object.values(state.files)
    .filter((file) => !!file.order_item_id && documentIsFromAutoLinkedin(file))
    .map((file) => file.order_item_id as number)

  if (possibleAutoLinkedinItemIDs.length === 0) {
    return null
  }

  return Math.max(...possibleAutoLinkedinItemIDs)
}

export const documentIsFromAutoLinkedin = (doc: File): boolean =>
  doc.template_tracking?.includes('autolinkedin') || false
