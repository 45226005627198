import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import CIOInfoModal from 'components/Modals/variants/CIOInfoModal'

import cioIcon from 'assets/images/career-io-white.svg'
import hintIcon from 'assets/images/hint-icon.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: '#EAF6FF',
      border: 'none',
      borderRadius: '.38em',
      padding: '1.5em 1.25em',

      [theme.breakpoints.down('sm')]: {
        padding: '1em 1.5em',
      },
    },
    title: {
      color: '#052a4d',
      fontSize: '1.125em',
      fontWeight: 600,
    },
    p: {
      color: '#052a4d',
      fontSize: '1em',
    },
    img: {
      margin: '0 0.25em',
      marginBottom: '-0.8em',
    },
    hintButton: {
      margin: '0 0.25em',
      marginBottom: '0.2em',
    },
  })
)

const CIOBanner: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [infoModalVisiblity, setInfoModalVisiblity] = useState(false)

  const showInfoModal = useCallback(() => setInfoModalVisiblity(true), [])

  const closeInfoModal = useCallback(() => setInfoModalVisiblity(false), [])

  return (
    <>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.title}>
          {t('components.Portfolio.CIOBanner.title')}
          <img alt="Career.io" className={classes.img} src={cioIcon} />
          Career.io
          <IconButton
            aria-label="hint"
            className={classes.hintButton}
            onClick={showInfoModal}
          >
            <img alt="Career.io" src={hintIcon} />
          </IconButton>
        </Typography>
        <Typography className={classes.p}>
          {t('components.Portfolio.CIOBanner.copy')}
        </Typography>
      </Paper>
      {infoModalVisiblity ? <CIOInfoModal onClose={closeInfoModal} /> : null}
    </>
  )
}

export default CIOBanner
