import { Auth0Error } from 'auth0-js'
import * as ram from '@talentinc/redux-axios-middleware'
import * as Sentry from '@sentry/browser'

import { AppThunkAction } from 'store'
import {
  constructLockFromConfig,
  setAuth0Cookie,
} from 'components/Auth/useAuth0'
import { APIError } from 'utils/axios'
import {
  CustomerJWT,
  SignUpPayload,
  User,
  PasswordGrantResponse,
  UserUpdatePayload,
  UserIdentifier,
  CIOMagicLinkResponse,
} from './types'
import { sendEvent } from 'store/events/actions'
import { UserUpdateValidationErrors, ValidationMessageKeys } from './validation'

export enum UserActions {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  LOGOUT = 'LOGOUT',
  CHECKING_SESSION = 'CHECKING_SESSION',

  SIGN_UP = 'SIGN_UP',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL = 'SIGN_UP_FAIL',

  FETCH_USER = 'FETCH_USER',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_FAIL = 'FETCH_USER_FAIL',

  PASSWORD_GRANT_LOGIN = 'PASSWORD_GRANT_LOGIN',
  PASSWORD_GRANT_LOGIN_SUCCESS = 'PASSWORD_GRANT_LOGIN_SUCCESS',
  PASSWORD_GRANT_LOGIN_FAIL = 'PASSWORD_GRANT_LOGIN_FAIL',

  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL = 'UPDATE_USER_FAIL',

  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL',

  CREATE_CIO_MAGIC_LINK = 'CREATE_CIO_MAGIC_LINK',
  CREATE_CIO_MAGIC_LINK_SUCCESS = 'CREATE_CIO_MAGIC_LINK_SUCCESS',
  CREATE_CIO_MAGIC_LINK_FAIL = 'CREATE_CIO_MAGIC_LINK_FAIL',
}

interface LoginSuccess {
  type: UserActions.LOGIN_SUCCESS
  idTokenPayload: CustomerJWT
  jwt: string
}

export const loginSuccess = (authResult: AuthResult): LoginSuccess => {
  const identity = authResult.idTokenPayload as unknown as CustomerJWT

  Sentry.setUser({
    id: identity['https://products.talentinc.com/user_id']?.toString() || '',
  })

  return {
    type: UserActions.LOGIN_SUCCESS,
    idTokenPayload: authResult.idTokenPayload as unknown as CustomerJWT,
    jwt: authResult.idToken,
  }
}

interface LoginFail {
  type: UserActions.LOGIN_FAIL
  error: Auth0Error
}

export const loginFailure = (error: Auth0Error): LoginFail => ({
  type: UserActions.LOGIN_FAIL,
  error,
})

interface Logout {
  type: typeof UserActions.LOGOUT
}

export const logout = (): Logout => ({ type: UserActions.LOGOUT })

interface CheckingSession {
  type: typeof UserActions.CHECKING_SESSION
  checking: boolean
}

export const checkingSession = (checking: boolean): CheckingSession => ({
  type: UserActions.CHECKING_SESSION,
  checking,
})

interface SignUp extends ram.AxiosMiddlewareActionCreator {
  type: typeof UserActions.SIGN_UP
}

interface SignUpSuccess extends ram.AxiosMiddlewareActionSuccess<User, SignUp> {
  type: typeof UserActions.SIGN_UP_SUCCESS
}

interface SignUpFail extends ram.AxiosMiddlewareActionFail<SignUp> {
  type: typeof UserActions.SIGN_UP_FAIL
}

export const signUp = (
  pat: string,
  brandID: string,
  siteID: string,
  data: SignUpPayload
): SignUp => ({
  type: UserActions.SIGN_UP,
  payload: {
    request: {
      url: '/v2/users',
      method: 'POST',
      params: { pat, brand_id: brandID, site_id: siteID },
      data,
    },
  },
})

export const signUpThunk =
  (
    pat: string,
    brandID: string,
    siteID: string,
    data: SignUpPayload
  ): AppThunkAction<User | APIError> =>
  async (dispatch): Promise<User | APIError> => {
    let val

    try {
      val = (await dispatch(
        signUp(pat, brandID, siteID, data)
      )) as unknown as SignUpSuccess

      // Send an event before continuing
      dispatch(
        sendEvent(
          {
            event: 'Portal Sign Up - Success',
          },
          pat
        )
      )
    } catch (e) {
      await dispatch(
        sendEvent(
          {
            event: 'Portal Sign Up - Failure',
          },
          pat
        )
      )

      return e as APIError
    }

    if ('password' in data) {
      await dispatch(passwordGrantLogin(data.email, data.password))
    }

    return val.payload.data
  }

export interface FetchUserSuccess
  extends ram.AxiosMiddlewareActionSuccess<User, FetchUser> {
  type: typeof UserActions.FETCH_USER_SUCCESS
}

export interface FetchUserFail
  extends ram.AxiosMiddlewareActionFail<FetchUser> {
  type: typeof UserActions.FETCH_USER_FAIL
}

export interface FetchUser extends ram.AxiosMiddlewareActionCreator {
  type: UserActions.FETCH_USER
  userIdentifier: UserIdentifier
}

export function fetchUser(userIdentifier: UserIdentifier): FetchUser {
  return {
    type: UserActions.FETCH_USER,
    userIdentifier,
    payload: {
      request: {
        url: `/v2/users/${userIdentifier}`,
      },
    },
  }
}

export const possiblyRefreshUserSession =
  (): AppThunkAction => async (dispatch, getState) => {
    if (getState().user.auth.checkingSession.loaded) {
      return
    }

    dispatch(checkingSession(true))
    const auth0Config = getState().config.config?.auth0

    if (auth0Config) {
      const lock = constructLockFromConfig(auth0Config)
      lock.checkSession({}, (_, authResult) => {
        if (authResult?.idTokenPayload) {
          setAuth0Cookie(authResult)
          dispatch(loginSuccess(authResult))
        }

        dispatch(checkingSession(false))
      })
    }
  }

export interface PasswordGrantLogin extends ram.AxiosMiddlewareActionCreator {
  type: UserActions.PASSWORD_GRANT_LOGIN
}

export interface PasswordGrantLoginSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    PasswordGrantResponse,
    PasswordGrantLogin
  > {
  type: UserActions.PASSWORD_GRANT_LOGIN_SUCCESS
}

export interface PasswordGrantLoginFail
  extends ram.AxiosMiddlewareActionSuccess<PasswordGrantLogin> {
  type: UserActions.PASSWORD_GRANT_LOGIN_FAIL
}

export const passwordGrantLogin = (
  email: string,
  password: string
): PasswordGrantLogin => ({
  type: UserActions.PASSWORD_GRANT_LOGIN,
  payload: {
    request: {
      url: `/v2/auth/password-grant`,
      method: 'POST',
      data: {
        email,
        password,
      },
    },
  },
})

interface UserUpdate extends ram.AxiosMiddlewareActionCreator {
  type: UserActions.UPDATE_USER
}

interface UserUpdateSuccess
  extends ram.AxiosMiddlewareActionSuccess<User, UserUpdate> {
  type: UserActions.UPDATE_USER_SUCCESS
}

interface UserUpdateFail extends ram.AxiosMiddlewareActionFail<UserUpdate> {
  type: UserActions.UPDATE_USER_FAIL
}

export const updateUser = (
  data: UserUpdatePayload,
  userIdentifier: UserIdentifier = 'me'
): UserUpdate => ({
  type: UserActions.UPDATE_USER,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}`,
      method: 'PATCH',
      data,
    },
  },
})

export const updateUserWithErrorHandling =
  (
    data: UserUpdatePayload,
    userIdentifier: UserIdentifier = 'me'
  ): AppThunkAction<UserUpdateValidationErrors | null> =>
  async (dispatch): Promise<UserUpdateValidationErrors | null> => {
    try {
      await dispatch(updateUser(data, userIdentifier))
    } catch (e) {
      const errorAction = e as UserUpdateFail

      if (!errorAction.error.response?.data) {
        return null
      }

      const errorResponse = errorAction.error.response.data as any
      let errors: string[] = []

      if ('error' in errorResponse) {
        errors = [errorResponse.error]
      }

      if ('errors' in errorResponse) {
        errors = [
          // @ts-ignore
          ...errorResponse.errors.map((err) => err.error),
        ]
      }

      const validationErrors = errors.reduce((acc, err) => {
        if (err.includes(ValidationMessageKeys.WrongCurrentPassword)) {
          acc.current_password = [ValidationMessageKeys.WrongCurrentPassword]
        }

        if (err.includes(ValidationMessageKeys.ConflictingEmailAddress)) {
          acc.email = [ValidationMessageKeys.ConflictingEmailAddress]
        } else if (err.includes(ValidationMessageKeys.CouldNotUpdateEmail)) {
          acc.email = [ValidationMessageKeys.CouldNotUpdateEmail]
        }

        return acc
      }, {} as UserUpdateValidationErrors)

      return validationErrors
    }

    return null
  }

interface ForgotPassword extends ram.AxiosMiddlewareActionCreator {
  type: UserActions.FORGOT_PASSWORD
}

interface ForgotPasswordSuccess
  extends ram.AxiosMiddlewareActionSuccess<any, ForgotPassword> {
  type: UserActions.FORGOT_PASSWORD_SUCCESS
}

interface ForgotPasswordFail
  extends ram.AxiosMiddlewareActionFail<ForgotPassword> {
  type: UserActions.FORGOT_PASSWORD_FAIL
}

export const forgotPasswordSubmission = (email: string): ForgotPassword => ({
  type: UserActions.FORGOT_PASSWORD,
  payload: {
    request: {
      url: '/v2/auth/forgot-password',
      method: 'POST',
      data: { email },
    },
  },
})

export interface CreateCIOMagicLink extends ram.AxiosMiddlewareActionCreator {
  type: UserActions.CREATE_CIO_MAGIC_LINK
}

export interface CreateCIOMagicLinkSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    CIOMagicLinkResponse,
    CreateCIOMagicLink
  > {
  type: UserActions.CREATE_CIO_MAGIC_LINK_SUCCESS
}

export interface CreateCIOMagicLinkFail
  extends ram.AxiosMiddlewareActionFail<CreateCIOMagicLink> {
  type: UserActions.CREATE_CIO_MAGIC_LINK_FAIL
}

export const createCIOMagicLink = (
  userIdentifier: UserIdentifier
): CreateCIOMagicLink => ({
  type: UserActions.CREATE_CIO_MAGIC_LINK,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/cio-magic-link`,
      method: 'GET',
    },
  },
})

export type UserAction =
  | LoginSuccess
  | LoginFail
  | Logout
  | CheckingSession
  | SignUp
  | SignUpSuccess
  | SignUpFail
  | FetchUser
  | FetchUserSuccess
  | FetchUserFail
  | PasswordGrantLogin
  | PasswordGrantLoginSuccess
  | PasswordGrantLoginFail
  | UserUpdate
  | UserUpdateSuccess
  | UserUpdateFail
  | ForgotPassword
  | ForgotPasswordSuccess
  | ForgotPasswordFail
  | CreateCIOMagicLink
  | CreateCIOMagicLinkSuccess
  | CreateCIOMagicLinkFail
