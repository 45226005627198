import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { sendEvent as reduxSendEvent } from 'store/events/actions'
import { UserIdentifier } from 'store/user/types'
import { EventPayload } from 'store/events/types'

/**
 * useEvents is a React hook that wraps sending events in Redux in such a way
 * that the identity of sendEvent is stable and won't change between renders.
 */
function useEvents() {
  const dispatch = useDispatch()
  const sendEvent = useCallback(
    async (payload: EventPayload, userIdentifier?: UserIdentifier) => {
      await dispatch(reduxSendEvent(payload, userIdentifier))
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  )

  return sendEvent
}

export default useEvents
