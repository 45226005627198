import every from 'lodash/every'
import { HashMap } from '@talentinc/state-utils'

import {
  ItemsState,
  Item,
  ItemWithDocuments,
  ItemStatus,
  ItemStatusTypes,
  UserDocumentsItemProps,
  ItemWithLatestDraftDate,
  ItemTypes,
  phoneTypes,
  emailFulfilledTypes,
  ItemWithExpert,
  InterviewPrepDocsDisplayProps,
  ProgressWidgetProps,
  ItemTypeWithHistory,
  defaultProgressSteps,
  PreparednessStatus,
  NextStepCTAProps,
} from './types'
import {
  FilesState,
  DocumentsByTypeAndOrderItemID,
  DocumentOrder,
  File,
} from 'store/files/types'
import {
  documentsByOrderItemID,
  latestDraftForOrderItem,
} from 'store/files/selectors'
import { OrdersState, Expert } from 'store/orders/types'
import {
  activeOrders,
  pastOrders,
  expertForOrder,
  completedOrders,
  allOrders,
  formattedOrderID,
  draftOrCallPending,
  allDraftOrCallCompleted,
} from 'store/orders/selectors'
import { RatingState } from 'store/ratings/types'
import { starRatingForItem } from 'store/ratings/selectors'
import { isDueForRefresh } from 'store/orders/order_widget'
import { SchedulingState } from 'store/scheduling/types'
import { itemHasCronofy } from 'store/scheduling/selectors'
import { UpsellsState } from 'store/upsells/types'
import { upsellsByItemTypes } from 'store/upsells/selectors'

export const itemByID = (state: ItemsState, itemID: number): Item | null =>
  state.items[itemID]

export const itemsByIDs = (
  state: ItemsState,
  itemIDs: number[] | Set<number>
) =>
  Array.from(itemIDs || [])
    .map((id) => state.items[id])
    .sort(
      (a, b) => (a.oms_order_item_id ? 0 : 1) - (b.oms_order_item_id ? 0 : 1)
    )

export const itemByOMSItemID = (
  state: ItemsState,
  omsItemID: number
): Item | null => state.items[state.byOMSOrderItemID[omsItemID]] || null

export const itemsForActiveOrdersWithDocuments = (
  itemsState: ItemsState,
  ordersState: OrdersState,
  filesState: FilesState,
  ratingsState: RatingState
) => {
  const orderItems = activeOrders(ordersState).flatMap((order) => {
    const itemsForOrder = itemsByIDs(itemsState, itemsState.byOrderID[order.id])
    return itemsForOrder
  })
  const items: ItemWithDocuments[] = orderItems
    .map((item) =>
      item.requires_document
        ? itemWithExpert(ordersState, itemWithDocuments(filesState, item))
        : itemWithDocuments(filesState, item)
    )
    .filter((item) => item.requires_document === true)
  return items
}

export const itemsForActiveOrders = (
  itemsState: ItemsState,
  ordersState: OrdersState
) =>
  activeOrders(ordersState).flatMap((order) =>
    itemsForOrder(itemsState, order.id)
  )

export const itemsForPastOrders = (
  itemsState: ItemsState,
  ordersState: OrdersState
) =>
  pastOrders(ordersState).flatMap((order) =>
    itemsForOrder(itemsState, order.id)
  )

export const itemsForPastOrdersWithDocuments = (
  itemsState: ItemsState,
  ordersState: OrdersState,
  filesState: FilesState,
  ratingsState: RatingState
) =>
  pastOrders(ordersState)
    .flatMap((order) =>
      itemsForOrderWithDocuments(
        itemsState,
        filesState,
        ordersState,
        ratingsState,
        order.id
      )
    )
    .map((item) => {
      // TOTALHACK: move any lingering 'active' item statuses to 'completed' b/c this is a 'past order'
      const completedItemStatus: ItemStatus[] = item.status.completed.concat(
        item.status.active
      )
      item.status = {
        active: [],
        completed: completedItemStatus,
      }
      return item
    })

export const itemsForOrder = (state: ItemsState, orderID: number): Item[] =>
  itemsByIDs(state, state.byOrderID[orderID])

export const itemsForOrders = (state: ItemsState, orderIDs: number[]): Item[] =>
  orderIDs.flatMap((orderID) => itemsForOrder(state, orderID))

export const itemsForPackage = (state: ItemsState, packageID: number): Item[] =>
  itemsByIDs(state, state.byPackageID[packageID])

export const itemForOMSOrderItemID = (
  state: ItemsState,
  omsItemID: number
): Item | null => state.items[state.byOMSOrderItemID[omsItemID]] || null

export const itemsForOMSClientID = (
  state: ItemsState,
  omsClientID: number
): Item[] => itemsByIDs(state, state.byOMSClientID[omsClientID])

export const itemsForOrdersRequiringDocuments = (
  state: ItemsState,
  orderID: number
) => itemsForOrder(state, orderID).filter((item) => item.requires_document)

export const itemWithDocuments = (
  filesState: FilesState,
  item: Item
): ItemWithDocuments => ({
  ...item,
  documents: item.oms_order_item_id
    ? documentsByOrderItemID(filesState, item.oms_order_item_id)
    : [],
})

export const extraItemsByForOrdersByID = (
  itemsState: ItemsState,
  ordersState: OrdersState
): HashMap<Item[]> => {
  const itemMap: HashMap<Item[]> = {}
  allOrders(ordersState).map(
    (o) =>
      (itemMap[o.id] = itemsForOrder(itemsState, o.id).filter(
        (i) => !i.requires_document && !emailFulfilledTypes.has(i.item_type)
      ))
  )
  return itemMap
}

export const documentItemsForOrdersByID = (
  itemsState: ItemsState,
  ordersState: OrdersState
): HashMap<Item[]> => {
  const itemMap: HashMap<Item[]> = {}
  allOrders(ordersState).forEach(
    (o) =>
      (itemMap[o.id] = itemsForOrder(itemsState, o.id).filter(
        (i) => i.requires_document || emailFulfilledTypes.has(i.item_type)
      ))
  )
  return itemMap
}

export const documentItemsForOrderID = (
  orderID: number,
  itemsState: ItemsState,
  ordersState: OrdersState
): ItemWithExpert[] =>
  itemsForOrder(itemsState, orderID)
    .filter((i) => i.requires_document || emailFulfilledTypes.has(i.item_type))
    .map((item) => ({
      ...item,
      expert: expertForOrder(ordersState, orderID),
      dueForRefresh: itemDueForFrefresh(ordersState, itemsState, item, orderID),
    }))

export const itemsNotCompleted = (state: ItemsState) =>
  Object.values(state.items).filter((item) => !itemCompleted(item))

export const itemCompleted = (item: Item) =>
  !item.status.completed.find(
    (status) => status.state === ItemStatusTypes.Complete
  )

export const itemWithExpert = (
  ordersState: OrdersState,
  item: ItemWithDocuments
): ItemWithDocuments => ({
  ...item,
  expert: expertForOrder(
    ordersState,
    ordersState.orders[ordersState.byItemID[item.id]].id
  ),
})

export const itemWithFreshness = (
  ordersState: OrdersState,
  itemsState: ItemsState,
  item: ItemWithDocuments,
  orderID: number
): ItemWithDocuments => ({
  ...item,
  dueForRefresh: itemDueForFrefresh(ordersState, itemsState, item, orderID),
})

export const itemDueForFrefresh = (
  orderState: OrdersState,
  itemState: ItemsState,
  item: ItemWithDocuments | Item,
  orderID: number
) => {
  if (!item || item.item_type !== ItemTypes.Resume) return false
  const order = orderState.orders[orderID]
  if (!order) return false
  const fresherItems = Object.values(orderState.orders)
    .filter(
      (o) =>
        o.id !== orderID && o.created_at.getTime() > order.created_at.getTime()
    )
    .flatMap((o) =>
      o.packages.flatMap((p) => p.itemIDs.flatMap((i) => itemState.items[i]))
    )
    .filter((i) => i && i.item_type === item.item_type)
  if (fresherItems.length > 0) return false
  return isDueForRefresh(orderState, orderID)
}

export const itemsForPackageWithDocuments = (
  itemsState: ItemsState,
  filesState: FilesState,
  ordersState: OrdersState,
  packageID: number
) =>
  itemsForPackage(itemsState, packageID).map((item) =>
    itemWithExpert(ordersState, itemWithDocuments(filesState, item))
  )

export const itemsForOrderWithDocuments = (
  itemsState: ItemsState,
  filesState: FilesState,
  ordersState: OrdersState,
  ratingsState: RatingState,
  orderID: number
) =>
  itemsForOrder(itemsState, orderID)
    .filter((item) => item.requires_document)
    .map((item) =>
      itemWithFreshness(
        ordersState,
        itemsState,
        itemWithExpert(
          ordersState,
          itemWithDocuments(
            filesState,
            itemWithRating(item, itemsState, ratingsState)
          )
        ),
        orderID
      )
    )

// return the item status for the requested step
export const stepItemStatus = (
  item: Item | ItemWithDocuments,
  step: string
): ItemStatus | null => {
  // If the item isn't in the OMS, there will never be statuses
  if (!item.oms_order_item_id) {
    return null
  }
  // If no completed statuses, the item is probably active
  const completedStatus: ItemStatus | null =
    item.status.completed.find((status) => status.state === step) || null
  if (!completedStatus) {
    return item.status.active.find((status) => status.state === step) || null
  }
  return completedStatus
}

export const currentItemStatus = (
  item: Item | ItemWithDocuments
): ItemStatus | null => {
  // If the item isn't in the OMS, there will never be statuses
  if (!item.oms_order_item_id) {
    return null
  }

  const activeStatuses = item.status.active

  // If no active statuses, the order is probably completed
  if (!activeStatuses.length) {
    return (
      item.status.completed.find(
        (status) => status.state === ItemStatusTypes.Complete
      ) || null
    )
  }

  // If the order is unassigned, we need to show the awaiting assignment bits.
  if ('expert' in item && item.expert === null) {
    const firstDraftDue = item.status.active.find(
      (status) => status.state === ItemStatusTypes.FirstDraft
    )
    let itemState: ItemStatusTypes = ItemStatusTypes.AwaitingWriterMatch
    if (phoneTypes.has(item.item_type)) {
      itemState = ItemStatusTypes.AwaitingPhoneCall
    }
    return {
      state_id: null,
      state: itemState,
      product_id: item.id,
      started_at: null,
      due_at: firstDraftDue?.due_at || null,
      ended_at: null,
    }
  }

  // if this is a linked-in item, return the 'send-linkedin' status before any 'revision' status
  if (item.item_type === ItemTypes.LinkedInDocument) {
    const sendLinkedInStatus: ItemStatus | null =
      (activeStatuses.find(
        (status) => status.state === ItemStatusTypes.SendLinkedin
      ) as ItemStatus) || null
    if (sendLinkedInStatus !== null) return sendLinkedInStatus
    if (activeStatuses.length > 0) {
      if (activeStatuses[0].state === ItemStatusTypes.RevisionMode) {
        // for linked-in items, if current status is 'revision' return 'AwaitingReply'
        // so the message says 'Ready for feedback' and not '7 days left for revisions'
        return {
          ...activeStatuses[0],
          state: ItemStatusTypes.AwaitingReply,
        }
      }
    }
  }

  // When in revisions and the writer is awaiting on a reply from the customer,
  // there will be 2 active statuses and we need to return the awaiting reply
  // status with the end date of the revision status
  const inRevisionAwaitingReplyStatuses = activeStatuses.filter((status) =>
    [ItemStatusTypes.RevisionMode, ItemStatusTypes.AwaitingReply].includes(
      status.state
    )
  )

  if (inRevisionAwaitingReplyStatuses.length === 2) {
    const revisionStatus = inRevisionAwaitingReplyStatuses.find(
      (status) => status.state === ItemStatusTypes.RevisionMode
    ) as ItemStatus
    const replyStatus = inRevisionAwaitingReplyStatuses.find(
      (status) => status.state === ItemStatusTypes.AwaitingReply
    ) as ItemStatus

    return {
      ...(replyStatus || revisionStatus),
      due_at: revisionStatus.due_at,
    }
  }

  return activeStatuses[0]
}

export const orderIDForItemID = (
  state: ItemsState,
  itemID: number
): number | null =>
  Object.entries(state.byOrderID)
    .map(([strOrderID, itemIDs]): [number, Set<number>] => [
      parseInt(strOrderID),
      itemIDs,
    ])
    .find(([, itemIDs]) => itemIDs.has(itemID))?.[0] || null

export const itemWithRating = (
  item: Item,
  itemsState: ItemsState,
  ratingState: RatingState
) => ({
  ...item,
  rating: starRatingForItem(ratingState, itemsState, item.id),
})

export const itemsByCompletedStatus = (
  itemsState: ItemsState,
  orderDs: number[] | null | undefined,
  statusKey: string
): Item[] => {
  if (!orderDs) return []
  if (orderDs.length === 0) return []
  const itemIDs = orderDs
    .map((id) =>
      itemsState.byOrderID[id] ? Array.from(itemsState.byOrderID[id]) : []
    )
    .flat()
  if (!itemIDs) return []
  return itemIDs
    .map((id) => itemsState.items[id])
    .filter(
      (item) =>
        item &&
        item.status.completed.find((status) => status.state === statusKey)
    )
}

export const latestItemDelivery = (
  itemsState: ItemsState,
  orderDs: number[] | null | undefined
): Date | null | undefined => {
  const deliveryDates = itemsByCompletedStatus(
    itemsState,
    orderDs,
    ItemStatusTypes.FirstDraft
  )
    .map(
      (item) =>
        item.status.completed.find(
          (status) => status.state === ItemStatusTypes.FirstDraft
        )?.ended_at
    )
    .sort((a, b) => (!b || !a ? -1 : b.getTime() - a.getTime()))
  if (deliveryDates.length === 0) return null
  return deliveryDates[0]
}

export const userActiveDocumentsDisplayProps = (
  itemsState: ItemsState,
  ordersState: OrdersState,
  filesState: FilesState,
  ratingState: RatingState,
  omsClientID: number | null
): DocumentsByTypeAndOrderItemID<UserDocumentsItemProps> | null => {
  if (!omsClientID) return null
  const activeEHOrderIDs = new Set(
    activeOrders(ordersState).flatMap((o) => o.expert_hub_order_ids)
  )
  const allItemsForClient = itemsForOMSClientID(itemsState, omsClientID).filter(
    (i) =>
      i.oms_order_id &&
      activeEHOrderIDs.has(i.oms_order_id) &&
      i.requires_document
  )

  return userDocumentsDisplayProps(
    itemsState,
    ordersState,
    filesState,
    ratingState,
    allItemsForClient
  )
}

export const userCompletedDocumentsDisplayProps = (
  itemsState: ItemsState,
  ordersState: OrdersState,
  filesState: FilesState,
  ratingState: RatingState,
  omsClientID: number | null
): DocumentsByTypeAndOrderItemID<UserDocumentsItemProps> | null => {
  if (!omsClientID) return null
  const activeEHOrderIDs = new Set(
    completedOrders(ordersState).flatMap((o) => o.expert_hub_order_ids)
  )
  const allItemsForClient = itemsForOMSClientID(itemsState, omsClientID).filter(
    (i) =>
      i.oms_order_id &&
      activeEHOrderIDs.has(i.oms_order_id) &&
      i.requires_document
  )

  return userDocumentsDisplayProps(
    itemsState,
    ordersState,
    filesState,
    ratingState,
    allItemsForClient
  )
}

export const userDocumentsDisplayProps = (
  itemsState: ItemsState,
  ordersState: OrdersState,
  filesState: FilesState,
  ratingState: RatingState,
  allItemsForClient: Item[]
): DocumentsByTypeAndOrderItemID<UserDocumentsItemProps> => {
  const result: DocumentsByTypeAndOrderItemID<UserDocumentsItemProps> =
    new Map()

  // Our result is going to look like this
  // {
  //    [item.name]: {
  //      [item.oms_order_item_id]: {
  //        ...item,
  //        rating,
  //        expert,
  //        documents,
  //      }
  //    }
  // }
  //
  // We will collect our data into an unsorted hashmap of this shape and then
  // convert it to Maps so that they are sorted in the exact order the UI needs.

  const orderIDSet = new Set()
  const orderIDs = allItemsForClient
    .flatMap((item) => orderIDForItemID(itemsState, item.id) || [])
    .filter((o) => {
      if (orderIDSet.has(o)) {
        return false
      }
      orderIDSet.add(o)
      return true
    })

  const unsortedGrouping = allItemsForClient.reduce((acc, item) => {
    if (!item.oms_order_item_id) {
      return acc
    }

    if (!acc[item.name]) {
      acc[item.name] = {}
    }

    acc[item.name][item.oms_order_item_id] = item

    return acc
  }, {} as Record<string, Record<number, Item>>)

  return Object.keys(unsortedGrouping)
    .sort((a, b) => {
      let aIndex = DocumentOrder.indexOf(
        Object.values(unsortedGrouping[a])[0].item_type
      )
      let bIndex = DocumentOrder.indexOf(
        Object.values(unsortedGrouping[b])[0].item_type
      )

      if (aIndex === -1) {
        aIndex = 99
      }

      if (bIndex === -1) {
        bIndex = 99
      }

      return aIndex - bIndex
    })
    .reduce((acc, itemName) => {
      const sortedOrderItemIDs = Object.keys(unsortedGrouping[itemName])
        .map((id) => parseInt(id))
        .sort((a, b) => b - a)
      const sortedItems = new Map<number, UserDocumentsItemProps>()

      sortedOrderItemIDs.forEach((orderItemID) => {
        const itemID = unsortedGrouping[itemName][orderItemID].id

        sortedItems.set(orderItemID, {
          ...unsortedGrouping[itemName][orderItemID],
          rating: starRatingForItem(ratingState, itemsState, itemID),
          expert: expertForItem(itemsState, ordersState, itemID),
          documents: documentsByOrderItemID(filesState, orderItemID),
          orderID: orderIDForItemID(itemsState, itemID),
          orderIDs: orderIDs,
        })
      })

      acc.set(itemName, sortedItems)

      return acc
    }, result)
}

export const itemNameForItemByOMSItemID = (
  state: ItemsState,
  omsItemID: number
): string | null =>
  state.items?.[state.byOMSOrderItemID[omsItemID]]?.name || null

// @TODO Eventually, this should get smart enough to determine the correct
// expert for interview prep and resume orders.
export const expertForItem = (
  itemsState: ItemsState,
  ordersState: OrdersState,
  itemID: number
): Expert | null => {
  const orderID = orderIDForItemID(itemsState, itemID)

  if (!orderID) {
    return null
  }

  return expertForOrder(ordersState, orderID)
}

export const itemsForApprovalModal = (
  itemsState: ItemsState,
  filesState: FilesState,
  orderIDs: number[]
): ItemWithLatestDraftDate[] =>
  itemsForOrders(itemsState, orderIDs)
    .filter(
      (item) =>
        item.requires_document &&
        item.oms_order_item_id &&
        !!latestDraftForOrderItem(filesState, item.oms_order_item_id)
    )
    .map((item) => {
      // Aggressively typecasting here because I already ensured that the
      // document exists in the filter
      const latestDraft = latestDraftForOrderItem(
        filesState,
        item.oms_order_item_id as number
      ) as File

      return {
        ...item,
        latestDraftDate: latestDraft.created_at,
        revision: latestDraft.revision + 1,
      }
    })

export const hasCompletedInterview = (state: ItemsState, itemID: number) => {
  const item = state.items[itemID]
  if (!item) return false
  if (
    item.status.completed.filter(
      (s) => s.state === ItemStatusTypes.ConductInterview
    ).length > 0
  ) {
    return true
  }

  return false
}

export const activeInterviewPrepDisplayProps = (
  itemState: ItemsState,
  orderState: OrdersState,
  filesState: FilesState,
  schedulingState: SchedulingState,
  clientID: number | null
) => {
  if (!clientID) return null
  const orderIDs = activeOrders(orderState).flatMap((o) => o.id)
  const pkgs = orderState.packagesByClientID[clientID]?.filter((p) =>
    orderIDs.includes(p.orderID)
  )
  if (!pkgs) return null
  const items = pkgs.flatMap((p) =>
    p.items.filter(
      (i) =>
        i.status.completed.filter(
          (s) => s.state === ItemStatusTypes.SendInterviewSummary
        ).length === 0
    )
  )
  return interviewPrepDocsDisplayProps(
    itemState,
    orderState,
    filesState,
    schedulingState,
    clientID,
    items,
    false
  )
}

export const hasPastInterviewPrepItems = (
  ordersState: OrdersState,
  clientID: number
) => {
  const pkgs = ordersState.packagesByClientID[clientID]
  if (!pkgs) return false
  const items = pkgs.flatMap((p) => p.items)
  return (
    items.filter(
      (i) =>
        i.status.completed.filter(
          (s) => s.state === ItemStatusTypes.SendInterviewSummary
        ).length > 0
    ).length > 0 && items.length > 1
  )
}

export const completedInterviewPrepDisplayProps = (
  itemState: ItemsState,
  orderState: OrdersState,
  filesState: FilesState,
  schedulingState: SchedulingState,
  clientID: number | null
) => {
  if (!clientID) return null
  const orderIDs = completedOrders(orderState).flatMap((o) => o.id)
  const pkgs = orderState.packagesByClientID[clientID]?.filter((p) =>
    orderIDs.includes(p.orderID)
  )
  if (!pkgs) return null
  const items = pkgs.flatMap((p) =>
    p.items.filter(
      (i) =>
        i.status.completed.filter(
          (s) => s.state === ItemStatusTypes.SendInterviewSummary
        ).length > 0
    )
  )
  return interviewPrepDocsDisplayProps(
    itemState,
    orderState,
    filesState,
    schedulingState,
    clientID,
    items,
    true
  )
}

export const interviewPrepDocsDisplayProps = (
  itemState: ItemsState,
  orderState: OrdersState,
  filesState: FilesState,
  schedulingState: SchedulingState,
  clientID: number | null,
  items: Item[],
  isCompleted: boolean
): InterviewPrepDocsDisplayProps | null => {
  if (!clientID) return null
  if (items.length === 0) return null

  const scheduledItems = items
    .filter((i) =>
      i.status.active.find(
        (s) => s.state === ItemStatusTypes.ConductInterview && s.due_at
      )
    )
    .sort((a, b) => a.id - b.id)

  //if there are items scheduled already show those first
  const earliestItem =
    scheduledItems.length > 0
      ? scheduledItems[0]
      : items.sort((a, b) => a.id - b.id)[0]

  return {
    item: earliestItem,
    expert: expertForItem(itemState, orderState, earliestItem.id),
    doc: earliestItem.oms_order_item_id
      ? documentsByOrderItemID(filesState, earliestItem.oms_order_item_id).sort(
          (a, b) => b.id - a.id
        )[0]
      : null,

    hasMultipleSessions: hasPastInterviewPrepItems(orderState, clientID),
    isCompleted: isCompleted,
    // @TODO Make this respect whether or not the assigned expert has Cronofy
    hasScheduler: itemHasCronofy(
      schedulingState,
      earliestItem.oms_order_item_id || 0
    ),
  }
}

export const allItemsInOrderHaveFirstDraft = (
  itemsState: ItemsState,
  omsClientID: number
) =>
  every(
    itemsForOMSClientID(itemsState, omsClientID)
      .filter(
        (item) =>
          item.requires_document && item.item_type !== ItemTypes.PhoneCall
      )
      .map(
        (item) =>
          !!item.status.completed.find(
            ({ state }) =>
              state === ItemStatusTypes.FirstDraft ||
              state === ItemStatusTypes.SendLinkedin
          )
      )
  )

export const schedulableItems = (state: ItemsState) =>
  Object.values(state.items).filter((item) => phoneTypes.has(item.item_type))

export const formattedOrderIDForItem = (
  ordersState: OrdersState,
  itemID: number
) => {
  const order = ordersState.orders[ordersState.byItemID[itemID]]

  if (!order) {
    return ''
  }

  return formattedOrderID(order)
}

export const formattedOrderIDForSchedulerItem = (
  ordersState: OrdersState,
  items: ItemsState,
  itemID: number
) => {
  const order = ordersState.orders[ordersState.byItemID[itemID]]

  if (!order) {
    return ''
  }

  if (order.expert_hub_client_ids?.length > 0) {
    const omsClientID = items.items[itemID].oms_client_id
    return `${order.id}-${omsClientID}`
  }

  return order.id.toString()
}

export const itemTypesWithHistory = (
  itemState: ItemsState,
  orderState: OrdersState,
  itemType: ItemTypes
): ItemTypeWithHistory[] =>
  Object.values(itemState.items)
    .filter((item) => item.item_type === itemType)
    .map((item) => {
      return {
        itemType: item.item_type,
        lastPurchased:
          orderState.orders[orderState.byItemID[item.id]].created_at,
      } as ItemTypeWithHistory
    })
    .sort((a, b) => {
      if (!b.lastPurchased || !a.lastPurchased) return 0
      return b.lastPurchased.getTime() - a.lastPurchased.getTime()
    })

export const itemTypeWithHistoryAndUpsell = (
  itemState: ItemsState,
  orderState: OrdersState,
  upsellState: UpsellsState,
  itemType: ItemTypes
): ItemTypeWithHistory => {
  const upsells = upsellsByItemTypes(upsellState, [itemType], [])
  const items = itemTypesWithHistory(itemState, orderState, itemType)
  if (items.length === 0)
    return { itemType, recommendedUpsell: upsells ? upsells[0] : null }
  return { ...items[0], recommendedUpsell: upsells ? upsells[0] : null }
}

export const progressWidgetProps = (
  itemState: ItemsState,
  orderState: OrdersState,
  upsellState: UpsellsState
): ProgressWidgetProps => {
  return {
    steps: defaultProgressSteps.map((step) => ({
      step: step.step,
      items: Array.from(step.items).map((item) =>
        itemTypeWithHistoryAndUpsell(
          itemState,
          orderState,
          upsellState,
          item.itemType
        )
      ),
    })),
  }
}

export const hasPurchasedItem180Days = (
  itemState: ItemsState,
  orderState: OrdersState,
  itemType: ItemTypes
) => {
  const daysAgo180 = new Date()
  daysAgo180.setDate(daysAgo180.getDate() - 180)
  return (
    itemTypesWithHistory(itemState, orderState, itemType).filter(
      (item) =>
        item.itemType === itemType &&
        item.lastPurchased &&
        item.lastPurchased.getTime() > daysAgo180.getTime()
    ).length > 0
  )
}

export const nextStepCTAProps = (
  itemState: ItemsState,
  upsellState: UpsellsState,
  orderState: OrdersState,
  preparednessStatus: PreparednessStatus
): NextStepCTAProps => {
  if (preparednessStatus === PreparednessStatus.UpdateNeeded)
    return { upsell: null, itemType: null }

  const draftPending = draftOrCallPending(orderState)
  const draftCompleted = allDraftOrCallCompleted(orderState)
  const purchasedInterview = hasPurchasedItem180Days(
    itemState,
    orderState,
    ItemTypes.InterviewPrep
  )
  const purchasedLinkedin = hasPurchasedItem180Days(
    itemState,
    orderState,
    ItemTypes.LinkedInDocument
  )

  if (draftPending && !purchasedLinkedin) {
    const upsells = upsellsByItemTypes(
      upsellState,
      [ItemTypes.LinkedInDocument],
      []
    )
    return {
      upsell: upsells && upsells.length > 0 ? upsells[0] : null,
      itemType: ItemTypes.LinkedInDocument,
    }
  }
  if (draftCompleted && !purchasedInterview) {
    const upsells = upsellsByItemTypes(
      upsellState,
      [ItemTypes.InterviewPrep],
      []
    )
    return {
      upsell: upsells && upsells.length > 0 ? upsells[0] : null,
      itemType: ItemTypes.InterviewPrep,
    }
  }

  return { upsell: null, itemType: null }
}
