import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { useForm } from 'react-final-form-hooks'

import PersonalInfoInput from './PersonalInfoInput'
import { UserUpdatePayload } from 'store/user/types'
import { validateUserUpdatePayload } from 'store/user/validation'
import { OnUpdateUser } from './types'

export type FormFieldType =
  | 'text'
  | 'password'
  | 'password-strength'
  | 'email'
  | 'tel'

export interface FormField {
  label: keyof UserUpdatePayload
  value?: string | null
  type?: FormFieldType
}

interface Props {
  formFields: FormField[]
  fieldName: string
  onUpdateUser: OnUpdateUser
  setEditing: any
  isUpdating?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      float: 'right',
      marginTop: '0',
      minWidth: '9.8em',
      width: 'initial',
    },
    cancel: {
      lineHeight: '1',
      position: 'absolute',
      right: '0',
      top: '1.78em',
    },
    enclosingContainer: {
      display: 'initial',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    formContainerColumn: {
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  })
)

const FormItem: React.FC<Props> = ({
  fieldName,
  formFields,
  onUpdateUser,
  setEditing,
  isUpdating,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // Add extra class on password forms
  const containerClass =
    fieldName === 'password' ? classes.formContainerColumn : null

  // Form management is here
  const { form, valid, handleSubmit, dirtySinceLastSubmit } =
    useForm<UserUpdatePayload>({
      onSubmit: async (data) => await onUpdateUser(data),
      initialValues: formFields.reduce((acc, field) => {
        // @ts-ignore
        acc[field.label] = field.value
        return acc
      }, {} as UserUpdatePayload),
      validate: validateUserUpdatePayload,
    })

  return (
    <Container
      className={classes.enclosingContainer}
      disableGutters
      component="form"
      onSubmit={handleSubmit}
    >
      <Container
        className={clsx(classes.formContainer, containerClass)}
        disableGutters
      >
        {formFields.map((formField) => (
          <PersonalInfoInput
            key={formField.label}
            fieldName={formField.label}
            type={formField.type}
            form={form}
            inputNum={formFields.length}
          />
        ))}
      </Container>
      <Button
        className={classes.button}
        color="primary"
        disabled={(!valid && !dirtySinceLastSubmit) || isUpdating}
        variant="contained"
        type="submit"
      >
        {t('pages.Account.save')}
      </Button>
      <Button
        className={classes.cancel}
        color="primary"
        onClick={() => setEditing(false)}
        variant="text"
      >
        {t('pages.Account.cancel')}
      </Button>
    </Container>
  )
}

export default FormItem
