import { HashMap } from '@talentinc/state-utils'
import { compareAsc, compareDesc, startOfDay } from 'date-fns'
import { futureDate } from 'utils/formatting'
import {
  OrderStatusTypes,
  Order,
  OrderStatus,
  CompletedOrderStatus,
  OrderAggregate,
  FullOrderStatus,
} from './types'

// flatten all active orders into a single order
export const activeOrderReducer = (
  // First loop through, orderAccumulator will be an Order, for all other
  // orders, it will be an OrderAggregate.
  orderAccumulator: Order | OrderAggregate,
  currentOrder: Order
): OrderAggregate => {
  // copy the orderAccumulator into a new variable, mergedOrder, because
  // orderAccumulator is frozen when coming from the store. mutating like
  // this selector is doing is impossible on a frozen object.
  const mergedOrder: OrderAggregate = {
    ...orderAccumulator,
    orderIDs: new Set<number>([
      ...Array.from(
        'orderIDs' in orderAccumulator ? orderAccumulator.orderIDs : []
      ),
      orderAccumulator.id,
      currentOrder.id,
    ]),
  }
  // use the min order id
  mergedOrder.id = Math.min(orderAccumulator.id, currentOrder.id)
  // combine total_amounts
  mergedOrder.total_amount += currentOrder.total_amount
  // combine experts, only add experts from currentOrder, if they don't exist
  // in the orderAccumulator
  mergedOrder.experts = orderAccumulator.experts
    ? orderAccumulator.experts.concat(
        currentOrder.experts.filter(
          (expertB) =>
            !orderAccumulator.experts
              .flatMap((expertA) => expertA.id)
              .includes(expertB.id)
        )
      )
    : []
  // combine expert_hub_client_ids, only add expert_hub_client_ids from
  // currentOrder, if they don't exist in the orderAccumulator
  mergedOrder.expert_hub_client_ids = orderAccumulator.expert_hub_client_ids
    ? orderAccumulator.expert_hub_client_ids.concat(
        currentOrder.expert_hub_client_ids.filter(
          (expertIDB) =>
            !orderAccumulator.expert_hub_client_ids.includes(expertIDB)
        )
      )
    : []
  // use the max due_date
  if (
    compareDesc(
      orderAccumulator.due_date || new Date(),
      currentOrder.due_date || new Date()
    ) === 1
  ) {
    mergedOrder.due_date = currentOrder.due_date || null
  }

  // use the MIN created_at date
  if (
    compareAsc(
      orderAccumulator.created_at || futureDate(),
      currentOrder.created_at || futureDate()
    ) === 1
  ) {
    mergedOrder.created_at = currentOrder.created_at || null
  }

  // add all of the packages from currentOrder, sort them id asc
  mergedOrder.packages = (
    orderAccumulator.packages
      ? orderAccumulator.packages.concat(currentOrder.packages)
      : []
  ).sort((a, b) => (a.id <= b.id ? -1 : 1))

  // here's the fun part, reducing order statuses
  // first, combine the active and completed status arrays in both orders
  const allStatuses =
    orderAccumulator &&
    orderAccumulator.status &&
    orderAccumulator.status.active
      ? orderAccumulator.status.active.concat(
          orderAccumulator.status.completed,
          currentOrder.status.active,
          currentOrder.status.completed
        )
      : []
  //console.log('allStatuses: ', allStatuses)

  // create an array of all OrderStatusTypes
  const statusTypes: string[] = []
  for (const statusKey in OrderStatusTypes) {
    const statusType =
      OrderStatusTypes[statusKey as keyof typeof OrderStatusTypes]
    statusTypes.push(statusType)
  }

  // loop through every OrderStatusType and reduce those statuses
  const allStatusesReduced: OrderStatus[] = statusTypes.flatMap(
    (statusType) => {
      let statuses: OrderStatus[] = []
      switch (statusType) {
        case OrderStatusTypes.CompleteQuestionnaire:
          // 'complete-questionnaire' - show a max of 1 completed and 1 active
          const reducedActiveQuestionnaireStatuses = questionnaireStatusFilter(
            statusType,
            allStatuses,
            true
          )
          const reducedCompletedQuestionnaireStatuses =
            questionnaireStatusFilter(statusType, allStatuses, false)
          statuses = statuses.concat(
            reducedActiveQuestionnaireStatuses,
            reducedCompletedQuestionnaireStatuses
          )
          break
        case OrderStatusTypes.ExpertAssignment:
          // special filter for 'expert-assignment' statuses
          const reducedExpertAssignedStatuses = expertAssignedStatusFilter(
            statusType,
            allStatuses
          )
          statuses = statuses.concat(reducedExpertAssignedStatuses)
          break
        case OrderStatusTypes.FirstDraft:
        case OrderStatusTypes.Completed:
        case OrderStatusTypes.RevisionPeriod:
          // 'first-draft' or 'completed' - reduce if they are 'expected' on the same day
          const reducedFirstDraftActiveStatuses = documentStatusFilter(
            statusType,
            allStatuses,
            true
          )
          statuses = statuses.concat(reducedFirstDraftActiveStatuses)
          const reducedFirstDraftCompletedStatuses = documentStatusFilter(
            statusType,
            allStatuses,
            false
          )
          statuses = statuses.concat(reducedFirstDraftCompletedStatuses)
          break
        case OrderStatusTypes.LinkedInDue:
          // 'linkedin-due' - keep all, no reducing
          const linkedInStatuses = allStatuses.filter(
            (status) => status.status === statusType
          )
          statuses = statuses.concat(linkedInStatuses)
          break
        case OrderStatusTypes.ScheduleInterview:
        case OrderStatusTypes.SchedulePhone:
          // 'schedule-phone','phone' and 'schedule-interview','interview' are dealt with as pairs
          let statusOne = OrderStatusTypes.Interview
          let statusTwo = OrderStatusTypes.ScheduleInterview
          if (statusType === OrderStatusTypes.SchedulePhone) {
            statusOne = OrderStatusTypes.Phone
            statusTwo = OrderStatusTypes.SchedulePhone
          }
          const reducedActiveInterviewStatuses = scheduleInterviewStatusFilter(
            statusOne,
            statusTwo,
            allStatuses,
            true
          )
          statuses = statuses.concat(reducedActiveInterviewStatuses)
          const reducedCompletedInterviewStatuses =
            scheduleInterviewStatusFilter(
              statusOne,
              statusTwo,
              allStatuses,
              false
            )
          statuses = statuses.concat(reducedCompletedInterviewStatuses)
          break
        case OrderStatusTypes.Phone:
        case OrderStatusTypes.Interview:
          // do nothing with 'phone' and 'interview', they are handled above
          break
        default:
          // for all other statuses, just add them all without merging for now
          const otherStatuses = allStatuses.filter(
            (status) => status.status === statusType
          )
          statuses = statuses.concat(otherStatuses)
          break
      }
      return statuses
    }
  )

  //console.log('reduced: ', allStatusesReduced)
  // split allStatusesReduced back into 'active' and 'completed' arrays
  const mergedOrderStatuses: FullOrderStatus = { ...mergedOrder.status } // create a copy of mergedOrder's status arrays
  // overwrite the active status array
  mergedOrderStatuses.active = allStatusesReduced.filter(
    (status) => status.completed_at === null
  )
  // for 'completed' we need to recast everything as a CompletedOrderStatus type
  const completedStatuses: CompletedOrderStatus[] = allStatusesReduced
    .filter((status) => status.completed_at !== null)
    .map((status) => {
      const completedStatus: CompletedOrderStatus = {
        ...status,
        completed_at: status.completed_at || new Date(), // don't worry none of these are null ;)
      }
      return completedStatus
    })
  // overwrite the completed status array
  mergedOrderStatuses.completed = completedStatuses
  mergedOrder.status = mergedOrderStatuses
  return mergedOrder
}

const questionnaireStatusFilter = (
  statusType: OrderStatusTypes,
  allStatuses: OrderStatus[],
  isActive: boolean
) => {
  // 'complete-questionnaire' - show a max of 1 completed and 1 active status
  const questionnaireStatuses = allStatuses.filter(
    (status) =>
      status.status === statusType &&
      (isActive ? status.completed_at === null : status.completed_at !== null)
  )
  if (questionnaireStatuses.length > 1) {
    // reduce to 1 active 'complete-questionnaire' status
    if (isActive) {
      return questionnaireStatuses.reduce(createdAtStatusReducer)
    } else {
      return questionnaireStatuses.reduce(completedAtStatusReducer)
    }
  } else if (questionnaireStatuses.length === 1) {
    // nothing to reduce, just return it
    return questionnaireStatuses[0]
  }
  return []
}

const expertAssignedStatusFilter = (
  statusType: OrderStatusTypes,
  allStatuses: OrderStatus[]
) => {
  // 'expert-assignment' filter - reduce to 1 status
  const completedStatuses = allStatuses.filter(
    (status) => status.status === statusType && status.completed_at !== null
  )
  const activeStatuses = allStatuses.filter(
    (status) => status.status === statusType && status.completed_at === null
  )
  if (completedStatuses.length === 0) {
    // no completedStatuses?  reduce to 1 active 'expert-assignment' status
    if (activeStatuses.length > 1) {
      return activeStatuses.reduce(createdAtStatusReducer)
    } else if (activeStatuses.length === 1) {
      return activeStatuses[0]
    }
  } else if (completedStatuses.length > 1) {
    // or reduce to 1 completed 'expert-assignment' status
    return completedStatuses.reduce(completedAtStatusReducer)
  } else if (completedStatuses.length === 1) {
    return completedStatuses[0]
  }
  return []
}

const documentStatusFilter = (
  statusType: OrderStatusTypes,
  allStatuses: OrderStatus[],
  isActive: boolean
) => {
  // 'first-draft' or 'completed' - reduce if they are 'expected' on the same day, otherwise include all
  const documentStatuses: OrderStatus[] = allStatuses
    .filter(
      (status) =>
        status.status === statusType &&
        (isActive ? status.completed_at === null : status.completed_at !== null)
    )
    .map((status) => {
      const flatStatus: OrderStatus = {
        ...status,
        expected_at:
          status.expected_at === null
            ? status.expected_at
            : startOfDay(status.expected_at || 0), // flatten to start of day
      }
      return flatStatus
    })

  const statusItemIDs: HashMap<number[]> = {} // use a hashmap to keep track of concatted statusItemIDs
  const foundDates = new Set() // use a Set to filter for unique dates
  const filteredDocumentStatuses = documentStatuses
    .filter((status) => {
      // convert Date to string for Set to stay unique
      const expectedAt =
        status.expected_at === null ? 'NULLDATE' : status.expected_at.toString()
      if (foundDates.has(expectedAt)) {
        statusItemIDs[expectedAt] = statusItemIDs[expectedAt].concat(
          status.itemIDs
        )
        return false
      }
      statusItemIDs[expectedAt] = status.itemIDs
      foundDates.add(expectedAt)
      return true
    })
    .map((status) => {
      // set statusItemIDs on the correct statuses
      const expected =
        status.expected_at === null ? 'NULLDATE' : status.expected_at.toString()
      status.itemIDs = statusItemIDs[expected]
      return status
    })
  return filteredDocumentStatuses
}

const scheduleInterviewStatusFilter = (
  statusTypeOne: OrderStatusTypes,
  statusTypeTwo: OrderStatusTypes,
  allStatuses: OrderStatus[],
  isActive: boolean
) => {
  // filter 'phone' and 'schedule-phone' statuses
  // or filter 'interview' and 'schedule-interview' statuses
  const statuses = allStatuses.filter(
    (statusC) =>
      (statusC.status === statusTypeOne || statusC.status === statusTypeTwo) &&
      (isActive ? statusC.completed_at === null : statusC.completed_at !== null)
  )
  // only return the first active or completed status, reduce the rest
  if (statuses.length > 0) {
    return statuses[0]
  }
  return []
}

const createdAtStatusReducer = (statusA: OrderStatus, statusB: OrderStatus) => {
  // reduce to the status with the most recent created_at date
  if (
    compareDesc(
      statusA.created_at || new Date(),
      statusB.created_at || new Date()
    ) === 1
  ) {
    return statusB
  } else {
    return statusA
  }
}

const completedAtStatusReducer = (
  statusA: OrderStatus,
  statusB: OrderStatus
) => {
  // reduce to the status with the oldest completed_at date
  if (
    compareAsc(
      statusA.completed_at || new Date(),
      statusB.completed_at || new Date()
    ) === 1
  ) {
    return statusB
  } else {
    return statusA
  }
}
