import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { GhostLinkButton } from 'components/shared/GhostLink'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(() =>
  createStyles({
    h2: {
      padding: '1.5em 1.6em 1.3em',
    },
    linkContainer: {
      padding: '2em',
    },
  })
)

const PurchaseHistoryInfo: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.h2} variant="h2">
        {t('pages.Account.purchaseHistoryTitle')}
      </Typography>
      <Divider />
      <Container className={classes.linkContainer}>
        <GhostLinkButton color="primary" to={t('routes.purchaseHistory')}>
          {t('pages.Account.purchaseHistoryLink')}
        </GhostLinkButton>
      </Container>
    </>
  )
}

export default PurchaseHistoryInfo
