import { isEqual } from 'lodash'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { AppState } from 'store'
import { curatedUpsells } from 'store/upsells/selectors'
import { Upsell } from 'store/upsells/types'

function useRandomUpsell(): Upsell | null {
  const upsells = useSelector<AppState, Upsell[] | null>(
    (state) => curatedUpsells(state.orders, state.upsells),
    isEqual
  )
  const [upsellIndex, setUpsellIndex] = useState(0)

  //randomly show a different upsell whenever the route changes
  useEffectOnce(() => {
    if (upsells && upsells.length > 0) {
      setUpsellIndex(Math.floor(Math.random() * upsells.length))
    }
  })

  const upsell = upsells && upsells.length > 0 ? upsells[upsellIndex] : null
  return upsell
}

export default useRandomUpsell
