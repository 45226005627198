import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import AddIcon from '@material-ui/icons/Add'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import CheckIcon from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { ItemTypeWithHistory } from 'store/items/types'
import useShoppingCart from 'hooks/useShoppingCart'

interface ItemIconProps {
  lastPurchased?: Date | null
  inCart?: boolean
}

const StepItemIcon: React.FC<ItemIconProps> = ({ lastPurchased, inCart }) => {
  const classes = useStyles()
  const daysAgo180 = new Date()
  daysAgo180.setDate(daysAgo180.getDate() - 180)
  if (!lastPurchased && !inCart)
    return <AddIcon className={classes.available} />
  if (
    (lastPurchased && lastPurchased.getTime() > daysAgo180.getTime()) ||
    inCart
  )
    return <CheckIcon className={inCart ? classes.inCart : classes.purchased} />
  return <MoreHorizIcon className={classes.purchased} />
}

interface Props {
  item: ItemTypeWithHistory
}

const PurchasedStepItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <StepItemIcon lastPurchased={item.lastPurchased} />
      <Typography
        variant="body2"
        className={clsx(classes.itemName, classes.purchased)}
      >
        {t(`itemTypes.${item.itemType}`)}
      </Typography>
    </Box>
  )
}

const AvailableStepItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { cartPlanCodes, addToCart, updateOpen } = useShoppingCart()

  const addItemToCart = useCallback(
    async (planCode: string) => {
      addToCart(planCode, 'progress-widget')
      updateOpen(true)
    },
    [updateOpen, addToCart]
  )

  const itemInCart = !!(
    item.recommendedUpsell &&
    cartPlanCodes.includes(item.recommendedUpsell.plan_code)
  )
  return (
    <Box className={classes.container}>
      <Button
        variant="text"
        onClick={() => {
          if (item.recommendedUpsell && !itemInCart)
            addItemToCart(item.recommendedUpsell.plan_code)
        }}
        disabled={itemInCart}
      >
        <StepItemIcon lastPurchased={item.lastPurchased} inCart={itemInCart} />
        <Typography
          variant="body2"
          className={clsx(
            classes.itemName,
            itemInCart ? classes.inCart : classes.available
          )}
        >
          {t(`itemTypes.${item.itemType}`)}
        </Typography>
      </Button>
    </Box>
  )
}

const StepItem: React.FC<Props> = ({ item }) => {
  if (item.lastPurchased) return <PurchasedStepItem item={item} />
  return <AvailableStepItem item={item} />
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginRight: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    itemName: {
      fontSize: '0.875rem',
      marginLeft: '0.25rem',
    },
    purchased: {
      color: colors.blue[700],
    },
    available: {
      color: colors.blue[400],
    },
    inCart: {
      color: colors.green[400],
    },
  })
)

export default StepItem
