import React, { useCallback, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { format as dateFormat, differenceInMinutes } from 'date-fns'
import { generatePath } from 'react-router-dom'

import { useWrapperStyles } from './Wrapper'
import { GhostLinkButton } from 'components/shared/GhostLink'
import Button from '@material-ui/core/Button'
import RescheduleModalContainer from './RescheduleModalContainer'
import { ItemTypes } from 'store/items/types'

interface Props {
  sessionStart: Date
  omsClientID: number
  omsItemID: number
  itemType: ItemTypes
  expertName?: string | null
}

const Scheduled: React.FC<Props> = ({
  sessionStart,
  omsItemID,
  omsClientID,
  itemType,
  expertName,
}) => {
  const { t } = useTranslation()
  const classes = useWrapperStyles()
  const joinBtnDisabled = differenceInMinutes(sessionStart, new Date()) > 15
  const tKey = 'components.Scheduler.Scheduled'
  const sessionType = t(`${tKey}.${itemType}`)

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)

  const toggleScheduleModal = useCallback(async () => {
    setScheduleModalOpen((open) => !open)
  }, [])

  // @TODO Is there a state for when the expert is waiting for them?
  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t(`${tKey}.header`, {
          sessionStart: dateFormat(sessionStart, t(`${tKey}.dateFormat`)),
          sessionType,
          expertName: expertName || t(`shared.yourExpert`),
        })}
      </Typography>
      <Typography className={classes.bodyCopy}>
        {t(`${tKey}.copy`, { sessionType })}
      </Typography>

      <GhostLinkButton
        variant="contained"
        color="primary"
        disabled={joinBtnDisabled}
        className={classes.bodyBtn}
        to={generatePath(t('routes.call'), { omsItemID })}
      >
        {t(`${tKey}.join`, { sessionType })}
      </GhostLinkButton>
      <GhostLinkButton
        variant="outlined"
        color="primary"
        className={classes.bodyBtn}
        to={generatePath(t('routes.messagesByClientAndItem'), {
          clientID: omsClientID,
          itemID: omsItemID,
        })}
      >
        {t(`${tKey}.prepare`, { sessionType })}
      </GhostLinkButton>

      <Button
        variant="outlined"
        color="primary"
        className={classes.bodyBtn}
        onClick={toggleScheduleModal}
      >
        {t(`${tKey}.reschedule`, { sessionType })}
      </Button>
      {scheduleModalOpen && (
        <RescheduleModalContainer
          omsItemID={omsItemID}
          onClose={toggleScheduleModal}
        />
      )}
    </>
  )
}

interface MinimalProps {
  sessionStart: Date
  omsItemID: number
  expertName?: string | null
}
export const MinimalScheduled: React.FC<MinimalProps> = ({
  sessionStart,
  omsItemID,
  expertName,
}) => {
  const { t } = useTranslation()
  const classes = useWrapperStyles()
  const tKey = 'components.Scheduler.ScheduledMinimal'
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)

  const toggleScheduleModal = useCallback(async () => {
    setScheduleModalOpen((open) => !open)
  }, [])
  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t(`${tKey}.header`, {
          sessionStart: dateFormat(sessionStart, t(`${tKey}.dateFormat`)),
          expertName: expertName || t(`shared.yourExpert`),
        })}
      </Typography>
      <Typography className={classes.bodyCopy}>{t(`${tKey}.copy`)}</Typography>

      <Button
        variant="outlined"
        color="primary"
        className={classes.bodyBtn}
        onClick={toggleScheduleModal}
      >
        {t(`${tKey}.reschedule`)}
      </Button>
      {scheduleModalOpen && (
        <RescheduleModalContainer
          omsItemID={omsItemID}
          onClose={toggleScheduleModal}
        />
      )}
    </>
  )
}

export default Scheduled
