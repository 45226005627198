import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useUser from 'hooks/useUser'
import { updateUserWithErrorHandling } from 'store/user/actions'
import { UserUpdatePayload } from 'store/user/types'
import Account from './index'
import { OnUpdateUser } from './types'
import { AppState } from 'store'

const AccountContainer: React.FC = () => {
  const user = useUser()
  const dispatch = useDispatch()

  const isUpdatingUser = useSelector<AppState, boolean>(
    (state) => state.user.meta.UPDATE_USER?.isLoading
  )

  const updateUser = useCallback(
    (data: UserUpdatePayload) =>
      dispatch(updateUserWithErrorHandling(data, 'me')),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  ) as unknown as OnUpdateUser

  return (
    <Account
      user={user}
      onUpdateUser={updateUser}
      isUpdatingUser={isUpdatingUser}
    />
  )
}

export default AccountContainer
