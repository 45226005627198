import React from 'react'
import Tabs, { TabsProps } from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles'

const useUnderlineTabsStyles = makeStyles((theme) =>
  createStyles({
    afterLine: {
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      borderBottom: `2px solid rgba(102, 103, 125, 0.2)`,
      color: `rgba(102, 103, 125, 0.2)`,
      height: '2px',
      width: '100%',
      alignSelf: 'flex-end',
      margin: 0,
    },
  })
)

export const UnderlineTabs: React.FC<TabsProps> = ({ children, ...props }) => {
  const classes = useUnderlineTabsStyles()

  return (
    <Tabs {...props}>
      {children}
      <hr className={classes.afterLine} />
    </Tabs>
  )
}

export const UnderlineTab = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    border: 0,
    paddingRight: '1em',
    padding: '0.5em 1em',
    fontSize: '1em',
    fontWeight: 'normal',
    height: '2em',
    color: theme.palette.secondary.main,
    borderBottom: `2px solid rgba(102, 103, 125, 0.3)`,
    marginRight: 0,
    marginLeft: 0,
  },
  wrapper: {
    lineHeight: 1,
  },
  selected: {
    borderRight: `0 !important`,
    borderLeft: `0 !important`,
    borderTop: `0 !important`,
    borderBottom: `2px solid ${theme.palette.primary.dark} !important`,
    color: theme.palette.primary.dark,
  },
}))(Tab)
