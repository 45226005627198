import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useLifecycles from 'react-use/lib/useLifecycles'

import useAuth0 from './useAuth0'
import { colors } from 'styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    auth0Container: {
      fontFamily: '"Proxima Nova", sans-serif !important',

      '& .auth0-lock-header': {
        padding: '0 !important',
        overflow: 'visible !important',
      },

      '& .auth0-global-message': {
        marginTop: '3em',
        textTransform: 'initial !important',
        fontSize: '.9em !important',
        borderRadius: `${theme.shape.borderRadius}px`,
      },

      '& .auth0-global-message-success': {
        backgroundColor: `${theme.palette.success.main} !important`,
      },

      '& .auth0-global-message-error': {
        backgroundColor: `${theme.palette.error.main} !important`,
      },

      '& .auth0-lock': {
        fontFamily: '"Proxima Nova", sans-serif !important',
        fontWeight: '400 !important',
      },

      '& .auth0-lock-header-welcome': {
        color: `${theme.palette.primary.dark} !important`,
        fontWeight: 'bold !important',
        fontSize: '1.13em !important',
      },

      '& .auth0-lock-header-bg .auth0-lock-header-bg-solid': {
        backgroundColor: 'transparent !important',
      },

      '& .auth0-lock-name, & .auth0-lock-firstname': {
        fontSize: '1.13em !important',
      },

      '& .auth0-lock-firstname': {
        marginTop: '0 !important',
      },

      '& .auth0-lock-header-logo': {
        display: 'none !important',
      },

      '& .auth0-lock-header-bg': {
        background: 'transparent !important',
      },

      '& .auth0-lock-header-bg-blur': {
        backgroundImage: 'none !important',
      },

      '& .auth0-lock-submit': {
        textTransform: 'initial !important',
        fontWeight: '600 !important',
        borderRadius: `${theme.shape.borderRadius}px !important`,
        fontSize: '1.13em !important',
        paddingTop: '0.56em !important',
        paddingBottom: '0.56em !important',
        letterSpacing: '0 !important',

        '& > .auth0-label-submit': {
          lineHeight: '1.5 !important',
          height: '1.13em !important',
          transform: 'none !important',
        },

        '&:hover, &:active': {
          backgroundColor: `${colors.blue.a400} !important`,
        },
      },

      '& .auth0-lock-header-avatar': {
        display: 'none !important',
      },

      '& .auth0-lock-input': {
        color: `${theme.palette.primary.dark} !important`,
      },

      '& .auth0-lock-content-body-wrapper': {
        paddingTop: '0 !important',
      },

      '& .auth0-lock.auth0-lock .auth0-lock-form p': {
        color: `${theme.palette.primary.dark} !important`,
      },

      '& .auth0-lock-input-wrap': {
        backgroundColor: `${theme.palette.background.default} !important`,
        color: `${theme.palette.secondary.main} !important`,

        '&.auth0-lock-focused': {
          borderColor: `${theme.palette.primary.main} !important`,
        },
      },

      '& .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon path, .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-custom-icon path':
        {
          fill: `${theme.palette.primary.dark} !important`,
        },

      '& .auth0-lock-back-button': {
        '& polyline, & line': {
          stroke: `${theme.palette.primary.dark} !important`,
        },
      },

      '& .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link':
        {
          color: `${theme.palette.primary.dark} !important`,
        },

      [theme.breakpoints.down('xs')]: {
        maxHeight: '30em',

        '& .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper': {
          height: '355px',
        },
      },
    },
  })
)

const LockForm: React.FC = () => {
  const classes = useStyles()
  const { lock } = useAuth0()

  useLifecycles(
    // mount
    () => {
      // lock will only be set if useAuth0 has determined that it is ready to
      // use (is the config present, we aren't checking the session currently,
      // etc.)
      if (lock) {
        lock.show({
          initialScreen: 'login',
          allowSignUp: false,
        })
      }
    },
    // unmount
    () => {
      // We only use one lock instance per app and we must hide it when
      // unmounted, otherwise, we cannot call lock.show again.
      if (lock) {
        lock.hide()
      }
    }
  )

  return <div className={classes.auth0Container} id="auth0-root"></div>
}

export default React.memo(LockForm)
