import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  CreateCIOMagicLinkSuccess,
  createCIOMagicLink,
} from 'store/user/actions'

export default function useViewInCIO() {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const onViewDocs = useCallback(async () => {
    setLoading(true)
    try {
      const response = (await dispatch(
        createCIOMagicLink('me')
      )) as unknown as CreateCIOMagicLinkSuccess
      const data = response.payload?.data

      if (data?.url) {
        const a = document.createElement('a')
        a.setAttribute('target', '_blank')
        a.href = data.url as string
        a.click()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  return {
    loading,
    onViewDocs,
  }
}
