import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { purchaseUrl } from 'store/upsells/selectors'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import UpsellIcon from 'components/Upsells/UpsellIcon'
import useRandomUpsell from 'hooks/useRandomUpsell'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      color: 'white',
    },
    container: {
      margin: 'initial',
      width: 'calc(100% - 25.7em)',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    h2: {
      fontSize: '1.6em',

      [theme.breakpoints.down('sm')]: {
        fontWeight: 600,
      },
    },
    img: {
      width: '20.7em',

      [theme.breakpoints.down('sm')]: {
        marginTop: '2em',
        width: '16.8em',
      },
    },
    p: {
      margin: '.78em 0 1.39em',
    },
    paper: {
      alignItems: 'center',
      backgroundColor: colors.blue.a500,
      border: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '3.5em 0 2.5em',
      padding: '2.5em 9.1em',

      [theme.breakpoints.down('sm')]: {
        borderRadius: '.38em',
        flexDirection: 'column',
        margin: '3.5em auto 2.5em',
        padding: '2.2em 1.5em 2em',
        width: 'calc(100% - 2.4em)',
      },
    },
  })
)

const UpsellBanner: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const upsell = useRandomUpsell()

  const planCodes = upsell ? [upsell.plan_code] : []

  const upsellUrl = useSelector<AppState, string | null>((state) =>
    purchaseUrl(state.user, state.brand, planCodes, 'article')
  )

  if (upsell) {
    return (
      <Paper className={classes.paper} variant="outlined">
        <Container className={classes.container} disableGutters>
          <Typography className={classes.h2} variant="h2">
            {upsell.name}
          </Typography>
          <Typography className={classes.p}>
            {upsell.very_short_pitch}
          </Typography>
          <Button
            classes={{ label: classes.button }}
            color="primary"
            href={upsellUrl || '#'}
            variant="contained"
          >
            {t('components.Content.upsellButton')}
          </Button>
        </Container>
        <UpsellIcon
          itemType={upsell.items[0].item_type_key}
          itemName={upsell.items[0].name}
          className={classes.img}
        />
      </Paper>
    )
  } else {
    return null
  }
}

export default UpsellBanner
