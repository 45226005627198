import * as ram from '@talentinc/redux-axios-middleware'
import { UserIdentifier } from '../user/types'
import { Transaction } from './types'

export enum TransactionsActions {
  FETCH_USER_TRANSACTIONS = 'FETCH_USER_TRANSACTIONS',
  FETCH_USER_TRANSACTIONS_SUCCESS = 'FETCH_USER_TRANSACTIONS_SUCCESS',
  FETCH_USER_TRANSACTIONS_FAIL = 'FETCH_USER_TRANSACTIONS_FAIL',
}

interface FetchUserTransactions extends ram.AxiosMiddlewareActionCreator {
  type: TransactionsActions.FETCH_USER_TRANSACTIONS
  userIdentifier: UserIdentifier
}

interface FetchUserTransactionsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    Transaction[],
    FetchUserTransactions
  > {
  type: TransactionsActions.FETCH_USER_TRANSACTIONS_SUCCESS
}

interface FetchUserTransactionsFail
  extends ram.AxiosMiddlewareActionFail<FetchUserTransactions> {
  type: TransactionsActions.FETCH_USER_TRANSACTIONS_FAIL
}

export const fetchUserTransactions = (
  userIdentifier: UserIdentifier = 'me'
): FetchUserTransactions => ({
  type: TransactionsActions.FETCH_USER_TRANSACTIONS,
  userIdentifier,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/transactions`,
    },
  },
})

export type TransactionAction =
  | FetchUserTransactions
  | FetchUserTransactionsSuccess
  | FetchUserTransactionsFail
