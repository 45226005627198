import React from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useTranslation } from 'react-i18next'

import ScrollToTop from 'components/shared/ScrollToTop'
import { LiteToolbar } from 'components/shared/LiteHeader'
import { PlainGhostLinkButton } from 'components/shared/PlainLinkButton'

const MinimalPageShell: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ScrollToTop />
      <LiteToolbar>
        <PlainGhostLinkButton to="/" startIcon={<ChevronLeftIcon />}>
          {t('components.MinimalPageShell.backToHome')}
        </PlainGhostLinkButton>
      </LiteToolbar>
      {children}
    </>
  )
}

export default MinimalPageShell
