import React from 'react'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'

import FaqSection from 'components/Faqs/FaqSection'
import FlashErrorBoundary from 'components/shared/Errors'
import PageShell from 'pages/PageShell'

const HomePage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageShell pageTitle={t('components.Faqs.title')}>
      <Container disableGutters>
        <FlashErrorBoundary>
          <FaqSection widget={false} />
        </FlashErrorBoundary>
      </Container>
    </PageShell>
  )
}

export default HomePage
