import { UserUpdatePayload } from './types'

export enum ValidationMessageKeys {
  WrongCurrentPassword = 'incorrect current password provided',
  InvalidEmail = 'invalid email',
  PasswordsDoNotMatch = 'passwords do not match',
  ConflictingEmailAddress = 'ix_users_email',
  CouldNotUpdateEmail = 'UpdateUserEmail()',
}

export type UserUpdateValidationErrors = {
  [T in keyof UserUpdatePayload]: ValidationMessageKeys[]
}

export const validateUserUpdatePayload = (
  data: UserUpdatePayload
): UserUpdateValidationErrors | undefined => {
  const validationErrors: UserUpdateValidationErrors = {}

  if (data.email && !data.email.includes('@')) {
    validationErrors.email = [ValidationMessageKeys.InvalidEmail]
  }

  if (data.new_password !== data.new_password_verification) {
    validationErrors.new_password_verification = [
      ValidationMessageKeys.PasswordsDoNotMatch,
    ]
  }

  return Object.values(validationErrors).length > 0
    ? validationErrors
    : undefined
}
