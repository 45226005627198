import React from 'react'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import OrderWidgetHeader from './Header'
import OrderWidgetItemDetails from './ItemDetails'
import OrderWidgetProgress from './Progress'
import { OrderTypes, OrderWidgetDisplayProps } from 'store/orders/types'
import Divider from './Divider'
import VoiceDrivenItemDetails from './VoiceItemDetails'

const OrderWidget: React.FC<OrderWidgetDisplayProps> = (props) => {
  const classes = useStyles()
  const { items, experts, upcomingVoiceDrivenItems, ...order } = props

  return (
    <Paper variant="outlined" className={classes.paper}>
      <OrderWidgetHeader {...props} />
      {!(props.orderType === OrderTypes.EmailOnly) &&
        !(props.orderType === OrderTypes.AddOnOnly) && (
          <>
            <Divider />
            {upcomingVoiceDrivenItems.map((i) => {
              const expert = experts.find(({ id }) => i.oms_expert_id === id)
              return (
                <VoiceDrivenItemDetails
                  expertName={
                    i.oms_expert_id && !!expert ? expert.display_name : null
                  }
                  item={i}
                  key={i.id}
                />
              )
            })}
            {upcomingVoiceDrivenItems.length > 0 && <Divider />}
            <OrderWidgetItemDetails items={items} />
            <Divider />
            <OrderWidgetProgress
              statuses={order.status}
              expertName={experts[0]?.display_name || null}
              orderID={order.id}
              canRate={props.canRate}
            />
          </>
        )}
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '2em 0 1em',

      '& > *': {
        paddingLeft: '2em',
        paddingRight: '2em',
      },
    },
  })
)

export default OrderWidget
