import React from 'react'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import { ListItem } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { TransactionStatus } from 'store/transactions/types'
import { format as dateFormat } from 'date-fns'
import { CreditCardType } from 'utils/creditCard'
import CreditCardLogo from 'components/shared/CreditCardLogo'
import Price from 'components/shared/Price'

interface Props {
  purchases: Purchase[]
}

export interface Purchase {
  id: number
  date: Date
  items: string
  paymentMethod: {
    creditCardType: CreditCardType
    accountNumber: string
  }
  total: {
    currency_code: string
    currency_symbol: string
    amount: number
  }
  status: TransactionStatus
  brandLogo: string
  brandName: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: '1.25em',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'start',
      },
      [theme.breakpoints.down('md')]: {
        alignItems: 'end',
        justifyContent: 'space-between',
      },
    },
    col: {
      paddingRight: '2em',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
      },
    },
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    headerRow: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    columnLeft: {
      display: 'flex',
      width: '70%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    columnRight: {
      display: 'flex',
      width: '29%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: 'unset',
      },
    },
    red: {
      color: theme.palette.error.main,
    },
    creditCardInfo: {
      '& > p': {
        paddingLeft: '1em',
      },
    },
    brandImg: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '35%',
      },
    },
    col0: {
      width: '20%',
    },
    col1: {
      width: '15%',
    },
    col2: {
      width: '50%',
    },
    col3: {
      width: '30%',
    },
    col4: {
      width: '40%',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4em',
      },
    },
    col5: {
      width: '40%',
    },
  })
)

const PurchaseHistory: React.FC<Props> = ({ purchases }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container className={classes.container} disableGutters>
      <Paper>
        <List>
          <ListItem
            key={'purchase-history-header'}
            divider
            className={clsx(classes.listItem, classes.headerRow)}
          >
            <Box className={classes.columnLeft}>
              <ListItem className={clsx(classes.col, classes.col0)}>
                <Typography className={classes.header}>
                  {t('pages.PurchaseHistory.listBrand')}
                </Typography>
              </ListItem>
              <ListItem className={clsx(classes.col, classes.col1)}>
                <Typography className={classes.header}>
                  {t('pages.PurchaseHistory.listDate')}
                </Typography>
              </ListItem>
              <ListItem className={clsx(classes.col, classes.col2)}>
                <Typography className={classes.header}>
                  {t('pages.PurchaseHistory.listItem')}
                </Typography>
              </ListItem>
              <ListItem className={clsx(classes.col, classes.col3)}>
                <Typography className={classes.header}>
                  {t('pages.PurchaseHistory.listPaymentMethod')}
                </Typography>
              </ListItem>
            </Box>
            <Box className={classes.columnRight}>
              <ListItem className={clsx(classes.col, classes.col4)}>
                <Typography className={classes.header}>
                  {t('pages.PurchaseHistory.listTotal')}
                </Typography>
              </ListItem>
              <ListItem className={clsx(classes.col, classes.col5)}>
                <Typography className={classes.header}>
                  {t('pages.PurchaseHistory.listStatus')}
                </Typography>
              </ListItem>
            </Box>
          </ListItem>
          {purchases.map((p) => (
            <ListItem key={p.id} divider className={classes.listItem}>
              <Box className={classes.columnLeft}>
                <ListItem className={clsx(classes.col, classes.col0)}>
                  {p.brandLogo ? (
                    <img
                      alt={p.brandName}
                      src={p.brandLogo}
                      className={classes.brandImg}
                    />
                  ) : null}
                </ListItem>
                <ListItem className={clsx(classes.col, classes.col1)}>
                  <Typography
                    color="textPrimary"
                    component="time"
                    dateTime={p.date.toISOString()}
                  >
                    {dateFormat(p.date, t('pages.PurchaseHistory.dateFormat'))}
                  </Typography>
                </ListItem>
                <ListItem className={clsx(classes.col, classes.col2)}>
                  <Typography color="textPrimary">{p.items}</Typography>
                </ListItem>
                <ListItem
                  className={clsx(
                    classes.col,
                    classes.col3,
                    classes.creditCardInfo
                  )}
                >
                  <CreditCardLogo type={p.paymentMethod.creditCardType} />
                  <Typography color="textPrimary">
                    {p.paymentMethod.accountNumber}
                  </Typography>
                </ListItem>
              </Box>
              <Box className={classes.columnRight}>
                <ListItem className={clsx(classes.col, classes.col4)}>
                  <Price
                    currencyCode={p.total.currency_code}
                    currencySymbol={p.total.currency_symbol}
                    amount={p.total.amount}
                  />
                </ListItem>
                <ListItem className={clsx(classes.col, classes.col5)}>
                  <Typography
                    color="textPrimary"
                    className={clsx(
                      p.status !== TransactionStatus.Processed
                        ? classes.red
                        : null
                    )}
                  >
                    {t(`pages.PurchaseHistory.transactionStatus.${p.status}`)}
                  </Typography>
                </ListItem>
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  )
}

export default PurchaseHistory
