import React from 'react'
import { format as dateFormat } from 'date-fns'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import { initials } from 'utils/formatting'
import MessageAvatar from 'components/Messaging/MessageAvatar'

interface Props {
  expandAccordion: any
  expanded: boolean
  readAt: Date | null
  sender: string
  sent: Date
  subject: string
  outbound: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionSummary: {
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
    accordionSummaryContent: {
      [theme.breakpoints.down('sm')]: {
        margin: '2em 0',
      },
    },
    h4: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    h5: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
    time: {
      textAlign: 'right',
    },
  })
)

const MessageSummary: React.FC<Props> = ({
  expandAccordion,
  expanded,
  readAt,
  sender,
  sent,
  subject,
  outbound,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  return (
    <AccordionSummary
      classes={{
        content: classes.accordionSummaryContent,
        root: classes.accordionSummary,
      }}
      onClick={() => expandAccordion(!expanded)}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={1}>
          <MessageAvatar
            avatarInitials={initials(sender)}
            readAt={readAt}
            sender={sender}
            outbound={outbound}
          />
        </Grid>
        <Grid item xs={isMobile ? 7 : 8}>
          <Container>
            <Typography className={classes.h5} variant="h5">
              {sender}
            </Typography>
            <Typography variant="body2">{subject}</Typography>
          </Container>
        </Grid>
        <Grid
          item
          xs={3}
          component={(props) => (
            <Typography
              variant="h4"
              component="time"
              dateTime={sent.toISOString()}
              {...props}
              className={clsx(props.className, classes.h4, classes.time)}
            >
              {dateFormat(sent, t('components.Messaging.messageDateFormat'))}
            </Typography>
          )}
        />
      </Grid>
    </AccordionSummary>
  )
}

export default MessageSummary
