import { Item } from 'store/items/types'
import { PackageDisplayProps } from 'store/orders/types'
import { Upsell } from 'store/upsells/types'

export const onlyInterview = (currentPackages: PackageDisplayProps[]) => {
  let nonInterviewItems: Item[] = []

  currentPackages.forEach((currentPackage: PackageDisplayProps) => {
    const nonIntItems = currentPackage.items.filter(
      (item: Item) => item.item_type !== 'interview-prep'
    )
    nonInterviewItems = [...nonInterviewItems, ...nonIntItems]
  })
  return nonInterviewItems.length === 0
}

export const filteredUpsells = (
  upsells: Upsell[],
  currentPackages: PackageDisplayProps[]
) =>
  onlyInterview(currentPackages)
    ? upsells.filter(
        (upsell: Upsell) =>
          upsell.name !== 'Resume Posting Service' &&
          upsell.name !== 'Recruiter.com Resume Distribution Service' &&
          upsell.name !== 'Phone Consultation' &&
          upsell.name !== 'We Apply For You: 20 Applications' &&
          upsell.name !== 'We Apply For You: 50 Applications'
      )
    : upsells
