import React from 'react'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import LinkedInAvatar from 'assets/images/linkedin-avatar.png'
import LinkedInButtons from 'assets/images/linkedin-buttons.png'
import LinkedInHero from 'assets/images/linkedin-hero.png'

import { colors } from 'styles'

import { User } from 'store/user/types'
import { useTranslation } from 'react-i18next'

interface Props {
  user: User | Partial<User>
}

const useStyles = makeStyles((theme) =>
  createStyles({
    addressContainer: {
      marginBottom: '2rem',
      filter: 'blur(5px)',
    },
    area: {
      fontSize: '1rem',
      fontWeight: 100,
    },
    avatar: {
      width: '8rem',
      height: '8rem',
      marginLeft: '1rem',
      marginBottom: '-3rem',
      backgroundColor: colors.gray.b200,
      color: colors.gray[200],
      border: 'solid white 4px',
      boxShadow: theme.shadows[3],
    },
    body: {
      fontSize: '0.9rem',
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: '1rem',
    },
    buttons: {
      width: '300px',
      height: 'auto',
    },
    contact: {
      fontSize: '.8rem',
      margin: 0,
      lineHeight: '1rem',
    },
    contactContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      justifyItems: 'flex-end',
      alignItems: 'flex-end',
      flexBasis: '60%',
    },
    divider: {
      marginTop: '2rem',
      marginBottom: '3rem',
    },
    document: {
      width: '98%',
      margin: '3rem 1% 0',
      paddingBottom: '2em',
      position: 'relative',
    },
    documentBodyContainer: {
      filter: 'blur(10px)',
    },
    documentHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      height: '10rem',
      backgroundColor: '#18a2c4',
      backgroundImage: `url(${LinkedInHero})`,
      backgroundSize: 'cover',
    },
    documentName: {
      fontWeight: 'bold',
    },
    greeting: {
      marginBottom: '1rem',
    },
    headerText: {
      fontFamily: 'Proxima Nova',
      color: '#052a4d',
    },
    overlay: {
      backgroundImage:
        'linear-gradient(rgba(255,255,255,0) 20%, rgba(255,255,255,1) 80%)',
      height: '14.7em',
      left: '-2em',
      position: 'absolute',
      top: '8em',
      width: 'calc(100% + 4em)',
    },
    paragraph: {
      marginBottom: '1rem',
    },
  })
)

const LinkedinPlaceholder: React.FC<Props> = ({ user }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const userName =
    user.first_name && user.last_name
      ? user.first_name + ' ' + user.last_name
      : t(`components.Documents.userDefaults.name`)
  const area = user.state || t(`components.Documents.userDefaults.cityOrState`)
  return (
    <Paper elevation={20} className={classes.document}>
      <Container disableGutters className={classes.documentHeaderContainer}>
        <Avatar
          alt="Remy Sharp"
          className={classes.avatar}
          src={LinkedInAvatar}
        />
      </Container>
      <Container disableGutters className={classes.buttonContainer}>
        <img
          src={LinkedInButtons}
          className={classes.buttons}
          alt="linkedin buttons"
        />
      </Container>
      <Container>
        <Typography
          variant="h3"
          component="h3"
          className={clsx(classes.headerText, classes.documentName)}
        >
          {userName}
        </Typography>
        <Typography
          variant="h3"
          component="h3"
          className={clsx(classes.headerText, classes.area)}
        >
          {area}
        </Typography>
      </Container>
      <div className={classes.overlay}></div>
    </Paper>
  )
}

export default LinkedinPlaceholder
