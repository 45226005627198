import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'

import { useWrapperStyles } from './Wrapper'
import NPSContainer from 'components/Rating/NPSContainer'
import { GhostLinkButton } from 'components/shared/GhostLink'

interface Props {
  sessionTime: Date
  rateable: boolean
  orderID: number
  purchaseURL: string
}

const Completed: React.FC<Props> = ({
  sessionTime,
  orderID,
  purchaseURL,
  rateable,
}) => {
  const { t } = useTranslation()
  const classes = useWrapperStyles()
  const [ratingVisible, showRatingModal] = useState(false)
  const tKey = 'components.Scheduler.Completed'

  // @TODO Replace the NPSContainer with the new item rating flow
  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t(`${tKey}.header`, {
          sessionTime: dateFormat(sessionTime, t(`${tKey}.dateFormat`)),
        })}
      </Typography>
      <Typography className={classes.bodyCopy}>{t(`${tKey}.copy`)}</Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.bodyBtn}
        href={purchaseURL}
      >
        {t(`${tKey}.buy`)}
      </Button>
      {!rateable && (
        <GhostLinkButton
          to={t('routes.portfolio')}
          variant="outlined"
          color="primary"
          className={classes.bodyBtn}
        >
          {t(`${tKey}.viewPortfolio`)}
        </GhostLinkButton>
      )}
      {ratingVisible && (
        <NPSContainer
          orderID={orderID}
          onClose={() => showRatingModal(false)}
        />
      )}
    </>
  )
}

interface MinimalProps {
  sessionTime: Date
  expertName?: string | null
}
export const MinimalCompleted: React.FC<MinimalProps> = ({
  sessionTime,
  expertName,
}) => {
  const { t } = useTranslation()
  const classes = useWrapperStyles()
  const tKey = 'components.Scheduler.UnAuthenticated.Completed'
  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t(`${tKey}.header`, {
          sessionTime: dateFormat(sessionTime, t(`${tKey}.dateFormat`)),
          expertName: expertName || t(`shared.yourExpert`),
        })}
      </Typography>
      <Typography className={classes.bodyCopy}>{t(`${tKey}.copy`)}</Typography>
    </>
  )
}

export default Completed
