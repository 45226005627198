import { produce } from 'immer'

import { BrandState, initialState } from './types'
import { BrandActions, BrandAction } from './actions'

export default function brandReducer(
  state: BrandState = initialState,
  action: BrandAction
): BrandState {
  return produce(state, (draft) => {
    switch (action.type) {
      case BrandActions.FETCH_BRAND_SUCCESS:
        draft.currentBrand = action.payload.data
        break
    }
  })
}
