import React, { useState, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import MultiStepModal, { Step } from '../MultiStepModal'
import ratingImage from 'assets/images/rating.svg'
import sentImage from 'assets/images/sent.svg'
import { RatingPayload } from 'store/ratings/types'
import useBrand from 'hooks/useBrand'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    npsButtonContainer: {
      '& > button:not(:last-child)': {
        marginRight: '0.3em',
      },
    },
    npsButton: {
      borderRadius: '50%',
      padding: 0,
      minWidth: '32px',
      minHeight: '32px',
    },
    feedbackContent: {
      textAlign: 'left',

      '& > *': {
        width: '100%',
      },
    },
    feedbackInputLabel: {
      position: 'static',
      width: '100%',
    },
    feedbackTextarea: {
      minHeight: '149px',
      marginTop: '0 !important',

      '& > textarea': {
        minHeight: '149px',
      },
    },
  })
)

interface Props {
  submitCallback: (
    data: RatingPayload,
    closeAfterSubmit?: boolean
  ) => Promise<any>
  onClose: () => void
}

const FeedbackModal: React.FC<Props> = ({ submitCallback, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brandName = useBrand().name
  const [rating, setRating] = useState<null | number>(null)
  const [comment, setComment] = useState('')
  // The bar to submit this form is that a rating has been selected and the user
  // clicks the submit button to go to the second step. If they close the modal
  // after any of the steps after 2, we submit to the server implicitly.
  const [submittable, setSubmittable] = useState(false)

  const handleSubmit = useCallback(
    async (closeAfterSubmit = true) => {
      if (submittable && rating !== null) {
        setSubmittable(false)

        await submitCallback(
          {
            value: rating,
            comment,
          },
          closeAfterSubmit
        )
      } else {
        onClose()
      }
    },
    [submitCallback, rating, comment, submittable, setSubmittable, onClose]
  )

  return (
    <MultiStepModal
      allowMovingBackward={false}
      disableStepper={true}
      onAdvance={() => setSubmittable(true)}
      onClose={handleSubmit}
      ContentProps={{
        className: classes.dialogContent,
      }}
      steps={[
        <Step
          heroImageURL={ratingImage}
          callToActionProps={{
            children: t('components.Modals.variants.Feedback.nps.cta'),
            disabled: rating === null,
          }}
        >
          <Typography variant="h2">
            {t('components.Modals.variants.Feedback.nps.title', { brandName })}
          </Typography>
          <Box className={classes.npsButtonContainer}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
              <NPSButton
                key={i}
                number={i}
                selected={i === rating}
                onClick={() => setRating(i)}
              />
            ))}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" component="span">
              {t('components.Modals.variants.Feedback.nps.notLikely')}
            </Typography>
            <Typography variant="body2" component="span">
              {t('components.Modals.variants.Feedback.nps.veryLikely')}
            </Typography>
          </Box>
        </Step>,
        <Step heroImageURL={ratingImage}>
          <Typography variant="h2">
            {t('components.Modals.variants.Feedback.thanks.title')}
          </Typography>
          <Typography variant="body2">
            {t('components.Modals.variants.Feedback.thanks.copy')}
          </Typography>
        </Step>,
        <Step
          callToActionProps={{
            onClick: async () => await handleSubmit(false),
          }}
        >
          <Box className={classes.feedbackContent}>
            <Typography variant="h2">
              {t('components.Modals.variants.Feedback.freeform.title')}
            </Typography>
            <FormControl>
              <InputLabel
                htmlFor="feedback-text"
                className={classes.feedbackInputLabel}
              >
                {t('components.Modals.variants.Feedback.freeform.label')}
              </InputLabel>
              <Input
                // The following `inputComponent` prop throws the following TS error while building:
                // TS2322: Type '"textarea"' is not assignable to type 'ElementType<InputBaseComponentProps> | undefined'.
                // This should be resolved once we upgrade Mui to v5
                // @ts-ignore
                inputComponent="textarea"
                id="feedback-text"
                className={classes.feedbackTextarea}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </FormControl>
          </Box>
        </Step>,
        <Step heroImageURL={sentImage}>
          <Typography variant="h2">
            {t('components.Modals.variants.Feedback.sent.title')}
          </Typography>
          <Typography variant="body2">
            {t('components.Modals.variants.Feedback.sent.copy', { brandName })}
          </Typography>
        </Step>,
      ]}
    />
  )
}

interface NPSButtonProps {
  number: number
  selected: boolean
  onClick: () => void
}

const NPSButton: React.FC<NPSButtonProps> = ({ number, selected, onClick }) => {
  const classes = useStyles()

  return (
    <Button
      className={classes.npsButton}
      variant={selected ? 'contained' : 'outlined'}
      onClick={onClick}
      color={selected ? 'primary' : 'default'}
    >
      {number}
    </Button>
  )
}

export default FeedbackModal
