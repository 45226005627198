import React, { useEffect } from 'react'
import { Route, RouteProps, Redirect, RedirectProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { useLocation } from 'react-router'

import useAuth0 from './useAuth0'
import Loader from 'components/shared/Loader'

export interface ProtectedRouteProps extends RouteProps {
  RedirectProps?: RedirectProps
}

export const REDIRECT_CACHE_KEY = 'redirect-after-login'

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { t } = useTranslation()
  const { loggedIn, checkingSession } = useAuth0()
  const redirectProps: RedirectProps = {
    to: t('routes.login'),
    ...(props.RedirectProps || {}),
  }
  const [, setRedirectAfterLogin] = useLocalStorage(REDIRECT_CACHE_KEY, '/')
  const { pathname, search, hash } = useLocation()

  useEffect(() => {
    if (!loggedIn) {
      setRedirectAfterLogin(pathname + search + hash)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loggedIn, pathname, search, hash])

  if (checkingSession.isLoading) {
    return <Loader />
  }

  if (!loggedIn) {
    return <Redirect {...redirectProps} />
  }

  return <Route {...props} />
}

export default ProtectedRoute
