import React from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

interface Props {
  orderID?: string
  itemName: string
}

const SchedulerWrapper: React.FC<Props> = ({ orderID, itemName, children }) => {
  const { t } = useTranslation()
  const classes = useWrapperStyles()

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box component="header" className={classes.header}>
        <Typography variant="h2">{itemName}</Typography>
        {orderID && (
          <Typography className={classes.orderNumber}>
            {t('components.Scheduler.orderNumber', { orderID })}
          </Typography>
        )}
      </Box>
      <Divider />
      <Box className={classes.body}>{children}</Box>
    </Paper>
  )
}

export const useWrapperStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: 0,
    },
    header: {
      padding: '2em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    orderNumber: {
      textTransform: 'uppercase',
      fontSize: '.9em',
    },
    body: {
      padding: '2em',
    },
    bodyHeader: {
      fontSize: '1.5em',
    },
    bodyCopy: {
      fontSize: '1.1em',
      marginBottom: '2em',
    },
    bodyBtn: {
      width: '100%',

      '&:disabled': {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
      },

      // This allows stacked buttons to breathe
      '& + &': {
        marginTop: '0.5em',
      },
    },
  })
)

export default SchedulerWrapper
