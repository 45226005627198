import React, { useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { isEqual } from 'lodash'
import { format as dateFormat } from 'date-fns'
import { HashMap } from '@talentinc/state-utils'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TooltipIcon from 'components/shared/TooltipIcon'
import OrderItemIcon from 'components/OrderItems/OrderItemIcon'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AppState } from 'store'
import {
  inactiveCTAStatuses,
  OrderStatus,
  OrderStatusTypes,
  OrderTypes,
  OrderWidgetDisplayProps,
} from 'store/orders/types'
import { Item, ItemWithExpert } from 'store/items/types'
import Divider from './Divider'
import {
  extraItemsByForOrdersByID,
  documentItemsForOrderID,
} from 'store/items/selectors'
import { formattedOrderID } from 'store/orders/selectors'

import OrderItemCard from './OrderItemCard'
import { GhostLinkButton } from 'components/shared/GhostLink'
import {
  currentOrderStatus,
  firstDraftDueForOrder,
  orderIsCompleted,
  revisionPeriodForOrder,
} from 'store/orders/selectors'
import { colors } from 'styles'
import NPSContainer from 'components/Rating/NPSContainer'
import { canRateOrder, isSubmittingNPS } from 'store/ratings/selectors'
import useBrand from 'hooks/useBrand'
import useUser from 'hooks/useUser'
import useViewInCIO from 'hooks/useViewInCIO'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    cta: {
      flexBasis: '100%',
    },
    ctaContainer: {
      display: 'flex',
      marginBottom: '2em',
      marginTop: '1rem',
    },
    h3: {
      alignItems: 'center',
      color: `${theme.palette.grey[700]}`,
      display: 'flex',
      marginLeft: '1rem',
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 400,
      margin: '.29em 0 1em',
    },
    headerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    icon: {
      margin: '0 .5em 1em 0',
      maxHeight: '2em',
      maxWidth: '2em',
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        margin: '0 .5em 1rem 0',
      },
      backgroundColor: `${colors.blue[100]}`,
      borderRadius: '10%',
    },
    itemsContainer: {
      marginTop: '1rem',
    },
    itemStatus: {
      marginLeft: '1rem',
    },
    orderNumber: {
      paddingTop: '.35em',
      marginBottom: '0em',
    },
    packageName: {
      fontWeight: 'bold',
      marginRight: '1rem',
      fontSize: '1.3rem',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
        marginBottom: '1rem',
      },
    },
    statusHeader: {
      marginTop: '1rem',
      fontSize: '1.6rem',
    },
    statusHelper: {
      marginTop: '0.2rem',
    },
    iconContainer: {
      display: 'flex',
    },
    packageNameContainer: {
      display: 'flex',
    },
  })
)

interface BaseHeadlineProps {
  currentStatus: OrderStatus | null
  revisionPeriod: Date
  firstDraftDue: Date
  items: Item[]
  expertName?: string | object | false
  completedDate: string
  orderType: OrderTypes
  orderDueDate: Date
  expectedDate: Date
  isCompleted: boolean
  canRate: boolean
  allowedInCIO: boolean
}

interface MinimalHeadlineProps {
  item: Item
}

const OrderHeadline: React.FC<BaseHeadlineProps> = ({
  currentStatus,
  revisionPeriod,
  firstDraftDue,
  items,
  expertName,
  completedDate,
  orderType,
  orderDueDate,
  expectedDate,
  isCompleted,
  canRate,
  allowedInCIO,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const headline =
    isCompleted && !canRate
      ? t(
          allowedInCIO
            ? 'components.OrderWidget.headline.viewDocsInCIO'
            : 'components.OrderWidget.headline.closed'
        )
      : t(
          `components.OrderWidget.headline.${orderType}.${currentStatus?.status}`,
          {
            revisionPeriodDate: dateFormat(
              revisionPeriod,
              t(`components.OrderWidget.dueDateFormat`)
            ),
            firstDraftDate: dateFormat(
              firstDraftDue,
              t(`components.OrderWidget.dueDateFormat`)
            ),
            orderDueDate: dateFormat(
              orderDueDate,
              t(`components.OrderWidget.dueDateFormat`)
            ),
            expertName: expertName,
            count: items.length,
            itemName: items[0]?.name || '',
          }
        )

  let helper = ''
  if (
    allowedInCIO &&
    orderType === OrderTypes.Standard &&
    currentStatus?.status === OrderStatusTypes.Completed
  ) {
    helper = t('components.OrderWidget.helperCopy.cioTrial')
  } else if (currentStatus?.status) {
    helper = t(
      `components.OrderWidget.helperCopy.${orderType}.${currentStatus.status}`,
      {
        expertName: expertName,
        statusDate: completedDate,
        expectedDate: dateFormat(
          expectedDate,
          t(`components.OrderWidget.dueDateFormat`)
        ),
        count: items.length,
        itemName: items[0]?.name || '',
      }
    )
  } else if (isCompleted && canRate && orderType === OrderTypes.VoiceOnly) {
    helper = t('components.OrderWidget.helperCopy.voice.summary', {
      expertName: expertName,
      itemName: items[0]?.name || '',
    })
  }

  return (
    <Grid container direction="column" justify="flex-start">
      <Grid item>
        <Typography variant="h2" className={classes.statusHeader}>
          {headline}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.statusHelper}>
          {helper}
        </Typography>
      </Grid>
    </Grid>
  )
}

const OrderHeadlineAddonOnly: React.FC<MinimalHeadlineProps> = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brand = useBrand()
  return (
    <Grid container direction="column" justify="flex-start">
      <Grid item>
        <Typography variant="body1" className={classes.statusHelper}>
          {t(`components.OrderWidget.helperCopy.addon.${item.item_type}`, {
            itemName: item.name,
            brandProduct: brand.default_resume_product || '',
          })}
        </Typography>
      </Grid>
    </Grid>
  )
}

const OrderHeadlineEmailOnly: React.FC<MinimalHeadlineProps> = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brand = useBrand()
  return (
    <Grid container direction="column" justify="flex-start">
      <Grid item>
        <Typography variant="h2" className={classes.statusHeader}>
          {t(`components.OrderWidget.headline.emailOnly`)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.statusHelper}>
          {t(`components.OrderWidget.helperCopy.emailOnly`, {
            brand: brand.name,
          })}
        </Typography>
      </Grid>
    </Grid>
  )
}

// This will contain the header and the snapshot of the items.
const OrderWidgetHeader: React.FC<OrderWidgetDisplayProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { allowed_in_cio: allowedInCIO } = useUser()
  const { loading: viewDocsLoading, onViewDocs } = useViewInCIO()

  const extraItems = useSelector<AppState, HashMap<Item[]>>(
    (state) => extraItemsByForOrdersByID(state.items, state.orders),
    isEqual
  )

  const documentItems = useSelector<AppState, ItemWithExpert[]>(
    (state) => documentItemsForOrderID(props.id, state.items, state.orders),
    isEqual
  )

  const currentStatus = useSelector<AppState, OrderStatus | null>(
    () => currentOrderStatus(props.status),
    isEqual
  )

  const revPeriod = useSelector<AppState, Date | undefined>((state) =>
    revisionPeriodForOrder(state.orders, props.id)
  )

  const firstDraftDue = useSelector<AppState, Date | undefined>((state) =>
    firstDraftDueForOrder(state.orders, props.id)
  )

  const canRate = useSelector<AppState, boolean>((state) =>
    canRateOrder(state.ratings, props.id)
  )

  const isCompleted = useSelector<AppState, boolean>((state) =>
    orderIsCompleted(state.orders, props.id)
  )

  const isSubmittingRating = useSelector<AppState, boolean>((state) =>
    isSubmittingNPS(state.ratings, props.id)
  )

  const [modalOpen, setModalOpen] = useState(false)

  const ratingCTA = isSubmittingRating
    ? t(`components.OrderWidget.cta.submittingRating`)
    : currentStatus?.status
    ? t(
        `components.OrderWidget.cta.${props.orderType}.${currentStatus?.status}`,
        {
          itemName: props.items[0]?.name || t(`shared.order`),
        }
      )
    : t(`components.OrderWidget.cta.default`)

  const ctaDisabled = currentStatus?.status
    ? inactiveCTAStatuses.has(currentStatus?.status) || isSubmittingRating
    : false

  const isCompletedOrder = !!(
    currentStatus?.status && currentStatus.status === OrderStatusTypes.Completed
  )

  const expertName =
    (props.experts.length > 0 && props.experts[0].display_name) ||
    t('components.OrderWidget.yourExpert')

  const completedDate =
    currentStatus?.status === OrderStatusTypes.Completed &&
    currentStatus.completed_at
      ? dateFormat(
          currentStatus.completed_at,
          t('components.OrderWidget.closedDateFormat')
        )
      : ''

  if (!props) return null

  return (
    <>
      <Container disableGutters>
        <Typography
          className={classes.orderNumber}
          variant="body2"
          align="left"
        >
          {t(`components.OrderWidget.orderNumber`)}
          {formattedOrderID(props)}
        </Typography>
        <Container disableGutters className={classes.headerContainer}>
          <Box className={classes.packageNameContainer}>
            <Typography
              variant="h3"
              component="span"
              className={classes.packageName}
            >
              {props.packages[0].name}
            </Typography>
          </Box>

          <Box className={classes.iconContainer}>
            {extraItems[props.id] &&
              extraItems[props.id].map((item) => (
                <TooltipIcon
                  copy={item.name}
                  tooltipClass={classes.icon}
                  key={item.id}
                >
                  <OrderItemIcon
                    itemType={item.item_type}
                    itemName={item.name}
                  />
                </TooltipIcon>
              ))}
          </Box>
        </Container>
      </Container>

      <Divider />
      {props.orderType === OrderTypes.AddOnOnly && (
        <OrderHeadlineAddonOnly item={props.items[0]} />
      )}
      {props.orderType === OrderTypes.EmailOnly && (
        <OrderHeadlineEmailOnly item={props.items[0]} />
      )}
      {props.orderType !== OrderTypes.EmailOnly &&
        props.orderType !== OrderTypes.AddOnOnly && (
          <>
            <OrderHeadline
              currentStatus={currentStatus}
              canRate={canRate}
              revisionPeriod={revPeriod || new Date()}
              firstDraftDue={firstDraftDue || new Date()}
              expertName={expertName}
              completedDate={completedDate}
              items={props.items}
              orderType={props.orderType}
              orderDueDate={props.due_date || new Date()}
              expectedDate={currentStatus?.expected_at || new Date()}
              isCompleted={isCompleted}
              allowedInCIO={!!allowedInCIO}
            />
            <Container className={clsx(classes.cardContainer)} disableGutters>
              {currentStatus?.status !== OrderStatusTypes.Open &&
                documentItems.map((item) => (
                  <OrderItemCard
                    key={item.id}
                    item={item}
                    orderCompleted={isCompletedOrder}
                    isDueForRefresh={!!item.dueForRefresh}
                  />
                ))}
            </Container>
            <Container disableGutters className={classes.ctaContainer}>
              {isCompletedOrder && canRate && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.cta}
                  disabled={ctaDisabled}
                  onClick={() => setModalOpen(true)}
                >
                  {ratingCTA}
                </Button>
              )}
              {isCompletedOrder &&
                !canRate &&
                (allowedInCIO ? (
                  <Button
                    className={classes.cta}
                    variant="contained"
                    color="primary"
                    disabled={ctaDisabled || viewDocsLoading}
                    onClick={onViewDocs}
                  >
                    {viewDocsLoading ? (
                      <CircularProgress color="secondary" size={28} />
                    ) : (
                      t(`components.OrderWidget.cta.default`)
                    )}
                  </Button>
                ) : (
                  <GhostLinkButton
                    variant="contained"
                    color="primary"
                    to={generatePath(t('routes.portfolio'))}
                    className={classes.cta}
                    disabled={ctaDisabled}
                  >
                    {t(`components.OrderWidget.cta.default`)}
                  </GhostLinkButton>
                ))}
              {!isCompletedOrder &&
                currentStatus?.status !== OrderStatusTypes.Open && (
                  <GhostLinkButton
                    variant="contained"
                    color="primary"
                    to={
                      props.expert_hub_client_ids.length > 0
                        ? generatePath(t('routes.messagesByClient'), {
                            clientID: props.expert_hub_client_ids[0],
                          })
                        : generatePath(t('routes.messages'))
                    }
                    className={classes.cta}
                    disabled={ctaDisabled}
                  >
                    {currentStatus?.status
                      ? t(
                          `components.OrderWidget.cta.${props.orderType}.${currentStatus?.status}`
                        )
                      : t(`components.OrderWidget.cta.default`)}
                  </GhostLinkButton>
                )}
              {!isCompletedOrder &&
                currentStatus?.status === OrderStatusTypes.Open &&
                props.questionnaire_link && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.cta}
                    href={props.questionnaire_link}
                    target="_blank"
                  >
                    {currentStatus?.status
                      ? t(
                          `components.OrderWidget.cta.${props.orderType}.${currentStatus?.status}`
                        )
                      : t(`components.OrderWidget.cta.default`)}
                  </Button>
                )}
            </Container>
          </>
        )}
      {modalOpen && (
        <NPSContainer orderID={props.id} onClose={() => setModalOpen(false)} />
      )}
    </>
  )
}

export default OrderWidgetHeader
