import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'

import { colors } from 'styles'
import { Notification } from 'store/notifications/types'
import { initials, joinConversationally } from 'utils/formatting'
import useBrand from 'hooks/useBrand'
import bullhornImage from 'assets/images/bullhorn.svg'

interface NotificationProps {
  notification: Notification
  onClick: () => void
}

//Placeholder styles
const useStyles = makeStyles((theme) =>
  createStyles({
    item: {
      width: '100%',
      maxWidth: '36ch',
      cursor: 'pointer',
      borderTop: `1px solid ${colors.gray[600]}`,
    },
    new: {
      backgroundColor: '#f3f9fe',
      '&:hover': {
        background: '#fafbfc',
      },
    },
    viewed: {
      backgroundColor: '#ffffff',
      '&:hover': {
        background: '#fafbfc',
      },
    },
    notificationTitle: {
      fontSize: '1em',
      fontWeight: 500,
      color: theme.palette.primary.dark,
    },
    notificationBody: {
      fontSize: '.9em',
      fontWeight: 500,
    },
    notificationDate: {
      fontSize: '12px',
      marginTop: '5px',
    },
    notificationContainer: {
      margin: '0px',
    },
  })
)

const NotificationItem: React.FC<NotificationProps> = ({
  notification,
  onClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brand = useBrand().name
  const expertName =
    'expertName' in notification.variables
      ? notification.variables.expertName
      : ''
  const items =
    'items' in notification.variables
      ? joinConversationally(
          notification.variables.items.map((itemType: string) =>
            t(`itemTypes.${itemType}`)
          )
        )
      : ''

  return (
    <>
      <ListItem
        className={clsx({
          [classes.item]: true,
          [classes.viewed]: notification.viewed_at,
          [classes.new]: !notification.viewed_at,
        })}
        onClick={() => onClick()}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          {'expertName' in notification.variables ? (
            <Avatar alt={notification.variables.expertName}>
              {initials(notification.variables.expertName)}
            </Avatar>
          ) : (
            <Avatar src={bullhornImage} />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="h6" className={classes.notificationTitle}>
              {t(`components.Notifications.${notification.name}.title`, {
                brand,
              })}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography component="p" className={classes.notificationBody}>
                {t(`components.Notifications.${notification.name}.body`, {
                  expertName,
                  items,
                })}
              </Typography>
              <Typography component="div" className={classes.notificationDate}>
                {t(`components.Notifications.date_ago`, {
                  date: formatDistanceToNow(notification.created_at),
                })}
              </Typography>
            </React.Fragment>
          }
          className={classes.notificationContainer}
        />
      </ListItem>
    </>
  )
}

export default NotificationItem
