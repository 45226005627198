import {
  HashMap,
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'

import { OrderActions } from './actions'
import { Item, ItemWithDocuments } from 'store/items/types'

export type OldOrderStatus =
  | 'rate'
  | 'approval'
  | 'feedback'
  | 'revisions'
  | 'writerMatch'
  | 'draft'

export enum OrderStatusTypes {
  CompleteQuestionnaire = 'complete-questionnaire',
  ExpertAssignment = 'expert-assignment',
  LinkedInDue = 'linkedin-due',
  SchedulePhone = 'schedule-phone',
  Phone = 'phone',
  ScheduleInterview = 'schedule-interview',
  Interview = 'interview',
  FirstDraft = 'first-draft',
  RevisionPeriod = 'revision-period',
  RevisionPeriodStart = 'revision-period-start',
  Completed = 'completed',
  PartialRefund = 'refund-partial',
  FullRefund = 'refund-full',
  Reassigned = 'reassigned',
  Open = 'open',
}

export enum OrderTypes {
  Standard = 'standard',
  EmailOnly = 'email',
  AddOnOnly = 'addon',
  VoiceOnly = 'voice',
}

// use a hashmap to keep track of sort order
export const sortOrder: HashMap<number> = Object.freeze({
  [OrderStatusTypes.Open]: 0,
  [OrderStatusTypes.CompleteQuestionnaire]: 1,
  [OrderStatusTypes.ExpertAssignment]: 2,
  [OrderStatusTypes.SchedulePhone]: 3,
  [OrderStatusTypes.ScheduleInterview]: 3,
  [OrderStatusTypes.LinkedInDue]: 4,
  [OrderStatusTypes.FirstDraft]: 4,
  [OrderStatusTypes.Phone]: 4,
  [OrderStatusTypes.Interview]: 4,
  [OrderStatusTypes.PartialRefund]: 4,
  [OrderStatusTypes.FullRefund]: 4,
  [OrderStatusTypes.Reassigned]: 4,
  [OrderStatusTypes.RevisionPeriod]: 5,
  [OrderStatusTypes.Completed]: 6,
})

export const inactiveCTAStatuses: Set<OrderStatusTypes> = new Set([
  OrderStatusTypes.ExpertAssignment,
  OrderStatusTypes.PartialRefund,
  OrderStatusTypes.FullRefund,
  OrderStatusTypes.Reassigned,
])

export interface OrderStatus {
  completed_at: Date | null
  created_at: Date
  expected_at: Date | null
  status: OrderStatusTypes
  updated_at: Date | null
  purchase_item_id: number | null
  itemIDs: number[]
}

export interface CompletedOrderStatus
  extends Omit<OrderStatus, 'completed_at'> {
  completed_at: Date
}

export interface FullOrderStatus {
  active: OrderStatus[]
  completed: CompletedOrderStatus[]
}

export interface Expert {
  id: number
  display_name: string
  avatar_logo: string | null
  industry_specialty: string | null
  orders_completed: number | null
  average_star_rating: number | null
}

export enum PaymentProcessor {
  PayPal = 'paypal',
  PayPalExpress = 'paypal_express',
  PayPalPayments = 'paypal payments',
  Braintree = 'braintree',
  Stripe = 'stripe',
  Affirm = 'affirm',
}

export interface PackageResponse {
  id: number
  name: string
  quantity: number
  date_purchased: Date
  expert_hub_client_id: number | null
  items: Item[]
  orderID: number
}

export interface Package extends Omit<PackageResponse, 'items'> {
  itemIDs: number[]
}

export interface PackageExt extends PackageResponse {
  itemIDs: number[]
}

interface BaseOrder<PackageType> {
  id: number
  packages: PackageType[]
  currency_symbol: string
  total_amount: number
  experts: Expert[]
  expert_hub_client_ids: number[]
  due_date: Date | null
  status: FullOrderStatus
  brand_order_description: string
  questionnaire_link: string | null
  closed_at: Date | null
  created_at: Date
  original_order_id: number | null
  credit_card_number: string | null
  payment_processor: PaymentProcessor | null
  expert_hub_order_ids: number[]
  sent_to_fulfillment_at: Date | null
}

export interface PackageGroup {
  summary: string
  items_summary: string
  expert_hub_client_id: number
  packages: PackageResponse[] | Package[]
  items: Item[]
}

export type OrderResponse = BaseOrder<PackageResponse>
export type Order = BaseOrder<Package>

export interface OrdersState {
  orders: HashMap<Order>
  byPackageID: HashMap<number>
  byItemID: HashMap<number>
  byClientID: HashMap<Set<number>>
  packagesByClientID: HashMap<PackageResponse[]>
  clientIDs: Set<number>
  meta: {
    [OrderActions.FETCH_ORDERS_FOR_USER]: LoadingLoadedErrorState
    [OrderActions.APPROVE_ORDER]: HashMap<LoadingLoadedErrorState>
    [OrderActions.FETCH_ORDER_FOR_USER]: HashMap<LoadingLoadedErrorState>
  }
  approveOrderLoading: boolean
}

export const initialState: OrdersState = Object.freeze({
  orders: {},
  byPackageID: {},
  byItemID: {},
  byClientID: {},
  packagesByClientID: {},
  clientIDs: new Set<number>(),
  meta: {
    [OrderActions.FETCH_ORDERS_FOR_USER]: { ...initialLoadedLoadingErrorState },
    [OrderActions.APPROVE_ORDER]: {},
    [OrderActions.FETCH_ORDER_FOR_USER]: {},
  },
  approveOrderLoading: false,
})

export interface FaqsDisplayProps {
  currentStatus: OrderStatus | null
}

export interface PackageDisplayProps {
  orderID: number
  name: string
  datePurchased: Date
  items: ItemWithDocuments[]
  expert: Expert | null
  currentOrderStatus: OrderStatus | null
  aggregatedOrderIDs: number[]
}

export interface OrderAggregate extends Order {
  orderIDs: Set<number>
}

export enum ClientStatus {
  completed = 'completed',
  active = 'active',
}

export interface ClientIDWithStatus {
  clientID: number
  status: ClientStatus
  key: string
}

export interface OrderWidgetDisplayProps extends Order {
  items: Item[]
  experts: Expert[]
  upcomingVoiceDrivenItems: Item[]
  canRate: boolean
  orderType: OrderTypes
}

export const callOrDraftStatuses: Set<OrderStatusTypes> = new Set([
  OrderStatusTypes.Interview,
  OrderStatusTypes.Phone,
  OrderStatusTypes.FirstDraft,
])
