import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import useLocation from 'react-use/lib/useLocation'

import ApproveOrderModal from 'components/Modals/variants/ApproveOrder'
import { approveOrdersForUser } from 'store/orders/thunks'
import { AppState } from 'store'
import { itemsForApprovalModal } from 'store/items/selectors'
import CTA from 'components/shared/Cta'
import useEvents from 'hooks/useEvents'
import { expertForOrderIDs } from 'store/orders/selectors'

interface Props {
  orderIDs: number[]
  onClose: () => void
  onActionComplete?: () => void
}

const ApproveOrderModalContainer: React.FC<Props> = ({
  orderIDs,
  onClose,
  onActionComplete,
}) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const sendEvent = useEvents()
  const items = useSelector<AppState, ReturnType<typeof itemsForApprovalModal>>(
    (state) => itemsForApprovalModal(state.items, state.files, orderIDs),
    isEqual
  )

  const expert = useSelector<AppState, ReturnType<typeof expertForOrderIDs>>(
    (state) => expertForOrderIDs(state.orders, Array.from(orderIDs))
  )

  const approveOrder = useCallback(async () => {
    await dispatch(approveOrdersForUser(orderIDs))
    orderIDs.forEach((orderID) => {
      sendEvent({
        event: 'User Approved Order From Modal',
        variables: {
          pathname,
          order_id: orderID,
        },
      })
    })

    if (onActionComplete) {
      onActionComplete()
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [orderIDs, sendEvent, pathname])

  return (
    <ApproveOrderModal
      expertName={expert?.display_name || 'Your Expert'}
      items={items}
      onClose={onClose}
      onSubmit={approveOrder}
    />
  )
}

export const ApproveOrderCTA: React.FC<Omit<Props, 'onClose'>> = ({
  orderIDs,
  onActionComplete,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <CTA
        onClick={() => setModalOpen(true)}
        copy={t('components.Rating.ApprovalOrderModalContainer.text')}
        buttonCopy={t('pages.Portfolio.approve')}
      />
      {modalOpen && (
        <ApproveOrderModalContainer
          orderIDs={orderIDs}
          onClose={() => setModalOpen(false)}
          onActionComplete={() => {
            if (onActionComplete) onActionComplete()
          }}
        />
      )}
    </>
  )
}

export default ApproveOrderModalContainer
