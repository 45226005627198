import { TransactionsActions } from './actions'
import {
  initialLoadedLoadingErrorState,
  LoadingLoadedErrorState,
} from '@talentinc/state-utils/lib'

export enum TransactionStatus {
  Refund = 'refund-full',
  PartialRefund = 'refund-partial',
  Chargeback = 'chargeback',
  Processed = 'processed',
}

export interface Transaction {
  id: number
  user_id: number
  order_id: number
  amount: number
  currency_code: string
  currency_symbol: string
  date_purchased: string
  payment_processor: string | null
  account_number: string
  // @TODO: Use `CreditCardType` enum
  cc_type:
    | 'visa'
    | 'mastercard'
    | 'discover'
    | 'amex'
    | 'jcb'
    | 'blackhawk'
    | 'applepay'
    | string
    | null
  status: TransactionStatus
  created_at: Date
  packages: UserTransactionPackage[]
  brand_name: string
  brand_logo: string
}

export interface UserTransactionPackage {
  id: number
  name: string
  amount: number
  currency: string
  date_purchased: string
  items: UserTransactionPackageItem[]
}

export interface UserTransactionPackageItem {
  id: number
  name: string
  type: string
  quantity: number
}

export interface TransactionsState {
  userTransactions: Transaction[]
  meta: {
    [TransactionsActions.FETCH_USER_TRANSACTIONS]: LoadingLoadedErrorState
  }
}

export const initialState: TransactionsState = Object.freeze({
  userTransactions: [],
  meta: {
    [TransactionsActions.FETCH_USER_TRANSACTIONS]: {
      ...initialLoadedLoadingErrorState,
    },
  },
})
