import React from 'react'
import Input from '@material-ui/core/Input'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import useMessageDraft from 'hooks/useMessageDraft'
import Attachments from './Attachments'
import ReplyButtons from './ReplyButtons'
import ValidationRule from 'components/shared/ValidationRule'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '4px',
    },
    input: {
      boxShadow: 'none',
      color: theme.palette.secondary.main,
      fontSize: '1em',
      width: '100%',
      border: 0,
    },
    inputActive: {
      paddingBottom: '4.06em',
    },
    textarea: {
      '&::placeholder': {
        opacity: 1,
      },
    },
  })
)

const MessageForm: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { formProps, bodyField } = useMessageDraft()
  const { handleSubmit, submitFailed, visited } = formProps
  const showButtons = visited && Object.values(visited).find((v) => v)

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        className={clsx(classes.input, showButtons && classes.inputActive)}
        multiline={true}
        placeholder={t('components.Messaging.messagePlaceholder')}
        id="body"
        // @ts-ignore
        inputProps={{
          ...bodyField.input,
          className: classes.textarea,
        }}
      />
      {showButtons && <ReplyButtons />}
      <Attachments />
      {submitFailed && (
        <ValidationRule invalid={true}>
          {t('components.Messaging.sendFailure')}
        </ValidationRule>
      )}
    </form>
  )
}

export default MessageForm
