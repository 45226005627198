import * as ram from '@talentinc/redux-axios-middleware'

import { Rating, NPSRespose, RatingPayload } from './types'
import { UserIdentifier } from 'store/user/types'

export enum RatingActions {
  FETCH_ORDER_NPS = 'FETCH_ORDER_NPS',
  FETCH_ORDER_NPS_SUCCESS = 'FETCH_ORDER_NPS_SUCCESS',
  FETCH_ORDER_NPS_FAIL = 'FETCH_ORDER_NPS_FAIL',
  SUBMIT_ORDER_NPS = 'SUBMIT_ORDER_NPS',
  SUBMIT_ORDER_NPS_SUCCESS = 'SUBMIT_ORDER_NPS_SUCCESS',
  SUBMIT_ORDER_NPS_FAIL = 'SUBMIT_ORDER_NPS_FAIL',
}

interface FetchOrderNPS extends ram.AxiosMiddlewareActionCreator {
  type: RatingActions.FETCH_ORDER_NPS
  orderID: number
}

interface FetchOrderNPSSuccess
  extends ram.AxiosMiddlewareActionSuccess<NPSRespose, FetchOrderNPS> {
  type: RatingActions.FETCH_ORDER_NPS_SUCCESS
}

interface FetchOrderNPSFail
  extends ram.AxiosMiddlewareActionFail<FetchOrderNPS> {
  type: RatingActions.FETCH_ORDER_NPS_FAIL
}

export const fetchOrderNPS = (
  orderID: number,
  userIdentifier: UserIdentifier = 'me'
): FetchOrderNPS => ({
  type: RatingActions.FETCH_ORDER_NPS,
  orderID,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/orders/${orderID}/nps`,
    },
  },
})

interface SubmitOrderNPS extends ram.AxiosMiddlewareActionCreator {
  type: RatingActions.SUBMIT_ORDER_NPS
  orderID: number
}

interface SubmitOrderNPSSuccess
  extends ram.AxiosMiddlewareActionSuccess<Rating, SubmitOrderNPS> {
  type: RatingActions.SUBMIT_ORDER_NPS_SUCCESS
}

interface SubmitOrderNPSFail
  extends ram.AxiosMiddlewareActionFail<SubmitOrderNPS> {
  type: RatingActions.SUBMIT_ORDER_NPS_FAIL
}

export const submitOrderNPS = (
  data: RatingPayload,
  orderID: number,
  userIdentifier: UserIdentifier = 'me'
): SubmitOrderNPS => ({
  type: RatingActions.SUBMIT_ORDER_NPS,
  orderID,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/orders/${orderID}/nps`,
      method: 'POST',
      data,
    },
  },
})

export type RatingAction =
  | FetchOrderNPS
  | FetchOrderNPSSuccess
  | FetchOrderNPSFail
  | SubmitOrderNPS
  | SubmitOrderNPSSuccess
  | SubmitOrderNPSFail
