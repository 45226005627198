import React from 'react'
import { ItemTypes } from 'store/items/types'
import { Bundles } from 'store/upsells/types'

import PngLetterThankYou from 'assets/images/product-thank-you-letter.png'
import PngLinkedIn from 'assets/images/product-linkedin.png'
import PngInterviewPrep from 'assets/images/product-interview-prep.png'
import PngRabbit from 'assets/images/product-posting-service.png'
import PngRecruiter from 'assets/images/product-recruiter-service.png'
import clsx from 'clsx'
import CatalogItemIcon from 'components/ShoppingCart/CatalogItemIcon'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { HashMap } from '@talentinc/state-utils'

interface Props {
  itemType: ItemTypes
  bundle?: Bundles | null
  className?: string
  itemName: string
}

const iconMap: HashMap<string> = {
  [ItemTypes.InterviewPrep]: PngInterviewPrep,
  [ItemTypes.LinkedInDocument]: PngLinkedIn,
  [ItemTypes.PostingService]: PngRabbit,
  [ItemTypes.RecruiterService]: PngRecruiter,
  [ItemTypes.ThankYouLetter]: PngLetterThankYou,
}

const SingleItemHeroImage: React.FC<Props> = ({
  itemType,
  className,
  itemName,
}) => {
  const classes = useStyles()
  const icon = iconMap[itemType]

  if (!icon)
    return (
      <CatalogItemIcon itemName={''} itemType={itemType || ItemTypes.Resume} />
    )
  return (
    <img className={clsx(classes.img, className)} src={icon} alt={itemName} />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: '100%',
      borderRadius: '0.5rem',
    },
  })
)

export default SingleItemHeroImage
