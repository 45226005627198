import { HashMap } from '@talentinc/state-utils'

import { File } from 'store/files/types'
import { Expert } from 'store/orders/types'
import { Upsell } from 'store/upsells/types'

export enum ItemStatusTypes {
  // These are status types returned by the API
  AwaitingResume = 'awaiting-resume',
  AwaitingPhoneCall = 'awaiting-phone',
  AwaitingReply = 'awaiting-reply',
  SendMessage = 'send-message',
  FirstDraft = 'first-draft',
  RevisionMode = 'revision',
  SendLinkedin = 'send-linkedin',
  SchedulePhone = 'schedule-phone',
  ConductCall = 'call',
  AwaitingScheduling = 'awaiting-scheduling',
  ScheduleInterview = 'schedule-interview',
  ConductInterview = 'interview',
  SendInterviewSummary = 'send-summary',
  Complete = 'complete',
  // After this comment, all these statuses are generated by the frontend
  // application based upon certain conditions
  AwaitingWriterMatch = 'awaiting-writer-match',
}

export enum ItemTypes {
  FollowUpLetter = 'follow-up-letter',
  ThankYouLetter = 'thank-you-letter',
  ResignationLetter = 'resignation-letter',
  OfferDeclinedLetter = 'offer-declined-letter',
  AcceptanceLetter = 'acceptance-letter',
  ReferencesPage = 'references-page',
  KSADocument = 'ksa',
  CareerBiography = 'biography',
  CoverLetter = 'cover-letter',
  CurriculumVitae = 'cv',
  LinkedInDocument = 'linkedin-document',
  PhoneCall = 'phone-call',
  InterviewPrep = 'interview-prep',
  Resume = 'resume',
  RushOrder = 'rush-order',
  RushOrder24Hours = 'rush-order|rush24-hour', //modified version of the original oms key 'rush-order|rush:24-hour' ':' is a reserved character in i18n
  RushOrder48Hours = 'rush-order|rush48-hour', //modified version of the original oms key 'rush-order|rush:48-hour'
  RushOrder72Hours = 'rush-order|rush72-hour', //modified version of the original oms key 'rush-order|rush:72-hour'
  PostingService = 'posting-service',
  RecruiterService = 'recruiter-service',
  Guarantee = 'guarantee',
  PersonalConsultation = 'personal-consultation',
  PersonalConsultationInterviewPrep = 'personal-consultation|interview-prep',
  PersonalConsultationPhoneCall = 'personal-consultation|phone-call',
  PersonalConsultationPhoneCallPreDocument = 'personal-consultation|phone-call,pre-documents',
  PersonalConsultationPhoneCallPrePostDocument = 'personal-consultation|phone-call,post-documents',
  AdditionalDocumentThankYouLetter = 'additional-document|thank-you-letter',
  AdditionalDocumentFollowUpLetter = 'additional-document|follow-up-letter',
  AdditionalDocumentKSA = 'additional-document|ksa',
  AdditionalDocumentReferencesPage = 'additional-document|references-page',
  AdditionalDocumentBiography = 'additional-document|biography',
  AdditionalDocumentAcceptanceLetter = 'additional-document|acceptance-letter',
  AdditionalDocumentDeclineLetter = 'additional-document|decline-letter',
  AdditionalDocumentResignationLetter = 'additional-document|resignation-letter',
  CareerIO = 'careerio',
  ResumeEdit = 'resume-edit',
  VideoCritique = 'video-critique',
  AutoApply = 'auto-apply',
}

export const phoneTypes: Set<ItemTypes> = new Set([
  ItemTypes.PhoneCall,
  ItemTypes.InterviewPrep,
  ItemTypes.PersonalConsultation,
  ItemTypes.PersonalConsultationInterviewPrep,
])

export const emailFulfilledTypes: Set<ItemTypes> = new Set([
  ItemTypes.VideoCritique,
  ItemTypes.PostingService,
  ItemTypes.RecruiterService,
  ItemTypes.AutoApply,
])

export const timeSensitiveTypes: Set<ItemTypes> = new Set([
  ItemTypes.RushOrder,
  ItemTypes.PhoneCall,
])

export const orderEnhancementItems: Set<ItemTypes> = new Set([
  ItemTypes.RushOrder,
  ItemTypes.PhoneCall,
  ItemTypes.Guarantee,
  ItemTypes.PostingService,
])

export const productDetailsEnhancementItems: Set<ItemTypes> = new Set([
  ItemTypes.RushOrder,
  ItemTypes.PhoneCall,
  ItemTypes.Guarantee,
])

export const addOnTypes: Set<ItemTypes> = new Set([
  ItemTypes.RushOrder,
  ItemTypes.RushOrder24Hours,
  ItemTypes.RushOrder48Hours,
  ItemTypes.RushOrder72Hours,
  ItemTypes.Guarantee,
])

export const documentTabUpsellTypes: Set<ItemTypes> = new Set([
  ItemTypes.Resume,
  ItemTypes.CoverLetter,
  ItemTypes.LinkedInDocument,
  ItemTypes.ThankYouLetter,
])

export const progressWidgetItemTypes: Set<ItemTypes> = new Set([
  ItemTypes.Resume,
  ItemTypes.LinkedInDocument,
  ItemTypes.CoverLetter,
  ItemTypes.RecruiterService,
  ItemTypes.InterviewPrep,
  ItemTypes.ThankYouLetter,
])

export interface ItemStatus {
  state_id: number | null
  state: ItemStatusTypes
  product_id: number
  started_at: Date | null
  due_at: Date | null
  ended_at: Date | null
}

export interface Item {
  id: number
  name: string
  item_type: ItemTypes
  quantity: number
  requires_document: boolean
  oms_client_id: number | null
  oms_custom_product_id: number | null
  oms_expert_id: number | null
  oms_order_item_id: number | null
  status: {
    active: ItemStatus[]
    completed: ItemStatus[]
  }
  rating?: number | null
  oms_order_id: number | null
  oms_custom_product_key: string | null
}

export interface ItemsState {
  items: HashMap<Item>
  byOrderID: HashMap<Set<number>>
  byPackageID: HashMap<Set<number>>
  byOMSOrderItemID: HashMap<number>
  byOMSClientID: HashMap<Set<number>>
}

export const initialState: ItemsState = {
  items: {},
  byOrderID: {},
  byPackageID: {},
  byOMSOrderItemID: {},
  byOMSClientID: {},
}

export interface ItemWithDocuments extends Item {
  documents: File[]
  expert?: Expert | null
  dueForRefresh?: boolean
}

export interface ItemWithExpert extends Item {
  expert: Expert | null
  dueForRefresh?: boolean
}

export interface UserDocumentsItemProps
  extends Omit<ItemWithDocuments, 'expert'> {
  expert: Expert | null
  rating: number | null
  orderID: number | null
  orderIDs: number[]
  showRatingModal?: () => void
}

export interface ItemWithLatestDraftDate extends Item {
  latestDraftDate: Date
  revision: number
}

export interface InterviewPrepDocsDisplayProps {
  expert: Expert | null
  item: Item
  doc: File | null
  hasMultipleSessions: boolean
  isCompleted: boolean
  hasScheduler: boolean
}

export interface ItemTypeWithHistory {
  itemType: ItemTypes
  lastPurchased?: Date | null
  recommendedUpsell?: Upsell | null
}

export enum ProgressStatus {
  Full = 'Full',
  Partial = 'Partial',
  Incomplete = 'Incomplete',
}

export enum ProgressSteps {
  Prep = 'Prep',
  Apply = 'Apply',
  Interview = 'Interview',
}

export enum PreparednessStatus {
  UnderPrepared = 'UnderPrepared',
  SomewhatPrepared = 'SomewhatPrepared',
  UpdateNeeded = 'UpdateNeeded',
  FullyPrepared = 'FullyPrepared',
}

export const dTest: HashMap<ItemTypes[]> = {}

export const prepTypes = new Set<ItemTypes>([
  ItemTypes.Resume,
  ItemTypes.LinkedInDocument,
])

export const applyTypes = new Set<ItemTypes>([
  ItemTypes.CoverLetter,
  ItemTypes.PostingService,
])

export const interviewTypes = new Set<ItemTypes>([
  ItemTypes.InterviewPrep,
  ItemTypes.ThankYouLetter,
])

export interface ProgressStepProps {
  step: ProgressSteps
  items: ItemTypeWithHistory[]
  hideDivider?: boolean
}

export interface ProgressWidgetProps {
  steps: ProgressStepProps[]
}

export const defaultProgressSteps: ProgressStepProps[] = [
  {
    step: ProgressSteps.Prep,
    items: [
      { itemType: ItemTypes.Resume },
      { itemType: ItemTypes.LinkedInDocument },
    ],
  },
  {
    step: ProgressSteps.Apply,
    items: [
      { itemType: ItemTypes.CoverLetter },
      { itemType: ItemTypes.RecruiterService },
    ],
  },
  {
    step: ProgressSteps.Interview,
    items: [
      { itemType: ItemTypes.InterviewPrep },
      { itemType: ItemTypes.ThankYouLetter },
    ],
  },
]

export interface NextStepCTAProps {
  upsell: Upsell | null
  itemType: ItemTypes | null
}
