import React from 'react'

const SvgHome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.787 8.03c.308-.34.278-.859-.066-1.16L9.123.222c-.345-.302-.898-.295-1.235.014L.264 7.225c-.338.31-.353.828-.037 1.157l.192.2c.317.33.83.37 1.144.087l.57-.51v7.47c0 .458.371.829.829.829h2.972c.457 0 .828-.37.828-.83v-5.225h3.79v5.226c-.006.457.322.829.78.829h3.149c.458 0 .83-.372.83-.83V8.265l.35.308c.195.17.602.033.91-.306l.216-.237z"
    />
  </svg>
)

export default SvgHome
