import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

interface Props {
  author: string
  quote: string
  title: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      borderLeft: `.25em solid ${theme.palette.secondary.main}`,
      display: 'flex',
      flexDirection: 'column',
      margin: '2em 0',
      padding: '.5em 0 .5em 1em',

      [theme.breakpoints.down('sm')]: {
        padding: '0 1.2em',
      },
    },
    h3: {
      color: `${theme.palette.secondary.main} !important`,
      fontWeight: 700,
      marginBottom: '0 !important',
    },
    h4: {
      fontStyle: 'italic',
      margin: '.5em 0',
    },
    p: {
      fontWeight: 'normal',
      fontSize: '.88em',
      fontStyle: 'italic',
      margin: '0 !important',
    },
  })
)

const Quote: React.FC<Props> = ({ author, quote, title }) => {
  const classes = useStyles()

  return (
    <Container className={classes.container} disableGutters>
      <Typography className={classes.h3} variant="h3">
        {title}
      </Typography>
      <Typography
        className={classes.h4}
        component="h4"
        variant="body1"
      >{`"${quote}"`}</Typography>
      <Typography className={classes.p}>{`— ${author}`}</Typography>
    </Container>
  )
}

export default Quote
