import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'

import StartSession from 'components/Call/StartSession'
import { itemForScheduler } from 'store/scheduling/selectors'
import { AppState } from 'store'
import Loader from 'components/shared/Loader'

interface Props {
  omsItemID: number
}

const AuthenticatedCallPage: React.FC<Props> = ({ omsItemID }) => {
  const item = useSelector<AppState, ReturnType<typeof itemForScheduler>>(
    (state) =>
      itemForScheduler(state.scheduling, state.items, state.orders, omsItemID)
  )

  if (!item?.meeting_id) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{item.name}</title>
      </Helmet>
      <StartSession item={item} />
    </>
  )
}

export default AuthenticatedCallPage
