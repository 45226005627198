import isEqual from 'lodash/isEqual'
import { orderWidgetDisplayProps } from '../store/orders/order_widget'
import { useSelector } from 'react-redux'
import axios from 'axios'
import useBrand from './useBrand'
import useUser from './useUser'

const CIO_BASEURL = process.env.REACT_APP_CIO_BASEURL as string
const CIO_TR_BASEURL = process.env.REACT_APP_CIO_TR_BASEURL as string

export const useCIORedirect = () => {
  const { allowed_in_cio } = useUser()
  const { site_id } = useBrand()
  const isTopResume = site_id === 1

  const orders = useSelector(
    (state: any) =>
      orderWidgetDisplayProps(state.orders, state.items, state.ratings),
    isEqual
  )

  const hasSomeCompletedOrder = orders.some((i: any) =>
    i?.status?.completed?.find((j: any) => j.status === 'completed')
  )

  const goto = async (path: string) => {
    const a = document.createElement('a')
    a.setAttribute('target', '_blank')

    try {
      const { data } = await axios.get('/v2/users/me/cio-magic-link', {
        params: { redirect_to: path },
      })

      a.href = data.url as string
      a.click()
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)

      a.href = (isTopResume ? CIO_TR_BASEURL : CIO_BASEURL) + path
      a.click()
    }
  }

  return {
    goto,
    enabled: allowed_in_cio && hasSomeCompletedOrder,
  }
}
