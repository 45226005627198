import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useShoppingCart from 'hooks/useShoppingCart'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) =>
  createStyles({
    close: {
      fontSize: '1.5em',
    },
    container: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1.8em 2.5em',
      [theme.breakpoints.down('sm')]: {
        padding: '1.8em 1.13em',
      },
    },
  })
)

const CheckoutTitle: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { updateOpen } = useShoppingCart()

  return (
    <Container className={classes.container}>
      <Typography variant="h2">{t('components.Checkout.title')}</Typography>
      <IconButton
        aria-label="Close menu"
        className={classes.close}
        onClick={() => updateOpen(false)}
      >
        <CloseIcon />
      </IconButton>
    </Container>
  )
}

export default CheckoutTitle
