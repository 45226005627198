export interface ResettableInterval {
  intervalID: number
  interval: number
}

export interface IntervalIDs {
  messages: ResettableInterval | null
  appUpdate: ResettableInterval | null
}

export interface CronState {
  intervals: IntervalIDs
}

export const initialState: CronState = {
  intervals: {
    messages: null,
    appUpdate: null,
  },
}

export interface CronSignature {
  interval: number
  // When initializing the cron, don't execute the http request
  initialize: boolean
}
