import { produce } from 'immer'
import * as stateUtilsHelpers from '@talentinc/state-utils'

import { NotificationState, initialNotificationState } from './types'

import { NotificationAction, NotificationActions } from './actions'

function notificationReducer(
  state: NotificationState = initialNotificationState,
  action: NotificationAction
): NotificationState {
  return produce(state, (draft) => {
    switch (action.type) {
      case NotificationActions.FETCH_NOTIFICATIONS_SUCCESS:
        draft.meta.global = stateUtilsHelpers.setSuccessState(state.meta.global)
        action.payload.data.forEach((notification) => {
          draft.notifications[notification.id] = notification
        })
        break
      case NotificationActions.FETCH_NOTIFICATIONS_FAIL:
        draft.meta.global = stateUtilsHelpers.setErrorState(
          state.meta.global,
          action.error
        )
        break
      case NotificationActions.FETCH_NOTIFICATIONS:
        draft.meta.global = stateUtilsHelpers.setStartState(state.meta.global)
        break
      case NotificationActions.MARK_VIEWED:
        //set loading state for individual notification
        draft.meta.individual[action.notificationID] =
          stateUtilsHelpers.setStartState(
            stateUtilsHelpers.initialLoadedLoadingErrorState
          )
        break
      case NotificationActions.MARK_VIEWED_SUCCESS:
        //set loaded state for inidivual notification
        draft.meta.individual[action.payload.data.id] =
          stateUtilsHelpers.setSuccessState(
            draft.meta.individual[action.payload.data.id]
          )
        //add notifiction to store
        if (draft.notifications) {
          draft.notifications[action.payload.data.id] = action.payload.data
        }
        break
      case NotificationActions.MARK_VIEWED_FAIL:
        //set error state for individual notification
        const previousAction = action.meta.previousAction
        draft.meta.individual[previousAction.notificationID] =
          stateUtilsHelpers.setErrorState(
            draft.meta.individual[previousAction.notificationID],
            action.error
          )
        break
    }
  })
}

export default notificationReducer
