import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import LinkedInPreview from '@talentinc/linkedin-preview'
import { autoLinkJsonToLinkedInPreviewProps } from '@talentinc/linkedin-preview'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { AppState } from 'store'
import DocumentImages from './DocumentImages'
import { UnderlineTabs, UnderlineTab } from 'components/shared/UnderlineTabs'
import { FallbackErrorBoundary } from 'components/shared/Errors'

interface Props {
  omsItemID: number
  documentImageURLs: string[]
}

const LinkedInPreviewContainer: React.FC<Omit<Props, 'documentImageURLs'>> = ({
  omsItemID,
}) => {
  const linkedinData = useSelector<
    AppState,
    ReturnType<typeof autoLinkJsonToLinkedInPreviewProps> | null
  >((state) =>
    state.files.linkedin?.[omsItemID]
      ? autoLinkJsonToLinkedInPreviewProps(
          state.files.linkedin?.[omsItemID].data_json
        )
      : null
  )

  if (!linkedinData) {
    return null
  }

  return (
    <LinkedInPreview
      accomplishmentsExpandedByDefault={true}
      {...linkedinData}
    />
  )
}

export const LinkedInPreviewTabs: React.FC<Props> = ({
  omsItemID,
  documentImageURLs,
}) => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useStyles()

  return (
    <FallbackErrorBoundary
      fallbackRender={() => (
        <DocumentImages documentImageURLs={documentImageURLs} />
      )}
    >
      <UnderlineTabs
        value={selectedTab}
        // @ts-ignore
        onChange={(_, value) => setSelectedTab(value)}
        className={classes.tabs}
      >
        <UnderlineTab
          label={t('components.LinkedInPreview.tabs.preview')}
          id="linkedin-tab-0"
        />
        <UnderlineTab
          label={t('components.LinkedInPreview.tabs.howTo')}
          id="linkedin-tab-1"
          className="blah"
        />
      </UnderlineTabs>
      <LinkedInTabPanel index={0} value={selectedTab}>
        <LinkedInPreviewContainer omsItemID={omsItemID} />
      </LinkedInTabPanel>
      <LinkedInTabPanel index={1} value={selectedTab}>
        <DocumentImages
          documentImageURLs={documentImageURLs}
          useDivider={false}
          paperClassName={classes.tabbedImages}
        />
      </LinkedInTabPanel>
    </FallbackErrorBoundary>
  )
}

interface LinkedInTabPanelProps {
  value: number
  index: number
}

const LinkedInTabPanel: React.FC<LinkedInTabPanelProps> = ({
  value,
  index,
  children,
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`linkedin-tabpanel-${index}`}
    aria-labelledby={`linkedin-tab-${index}`}
  >
    {value === index && children}
  </div>
)

export const useStyles = makeStyles((theme) =>
  createStyles({
    tabs: {
      marginTop: '2em',
      marginBottom: '1.5em',
    },
    tabbedImages: {
      '&:first-child': {
        marginTop: 0,
      },
    },
  })
)

export default LinkedInPreviewContainer
