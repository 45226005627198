export interface UserBrand {
  id: number
  name: string
  logo: string
  favicon: string
  home_url: string
  portal_url: string
  checkout_url: string
  colors: (string | null)[]
  default_support_email: string | null
  default_phone_number: string | null
  default_resume_product: string | null
  site_id: number
}

export interface BrandState {
  currentBrand: UserBrand | null
}

export const initialState: BrandState = {
  currentBrand: null,
}
