import * as ram from '@talentinc/redux-axios-middleware'
import { AutoLinkedinAPIResponse } from '@talentinc/linkedin-preview'

import { File } from './types'
import { UserIdentifier } from 'store/user/types'
import {
  FetchMessagesForUserSuccess,
  SendMessageSuccess,
} from 'store/messages/actions'

export enum FileActions {
  FETCH_FILES_FOR_USER = 'FETCH_FILES_FOR_USER',
  FETCH_FILES_FOR_USER_SUCCESS = 'FETCH_FILES_FOR_USER_SUCCESS',
  FETCH_FILES_FOR_USER_FAIL = 'FETCH_FILES_FOR_USER_FAIL',
  FETCH_LINKEDIN_FOR_ITEM = 'FETCH_AUTOLINKEDIN_FOR_ITEM',
  FETCH_LINKEDIN_FOR_ITEM_SUCCESS = 'FETCH_AUTOLINKEDIN_FOR_ITEM_SUCCESS',
  FETCH_LINKEDIN_FOR_ITEM_FAIL = 'FETCH_AUTOLINKEDIN_FOR_ITEM_FAIL',
}

interface FetchFilesForUser extends ram.AxiosMiddlewareActionCreator {
  type: FileActions.FETCH_FILES_FOR_USER
}

interface FetchFilesForUserSuccess
  extends ram.AxiosMiddlewareActionSuccess<File[], FetchFilesForUser> {
  type: FileActions.FETCH_FILES_FOR_USER_SUCCESS
}

interface FetchFilesForUserFail
  extends ram.AxiosMiddlewareActionFail<FetchFilesForUser> {
  type: FileActions.FETCH_FILES_FOR_USER_FAIL
}

export const fetchFilesForUser = (
  userIdentifier: UserIdentifier = 'me'
): FetchFilesForUser => ({
  type: FileActions.FETCH_FILES_FOR_USER,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/files`,
    },
  },
})

interface FetchLinkedinForItem extends ram.AxiosMiddlewareActionCreator {
  type: FileActions.FETCH_LINKEDIN_FOR_ITEM
  omsItemID: number
}

interface FetchLinkedinForItemSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    AutoLinkedinAPIResponse,
    FetchLinkedinForItem
  > {
  type: FileActions.FETCH_LINKEDIN_FOR_ITEM_SUCCESS
}

interface FetchLinkedinForItemFail
  extends ram.AxiosMiddlewareActionFail<FetchLinkedinForItem> {
  type: FileActions.FETCH_LINKEDIN_FOR_ITEM_FAIL
}

export const fetchLinkedInForItem = (
  omsItemID: number
): FetchLinkedinForItem => ({
  type: FileActions.FETCH_LINKEDIN_FOR_ITEM,
  omsItemID,
  payload: {
    request: {
      url: `/v2/files/linkedin/${omsItemID}`,
    },
  },
})

export type FileAction =
  | FetchFilesForUser
  | FetchFilesForUserSuccess
  | FetchFilesForUserFail
  | FetchMessagesForUserSuccess
  | SendMessageSuccess
  | FetchLinkedinForItem
  | FetchLinkedinForItemSuccess
  | FetchLinkedinForItemFail
