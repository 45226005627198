import {
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'

export interface PreloadState {
  preloadStatus: LoadingLoadedErrorState
  patPreloadStatus: LoadingLoadedErrorState
}

export const initialState: PreloadState = {
  preloadStatus: {
    ...initialLoadedLoadingErrorState,
    isLoading: true,
  },
  patPreloadStatus: {
    ...initialLoadedLoadingErrorState,
  },
}
