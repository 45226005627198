import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles/index'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import OrderNavigation from 'components/Messaging/OrderNavigation'

import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { ClientIDWithStatus } from 'store/orders/types'

interface Props {
  open: boolean
  subHeaderText: string
  popper: boolean
  headerText: string
  setInfoOpen: any
  activeNum?: number
  total?: number
  prevClientID: ClientIDWithStatus | null
  nextClientID: ClientIDWithStatus | null
  isCompletedView: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0',
    },
    h4: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    h5: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
    header: {
      borderBottom: `1px solid ${colors.gray[600]}`,
      padding: '1em 1.5em',
      width: '100%',
    },
    icon: {
      color: theme.palette.secondary.main,
      height: '1.5em',
      width: '1.5em',
    },
    packageText: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: '15%',
      },
    },
    textInfo: {
      paddingRight: '1.1em',
      width: 'calc(100% - 6.3em)',
    },
    textInfoSingleClient: {
      paddingRight: '1.1em',
    },
  })
)

const Header: React.FC<Props> = ({
  open,
  subHeaderText,
  popper,
  headerText,
  setInfoOpen,
  activeNum = 0,
  total = 0,
  prevClientID,
  nextClientID,
  isCompletedView,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const showOrderNavigation = !popper && total > 1

  return (
    <Container className={clsx(classes.flex, classes.header)} disableGutters>
      <Container
        className={clsx({
          [classes.flex]: true,
          [classes.textInfo]: !popper && total > 1,
          [classes.textInfoSingleClient]: !popper && total <= 1,
        })}
        disableGutters
      >
        <Container className={classes.packageText} disableGutters>
          <Typography className={classes.h5} variant="h5">
            {headerText}
          </Typography>
          {!isCompletedView && (
            <Typography className={classes.h4} variant="h4">
              {popper ? t('components.Messaging.orderDetails') : subHeaderText}
            </Typography>
          )}
          {isCompletedView && (
            <Typography className={classes.h4} variant="h4">
              {t('components.Messaging.completedOrders')}
            </Typography>
          )}
        </Container>
        {!isCompletedView && (
          <IconButton onClick={() => setInfoOpen(!open)}>
            {!popper && <InfoOutlinedIcon className={classes.icon} />}
            {popper && <CloseIcon className={classes.icon} />}
          </IconButton>
        )}
      </Container>
      {showOrderNavigation && (
        <OrderNavigation
          activeNum={activeNum}
          total={total}
          prevClientID={prevClientID}
          nextClientID={nextClientID}
        />
      )}
    </Container>
  )
}

export default Header
