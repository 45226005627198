import React, { useLayoutEffect } from 'react'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'

import { CronofyAvailablityViewerArgs } from 'store/scheduling/types'

interface Props extends CronofyAvailablityViewerArgs {
  className?: string
  itemID: number
}

const AvailabilityViewer: React.FC<Props> = ({
  className,
  itemID,
  ...props
}) => {
  const theme = useTheme()
  const elementID = `cronofy-availability-viewer-${itemID}`

  useLayoutEffect(() => {
    // CronofyElements is 200kb gziped. We only need one of them for a single
    // screen, so we code split with an import like this only when it is needed.
    ;(async function createViewer() {
      // @ts-ignore
      const CronofyElements = await import('cronofy-elements')
      CronofyElements.AvailabilityViewer({
        target_id: elementID,
        styles: {
          colors: {
            primary: theme.palette.primary.main,
            available: theme.palette.primary.light,
            availableHover: theme.palette.primary.main,
          },
        },
        ...props,
      })
    })()
  }, [
    theme.palette.primary.main,
    theme.palette.primary.light,
    props,
    elementID,
  ])

  return <Box id={elementID} className={className} />
}

export default AvailabilityViewer
