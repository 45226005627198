import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import { NotificationTypes } from 'store/notifications/types'
import { AppState } from 'store'
import { Notification } from 'store/notifications/types'

import { unreadRouteNotificationsByType } from 'store/notifications/selectors'
import NotificationModalContainer from 'components/Modals/NotificationModalContainer'
import { HashMap } from '@talentinc/state-utils'

const RouteNotifications: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  //a static mapping of routes to notifications. This drives page specific modals
  // this can grow over time or be moved to somewhere more suitable
  const routeNotificationMap: HashMap<NotificationTypes> = {
    [t('routes.messages')]: NotificationTypes.FirstDraft,
  }

  const notifications = useSelector<AppState, Notification[]>(
    (state) =>
      unreadRouteNotificationsByType(
        state.notifications,
        routeNotificationMap,
        location.pathname
      ),
    isEqual
  )

  if (notifications.length === 0) {
    return null
  }
  return <NotificationModalContainer notificationID={notifications[0].id} />
}

export default RouteNotifications
