import React from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OrderItemIcon from 'components/OrderItems/OrderItemIcon'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { currentItemStatus } from 'store/items/selectors'
import { Item } from 'store/items/types'
import { PaperLinkButton } from 'components/shared/PaperButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useCIORedirect } from 'hooks/useCIORedirect'

interface Props {
  item: Item
  orderCompleted: boolean
  isDueForRefresh: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textContainer: {
      display: 'flex',
      margin: '1rem',
    },
    textContainer2: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'calc(100% - 5.3em)',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    h3: {
      alignItems: 'center',
      display: 'flex',
      marginLeft: '1rem',
    },
    itemStatus: {
      marginLeft: '1rem',
    },
    itemStatusRefresh: {
      marginLeft: '1rem',
      color: theme.palette.error.main,
    },
    orderItemIconContainer: {
      alignItems: 'center',
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: '100%',
      display: 'flex',
      height: '5.3em',
      justifyContent: 'center',
      width: '5.3em',
    },
    container: {
      padding: '2em 2em',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0',
        padding: '2em 5%',
      },
    },
    itemContainer: {
      marginRight: '0',
      flexGrow: 'initial',
      width: 'calc(50% - .5em)',
      '&:hover': {
        textDecoration: 'none',
      },
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '.5em',
        marginRight: '.5em',
        marginTop: '1rem',
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: '.38em',
        marginLeft: '0',
        marginRight: '0',
      },
    },
    paper: {
      display: 'flex',
      padding: 0,
      marginTop: 0,
      border: `1px solid ${theme.palette.divider}`,
    },
  })
)

const OrderItemCard: React.FC<Props> = ({
  item,
  orderCompleted,
  isDueForRefresh,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const status = currentItemStatus(item)
  const timeDifference = status?.due_at
    ? Math.max(differenceInDays(status?.due_at, new Date()), 0)
    : 0
  const statusText = isDueForRefresh
    ? t(`components.OrderWidget.dueForUpdate`)
    : item.oms_custom_product_id
    ? status?.state === null || status?.state === undefined
      ? ''
      : t(`status.${status?.state}`, {
          count: timeDifference,
          daysLeft: timeDifference,
        })
    : t(`components.OrderWidget.emailFulfilled`)
  const toolTipText = isDueForRefresh
    ? t(`components.OrderWidget.dueForUpdateToolTip`)
    : ''

  return (
    <LinkWrapper
      clientID={item.oms_client_id}
      itemID={item.oms_order_item_id}
      requiresDocument={item.requires_document}
      orderCompleted={orderCompleted}
    >
      <Tooltip arrow placement="top" title={toolTipText}>
        <Container className={classes.textContainer} disableGutters>
          <Container className={classes.orderItemIconContainer} disableGutters>
            <OrderItemIcon itemType={item.item_type} itemName={item.name} />
          </Container>
          <Container className={classes.textContainer2} disableGutters>
            <Typography className={classes.h3} component="h3" variant="h3">
              {item.name}
            </Typography>
            <Typography
              variant="body2"
              className={
                isDueForRefresh ? classes.itemStatusRefresh : classes.itemStatus
              }
            >
              {statusText}
            </Typography>
          </Container>
        </Container>
      </Tooltip>
    </LinkWrapper>
  )
}

interface LinkWrapperProps {
  clientID: number | null
  itemID: number | null
  requiresDocument: boolean
  orderCompleted: boolean
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({
  children,
  clientID,
  itemID,
  requiresDocument,
  orderCompleted,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const CIORedirect = useCIORedirect()

  if (!clientID || !itemID || !requiresDocument) {
    return (
      <Paper
        variant="outlined"
        className={clsx(classes.itemContainer, classes.paper)}
      >
        {children}
      </Paper>
    )
  }

  let linkProps: any = {
    to: generatePath(t('routes.messagesByClientAndItem'), {
      clientID,
      itemID,
    }),
  }

  if (orderCompleted) {
    if (CIORedirect.enabled) {
      linkProps = {
        onClick: () => CIORedirect.goto('/app'),
      }
    } else {
      linkProps = {
        pathname: t('routes.portfolio'),
        hash: `#item-${itemID}`,
      }
    }
  }

  return (
    <PaperLinkButton
      {...linkProps}
      variant="outlined"
      className={clsx(classes.itemContainer, classes.paper)}
    >
      {children}
    </PaperLinkButton>
  )
}

export default OrderItemCard
