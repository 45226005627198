import {
  NotificationState,
  Notification,
  ModalNotificationTypes,
  NotificationTypes,
  MenuNotificationTypes,
} from './types'
import { HashMap } from '@talentinc/state-utils'

export const userNotifications = (state: NotificationState): Notification[] => {
  return Object.values(state.notifications).sort((a, b) => b.id - a.id)
}

export const menuNotifications = (state: NotificationState): Notification[] => {
  return Object.values(state.notifications)
    .filter((n) => MenuNotificationTypes.has(n.name))
    .sort((a, b) => b.id - a.id)
}

export const notificationsByType = (
  state: NotificationState,
  notificationType: NotificationTypes
): Notification[] => {
  if (!notificationType) return []
  return Object.values(state.notifications).filter(
    (n) => n.name === notificationType
  )
}

export const unreadRouteNotificationsByType = (
  state: NotificationState,
  routeNotificationMapping: HashMap<NotificationTypes>,
  currentRoute: string
): Notification[] => {
  let nResults: Notification[] = []
  Object.entries(routeNotificationMapping).forEach(([route, nTypes]) => {
    if (currentRoute.includes(route)) {
      nResults = notificationsByType(state, nTypes).filter((n) => !n.viewed_at)
    }
  })
  return nResults
}

export const unReadDocumentNotifications = (
  state: NotificationState,
  documentType: string
) => {
  return notificationsByType(
    state,
    NotificationTypes.FirstDraftDocument
  ).filter(
    (n) =>
      !n.viewed_at &&
      n.name === NotificationTypes.FirstDraftDocument &&
      n.variables.doc_type === documentType
  )
}

export const hasUnreadNotificationsByType = (
  state: NotificationState,
  notificationType: NotificationTypes
): boolean => {
  const result = notificationsByType(state, notificationType).filter(
    (n) => !n.viewed_at
  )
  if (result.length > 0) return true
  return false
}

export const unreadNotifications = (state: NotificationState): Notification[] =>
  Object.values(state.notifications).filter(
    (n) =>
      !n.viewed_at &&
      MenuNotificationTypes.has(n.name) &&
      (!state.meta.individual[n.id] || !state.meta.individual[n.id].error)
  )

export const notificationToShowModalFor = (
  state: NotificationState
): Notification | null => {
  const notifications = unreadNotifications(state).filter((n) =>
    ModalNotificationTypes.includes(n.name)
  )

  //If there are multiple notifications, including an order received notification, show that one first
  const orderReceived = notifications.filter(
    (n) => n.name === NotificationTypes.OrderReceived
  )
  if (notifications.length > 1 && orderReceived.length > 0) {
    return orderReceived[0]
  }

  return (
    notifications.sort(
      (a, b) => b.created_at.getTime() - a.created_at.getTime()
    )?.[0] || null
  )
}

export const isLoadingNotification = (state: NotificationState): boolean =>
  Object.values(state.meta.individual).filter((x) => x.isLoading).length > 0
