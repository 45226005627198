import React from 'react'
import { ItemTypes } from 'store/items/types'
import { Bundles } from 'store/upsells/types'

import PngAccelerateSearch from 'assets/images/bundle-accelerate-search-thumb.png'
import PngNextStep from 'assets/images/bundle-next-step-thumb.png'
import PngDigitallySavvy from 'assets/images/bundle-digitally-savvy-thumb.png'
import PngWinInterview from 'assets/images/bundle-win-interview-thumb.png'
import PngGetWordOut from 'assets/images/bundle-get-word-out-thumb.png'
import PngExpertConnect from 'assets/images/bundle-expert-connect-thumb.png'
import PngWriterExpertise from 'assets/images/bundle-writer-expertise-thumb.png'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { HashMap } from '@talentinc/state-utils'
import SingleItemHeroImage from 'components/Product/SingleItemHeroImage'

interface Props {
  itemType: ItemTypes
  bundle?: Bundles | null
  className?: string
  itemName: string
}

const iconMap: HashMap<string> = {
  [Bundles.NextStep]: PngNextStep,
  [Bundles.DigitallySavvy]: PngDigitallySavvy,
  [Bundles.AccelerateSearch]: PngAccelerateSearch,
  [Bundles.WinInterview]: PngWinInterview,
  [Bundles.ExpertConnect]: PngExpertConnect,
  [Bundles.GetWordOut]: PngGetWordOut,
  [Bundles.WriterExpertise]: PngWriterExpertise,
}

const BundleThumbnail: React.FC<Props> = ({
  bundle,
  itemType,
  className,
  itemName,
}) => {
  const classes = useStyles()
  const icon = iconMap[bundle || '']

  if (!icon) {
    return (
      <SingleItemHeroImage
        itemType={itemType || ItemTypes.Resume}
        itemName={itemName}
      />
    )
  }
  return (
    <img className={clsx(classes.img, className)} src={icon} alt={itemName} />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: '100%',
    },
  })
)

export default BundleThumbnail
