import * as ram from '@talentinc/redux-axios-middleware'

import { UserIdentifier } from 'store/user/types'
import { OrderResponse } from './types'

export enum OrderActions {
  FETCH_ORDERS_FOR_USER = 'FETCH_ORDERS_FOR_USER',
  FETCH_ORDERS_FOR_USER_SUCCESS = 'FETCH_ORDERS_FOR_USER_SUCCESS',
  FETCH_ORDERS_FOR_USER_FAIL = 'FETCH_ORDERS_FOR_USER_FAIL',
  APPROVE_ORDER = 'APPROVE_ORDER',
  APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS',
  APPROVE_ORDER_FAIL = 'APPROVE_ORDER_FAIL',
  FETCH_ORDER_FOR_USER = 'FETCH_ORDER_FOR_USER',
  FETCH_ORDER_FOR_USER_SUCCESS = 'FETCH_ORDER_FOR_USER_SUCCESS',
  FETCH_ORDER_FOR_USER_FAIL = 'FETCH_ORDER_FOR_USER_FAIL',
  APPROVE_ORDER_LOADING = 'APPROVE_ORDER_LOADING',
}

interface FetchOrdersForUser extends ram.AxiosMiddlewareActionCreator {
  type: OrderActions.FETCH_ORDERS_FOR_USER
}

export interface FetchOrdersForUserSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    OrderResponse[],
    FetchOrdersForUser
  > {
  type: OrderActions.FETCH_ORDERS_FOR_USER_SUCCESS
}

interface FetchOrdersForUserFail
  extends ram.AxiosMiddlewareActionFail<FetchOrdersForUser> {
  type: OrderActions.FETCH_ORDERS_FOR_USER_FAIL
}

export const fetchOrdersForUser = (
  userIdentifier: UserIdentifier = 'me'
): FetchOrdersForUser => ({
  type: OrderActions.FETCH_ORDERS_FOR_USER,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/orders`,
      params: {
        exclude_german: true,
      },
    },
  },
})

interface FetchOrderForUser extends ram.AxiosMiddlewareActionCreator {
  type: OrderActions.FETCH_ORDER_FOR_USER
  orderID: number
}

export interface FetchOrderForUserSuccess
  extends ram.AxiosMiddlewareActionSuccess<OrderResponse, FetchOrderForUser> {
  type: OrderActions.FETCH_ORDER_FOR_USER_SUCCESS
}

interface FetchOrderForUserFail
  extends ram.AxiosMiddlewareActionFail<FetchOrderForUser> {
  type: OrderActions.FETCH_ORDER_FOR_USER_FAIL
}

export const fetchOrderForUser = (
  userIdentifier: UserIdentifier = 'me',
  orderID: number
): FetchOrderForUser => ({
  type: OrderActions.FETCH_ORDER_FOR_USER,
  orderID,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/orders/${orderID}`,
    },
  },
})

export interface ApproveOrder extends ram.AxiosMiddlewareActionCreator {
  type: OrderActions.APPROVE_ORDER
  orderID: number
}

export interface ApproveOrderSuccess
  extends ram.AxiosMiddlewareActionSuccess<OrderResponse, ApproveOrder> {
  type: OrderActions.APPROVE_ORDER_SUCCESS
}

interface ApproveOrderFail extends ram.AxiosMiddlewareActionFail<ApproveOrder> {
  type: OrderActions.APPROVE_ORDER_FAIL
}

export const approveOrderForUser = (
  orderID: number,
  userIdentifier: UserIdentifier = 'me'
): ApproveOrder => ({
  type: OrderActions.APPROVE_ORDER,
  orderID,
  payload: {
    request: {
      url: `/v2/users/${userIdentifier}/orders/${orderID}/approve`,
      method: 'PATCH',
    },
  },
})

export interface ApproveOrderLoading {
  type: OrderActions.APPROVE_ORDER_LOADING
  payload: boolean
}

export const approveOrderLoading = (payload: boolean): ApproveOrderLoading => ({
  type: OrderActions.APPROVE_ORDER_LOADING,
  payload,
})

export type OrderAction =
  | FetchOrdersForUser
  | FetchOrdersForUserSuccess
  | FetchOrdersForUserFail
  | ApproveOrder
  | ApproveOrderSuccess
  | ApproveOrderFail
  | FetchOrderForUser
  | FetchOrderForUserSuccess
  | FetchOrderForUserFail
  | ApproveOrderLoading
