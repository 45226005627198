import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

interface Props {
  fieldName: string
  handleClick: any
  value?: string | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      minWidth: '0',
    },
    h3: {
      marginBottom: '.22em',
    },
  })
)

const TextItem: React.FC<Props> = ({ fieldName, handleClick, value }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Container disableGutters>
        <Typography className={classes.h3} variant="h3">
          {t(`pages.Account.${fieldName}`)}
        </Typography>
        {value && <Typography variant="body1">{value}</Typography>}
      </Container>
      {handleClick && (
        <Button
          className={classes.button}
          color="primary"
          onClick={() => handleClick(true)}
          variant="text"
        >
          {t('pages.Account.edit')}
        </Button>
      )}
    </>
  )
}

export default TextItem
