import React from 'react'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Upsell } from 'store/upsells/types'
import useShoppingCart from 'hooks/useShoppingCart'
import { packageDisplayProps } from 'store/orders/selectors'
import { AppState } from 'store'
import isEqual from 'lodash/isEqual'
import { filteredUpsells } from 'utils/upsells'
import Carousel from 'components/shared/Carousel'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Price from 'components/shared/Price'

interface Props {
  upsells: Upsell[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      borderRadius: '100% !important',
      height: '2.5em',
      minWidth: 'initial !important',
      width: '2.5em',
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
    },
    containerCarousel: {
      padding: '2em 2.5em 3em',
      [theme.breakpoints.down('sm')]: {
        padding: '2em 1.13em 3em',
      },
    },
    containerPackage: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    containerText: {
      flexDirection: 'column',
      width: 'calc(100% - 5em)',
    },
    h3: {
      fontSize: '1em',
      fontWeight: 600,
    },
    h4: {
      fontWeight: 400,
      marginTop: '.21em',
      maxHeight: '6em',
      overflow: 'auto',
    },
    iconAdd: {
      fontSize: '1.5em',
    },
    price: {
      fontWeight: 400,
      marginLeft: '.61em',
    },
    slide: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px',
      padding: '1.1em 1.5em',
    },
  })
)

const CheckoutUpsellCarousel: React.FC<Props> = ({ upsells }) => {
  const classes = useStyles()
  const { addToCart } = useShoppingCart()

  const currentPackages = useSelector<
    AppState,
    ReturnType<typeof packageDisplayProps>
  >(
    (state) =>
      packageDisplayProps(
        state.orders,
        state.items,
        state.files,
        state.ratings
      ),
    isEqual
  )

  return (
    <Container className={classes.containerCarousel}>
      <Carousel>
        {filteredUpsells(upsells, currentPackages).map(
          (upsell: Upsell, index: number) => (
            <div className={classes.slide} key={index}>
              <Container className={classes.container} disableGutters>
                <Container
                  className={clsx(classes.container, classes.containerText)}
                  disableGutters
                >
                  <Container
                    className={clsx(
                      classes.container,
                      classes.containerPackage
                    )}
                    disableGutters
                  >
                    <Typography className={classes.h3} variant="h3">
                      {upsell.name}
                    </Typography>
                    <Typography
                      className={classes.price}
                      color="secondary"
                      component="p"
                      variant="h4"
                    >
                      +
                    </Typography>
                    <Price
                      currencyCode={upsell.currency_code}
                      currencySymbol={upsell.currency_symbol}
                      amount={upsell.price}
                      typographyProps={{
                        variant: 'h4',
                        color: 'secondary',
                        component: 'p',
                      }}
                    />
                  </Container>
                  <Typography className={classes.h4} variant="h4">
                    {upsell.very_short_pitch
                      ? upsell.very_short_pitch
                      : upsell.short_pitch}
                  </Typography>
                </Container>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => addToCart(upsell.plan_code, 'shopping-cart')}
                >
                  <AddIcon classes={{ root: classes.iconAdd }} />
                </Button>
              </Container>
            </div>
          )
        )}
      </Carousel>
    </Container>
  )
}

export default CheckoutUpsellCarousel
