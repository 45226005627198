import React from 'react'
import PurchaseHistory, { Purchase } from './index'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import {
  fetchUserTransactions,
  TransactionsActions,
} from 'store/transactions/actions'
import { AppState } from 'store'
import Skeleton from 'components/shared/Skeleton'
import { purchaseHistoryList } from 'store/transactions/selectors'

const PurchaseHistoryContainer: React.FC = () => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(fetchUserTransactions())
  })

  const transactionsFinishedLoading = useSelector<AppState, boolean>(
    (state) =>
      state.transactions.meta[TransactionsActions.FETCH_USER_TRANSACTIONS]
        .loaded
  )
  const purchases = useSelector<AppState, Purchase[]>((state) =>
    purchaseHistoryList(state.transactions)
  )

  if (!transactionsFinishedLoading) {
    return <Skeleton components={[{ height: '30em', width: '100%' }]} />
  }

  return <PurchaseHistory purchases={purchases} />
}

export default PurchaseHistoryContainer
