import React from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import Grow from '@material-ui/core/Grow'
import clsx from 'clsx'

import { colors } from 'styles'

interface Props {
  level: 'error' | 'warning' | 'info' | 'success'
  className?: string
}

const FlashMessage: React.FC<Props> = ({ children, level, className }) => {
  const classes = useStyles({ level })
  let icon = <WarningIcon className={classes.icon} />

  switch (level) {
    case 'warning':
    case 'info':
      icon = <InfoIcon className={classes.icon} />
      break
    case 'error':
      icon = <WarningIcon className={classes.icon} />
      break
  }

  return (
    <Grow in={true}>
      <Paper variant="outlined" className={clsx(classes.paper, className)}>
        <Box className={classes.iconBox}>{icon}</Box>
        <Box className={classes.textBox}>{children}</Box>
      </Paper>
    </Grow>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles<string, Props>({
    paper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      position: 'relative',
      overflow: 'hidden',
    },
    iconBox: {
      backgroundColor: ({ level }) => {
        switch (level) {
          case 'info':
            return colors.blue.a400
          case 'success':
            return theme.palette.success.main
          case 'warning':
            return colors.yellow[100]
          case 'error':
            return theme.palette.error.main
        }
      },
      height: '100%',
      marginRight: '1em',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: 'white',
      fontSize: '2rem',
      margin: '0.3em',
    },
    textBox: {
      padding: '1em 1em 1em 0',
      marginLeft: '4.5rem',
      width: '100%',
    },
  })
)

export default FlashMessage
