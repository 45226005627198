import React, { useContext, useCallback, useEffect } from 'react'
import {
  useForm,
  FormRenderProps,
  useField,
  FieldRenderProps,
  useFormState,
} from 'react-final-form-hooks'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { AppState } from 'store'
import { sendMessageAndUpdateOrder } from 'store/messages/thunks'
import { SendMessagePayload } from 'store/messages/types'
import {
  uploadAttachment as reduxUploadAttachment,
  removeAttachment as reduxRemoveAttachment,
  UploadAttachmentSuccess,
} from 'store/attachments/actions'
import { AttachmentStateItem } from 'store/attachments/types'
import { unsendableAttachments as reduxUnsendableAttachments } from 'store/attachments/selectors'

interface MessageDraftContextValue {
  formProps: FormRenderProps<SendMessagePayload>
  bodyField: FieldRenderProps<string>
}

export const MessageDraftContext = React.createContext<
  MessageDraftContextValue | Partial<MessageDraftContextValue>
>({})

interface MessageDraftProviderProps {
  initialValues: SendMessagePayload
}

export const MessageDraftProvider: React.FC<MessageDraftProviderProps> = ({
  initialValues,
  children,
}) => {
  const dispatch = useDispatch()
  const unsendableAttachments = useSelector<AppState, AttachmentStateItem[]>(
    (state) => reduxUnsendableAttachments(state.attachments),
    isEqual
  )
  const validate = useCallback(
    (values: SendMessagePayload) => {
      if (values.expert_hub_client_id < 1) {
        return {
          expert_hub_client_id: "Order isn't selected to send message to.",
        }
      }

      if (unsendableAttachments.length > 0) {
        return {
          portal_attachment_ids: 'Invalid attachments in the current message',
        }
      }

      return undefined
    },
    [unsendableAttachments]
  )
  const formProps = useForm<SendMessagePayload>({
    initialValues,
    initialValuesEqual: isEqual,
    validate,
    onSubmit: async (values) => {
      if (!(await dispatch(sendMessageAndUpdateOrder(values, 'me')))) {
        return 'Failed to send message'
      }
    },
  })
  const formState = useFormState(formProps.form, { submitSucceeded: true })
  const bodyField = useField<string, SendMessagePayload>(
    'body',
    formProps.form,
    (value: string) => (value.length > 0 ? undefined : 'Too Short')
  )

  // With addition of the initialValuesEqual, form resetting after successfully
  // submission stopped working. This seems to have fixed it.
  // https://github.com/final-form/react-final-form-hooks/issues/39#issuecomment-485795067
  useEffect(() => {
    if (formState.submitSucceeded) {
      formProps.form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitSucceeded])

  return (
    <MessageDraftContext.Provider value={{ formProps, bodyField }}>
      {children}
    </MessageDraftContext.Provider>
  )
}

function useMessageDraft() {
  const dispatch = useDispatch()
  const { bodyField, formProps } = useContext(MessageDraftContext)
  const attachments = useSelector<AppState, AttachmentStateItem[]>((state) =>
    Object.values(state.attachments.attachments)
  )

  const addAttachments = useCallback(async (files: FileList | null) => {
    if (!files) {
      return
    }

    Array.from(files).map((file) => {
      return dispatch(
        reduxUploadAttachment(file)
      ) as unknown as UploadAttachmentSuccess
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const removeAttachment = useCallback((filename: string) => {
    dispatch(reduxRemoveAttachment(filename))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return {
    attachments,
    addAttachments,
    removeAttachment,
    bodyField: bodyField as FieldRenderProps<string>,
    formProps: formProps as FormRenderProps<SendMessagePayload>,
  }
}

export default useMessageDraft
