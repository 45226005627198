import React from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import OrderWidget from './index'
import { AppState } from 'store'
import { orderWidgetDisplayProps } from 'store/orders/order_widget'
import Skeleton from 'components/shared/Skeleton'

const OrdersWidgetContainer: React.FC = () => {
  const ordersProps = useSelector<
    AppState,
    ReturnType<typeof orderWidgetDisplayProps>
  >(
    (state) =>
      orderWidgetDisplayProps(state.orders, state.items, state.ratings),
    isEqual
  )

  const ordersFinishedLoading = useSelector<AppState, boolean>(
    (state) => state.orders.meta.FETCH_ORDERS_FOR_USER.loaded
  )

  if (!ordersFinishedLoading) {
    return <Skeleton components={[{ height: '60em', width: '100%' }]} />
  }

  return (
    <>
      {ordersProps.map((orderProps) => (
        <OrderWidget key={orderProps.id} {...orderProps} />
      ))}
    </>
  )
}

export default OrdersWidgetContainer
