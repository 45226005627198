import React from 'react'
import { ItemTypes } from 'store/items/types'
import { Bundles } from 'store/upsells/types'

import PngAccelerateSearch from 'assets/images/bundle-accelerate-search.png'
import PngNextStep from 'assets/images/bundle-next-step.png'
import PngDigitallySavvy from 'assets/images/bundle-digitally-savvy.png'
import PngWinInterview from 'assets/images/bundle-win-interview.png'
import PngGetWordOut from 'assets/images/bundle-get-word-out.png'
import PngExpertConnect from 'assets/images/bundle-expert-connect.png'
import PngWriterExpertise from 'assets/images/bundle-writer-expertise.png'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import SingleItemHeroImage from './SingleItemHeroImage'
import { HashMap } from '@talentinc/state-utils'

interface Props {
  itemType: ItemTypes
  bundle?: Bundles | null
  className?: string
  itemName: string
}

const iconMap: HashMap<string> = {
  [Bundles.NextStep]: PngNextStep,
  [Bundles.DigitallySavvy]: PngDigitallySavvy,
  [Bundles.AccelerateSearch]: PngAccelerateSearch,
  [Bundles.WinInterview]: PngWinInterview,
  [Bundles.ExpertConnect]: PngExpertConnect,
  [Bundles.GetWordOut]: PngGetWordOut,
  [Bundles.WriterExpertise]: PngWriterExpertise,
}

const BundleHeroImage: React.FC<Props> = ({
  bundle,
  itemType,
  className,
  itemName,
}) => {
  const classes = useStyles()
  const icon = iconMap[bundle || '']

  if (!icon) {
    return (
      <SingleItemHeroImage
        itemType={itemType || ItemTypes.Resume}
        itemName={itemName}
      />
    )
  }
  return (
    <img className={clsx(classes.img, className)} src={icon} alt={itemName} />
  )
}

const ProductHeroImage: React.FC<Props> = ({
  itemType,
  bundle,
  className,
  itemName,
}) => {
  if (bundle)
    return (
      <BundleHeroImage
        itemType={itemType}
        bundle={bundle}
        className={className}
        itemName={itemName}
      />
    )
  return (
    <SingleItemHeroImage
      itemType={itemType}
      className={className}
      itemName={itemName}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: '100%',
      borderRadius: '0.5rem',
    },
  })
)

export default ProductHeroImage
