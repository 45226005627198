import React from 'react'

const SvgActiveStep = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 17 16"
    {...props}
  >
    <g fill="currentColor">
      <path
        d="M15.92 2.96L13.36.08c-.24-.16-.56-.08-.56.24V1.6c-3.28 0-6.4 2.88-6.4 6.16 0 .56.16 1.36.24 1.84.72-2.56 3.36-4.8 6.16-4.8v1.28c0 .32.32.48.56.24l2.56-2.88c.08-.16.08-.32 0-.48z"
        transform="translate(.5)"
      />
      <path
        d="M12.48 8.8h-.96c-.16 0-.32.16-.32.32v5.28H1.6V4.8h3.68c.16 0 .32-.16.32-.32v-.96c0-.16-.16-.32-.32-.32H.8c-.48 0-.8.32-.8.8v11.2c0 .48.32.8.8.8H12c.48 0 .8-.32.8-.8V9.12c0-.16-.16-.32-.32-.32z"
        transform="translate(.5)"
      />
    </g>
  </svg>
)

export default SvgActiveStep
