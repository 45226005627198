import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import GhostLink from 'components/shared/GhostLink'

interface Props {
  title: string
  buttonLink?: string | null
  buttonText?: string | null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1.9em 1.9em 1.6em',
    },
  })
)

const WidgetTitle: React.FC<Props> = ({
  title,
  buttonLink = null,
  buttonText = null,
}) => {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <Typography variant="h2">{title}</Typography>
      {buttonLink && buttonText && (
        <Button
          color="primary"
          component={GhostLink}
          to={buttonLink ? buttonLink : ''}
          variant="text"
        >
          {buttonText ? buttonText : ''}
        </Button>
      )}
    </Container>
  )
}

export default WidgetTitle
