import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import useShoppingCart from 'hooks/useShoppingCart'

import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { colors } from 'styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Price from 'components/shared/Price'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleSharpIcon from '@material-ui/icons/RemoveCircleSharp'
import Chip from '@material-ui/core/Chip'

import { GhostRedirect } from 'components/shared/GhostLink'
import CatalogBundleItemIcon from './CatalogBundleItemIcon'

import { Upsell } from 'store/upsells/types'
import { generatePath } from 'react-router-dom'
import useEvents from 'hooks/useEvents'

interface Props {
  updateUpsells: (isSelected: boolean, planCode: string) => void
  upsell: Upsell
  className?: string
}

const CatalogBundleItem: React.FC<Props> = ({
  className,
  updateUpsells,
  upsell,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { cartPlanCodes } = useShoppingCart()
  const sendEvent = useEvents()

  const [showButton, setShowButton] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const isSelected = cartPlanCodes.includes(upsell.plan_code)

  const handleButtonClick = useCallback(
    async (e) => {
      e.stopPropagation()
      updateUpsells(isSelected, upsell.plan_code)
    },
    [isSelected, updateUpsells, upsell.plan_code]
  )

  const handleUpsellClick = useCallback(async () => {
    setShowDetails(true)
    sendEvent({
      event: `Portal - Product Page Viewed`,
      variables: {
        plan_code: upsell.plan_code,
        bundle: upsell.bundle || '',
      },
    })
  }, [sendEvent, upsell.plan_code, upsell.bundle])

  if (upsell.items.length === 0) return null

  const item = upsell.items[0]

  const isDiscounted = !!(
    upsell.original_price &&
    upsell.original_price > 0 &&
    upsell.original_price > upsell.price
  )

  const discountedPrice = Math.floor(
    isDiscounted ? upsell.original_price || upsell.price : upsell.price
  )

  const upsellBundle = upsell.bundle

  if (showDetails && upsellBundle) {
    return (
      <GhostRedirect
        to={generatePath(t(`routes.bundledUpgrades`), { bundle: upsellBundle })}
        push
      />
    )
  }
  return (
    <Box
      className={clsx(classes.paper, className)}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      onClick={handleUpsellClick}
    >
      <Container disableGutters className={classes.iconContainer}>
        <CatalogBundleItemIcon
          itemType={item.item_type_key}
          itemName={item.name}
          bundle={upsellBundle}
        />

        <div
          className={clsx(
            classes.hoverContainer,
            isSelected ? classes.hoverSelected : ''
          )}
        >
          <Box className={classes.discountContainer}>
            {upsell.items.length > 1 && (
              <Chip label="30% OFF" className={classes.chip} />
            )}
          </Box>
          {!isMobile && (
            <Button
              color="primary"
              variant="contained"
              className={clsx(
                classes.hoverButton,
                !showButton ? classes.hidden : ''
              )}
              onClick={handleButtonClick}
            >
              {isSelected
                ? t(`components.Upsells.shoppingCart.remove`)
                : t(`components.Upsells.shoppingCart.addToCart`)}
            </Button>
          )}
          {isMobile && (
            <Container disableGutters className={classes.mobileButtonContainer}>
              <IconButton
                aria-label="add"
                className={classes.mobileButton}
                onClick={(e) => handleButtonClick(e)}
              >
                {!isSelected && <AddCircleIcon className={classes.addIcon} />}
                {isSelected && (
                  <RemoveCircleSharpIcon className={classes.addIcon} />
                )}
              </IconButton>
            </Container>
          )}
        </div>
      </Container>
      <Container disableGutters>
        <Typography variant="h2" className={classes.title}>
          {t(`components.Product.bundles.title.${upsellBundle}`)}
        </Typography>
        <Typography variant="body1" className={classes.shortPitch}>
          {t(
            `components.Upsells.shoppingCart.bundleShortPitch.${upsellBundle}`
          )}
        </Typography>
        <Container disableGutters className={classes.priceContainer}>
          <Price
            currencyCode={upsell.currency_code}
            currencySymbol={upsell.currency_symbol}
            amount={discountedPrice}
            className={clsx(
              classes.price,
              isDiscounted ? classes.originalPrice : ''
            )}
          />
          {isDiscounted && (
            <Price
              currencyCode={upsell.currency_code}
              currencySymbol={upsell.currency_symbol}
              amount={upsell.price}
              className={clsx(classes.price, classes.salePrice)}
            />
          )}
        </Container>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: 'inherit',
      maxWidth: '22em',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      flexShrink: 1,
      flexBasis: '30%',
      [theme.breakpoints.up('lg')]: {
        flexGrow: 1,
      },
      marginBottom: '2rem',
      minWidth: '12em',
      cursor: 'pointer',
    },
    iconContainer: {
      maxWidth: '22em',
      position: 'relative',
      marginBottom: '0.93rem',
    },
    hoverContainer: {
      position: 'absolute',
      left: '0em',
      top: '0em',
      width: '100%',
      height: '97%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '1rem 1rem 1.5rem 1rem',
      borderRadius: '0.25rem',
    },
    hoverSelected: {
      border: `solid ${theme.palette.primary.main} 3px`,
      backgroundColor: colors.blue.a100,
    },
    hoverButton: {
      width: '100%',
      opacity: '1',
      backgroundColor: theme.palette.primary.main,
    },
    mobileButton: {
      padding: '0em',
      width: '2rem',
      height: '2rem',
      borderRadius: '50%',
      backgroundColor: 'white',
    },
    mobileButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    addIcon: {
      color: theme.palette.primary.main,
      fontSize: 'medium',
      height: '3rem',
      width: '3rem',
    },
    hidden: {
      display: 'none',
    },
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 600,
      color: colors.blue[700],
    },
    shortPitch: {
      fontSize: '1rem',
    },
    price: {
      fontWeight: 600,
      fontSize: '1rem',
      marginTop: '1rem',
    },
    originalPrice: {
      textDecoration: 'line-through',
      fontWeight: 600,
      fontSize: '1rem',
    },
    salePrice: {
      color: colors.green[600],
      marginLeft: '0.5rem',
    },
    priceContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    discountContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    chip: {
      borderRadius: '3em',
      backgroundColor: colors.green[600],
      color: colors.white[100],
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      fontSize: '0.8rem',
      fontWeight: 600,
    },
  })
)

export default CatalogBundleItem
