import React from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { PreparednessStatus } from 'store/items/types'

interface Props {
  preparedNessStatus: PreparednessStatus
}

const PreparationStatusChip: React.FC<Props> = ({ preparedNessStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getColorStyle = (status: PreparednessStatus) => {
    let style = ''
    switch (status) {
      case PreparednessStatus.FullyPrepared:
        style = classes.fullyPrepared
        break
      case PreparednessStatus.SomewhatPrepared:
        style = classes.somewhatPrepared
        break
      case PreparednessStatus.UnderPrepared:
        style = classes.underPrepared
        break
      case PreparednessStatus.UpdateNeeded:
        style = classes.updateNeeded
        break
    }
    return style
  }

  return (
    <Paper className={clsx(classes.paper, getColorStyle(preparedNessStatus))}>
      <Typography variant="overline" className={classes.text}>
        {t(
          `components.ProgressWidget.preparednessStatus.${preparedNessStatus}`
        )}
      </Typography>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      borderRadius: '0.15em',
    },
    text: {
      color: colors.white[100],
      fontWeight: 600,
      fontSize: '0.7rem',
      letterSpacing: '0.1rem',
    },
    fullyPrepared: {
      backgroundColor: colors.green[500],
    },
    somewhatPrepared: {
      backgroundColor: colors.yellow[200],
    },
    underPrepared: {
      backgroundColor: colors.orange[400],
    },
    updateNeeded: {
      backgroundColor: colors.gray.p100,
    },
  })
)

export default PreparationStatusChip
