import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import PasswordReset from 'components/Auth/Reset'
import UnauthenticatedPageShell from 'pages/PageShell/Unauthenticated'

const Reset: React.FC = () => {
  const { t } = useTranslation()

  return (
    <UnauthenticatedPageShell footer={false} logoGoesToPortal={false}>
      <Helmet>
        <title>{t('components.Auth.Reset.title')}</title>
      </Helmet>
      <PasswordReset />
    </UnauthenticatedPageShell>
  )
}

export default Reset
