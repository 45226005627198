// Stolen and modified from the check-password-strenth library.
// It didn't have types and I didn't like how it's API, so I modified that.
// https://github.com/deanilvincent/check-password-strength/blob/master/index.js

const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W)(?=.{8,})/

const passwordStrength = (password: string): 'strong' | 'weak' => {
  if (strongRegex.test(password)) {
    return 'strong'
  }

  return 'weak'
}

export default passwordStrength
