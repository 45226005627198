import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import ModalWrapper, { ModalProps } from '../Wrapper'
import downloadImage from 'assets/images/download.png'

interface DownloadModalProps extends ModalProps {
  wordURL: string
  pdfURL: string | null
  disableWord: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    filetypeButtons: {
      marginTop: '2em',

      '& > *': {
        width: '48%',
      },
    },
    fullWidthButton: {
      '& > *': {
        width: '100%',
      },
    },
  })
)

const DownloadModal: React.FC<DownloadModalProps> = ({
  callToActionProps,
  wordURL,
  pdfURL,
  disableWord = false,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [selectedFileType, setSelectedFileType] = useState<'word' | 'pdf'>(
    disableWord ? 'pdf' : 'word'
  )

  return (
    <ModalWrapper
      callToActionProps={{
        children: t('components.Modals.variants.Download.cta'),
        href: !!pdfURL
          ? selectedFileType === 'word'
            ? wordURL
            : pdfURL
          : wordURL,
        ...callToActionProps,
      }}
      heroImageURL={downloadImage}
      {...props}
    >
      <Typography variant="h2">
        {t('components.Modals.variants.Download.title')}
      </Typography>
      <Typography variant="body2">
        {t('components.Modals.variants.Download.copy')}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        className={clsx(
          classes.filetypeButtons,
          !pdfURL && classes.fullWidthButton
        )}
      >
        <FileTypeButton
          onClick={() => setSelectedFileType('word')}
          color={selectedFileType === 'word' ? 'primary' : 'default'}
          disabled={disableWord}
        >
          {t('components.Modals.variants.Download.word')}
        </FileTypeButton>
        {pdfURL && (
          <FileTypeButton
            onClick={() => setSelectedFileType('pdf')}
            color={selectedFileType === 'pdf' ? 'primary' : 'default'}
          >
            {t('components.Modals.variants.Download.pdf')}
          </FileTypeButton>
        )}
      </Box>
    </ModalWrapper>
  )
}

const FileTypeButton = withStyles((theme) => ({
  root: {
    fontWeight: 'normal',
    minHeight: '120px',
  },
  outlined: {
    color: theme.palette.secondary.main,
  },
  outlinedPrimary: {
    color: theme.palette.secondary.main,
    backgroundColor: '#eaf7fd',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover, &:active': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}))((props: ButtonProps) => <Button variant="outlined" {...props} />)

export default DownloadModal
