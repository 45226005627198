import React, { useEffect } from 'react'
import useLocation from 'react-use/lib/useLocation'

import useEvents from 'hooks/useEvents'
import useUser from 'hooks/useUser'

/**
 * Tracking is a component that can be used to bury scripts and logic around
 * tracking the user.
 */
const Tracking: React.FC = () => {
  const user = useUser()
  const { pathname } = useLocation()
  const sendEvent = useEvents()

  // Send an event to Aramis for each page view
  useEffect(() => {
    if (!user.id) {
      return
    }

    sendEvent({
      event: 'Portal - Page Navigation',
      variables: {
        pathname: pathname,
      },
    })
  }, [sendEvent, user.id, pathname])

  return null
}

export default Tracking
