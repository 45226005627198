import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import useMessageDraft from 'hooks/useMessageDraft'

const useStyles = makeStyles((theme) =>
  createStyles({
    attachFileIcon: {
      height: '1.25em',
    },
    button: {
      fontSize: '.88em',
      marginLeft: '.78em',
    },
    container: {
      justifyContent: 'flex-end',
      display: 'flex',
      marginBottom: '1em',
      marginTop: '-3.25em',
      padding: '0 1em',
    },
    fileInput: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '200%',
      transform: 'translateY(-50%)',
      opacity: 0,
      cursor: 'pointer',
    },
  })
)

const ReplyButtons: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { formProps, addAttachments } = useMessageDraft()
  const { submitting, valid } = formProps

  return (
    <Container className={classes.container} disableGutters>
      <IconButton>
        <AttachFileIcon className={classes.attachFileIcon} />
        <input
          type="file"
          onChange={(e) => {
            addAttachments(e.target.files)
            // Reset so that the same file can be uploading again if it fails
            e.target.value = ''
          }}
          className={classes.fileInput}
        />
      </IconButton>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitting || !valid}
      >
        {t(`components.Messaging.${submitting ? 'sending' : 'send'}`)}
      </Button>
    </Container>
  )
}

export default ReplyButtons
