import React, { useCallback, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import uniqBy from 'lodash/uniqBy'
import IconButton from '@material-ui/core/IconButton'

import OrderWidgetAccordion from './Accordion'
import { Item } from 'store/items/types'

import hintIcon from 'assets/images/hint-icon.svg'
import CIOInfoModal from 'components/Modals/variants/CIOInfoModal'

interface Props {
  items: Item[]
}

const tKey = 'components.OrderWidgetItemDetails'

// This will contain the item details accordion
const OrderWidgetItemDetails: React.FC<Props> = ({ items }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [infoModalVisiblity, setInfoModalVisiblity] = useState(false)

  const showInfoModal = useCallback(() => setInfoModalVisiblity(true), [])

  const closeInfoModal = useCallback(() => setInfoModalVisiblity(false), [])

  return (
    <OrderWidgetAccordion title={t(`${tKey}.title`)}>
      <dl className={classes.list}>
        {uniqBy(
          items,
          (item) => item.oms_custom_product_key || item.item_type
        ).map((item) => (
          <React.Fragment key={item.id}>
            <Typography component="dt" variant="h3">
              {item.item_type === 'careerio'
                ? item.name
                : t(`${tKey}.items.${item.item_type}.name`)}
              {item.item_type === 'careerio' ? (
                <IconButton
                  aria-label="hint"
                  className={classes.hintButton}
                  onClick={showInfoModal}
                >
                  <img alt="hint" src={hintIcon} />
                </IconButton>
              ) : null}
            </Typography>
            <Typography component="dd" className={classes.description}>
              <Trans
                components={{
                  /* eslint-disable jsx-a11y/anchor-has-content */
                  /* eslint-disable jsx-a11y/anchor-is-valid */
                  a: <a />,
                }}
                i18nKey={`${tKey}.items.${getItemKey(item)}.description`}
              />
            </Typography>
          </React.Fragment>
        ))}
      </dl>
      {infoModalVisiblity ? <CIOInfoModal onClose={closeInfoModal} /> : null}
    </OrderWidgetAccordion>
  )
}

// In order to determine the rush speed of an order, we need to look at the
// oms_custom_product_key to determine the timing. In all other cases, we need
// to use the item_type.
const getItemKey = (item: Item) => {
  if (item.oms_custom_product_key?.includes('rush-order')) {
    return cleanCoreProductKey(item.oms_custom_product_key)
  }

  return item.item_type
}

function cleanCoreProductKey(key: string) {
  return key.replace(':', '')
}

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      margin: 0,
    },
    description: {
      marginBottom: '1.5em',
      fontSize: '0.9em',
      lineHeight: '1.85em',

      '&:last-child': {
        marginBottom: 0,
      },
    },
    hintButton: {
      margin: '0 0.25em',
      marginBottom: '0.2em',
    },
  })
)

export default OrderWidgetItemDetails
