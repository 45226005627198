import { TransactionsState } from './types'
import { Purchase } from '../../components/PurchaseHistory'
import {
  creditCardTypeFromObfuscatedNumber,
  accountNumber,
} from '../../utils/creditCard'

export const purchaseHistoryList = (state: TransactionsState): Purchase[] => {
  return state.userTransactions.map((t) => {
    return {
      date: t.created_at,
      items: t.packages
        .map((p) => `${p.name}: ${p.items.map((i) => i.name).join(', ')}`)
        .reduce(
          (result, p, i) =>
            i === 0 ? (result += `${p}`) : (result += `; ${p}`),
          ''
        ),
      paymentMethod: {
        creditCardType: creditCardTypeFromObfuscatedNumber(t.account_number),
        accountNumber: accountNumber(t),
      },
      total: {
        currency_code: t.currency_code,
        currency_symbol: t.currency_symbol,
        amount: t.amount,
      },
      status: t.status,
      id: t.id,
      brandLogo: t.brand_logo,
      brandName: t.brand_name,
    }
  })
}
