import {
  fetchOrdersForUser,
  approveOrderForUser,
  ApproveOrder,
  fetchOrderForUser,
  approveOrderLoading,
} from './actions'
import { allOrderIDs } from './selectors'
import { AppThunkAction } from 'store'
import { UserIdentifier } from 'store/user/types'
import { fetchOrderNPS } from 'store/ratings/actions'
import { fetchSchedulableItemsAndDetails } from 'store/scheduling/thunks'
import { fetchUser } from 'store/user/actions'

export const fetchOrdersAndOtherMetadataForUser =
  (userIdentifier: UserIdentifier = 'me'): AppThunkAction =>
  async (dispatch, getState) => {
    // Fetch scheduling details for items that need it
    dispatch(fetchSchedulableItemsAndDetails(userIdentifier))

    // Fetch the orders for this user
    await dispatch(fetchOrdersForUser(userIdentifier))

    // Fetch all the NPS info for the orders
    allOrderIDs(getState().orders).forEach((orderID) =>
      dispatch(fetchOrderNPS(orderID))
    )
  }

export const fetchOrderAndSchedulingDetailsForUser =
  (userIdentifier: UserIdentifier = 'me', orderID: number): AppThunkAction =>
  async (dispatch, getState) => {
    await dispatch(fetchOrderForUser(userIdentifier, orderID))
    await dispatch(fetchSchedulableItemsAndDetails(userIdentifier))
  }

export const approveOrdersForUser =
  (
    orderIDs: number[],
    userIdentifier: UserIdentifier = 'me'
  ): AppThunkAction<ApproveOrder[]> =>
  async (dispatch) => {
    dispatch(approveOrderLoading(true))
    const delay = () => {
      return new Promise((resolve) =>
        setTimeout(async function () {
          const res = await dispatch(fetchUser('me'))
          resolve(res)
        }, 3000)
      )
    }

    const response = await Promise.all(
      orderIDs.map((orderID) =>
        dispatch(approveOrderForUser(orderID, userIdentifier))
      )
    )

    await delay()
    dispatch(approveOrderLoading(false))

    return response
  }
