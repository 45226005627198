import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { format as dateFormat } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useWrapperStyles } from './Wrapper'
import ScheduleModal from './ScheduleModal'

interface Props {
  sessionTime: Date
  expertName?: string | null
  omsItemID: number
}

const tKey = 'components.Scheduler.MissedCall'

const MissedCall: React.FC<Props> = ({
  expertName,
  sessionTime,
  omsItemID,
}) => {
  const { t } = useTranslation()
  const classes = useWrapperStyles()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Typography variant="h1" className={classes.bodyHeader}>
        {t(`${tKey}.header`, {
          expertName: expertName || t('shared.yourExpert'),
          sessionTime: dateFormat(sessionTime, t(`${tKey}.dateFormat`)),
        })}
      </Typography>
      <Typography className={classes.bodyCopy}>{t(`${tKey}.copy`)}</Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.bodyBtn}
        onClick={() => setShowModal(true)}
      >
        {t(`${tKey}.reschedule`)}
      </Button>
      <ScheduleModal
        omsItemID={omsItemID}
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
    </>
  )
}

export default MissedCall
