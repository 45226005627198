import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import ProtectedRoute from 'components/Auth/ProtectedRoute'
import PATProtectedRoute from 'components/Auth/PATProtectedRoute'
import HomePage from 'pages/Home'
import MessagesPage from 'pages/Messages'
import PortfolioPage from 'pages/Portfolio'
import AccountPage from 'pages/Account'
import Article1 from 'pages/Article1'
import Article2 from 'pages/Article2'
import Article3 from 'pages/Article3'
import Article4 from 'pages/Article4'
import Article5 from 'pages/Article5'
import Login from 'pages/Auth/Login'
import Logout from 'pages/Auth/Logout'
import Reset from 'pages/Auth/Reset'
import PasswordResetCallback from 'pages/Auth/PasswordResetCallback'
import CallbackPage from 'pages/Auth/Callback'
import SignUpPage from 'pages/SignUp'
import UpgradePage from './Upgrade'
import ErrorPage from './Error'
import useBrand from 'hooks/useBrand'
import PurchaseHistoryPage from './PurchaseHistory'
import Faqs from 'pages/Faqs'
import CallPage from 'pages/Call'
import PageAccessories from 'pages/PageShell/Accessories'
import SchedulePage from './Schedule'
import UpgradeRedirect from './Redirect/UpgradeRedirect'
import ProductPage from './Upgrade/Products'

const Controller: React.FC = () => {
  const { t } = useTranslation()
  const brandName = useBrand().name
  return (
    <>
      <Helmet
        titleTemplate={t(
          `pages.PageShell.titleTemplate${brandName ? '' : 'NoBrand'}`,
          { brandName }
        )}
      />
      <BrowserRouter>
        <PageAccessories />
        <Switch>
          <ProtectedRoute exact path={t('routes.home')}>
            <HomePage />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path={[
              t('routes.messages'),
              t('routes.messagesByClient'),
              t('routes.messagesByClientAndItem'),
              t('routes.completedMessagesByClient'),
            ]}
          >
            <MessagesPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.portfolio')}>
            <PortfolioPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.upgrade')}>
            <UpgradePage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.account')}>
            <AccountPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.purchaseHistory')}>
            <PurchaseHistoryPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.faqs')}>
            <Faqs />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.article1')}>
            <Article1 />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.article2')}>
            <Article2 />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.article3')}>
            <Article3 />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.article4')}>
            <Article4 />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.article5')}>
            <Article5 />
          </ProtectedRoute>
          <PATProtectedRoute exact path={t('routes.call')}>
            <CallPage />
          </PATProtectedRoute>
          <PATProtectedRoute exact path={[t('routes.schedule')]}>
            <SchedulePage />
          </PATProtectedRoute>

          <PATProtectedRoute
            exact
            path={[t('routes.bundledUpgrades'), t('routes.productUpgrades')]}
          >
            <ProductPage />
          </PATProtectedRoute>

          <Route exact path={t('routes.login')}>
            <Login />
          </Route>
          <Route exact path={t('routes.logout')}>
            <Logout />
          </Route>
          <Route exact path={t('routes.resetPassword')}>
            <Reset />
          </Route>
          <Route exact path="/auth/callback">
            <CallbackPage />
          </Route>
          <Route exact path="/reset-password/callback/:pat">
            <PasswordResetCallback />
          </Route>
          <Route exact path="/sign-up">
            <SignUpPage />
          </Route>
          <Route exact path={t('routes.bundledUpgradesByEmail')}>
            <UpgradeRedirect />
          </Route>
          <Route>
            <ErrorPage code={404} />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default Controller
