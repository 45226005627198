import React from 'react'

const SvgDelete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    {...props}
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="#66677D" fill-rule="nonzero">
        <g>
          <path
            d="M6.655 12.986h.051c.41-.034.734-.393.7-.802l-.478-6.86c-.034-.41-.393-.717-.802-.7-.41.034-.734.393-.7.802l.478 6.86c.034.393.358.7.75.7zM9.675 12.969h.051c.393 0 .717-.308.751-.7l.478-6.86c.034-.41-.29-.768-.7-.802-.41-.017-.767.29-.802.7l-.477 6.86c-.034.426.273.785.7.802z"
            transform="translate(-1384 -107) translate(1384 107.276)"
          />
          <path
            d="M15.545 1.604h-4.351L10.392.478C10.238.273 9.999.154 9.76.154H6.62c-.256 0-.495.119-.632.324l-.802 1.126H.837c-.41 0-.752.341-.752.75 0 .41.342.752.751.752h.649L2.78 13.94c.137 1.11 1.075 1.946 2.202 1.946h6.433c1.126 0 2.065-.837 2.201-1.946l1.297-10.835h.648c.41 0 .751-.342.751-.751-.017-.41-.358-.751-.768-.751zm-3.43 12.15c-.034.358-.34.63-.7.63H4.984c-.359 0-.666-.272-.7-.63L3.003 3.106h10.392l-1.28 10.648z"
            transform="translate(-1384 -107) translate(1384 107.276)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgDelete
