import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'

import { AppState } from 'store'
import { UserBrand } from 'store/brand/types'

function useBrand() {
  const currentBrand = useSelector<AppState, Partial<UserBrand>>(
    (state) => state.brand.currentBrand || {},
    isEqual
  )

  return currentBrand
}

export default useBrand
