import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import ModalWrapper, { ModalProps } from '../Wrapper'
import matchedImage from 'assets/images/matched.svg'

const translationKey = 'components.Modals.variants.OrderUnassigned'

const OrderUnassignedModal: React.FC<ModalProps> = ({
  callToActionProps,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      callToActionProps={{
        children: t(`${translationKey}.cta`),
        ...callToActionProps,
      }}
      heroImageURL={matchedImage}
      {...props}
    >
      <Typography variant="h2">{t(`${translationKey}.title`)}</Typography>
      <Typography variant="body2">{t(`${translationKey}.copy`)}</Typography>
    </ModalWrapper>
  )
}

export default OrderUnassignedModal
